import InformationModal from './InformationModal';
import { Box } from '@mui/system';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as DownArrow } from '../../assets/images/downarrowcolored.svg';
import { ReactComponent as UpArrow } from '../../assets/images/uparrowcolored.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/infoIcon.svg';
import { ReactComponent as UnDo } from '../../assets/images/undoIcon1.svg';
import Textinput from '../../components/formlib/Textinput';
import CustomCheckBox from '../../components/formlib/CustomCheckBox';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
import IfspSignature, { Signature } from './IfspSignature';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import IfspContext from './ifspContextApi/IfspContex';
import { DateString, OptionType } from '../../utils/type';
import { Grid, autocompleteClasses } from '@mui/material';
import AutocompleteSearch from '../../components/formlib/AutocompleteSearch';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import { checkPermissionForFeature } from '../../utils/checkPermission';

interface ConsentChangeServiceDeliveryPlanProps {
  close: boolean;
  handelClose: Dispatch<SetStateAction<boolean>>;
  info: boolean;
  isIfspScreenLocked: boolean;
  selectedLanguage: string;
  closeInfo: (info: boolean) => void;
  openInfo: (info: boolean) => void;
}

interface ConsentChangeServiceDeliveryPlanDataType {
  changes: string;
  isEligibleUntilThirdBirthday: string;
  eligibleTool: OptionType;
  assessmentCompletionDate: DateString;
  reEstablishedDate: DateString;
  parentInitial: OptionType;
  isChecked: boolean;
  consentType: string;
  sessionReviewedDate: DateString;
  notifiedParent: string;
  participated: boolean;
  agreeDisagree: string;
  exceptionList: string;
  parentSignature: {
    id: string;
    isUpdated: boolean;
    signature: string;
    verbalConsent: boolean;
    date: DateString;
    partitionKey: string;
    rowKey: string;
  }[];
  consent: boolean;
  consentSignature: {
    id: string;
    isUpdated: boolean;
    signature: string;
    verbalConsent: boolean;
    date: DateString;
    partitionKey: string;
    rowKey: string;
  }[];
}

const ConsentChangeServiceDeliveryPlan = ({
  close,
  handelClose,
  closeInfo,
  info,
  openInfo,
  isIfspScreenLocked,
  selectedLanguage,
}: ConsentChangeServiceDeliveryPlanProps) => {
  const { ifsp, updateIfspContext } = useContext(IfspContext);

  const [consentForChangeforSDP, setConsentForChangeforSDP] = useState<
    ConsentChangeServiceDeliveryPlanDataType[]
  >(ifsp.ifspTeam.forms.consentForChangeForSDP);

  const [openAccordianIndex, setOpenAccordianIndex] = useState<number>(0);
  const [openAccordian, setOpenAccordian] = useState<boolean>(false);

  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);

  useEffect(() => {
    if (
      checkPermissionForFeature('backend.clients', 'editPermission') &&
      selectedLanguage === 'en'
    ) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, [selectedLanguage]);
  useEffect(() => {
    if (close) setOpenAccordian(false);
  }, [close]);
  const parentRef = useRef<HTMLDivElement>(null);

  const handleParentSignature = (
    updatedSignatures: Signature[],
    index: number,
  ) => {
    const signatureDataURI = updatedSignatures[index]?.signature;

    setConsentForChangeforSDP((prev) => {
      return prev.map((item, i) => {
        if (i === openAccordianIndex) {
          const updatedParentSignatures = [...item.parentSignature];
          updatedParentSignatures[index] = {
            id: 'p.' + index.toString(),
            isUpdated: true,
            signature: signatureDataURI,
            verbalConsent: updatedSignatures[index]?.verbalConsent,
            date: updatedSignatures[index]?.date,
            partitionKey: '',
            rowKey: '',
          };

          return {
            ...item,
            parentSignature: updatedParentSignatures,
          };
        }
        return item;
      });
    });
  };

  const handleConsentSignature = (
    updatedSignatures: Signature[],
    index: number,
  ) => {
    const signatureDataURI = updatedSignatures[index]?.signature;

    setConsentForChangeforSDP((prev) => {
      return prev.map((item, i) => {
        if (i === openAccordianIndex) {
          const updatedConsentSignatures = [...item.consentSignature];
          updatedConsentSignatures[index] = {
            id: 'c.' + index.toString(),
            isUpdated: true,
            signature: signatureDataURI,
            verbalConsent: updatedSignatures[index]?.verbalConsent,
            date: updatedSignatures[index]?.date,
            partitionKey: '',
            rowKey: '',
          };

          return {
            ...item,
            consentSignature: updatedConsentSignatures,
          };
        }
        return item;
      });
    });
  };

  useEffect(() => {
    const newConsentForChangeForSDP =
      ifsp.ifspTeam.forms.consentForChangeForSDP;
    if (newConsentForChangeForSDP !== consentForChangeforSDP) {
      setConsentForChangeforSDP(newConsentForChangeForSDP);
    }
  }, [ifsp]);

  useEffect(() => {
    updateIfspContext({
      ...ifsp,
      ifspTeam: {
        ...ifsp.ifspTeam,
        forms: {
          ...ifsp.ifspTeam.forms,
          consentForChangeForSDP: consentForChangeforSDP,
        },
      },
    });
  }, [consentForChangeforSDP]);

  const showSessionReviewedDate = (date: string | Dayjs | null) => {
    return (
      <Box
        sx={{
          display: 'flex',
          padding: '4px 8px 4px 8px',
          borderRadius: '4px',
          marginLeft: '24px',
          backgroundColor: '#97A6A5',
          alignItems: 'center',
        }}
      >
        <MediumTypography
          labelid="IFSP.UpdatedOn"
          defaultlabel="Updated On: "
          fontSize="14px"
          fontweight={400}
          textColor="#fff"
        />
        <MediumTypography
          fontSize="14px"
          fontweight={400}
          textColor="#fff"
          label={date as string}
        />
      </Box>
    );
  };

  const showAddSectionCreationUi = (mainIndex: number) => {
    return !isIfspScreenLocked &&
      !isScreenLocked &&
      mainIndex > 0 &&
      mainIndex === ifsp.ifspTeam.forms.consentForChangeForSDP.length - 1 &&
      consentForChangeforSDP[mainIndex]?.consentType === 'consent' ? (
      <Box
        component={'div'}
        className="cursorPointer flex__ align__items__center pr-md"
        onClick={() => {
          setConsentForChangeforSDP((prev) => {
            return prev.map((item, index) => {
              if (index === mainIndex) {
                return {
                  ...item,
                  consentType: 'consentForChange',
                };
              }
              return item;
            });
          });
        }}
        sx={{
          padding: '4px 8px 4px 8px',
          borderRadius: '4px',
          marginRight: '24px',
          border: '1px solid #008C82',
          backgroundColor: '#fff',
        }}
      >
        <Box sx={{ display: 'flex' }} className="pr-xs">
          <UnDo style={{ width: '14px', height: '14px' }} />
        </Box>
        <MediumTypography
          labelid="IFSP.UnDo"
          defaultlabel="Undo"
          fontSize="12px"
          fontweight={600}
        />
      </Box>
    ) : (
      !isIfspScreenLocked &&
        !isScreenLocked &&
        mainIndex > 0 &&
        mainIndex === ifsp.ifspTeam.forms.consentForChangeForSDP.length - 1 &&
        mainIndex > 0 &&
        consentForChangeforSDP[mainIndex]?.consentType ===
          'consentForChange' && (
          <Box
            className="cursorPointer"
            sx={{
              padding: '4px 8px 4px 8px',
              borderRadius: '4px',
              marginRight: '24px',
              border: '1px solid #008C82',
              backgroundColor: '#fff',
            }}
            onClick={() => {
              setConsentForChangeforSDP((prev) => {
                return prev.map((item, index) => {
                  if (index === mainIndex) {
                    return {
                      ...item,
                      consentType: 'consent',
                    };
                  }
                  return item;
                });
              });
            }}
          >
            <MediumTypography
              labelid="IFSP.ConvertToConsent"
              defaultlabel="Convert To Consent"
              fontSize="12px"
              fontweight={600}
            />
          </Box>
        )
    );
  };

  const labelValue = (
    mainIndex: number,
    consentTypeValue: string,
    consentForChnageTypeValye: string,
  ) => {
    if (
      mainIndex > 0 &&
      consentForChangeforSDP[mainIndex]?.consentType === 'consentForChange'
    ) {
      return consentForChnageTypeValye;
    } else {
      return consentTypeValue;
    }
  };

  return (
    <Box component="main">
      {info && (
        <InformationModal
          open={info}
          titleLabel="IFSP.Information"
          titleDefaultLabel="Information"
          buttonLabel="IFSP.InfoOkButton"
          buttonDefaultLabel="Ok"
          onClose={() => {
            closeInfo(info);
          }}
          info={
            "A review of the Individualized Family Service Plan (IFSP) for a child and the child's family must be conducted every six (6) months or more frequently if conditions warrant or if the family requests a meeting to review the IFSP. The purpose of the periodic review is to determine the degree to which progress toward achieving the results or outcomes identified in the IFSP is being made and/or if modifications or revisions of the results, outcomes or Early Intervention services identified in the IFSP are necessary. The review may be carried out by a meeting or by another means that is acceptable to parents and other participants."
          }
        />
      )}
      {ifsp.ifspTeam.forms.consentForChangeForSDP.map((ifspData, mainIndex) => (
        <Box
          key={mainIndex}
          component="section"
          className="p-lg gray3 borderRadius4 mb-lg"
          sx={{
            border: '1px solid #00C6B8',
          }}
        >
          <Box component="div" className="flex__justify__space-between">
            {openAccordian && mainIndex === openAccordianIndex ? (
              <Box component="div" className="flex__justify__space-between">
                <MediumTypography
                  labelid={labelValue(
                    mainIndex,
                    'IFSP.session9',
                    'IFSP.ConsentForChanges',
                  )}
                  defaultlabel={labelValue(
                    mainIndex,
                    'Cnsent',
                    'Consent For Changes',
                  )}
                  fontSize="16px"
                  fontweight={600}
                  textColor="#2A4241"
                  className="pr-md"
                />
                <InfoIcon
                  onClick={() => {
                    openInfo(info);
                  }}
                />
                {ifsp.ifspTeam.forms.consentForChangeForSDP[mainIndex]
                  .sessionReviewedDate &&
                  showSessionReviewedDate(
                    ifsp.ifspTeam.forms.consentForChangeForSDP[mainIndex]
                      .sessionReviewedDate,
                  )}
              </Box>
            ) : (
              <Box className="flex__justify__space-between">
                <MediumTypography
                  labelid={labelValue(
                    mainIndex,
                    'IFSP.session9',
                    'IFSP.ConsentForChanges',
                  )}
                  defaultlabel={labelValue(
                    mainIndex,
                    'Cnsent',
                    'Consent For Changes',
                  )}
                  fontSize="18px"
                  fontweight={600}
                  textColor="#2A4241"
                />
                {ifsp.ifspTeam.forms.consentForChangeForSDP[mainIndex]
                  .sessionReviewedDate &&
                  showSessionReviewedDate(
                    ifsp.ifspTeam.forms.consentForChangeForSDP[mainIndex]
                      .sessionReviewedDate,
                  )}
              </Box>
            )}
            {openAccordian && mainIndex === openAccordianIndex ? (
              <Box className="flex__ align__items__center">
                {showAddSectionCreationUi(mainIndex)}
                <UpArrow
                  className="cursorPointer"
                  onClick={() => {
                    setOpenAccordianIndex(openAccordianIndex);
                    setOpenAccordian(false);
                  }}
                />
              </Box>
            ) : (
              <Box className="flex__ align__items__center">
                {showAddSectionCreationUi(mainIndex)}
                <DownArrow
                  className="cursorPointer"
                  onClick={() => {
                    setOpenAccordianIndex(mainIndex);
                    setOpenAccordian(true);
                    handelClose(false);
                  }}
                />
              </Box>
            )}
          </Box>
          {openAccordian && mainIndex === openAccordianIndex && (
            <Box component="div">
              <div
                ref={parentRef}
                style={{
                  pointerEvents: isScreenLocked ? 'none' : 'auto',
                  opacity: isScreenLocked ? '0.5' : '1 ',
                }}
                onKeyDownCapture={(e) => {
                  if (isScreenLocked) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                onFocus={() => {
                  if (isScreenLocked) {
                    if (parentRef.current) parentRef.current.focus();
                  }
                }}
              >
                {mainIndex > 0 &&
                consentForChangeforSDP[mainIndex]?.consentType ===
                  'consentForChange' ? (
                  <Box>
                    <MediumTypography
                      labelid="IFSP.changesToServiceDelivery"
                      defaultlabel="Changes To Service Delivery"
                      fontSize="16px"
                      fontweight={600}
                      textColor="#2A4241"
                      className="pt-md"
                    />
                    <Textinput
                      className="pt-sm "
                      sxProps={{ width: '840px' }}
                      inputProps={{ className: 'translate', maxLength: 500 }}
                      Value={consentForChangeforSDP[openAccordianIndex].changes}
                      handlechange={(text) =>
                        setConsentForChangeforSDP((prev) => {
                          return prev.map((item, index) => {
                            if (index === openAccordianIndex) {
                              return {
                                ...item,
                                changes: text,
                              };
                            } else {
                              return item;
                            }
                          });
                        })
                      }
                      disabled={!!ifspData.sessionReviewedDate}
                    />
                  </Box>
                ) : null}
                {mainIndex === 0 ||
                consentForChangeforSDP[mainIndex]?.consentType === 'consent' ? (
                  <Box>
                    <MediumTypography
                      labelid="IFSP.Eligibility"
                      defaultlabel="Eligibility"
                      fontSize="16px"
                      fontweight={600}
                      textColor="#2A4241"
                      className="pt-md pb-md"
                    />
                    <MediumTypography
                      labelid="IFSP.ifspServicesProvidedForAsLongAs"
                      defaultlabel="IFSP services are provided for as long as a child is eligible or until the guardian revokes consent for any or all services."
                      fontweight={400}
                      textColor="#2A4241"
                    />
                    <Box component="div" className="pl-md pt-sm">
                      <CustomRadioButton
                        data={[
                          {
                            value: 'iseligible',
                            labelId: 'IFSP.IsEligible',
                            defaultLabel:
                              'Is eligible based on the eligibility evaluation and assessment completed',
                          },
                          {
                            value: 'untilnotonthirdbirthday',
                            labelId: 'IFSP.UntilButNotOnOurChildsThirdBirthday',
                            defaultLabel:
                              "Until (but not on) our child's third birthday based on a diagnosis from the DPH Diagnosed Conditions List.",
                          },
                        ]}
                        value={
                          consentForChangeforSDP[openAccordianIndex]
                            .isEligibleUntilThirdBirthday
                        }
                        onSelectItem={(value) => {
                          if (value) {
                            if (value === 'untilnotonthirdbirthday') {
                              setConsentForChangeforSDP((prev) => {
                                return prev.map((item, index) => {
                                  if (index === openAccordianIndex) {
                                    return {
                                      ...item,
                                      assessmentCompletionDate: null,
                                      isEligibleUntilThirdBirthday: value,
                                      eligibleTool: {
                                        id: '',
                                        label: '',
                                      },
                                    };
                                  }
                                  return item;
                                });
                              });
                            } else {
                              setConsentForChangeforSDP((prev) => {
                                return prev.map((item, index) => {
                                  if (index === openAccordianIndex) {
                                    return {
                                      ...item,
                                      assessmentCompletionDate: null,
                                      isEligibleUntilThirdBirthday: value,
                                    };
                                  }
                                  return item;
                                });
                              });
                            }
                          }
                        }}
                        row={false}
                        flexDirection="column"
                        paddingY="4px"
                        disable={!!ifspData.sessionReviewedDate}
                      />
                    </Box>
                    {consentForChangeforSDP[openAccordianIndex]
                      .isEligibleUntilThirdBirthday === 'iseligible' && (
                      <Box component="div">
                        <Grid container className="alignItemCenter pt-sm">
                          <Grid item>
                            <MediumTypography
                              labelid="IFSP.EligibilityEvaluation"
                              defaultlabel="Eligibility evaluation:"
                              className="pr-md"
                              fontweight={400}
                              textColor="#2A4241"
                            />
                          </Grid>
                          <Grid item>
                            <AutocompleteSearch
                              labelId={'IFSP.Tool'}
                              defaultId={'Tool'}
                              optionHintId={'noemployeeDropdownTextId'}
                              sxProps={{
                                [`& .${autocompleteClasses.popupIndicator}`]: {
                                  transform: 'none',
                                },
                                backgroundColor: '#fff',
                                width: '220px',
                              }}
                              selectedValue={
                                consentForChangeforSDP[openAccordianIndex]
                                  .eligibleTool
                              }
                              data={[
                                { id: 'bdi2', label: 'BDI' },
                                { id: 'asq:se2', label: 'ASQ-SE2' },
                                { id: 'meidp', label: 'MEIDP' },
                              ]}
                              onSelectItem={(value: OptionType | null) => {
                                if (value) {
                                  setConsentForChangeforSDP((prev) => {
                                    return prev.map((item, index) => {
                                      if (index === openAccordianIndex) {
                                        return {
                                          ...item,
                                          eligibleTool: {
                                            id: value.id,
                                            label: value.label,
                                          },
                                        };
                                      }
                                      return item;
                                    });
                                  });
                                }
                              }}
                              disableLabel={false}
                              disabled={!!ifspData.sessionReviewedDate}
                            />
                          </Grid>
                        </Grid>
                        <Grid container className="alignItemCenter pt-sm">
                          <Grid item>
                            <MediumTypography
                              labelid="IFSP.AssessmentCompletedOn"
                              defaultlabel="Assessment completed on:"
                              className="pr-md"
                              fontweight={400}
                              textColor="#2A4241"
                            />
                          </Grid>
                          <Grid item>
                            <DatePickerComponent
                              className="bg__white"
                              labelid={'Date'}
                              defaultlabelid={'Date'}
                              value={dayjs(
                                consentForChangeforSDP[openAccordianIndex]
                                  .assessmentCompletionDate,
                              )}
                              handlechange={(dateValue: Dayjs | null) => {
                                if (dateValue !== null) {
                                  const formattedDate =
                                    dayjs(dateValue).format('MM/DD/YYYY');
                                  setConsentForChangeforSDP((prev) => {
                                    return prev.map((item, index) => {
                                      if (index === openAccordianIndex) {
                                        return {
                                          ...item,
                                          assessmentCompletionDate:
                                            formattedDate,
                                        };
                                      } else {
                                        return item;
                                      }
                                    });
                                  });
                                } else {
                                  setConsentForChangeforSDP((prev) => {
                                    return prev.map((item, index) => {
                                      if (index === openAccordianIndex) {
                                        return {
                                          ...item,
                                          assessmentCompletionDate: null,
                                        };
                                      } else {
                                        return item;
                                      }
                                    });
                                  });
                                }
                              }}
                              disabledDate={!!ifspData.sessionReviewedDate}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    <Grid container className="alignItemCenter pt-sm">
                      <Grid item>
                        <MediumTypography
                          labelid="IFSP.EligibilityMustBeReestablishedOn"
                          defaultlabel="Eligibility must be re-established on:"
                          className="pr-md"
                          fontweight={400}
                          textColor="#2A4241"
                        />
                      </Grid>
                      <Grid item>
                        <DatePickerComponent
                          className="bg__white"
                          labelid={'Date'}
                          defaultlabelid={'Date'}
                          value={dayjs(
                            consentForChangeforSDP[openAccordianIndex]
                              .reEstablishedDate,
                          )}
                          handlechange={(dateValue: Dayjs | null) => {
                            if (dateValue !== null) {
                              const formattedDate =
                                dayjs(dateValue).format('MM/DD/YYYY');
                              setConsentForChangeforSDP((prev) => {
                                return prev.map((item, index) => {
                                  if (index === openAccordianIndex) {
                                    return {
                                      ...item,
                                      reEstablishedDate: formattedDate,
                                    };
                                  }
                                  return item;
                                });
                              });
                            } else {
                              setConsentForChangeforSDP((prev) => {
                                return prev.map((item, index) => {
                                  if (index === openAccordianIndex) {
                                    return {
                                      ...item,
                                      reEstablishedDate: null,
                                    };
                                  }
                                  return item;
                                });
                              });
                            }
                          }}
                          disabledDate={!!ifspData.sessionReviewedDate}
                        />
                      </Grid>
                    </Grid>
                    <MediumTypography
                      labelid="IFSP.servicesMayOccurForLessThan"
                      defaultlabel="*Services may occurforlessthan the specified period based on the child's age atthe time of the eligibility evaluation. All EIservices end one day prior to the child'sthird birthday."
                      className="pt-sm"
                      fontweight={400}
                      textColor="#2A4241"
                    />
                  </Box>
                ) : null}

                <MediumTypography
                  labelid="IFSP.familyRightsNotice"
                  defaultlabel="Family Rights Notice"
                  fontSize="16px"
                  fontweight={600}
                  textColor="#2A4241"
                  className="pt-md pb-md"
                />
                <AutocompleteSearch
                  labelId={'IFSP.familyRightsNotice'}
                  defaultId="Family Rights Notice"
                  optionHintId={'noemployeeDropdownTextId'}
                  sxProps={{
                    [`& .${autocompleteClasses.popupIndicator}`]: {
                      transform: 'none',
                    },
                    backgroundColor: '#fff',
                    width: '840px',
                  }}
                  data={ifsp.ifspTeam.contact.map((item) => ({
                    id: item.id.toString(),
                    label: item.name !== null ? item.name ?? '' : '',
                  }))}
                  onSelectItem={(value: OptionType | null) => {
                    if (value) {
                      setConsentForChangeforSDP((prev) => {
                        return prev.map((item, index) => {
                          if (index === openAccordianIndex) {
                            return {
                              ...item,
                              parentInitial: {
                                id: value.id,
                                label: value.label,
                              },
                            };
                          }
                          return item;
                        });
                      });
                    }
                  }}
                  selectedValue={
                    consentForChangeforSDP[openAccordianIndex].parentInitial
                  }
                  disableLabel={false}
                  disabled={!!ifspData.sessionReviewedDate}
                />
                <MediumTypography
                  labelid="IFSP.understandingTheConsent"
                  defaultlabel="I/We have been informed of and received a copy of our Family Rights during the IFSP development process and understand that any accepted services will be provided."
                  fontweight={400}
                  textColor="#2A4241"
                  className="pt-lg"
                />
                <MediumTypography
                  labelid="IFSP.consentForService"
                  defaultlabel="Consent For Service"
                  fontSize="16px"
                  fontweight={600}
                  textColor="#2A4241"
                  className="pt-md"
                />
                {mainIndex > 0 &&
                consentForChangeforSDP[mainIndex]?.consentType ===
                  'consentForChange' ? (
                  <Box>
                    <MediumTypography
                      labelid="IFSP.parentRights"
                      defaultlabel="Guardians have the right to provide, withhold or revoke consent for any and all services. Guardians must give written consent for any change in EI services. Consent means that you have been made aware of changes and that you agree to them. Any service for which a guardian provides consent must be provided within 30 days."
                      fontweight={400}
                      textColor="#2A4241"
                      className="pt-sm"
                    />
                  </Box>
                ) : null}
                <Box
                  component="div"
                  className="flex__ align__items__center pb-xs pt-md"
                >
                  <CustomCheckBox
                    value={
                      consentForChangeforSDP[openAccordianIndex].participated
                    }
                    onCheckBoxClick={(value) =>
                      setConsentForChangeforSDP((prev) => {
                        return prev.map((item, index) => {
                          if (index === openAccordianIndex) {
                            return {
                              ...item,
                              participated: value,
                            };
                          }
                          return item;
                        });
                      })
                    }
                    style={{ padding: 0 }}
                    disable={!!ifspData.sessionReviewedDate}
                  />
                  <MediumTypography
                    labelid="IFSP.participatedIFSP"
                    defaultlabel="I/We have participated in the development of our IFSP and:"
                    fontweight={400}
                    textColor="#2A4241"
                    className="pl-sm"
                  />
                </Box>
                <Box component="div" className="pl-lg">
                  <CustomRadioButton
                    data={[
                      {
                        value: 'agree',
                        labelId: 'IFSP.agree',
                        defaultLabel:
                          'I/We agree to to the services described in this plan.',
                      },
                      {
                        value: 'disagree',
                        labelId: 'IFSP.disagree',
                        defaultLabel: 'I/We decline IFSP services at this time',
                      },
                      {
                        value: 'agreeException',
                        labelId: 'IFSP.agreeException',
                        defaultLabel:
                          'I/We agree to the services in this plan with the following exceptions',
                      },
                    ]}
                    value={
                      consentForChangeforSDP[openAccordianIndex].agreeDisagree
                    }
                    onSelectItem={(value) => {
                      if (value) {
                        setConsentForChangeforSDP((prev) => {
                          return prev.map((item, index) => {
                            if (index === openAccordianIndex) {
                              return { ...item, agreeDisagree: value };
                            }
                            return item;
                          });
                        });
                      }
                    }}
                    row={false}
                    flexDirection="column"
                    paddingY="4px"
                    disable={!!ifspData.sessionReviewedDate}
                  />
                </Box>
                <MediumTypography
                  labelid="IFSP.exceptionList"
                  defaultlabel="List exceptions"
                  fontSize="16px"
                  fontweight={600}
                  textColor="#2A4241"
                  className="pt-md"
                />
                <Textinput
                  fieldheight="100px"
                  className="mt-sm bg__white"
                  placeHolder="Enter your comments"
                  labelid="IFSP.enterYourComments"
                  defaultlabelid="Enter your comments"
                  multiline={true}
                  sxProps={{ width: '840px' }}
                  inputProps={{
                    style: {
                      width: '795px',
                      height: '75px',
                      paddingBottom: '8px',
                      padding: '0px 0px 0px 0px',
                      textAlign: 'justify',
                    },
                    maxLength: 500,
                    className: 'translate',
                  }}
                  Value={
                    consentForChangeforSDP[openAccordianIndex].exceptionList
                  }
                  handlechange={(text) =>
                    setConsentForChangeforSDP((prev) => {
                      return prev.map((item, index) => {
                        if (index === openAccordianIndex) {
                          return { ...item, exceptionList: text };
                        }
                        return item;
                      });
                    })
                  }
                  disabled={!!ifspData.sessionReviewedDate}
                />
                <IfspSignature
                  openAccordianIndex={openAccordianIndex}
                  signatureNeed="parent"
                  onSignatureChange={handleParentSignature}
                  idIndication="p."
                  disabled={!!ifspData.sessionReviewedDate}
                />
                <MediumTypography
                  labelid="IFSP.consentToAccessInsurance"
                  defaultlabel="Consent To Access Insurance"
                  fontSize="16px"
                  fontweight={600}
                  textColor="#2A4241"
                  className="pt-md"
                />
                <Box component="div" className="flex__ align__items__center">
                  <Box component="div" className="pt-xs">
                    <CustomCheckBox
                      value={consentForChangeforSDP[openAccordianIndex].consent}
                      onCheckBoxClick={(value) =>
                        setConsentForChangeforSDP((prev) => {
                          return prev.map((item, index) => {
                            if (index === openAccordianIndex) {
                              return { ...item, consent: value };
                            }
                            return item;
                          });
                        })
                      }
                      style={{ padding: 0 }}
                      disable={!!ifspData.sessionReviewedDate}
                    />
                  </Box>
                  <MediumTypography
                    labelid="IFSP.InsuranceContent"
                    defaultlabel=" I/We consent for the EI Program to access our public and/ or private insurance for payment of Early Intervention services described in this plan."
                    fontweight={400}
                    textColor="#2A4241"
                    className="pl-sm pt-sm"
                  />
                </Box>
                <IfspSignature
                  openAccordianIndex={openAccordianIndex}
                  signatureNeed="consent"
                  onSignatureChange={handleConsentSignature}
                  idIndication="c."
                  disabled={!!ifspData.sessionReviewedDate}
                />
              </div>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default ConsentChangeServiceDeliveryPlan;
