import { ApiMessage, ApiResponseHandler } from '../../ApiResponseHandler';
import ThomApiClient from '../../ThomApiClient';
import { StatusType } from '../../../pages/activitySheet/ActivityTableRow';
import { EmployeeType } from '../../../utils/type';
import dayjs from 'dayjs';
import {
  ActivitySheetEmployeeResponse,
  ReviewActivitySheetOverView,
} from '../../../pages/activitySheet/types';

export type ApptStatus = 'Pending' | 'Submitted';

export interface Appointment {
  appointmentId: string;
  appointmentType: string;
  groupBillingStartTime: string | null;
  groupBillingDuration: string | null;
  commonAppointmentId: string;
  progressNotesId: string;
  progressNotesStatus: 'Pending' | 'Completed' | 'In Progress' | 'Canceled';
  appStartTime: string;
  appEndTime: string;
  clientId: string;
  attendeeId: string;
  clientFirstName: string;
  clientMiddleName: string;
  clientLastName: string;
  sessionDuration: string | null;
  archived: boolean;
  archivalStatus: ApptStatus | null;
  startTime: string | null;
  billingCode: string | null;
  billingModifiers: string | null;
  serviceCode: string | null;
  settingCode: string | null;
  coTreatment: string | null;
  modifier: string | null;
  description: string | null;
  isLastIndex: boolean;
}

export interface Activity {
  activitySheetId: string;
  rowId: number;
  archived: boolean;
  appointmentId: string | null;
  isHoliday: boolean | null;
  holidayTitle: string | null;
  day: string | null;
  billableHours: string | null;
  approvedNonBillableHours: string | null;
  isNonBillableUpdated: boolean | null;
  vacationHours: string | null;
  hoursOut: string | null;
  etHours: string | null;
  ltiHours: string | null;
  flexHours: string | null;
  profHours: string | null;
  otherHours: string | null;
  sickHours: string | null;
  totalHours: string | null;
  status: StatusType | null;
  actionRequired: 'YES' | 'NO' | 'NONE';
  description: string | null;
  archivalStatus: ApptStatus | null;
  appointments: Appointment[];
  groupBilling: GroupAppointment[];
}

export interface GroupAppointment {
  apptTime: string;
  appointmentId: string;
  attendeeId: string;
  appointmentType: string;
  groupBillingStartTime: string | null;
  groupBillingDuration: string | null;
  appointments: Appointment[];
}

export interface Productivity {
  weekStart: string;
  weekEnd: string;
  percent: string;
}

export interface ActivitySheet {
  totalBillableHours: string | null;
  totalNonBillableHours: string | null;
  totalApprovedNonBillableHours: string | null;
  totalWorkingHours: string | null;
  totalWorkUsage: string | null;
  totalPayHours: string | null;
  totalFlexHours: string | null;
  startDate: string | null;
  endDate: string | null;
  employeeType: EmployeeType | null;
  activities: Activity[];
  productivity: Productivity[] | null;
  status: StatusType | null;
  totalProductivity: string | null;

  totalHoursOut: string | null;
  totalEtHours: string | null;
  totalLtiHours: string | null;
  totalProfessionalHours: string | null;
  totalOtherHours: string | null;
  totalSickHours: string | null;
}

interface ActivityAppointment {
  activitySheetId: string | null;
  status: string | null;
  progressNotesIds: string[];
  groupBilling: GroupAppointment[];
}

export interface ActivitySubmitRequest {
  startDate: string;
  endDate: string;
  employeeId: string;
  activities: ActivityAppointment[];
}

export interface UpdateAppointment {
  activitySheetId: string;
  description?: string;
  totalHours?: string | null;
  approvedNonBillableHours?: string | null;
}

export const getSubmittedActivitySheet = async (
  date: string,
): Promise<ReviewActivitySheetOverView> => {
  const timezone = encodeURIComponent(dayjs(new Date(date)).format('Z'));
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ReviewActivitySheetOverView>(
      `review/charts?date=${date}&timezone=${timezone}`,
    ),
  );
};

export const getSubmittedActivitySheetEmployees = async (
  date: string,
  search: string,
  page: number,
): Promise<ActivitySheetEmployeeResponse> => {
  const timezone = encodeURIComponent(dayjs(new Date(date)).format('Z'));
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ActivitySheetEmployeeResponse>(
      `review?date=${date}&timezone=${timezone}&search=${search}&page=${page}&size=10`,
    ),
  );
};

export const getWeeklyActivitySheet = async (
  date: string,
  employeeId: string,
): Promise<ActivitySheet> => {
  const timezone = encodeURIComponent(dayjs(new Date(date)).format('Z'));
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ActivitySheet>(
      `activities?employeeId=${employeeId}&date=${date}&timezone=${timezone}&zoneName=${zoneName}`,
    ),
  );
};

export const getApprovalActivities = async (
  date: string,
  employeeId: string,
): Promise<ActivitySheet> => {
  const timezone = encodeURIComponent(dayjs(new Date(date)).format('Z'));
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ActivitySheet>(
      `approval?employeeId=${employeeId}&date=${date}&timezone=${timezone}&zoneName=${zoneName}`,
    ),
  );
};

export const submitWeeklyActivitySheet = async (
  requestBody: ActivitySubmitRequest,
): Promise<ApiMessage> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<ActivitySubmitRequest, ApiMessage>(
      `activities/submit?timezone=${timezone}&zoneName=${zoneName}`,
      requestBody,
    ),
  );
};

export const submitApprovedActivitySheet = async (
  requestBody: ActivitySubmitRequest,
): Promise<ApiMessage> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<ActivitySubmitRequest, ApiMessage>(
      `approval/submit?timezone=${timezone}&zoneName=${zoneName}`,
      requestBody,
    ),
  );
};

export const archiveAnAppointment = async (
  activitySheetId: string,
  progressNotesId: string,
  archive: boolean,
) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete(
      `activity?activitySheetId=${activitySheetId}&progressNotesId=${progressNotesId}&archive=${archive}`,
    ),
  );
};

export const updateAppointment = async (body: UpdateAppointment) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<UpdateAppointment, ApiMessage>(
      'activity',
      body,
    ),
  );
};
