import React, { FC, useEffect, useState } from 'react';
import {
  Modal,
  Grid,
  Box,
  IconButton,
  Card,
  Tooltip,
  AlertColor,
} from '@mui/material';
import dayjs from 'dayjs';
import { ReactComponent as Users } from '../../assets/images/users.svg';
import { ReactComponent as User } from '../../assets/images/User.svg';
import { ReactComponent as MapPin } from '../../assets/images/map_pin.svg';
import { ReactComponent as AlignLeft } from '../../assets/images/align_left.svg';
import { ReactComponent as Calender } from '../../assets/images/Calendar.svg';
import { ReactComponent as ProgressNote } from '../../assets/images/ProgressNoteIcon.svg';
import { ReactComponent as Edit } from '../../assets/images/highlight.svg';
import { ReactComponent as Close } from '../../assets/images/x.svg';
import { ReactComponent as DELETE } from '../../assets/images/delete.svg';
import {
  CLIENT_NAME_KEY,
  LOCAL_TIME_12_HOUR_FORMAT,
  REFERRAL_ID_KEY,
  STORAGE_USER_ID_KEY,
} from '../../services/Constant';
import { getOwnerEventDetailsById } from '../../services/schedulerEventsApi';
import { Client, SchedulerEventType } from '../../utils/type';
import DeleteSchedulerEventModal from './DeleteSchedulerEventModal';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { Employee } from '../../services/configApi/employees/employeeServices';
import { useNavigate } from 'react-router';
import { FACESHEET, PROGRESS_NOTE_SETUP } from '../../routes/Routing';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import MediumTypography from '../../components/formlib/MediumTypography';

interface ModalFieldProps {
  eventId: string;
  openViewEventModel: boolean;
  screenFrom: string;
  onClose: (
    openFromView: boolean,
    toastrVariableFromView?: AlertColor,
    toastrMessageFromView?: string,
    toastrIdFromView?: string,
    toastrDefaultMessageFromView?: string,
    deleteAppointment?: string,
  ) => void;
  showEdit: () => void;
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '410px',
  height: 'auto',
  bgcolor: 'background.paper',
  padding: '10px 20px 20px 20px',
  maxHeight: '450px',
  borderRadius: '6px',
  overflowX: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1)',
  '&::-webkit-scrollbar': {
    width: '6px',
    background: 'none',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '4px',
  },
  outline: 'none',
  fontFamily: 'Lato-Regular',
};

const SchedulerEventModal: FC<ModalFieldProps> = ({
  eventId,
  openViewEventModel,
  screenFrom,
  onClose,
  showEdit,
}) => {
  // const loggedInClinicianId = localStorage.getItem(STORAGE_USER_ID_KEY);
  const navigate = useNavigate();

  const intl = useIntl();
  const [schedulerEvent, setSchedulerEvent] = useState<SchedulerEventType>();
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [isAttendee, setIsAttendee] = useState(false);
  const [permissions, setPermissions] = useState(false);

  let appointmentTypeLabel = '';
  let appointTypeLabelColourClassName = '';
  let formattedEventStart = '';
  let formattedEventEnd = '';
  const occurrences = [];
  let ownerName = '';

  if (schedulerEvent != undefined) {
    appointmentTypeLabel = intl.formatMessage({
      id: schedulerEvent.appointmentType,
    });
    if (
      schedulerEvent.appointmentType &&
      schedulerEvent.appointmentType === 'appointmentType10'
    ) {
      appointmentTypeLabel += ' - ' + schedulerEvent.otherDescription;
    }
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (schedulerEvent.owner.id === userId) {
      formattedEventStart = dayjs(schedulerEvent.start).format(
        'dddd, MMMM DD hh:mm A',
      );
      formattedEventEnd = dayjs(schedulerEvent.end).format(
        LOCAL_TIME_12_HOUR_FORMAT,
      );
    } else {
      let userAnAttendee = false;
      schedulerEvent.attendeeList.forEach((attendee) => {
        if (attendee.attendeeId === userId) {
          userAnAttendee = true;
          formattedEventStart = dayjs(attendee.startTime).format(
            'dddd, MMMM DD hh:mm A',
          );
          formattedEventEnd = dayjs(attendee.endTime).format(
            LOCAL_TIME_12_HOUR_FORMAT,
          );
        }
        return attendee;
      });
      if (!userAnAttendee) {
        formattedEventStart = dayjs(schedulerEvent.start).format(
          'dddd, MMMM DD hh:mm A',
        );
        formattedEventEnd = dayjs(schedulerEvent.end).format(
          LOCAL_TIME_12_HOUR_FORMAT,
        );
      }
    }

    appointTypeLabelColourClassName = schedulerEvent.appointmentType + 'Color';

    if (schedulerEvent.owner != null && schedulerEvent.owner != undefined) {
      ownerName = schedulerEvent.owner?.firstName
        ? schedulerEvent.owner?.firstName
        : '';
      if (
        schedulerEvent.owner?.lastName != undefined &&
        schedulerEvent.owner?.lastName.length != 0
      ) {
        ownerName = ownerName
          .concat(' ')
          .concat(
            schedulerEvent.owner?.lastName?.substring(0, 1).toUpperCase(),
          );
      }
    }

    if (schedulerEvent.occurrences && schedulerEvent.occurrences.length > 0) {
      occurrences[0] = dayjs(schedulerEvent.occurrences[0].start).format(
        'MMM DD, YYYY',
      );
      occurrences[1] = dayjs(
        schedulerEvent.occurrences[schedulerEvent.occurrences.length - 1].start,
      ).format('MMM DD, YYYY');
    }
  }

  const showDelete = () => setOpenDeleteModel(true);
  const closeDeleteModal = (
    openFromDelete: boolean,
    toastrVariableFromDelete?: AlertColor,
    toastrMessageFromDelete?: string,
    toastrIdFromEdit?: string,
    toastrDefaultMessageFromDelete?: string,
  ) => {
    onClose(
      openFromDelete,
      toastrVariableFromDelete,
      toastrMessageFromDelete,
      toastrIdFromEdit,
      toastrDefaultMessageFromDelete,
    );
    setOpenDeleteModel(false);
  };
  const getSchedulerEventDetails = () => {
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (userId === null) {
      return;
    }
    toggleLoader(true);
    getOwnerEventDetailsById(eventId, userId)
      .then((data) => {
        toggleLoader(false);
        setPermissions(data.allowEdit);
        if (data?.owner.id === localStorage.getItem(STORAGE_USER_ID_KEY)) {
          setIsAttendee(true);
        } else if (data?.attendees != undefined) {
          data.attendees.forEach((attendee) => {
            if (attendee.id === localStorage.getItem(STORAGE_USER_ID_KEY)) {
              setIsAttendee(true);
            }
          });
        }
        setSchedulerEvent(data);
      })
      .catch((error) => {
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          onClose(
            true,
            'error',
            intl.formatMessage({
              id: apiError.id,
              defaultMessage: apiError.message,
            }),
            undefined,
            undefined,
          );
        } else {
          const errorResponse = error as ApiMessage;
          if (errorResponse.code === 404 || errorResponse.code === 13003) {
            onClose(
              true,
              'error',
              undefined,
              'apptNotFoundMessage',
              'Appointment does not exist',
              eventId,
            );
          } else {
            onClose(true, 'error', error, undefined, undefined);
          }
        }
      });
  };

  const navigateToProgressNote = () => {
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (schedulerEvent && userId !== null && schedulerEvent.attendees) {
      navigate(PROGRESS_NOTE_SETUP, {
        state: {
          eventId: schedulerEvent.commonAppointmentId,
          attendeeId: schedulerEvent.attendees,
          screenFrom,
          sortRequired: isOwnerExist(schedulerEvent.attendees),
        },
      });
    }
  };

  const isOwnerExist = (attendees: Employee[]) => {
    let enable: boolean = false;
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (userId !== null) {
      attendees.forEach((attendee) => {
        if (attendee.id === userId) {
          enable = true;
        }
      });
    }
    return enable;
  };

  const allowDelete = () => {
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (schedulerEvent && userId !== null) {
      if (userId === schedulerEvent.owner.id) {
        // He is the owner
        let canDelete: boolean = true;
        schedulerEvent.attendeeList.forEach((attendee) => {
          if (!attendee.allowUpdateDeleteActions) {
            canDelete = false;
          }
        });
        return canDelete;
      } else {
        // He is the attendee
        let canDelete: boolean = true;
        schedulerEvent.attendeeList.forEach((attendee) => {
          if (
            attendee.attendeeId === userId &&
            !attendee.allowUpdateDeleteActions
          ) {
            canDelete = false;
          }
        });
        return canDelete;
      }
    }
  };

  useEffect(() => {
    getSchedulerEventDetails();
  }, []);

  const displayFrequencyText = (datePassed: Date) => {
    if (schedulerEvent?.frequency === 'daily') {
      return 'Daily on Weekdays';
    } else if (schedulerEvent?.frequency === 'weekly' && datePassed !== null) {
      return `Weekly on ${dayjs(datePassed).format('dddd')}`;
    } else if (
      schedulerEvent?.frequency === 'biweekly' &&
      datePassed !== null
    ) {
      return `Biweekly on ${dayjs(datePassed).format('dddd')}`;
    } else if (schedulerEvent?.frequency === 'monthly' && datePassed !== null) {
      return `Monthly on ${dayjs(datePassed).format('DD')}`;
    } else if (schedulerEvent?.frequency === 'yearly' && datePassed !== null) {
      return `Yearly on ${dayjs(datePassed).format('DD MMM')}`;
    }
  };

  return (
    <>
      {schedulerEvent != undefined && openDeleteModel && (
        <DeleteSchedulerEventModal
          schedulerEvent={schedulerEvent}
          openDeleteEventModel={openDeleteModel}
          closeDeleteModal={closeDeleteModal}
        ></DeleteSchedulerEventModal>
      )}
      <Card
        sx={{
          my: '0px',
          ml: '0px',
          mt: '0px',
          opacity: 1,
          pointerEvents: 'auto',
        }}
      >
        {schedulerEvent != undefined && (
          <Modal
            open={openViewEventModel}
            onClose={() => {
              onClose(false, undefined, undefined, undefined, undefined);
            }}
          >
            <Box sx={style}>
              <Box display="flex" justifyContent="flex-end">
                {checkPermissionForFeature(
                  'backend.progress_note',
                  'viewPermission',
                ) &&
                  schedulerEvent.attendees &&
                  schedulerEvent.attendees?.length > 0 &&
                  schedulerEvent.clients &&
                  schedulerEvent.clients?.length > 0 && (
                    <>
                      <Tooltip title="Progress Note">
                        <IconButton onClick={navigateToProgressNote}>
                          <ProgressNote stroke={'#97A6A5'} />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}

                {checkPermissionForFeature(
                  'backend.appointment',
                  'editPermission',
                ) &&
                  (isAttendee || permissions) && (
                    <Tooltip title="Edit">
                      <IconButton onClick={showEdit}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  )}
                {checkPermissionForFeature(
                  'backend.appointment',
                  'deletePermission',
                ) &&
                  isAttendee &&
                  allowDelete() && (
                    <Tooltip title="Delete">
                      <IconButton onClick={showDelete}>
                        <DELETE />
                      </IconButton>
                    </Tooltip>
                  )}
                <IconButton
                  onClick={() =>
                    onClose(false, undefined, undefined, undefined, undefined)
                  }
                >
                  <Close />
                </IconButton>
              </Box>
              <h2
                className={appointTypeLabelColourClassName}
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '16px',
                  marginBottom: '0px',
                  marginTop: '5px',
                  fontFamily: 'Lato-Bold',
                }}
              >
                {appointmentTypeLabel}
              </h2>
              <b
                style={{
                  color: '#2A4241',
                  fontSize: '12px',
                  fontWeight: '600',
                  lineHeight: '16px',
                  fontFamily: 'Lato-Bold',
                }}
              >
                {' '}
                {formattedEventStart} - {formattedEventEnd}
              </b>
              <br />
              {occurrences.length > 0 && (
                <Box
                  sx={{ mt: 2 }}
                  style={{
                    color: '#97A6A5',
                    fontSize: '12px',
                    fontWeight: '600',
                    lineHeight: '16px',
                    fontFamily: 'Lato-Bold',
                  }}
                >
                  <Box>
                    Recurrence:
                    <span
                      style={{
                        color: '#2A4241',
                        fontSize: '12px',
                        fontWeight: '600',
                        lineHeight: '16px',
                        fontFamily: 'Lato-Bold',
                      }}
                    >
                      {' '}
                      {schedulerEvent.occurrences &&
                        schedulerEvent.occurrences[0].start &&
                        displayFrequencyText(
                          new Date(
                            schedulerEvent.occurrences[0].start.toString(),
                          ),
                        )}
                    </span>
                  </Box>
                  <Box>
                    From:
                    <span
                      style={{
                        color: '#2A4241',
                        fontSize: '12px',
                        fontWeight: '600',
                        lineHeight: '16px',
                        fontFamily: 'Lato-Bold',
                      }}
                    >
                      {' '}
                      {occurrences[0]}{' '}
                    </span>
                    To:
                    <span
                      style={{
                        color: '#2A4241',
                        fontSize: '12px',
                        fontWeight: '600',
                        lineHeight: '16px',
                        fontFamily: 'Lato-Bold',
                      }}
                    >
                      {' '}
                      {occurrences[1]}
                    </span>
                  </Box>
                </Box>
              )}

              <Grid container direction="row" alignItems="top" sx={{ mt: 2 }}>
                <Grid item xs={1.3} className="svgIcon">
                  <Users />
                </Grid>
                <Grid item xs={10.7} sx={{ mt: 0.5 }}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    style={{ gap: 8 }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        color: '#97A6A5',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '16px',
                      }}
                    >
                      {schedulerEvent.clients != undefined
                        ? schedulerEvent.clients.length + ' '
                        : 0}
                      <FormattedMessage
                        id="ViewSchedulerEvent.clients"
                        defaultMessage="Client(s)"
                      />
                    </Grid>
                    <Grid item>
                      {schedulerEvent.clients != undefined &&
                        schedulerEvent.clients.map((client: Client) => {
                          let clientName = '';
                          clientName = client.firstName;
                          if (
                            client.middleName != undefined &&
                            client.middleName.length != 0
                          ) {
                            clientName = clientName
                              .concat(' ')
                              .concat(
                                client.middleName.substring(0, 1).toUpperCase(),
                              );
                          }
                          clientName = clientName
                            .concat(' ')
                            .concat(client.lastName);
                          return (
                            <Grid
                              item
                              key={client.clientId}
                              style={{
                                alignSelf: 'baseline',
                                marginRight: '8px',
                                color: '#008C82',
                                wordWrap: 'break-word',
                                cursor: checkPermissionForFeature(
                                  'backend.clients',
                                  'viewPermission',
                                )
                                  ? 'pointer'
                                  : 'default',
                              }}
                            >
                              <MediumTypography
                                label={clientName}
                                sxProps={{
                                  marginTop: '4px',
                                  fontSize: '14px',
                                  lineHeight: '18px',
                                  paddingTop: '2px',
                                  paddingBottom: '2px',
                                }}
                                onClick={async () => {
                                  if (
                                    checkPermissionForFeature(
                                      'backend.clients',
                                      'viewPermission',
                                    )
                                  ) {
                                    localStorage.setItem(
                                      'ClientId',
                                      client.clientId,
                                    );
                                    localStorage.setItem(
                                      CLIENT_NAME_KEY,
                                      clientName,
                                    );
                                    localStorage.setItem(
                                      REFERRAL_ID_KEY,
                                      client.referralId !== null
                                        ? client.referralId
                                        : '',
                                    );
                                    navigate(FACESHEET);
                                  }
                                }}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="top" sx={{ mt: 2 }}>
                <Grid item xs={1.3} className="svgIcon">
                  <User />
                </Grid>
                <Grid item xs={10.7} sx={{ mt: 0.5 }}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    style={{ gap: 8 }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        color: '#97A6A5',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '16px',
                      }}
                    >
                      {schedulerEvent.attendees != undefined
                        ? schedulerEvent.attendees.length + ' '
                        : 0}
                      <FormattedMessage
                        id="ViewSchedulerEvent.clinicians"
                        defaultMessage="Attendee(s)"
                      />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'contents' }}>
                      {schedulerEvent.attendees != undefined &&
                        schedulerEvent.attendees.map((clinician: Employee) => {
                          let clinicianName = '';
                          clinicianName = clinician.firstName
                            ? clinician.firstName
                            : '';
                          if (
                            clinician.lastName != undefined &&
                            clinician.lastName.length != 0
                          ) {
                            clinicianName = clinicianName
                              .concat(' ')
                              .concat(
                                clinician.lastName
                                  .substring(0, 1)
                                  .toUpperCase(),
                              );
                          }
                          return (
                            <Grid
                              key={clinician.id}
                              style={{
                                backgroundColor: '#E7F0F0',
                                padding: '5px',
                                borderRadius: '4px',
                                marginRight: '8px',
                                color: '#2A4241',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '18px',
                                wordWrap: 'break-word',
                              }}
                            >
                              {' '}
                              {clinicianName}
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                sx={{ mt: 2 }}
                style={{ padding: '0px 0px' }}
              >
                <Grid item xs={1.3} className="svgIcon">
                  <MapPin />
                </Grid>
                <Grid
                  item
                  xs={10.7}
                  style={{
                    color: '#2A4241',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '18px',
                    wordWrap: 'break-word',
                  }}
                >
                  {' '}
                  {schedulerEvent.location != undefined &&
                  schedulerEvent.location != '' ? (
                    schedulerEvent.location
                  ) : (
                    <span
                      style={{
                        color: '#97A6A5',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '16px',
                      }}
                    >
                      <FormattedMessage
                        id="ViewSchedulerEvent.noLocationAdded"
                        defaultMessage="No Location Added"
                      />
                    </span>
                  )}{' '}
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                sx={{ mt: 2 }}
                style={{ padding: '0px 0px' }}
              >
                <Grid item xs={1.3} className="svgIcon">
                  <AlignLeft />
                </Grid>
                <Grid
                  item
                  xs={10.7}
                  style={{
                    color: '#2A4241',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '18px',
                    wordWrap: 'break-word',
                  }}
                >
                  {' '}
                  {schedulerEvent.description != undefined &&
                  schedulerEvent.description != '' ? (
                    schedulerEvent.description
                  ) : (
                    <span
                      style={{
                        color: '#97A6A5',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '16px',
                      }}
                    >
                      {' '}
                      <FormattedMessage
                        id="ViewSchedulerEvent.noDescriptionAdded"
                        defaultMessage="No Notes Added"
                      />
                    </span>
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                sx={{ mt: 2 }}
                style={{ padding: '0px 0px' }}
              >
                <Grid item xs={1.3} className="svgIcon">
                  <Calender />
                </Grid>
                <Grid
                  item
                  xs={10.7}
                  style={{
                    color: '#2A4241',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '18px',
                    wordWrap: 'break-word',
                  }}
                >
                  {' '}
                  {ownerName ? ownerName : 'To be added'}
                </Grid>
              </Grid>
            </Box>
          </Modal>
        )}
      </Card>
    </>
  );
};

export default SchedulerEventModal;
