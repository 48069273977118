import { Dayjs } from 'dayjs';
import { EventInput } from '@fullcalendar/core';
import {
  Employee,
  ProgressNoteItem,
  UpcommingEventItem,
} from '../services/configApi/employees/employeeServices';

import { ClientFile } from '../services/configApi/clientFiles/clientFilesProvider';
import { ReferralParent } from '../pages/referral/ReferralParentComponent';
import { AlertColor } from '@mui/material';
import {
  ConsentClientSignature,
  ConsentList,
  ConsentListData,
} from '../services/configApi/forms/Consent/ConsentServices';
import { IfspContextDataType } from '../pages/ifsp/ifspContextApi/IfspContex';
import { EmployeeListType } from '../services/ifspClient/IfspApi';
import { CommunicationLogResponse } from '../services/configApi/communicationLog/communication';
import { SiblingTypeResponse } from '../services/configApi/User';
import { Codes } from '../services/configApi/codelist/diagnosticCodes';
import {
  IntakeCurrentHealthInfoResponseType,
  IntakeClientInfoResponseType,
  IntakeFamilyHistoryInfoResponseType,
  IntakePregnancyInfoResponseType,
} from '../services/configApi/forms/Intake/IntakeServices';
import { TCSLookUpResponse } from '../services/configApi/tcsLookups';
import { CodeListType } from '../pages/ProgressNote/ProgressNote';
import { SimilarClient } from '../pages/referral/type';

export type AddNewClient = {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  nickName: string | null;
  nickNameWithheldIndicator: boolean;
  dateOfBirth: Dayjs | null | string;
  sex: string | null;
  race: string;
  ethnicity: string;
  multipleBirth: string;
  childBorn: string;
  childAdopted: string;
  primarySpokenLanguage: string;
  primarySpokenLanguageOther: string;
  primaryWrittenLanguage: string;
  primaryWrittenLanguageOther: string;
  languageForPriorWrittenNotice: string;
  languageForPriorWrittenNoticeOther: string;
  smokingInTheHouse: null;
  petsInTheHouse: null;
  weapons: null;
  weaponSecure: null;
  street: string;
  address2: string;
  city: string;
  zipCode: string | OptionType;
  remainingAssessmentHours: string;
  state: string;
  referralDate: DateString;
  ifspDueDate: DateString;
  mailingAddressFlag: boolean;
  mStreet: string;
  mCity: string;
  mAddress2: string;
  mState: string;
  mZipCode: string | OptionType;
  dphId: string | null;
  evaluationAddressFlag: boolean;
  eStreet: string;
  eCity: string;
  eAddress2: string;
  eState: string;
  eZipCode: string | OptionType;
  enterNote: string | null;
  clientId: string;
  clientUuid: string;
  appSource: string;
  version: number;
  referralId: string;
  profileUploadId: string | null;
  profileImageUrl: string | null;
  evaluationStatus: boolean | null;
  consentToShare: boolean;
  billingDiagnosisCode: string | null;
  similarClients: SimilarClient[] | null;
};

export type ProfilePicture = {
  id: string;
  name: string;
  url: string;
};

export type GetClientsProgramsRequestType = {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: Dayjs | null | string;
  sex: string;
};

export type ClientProgram = {
  programId: string;
  programName: string;
  clientStatus: string;
  programAcronym: string;
};

export type GetClientProgramsResponseType = {
  programDetails: ClientProgram;
};

export type AddQuestionFormRender = {
  questionName: string;
};

export type DynamicQuestion = {
  id: string;
  question: string;
  answer: string;
};

export type AddNewParent = {
  firstName: string;
  lastName: string;
  relationShipToClient: string;
  sex: string | null;
  isAddressSameAsClientAddress: boolean;
  email: string;
  dateOfBirth: Dayjs | null | string;
  isPrimary: boolean;
  isLegal: boolean;
  homePhoneNumber: string;
  workPhoneNumber: string;
  mobilePhoneNumber: string;
  street: string;
  city: string;
  zipCode: string | OptionType;
  state: string;
  isParentIndex: boolean;
  careGiverId: string;
  apartment: string;
  isEdit: boolean;
  contactId: string;
  blockedFlag: boolean;
};

export type ClientsListType = {
  id: string;
  firstName: string;
  lastName: string;
  clientId: string;
  middleName: string;
  dateOfBirth: string;
  dphId: string;
  status: string;
  ifspDueDate: string;
  remainingAssessmentHours: string;
  referralId: string;
  sno: string;
  intakeFacilitator: string;
  serviceCoordinator: string;
};
export type ClientsFilterType = {
  startDate: Dayjs | null | string;
  endDate: Dayjs | null | string;
  startAge: '';
  endAge: '';
  ifspDueStartDate: Dayjs | null | string;
  ifspDueEndDate: Dayjs | null | string;
  serviceCoordinator: string;
  assesmentStartHour: string;
  assesmentEndHour: string;
  status: string;
  ageMonthStart: string;
  ageMonthEnd: string;
  intakeFacilitator: string;
  noIntakeFacilitator: string;
  noServiceCoordinator: string;
};

export type TaskManagerFilterType = {
  dateAddedFrom: Dayjs | null | string;
  dateAddedTo: Dayjs | null | string;
  dueDateFrom: Dayjs | null | string;
  dueDateTo: Dayjs | null | string;
  client: Client[];
  origin: Employee[];
};

export type SignInType = {
  email: string;
  password: string;
};

export type LoginStateType = {
  accessToken: string;
  refreshToken: string;
  offlineToken: string;
  superProgramId: string;
  userId: string;
  email: string;
  name: string;
  refreshExpiresIn: string;
  programList: OptionType[] | null;
  defaultProgramId: string | null;
};
export interface RefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
}

export type ZipCodeType = {
  zipCode: string;
  city: string;
  cityAliasName: string;
  stateCode: string;
  stateFullName: string;
  country: string;
  region: string;
  timeZone: string;
  state: string;
};

export interface OptionType {
  id: string;
  label: string;
  color?: string;
}

export interface CommunicationLog {
  communicationLogId?: string;
  contactedBy: string;
  contactedById?: string;
  contact: string;
  contactId?: string;
  communicationType: string;
  createdOn?: string;
  reasonForContact: string;
  notes: string | null;
}

export interface Contact {
  contactId: string;
  firstName: string | null;
  lastName: string | null;
  contactType: string | null;
}

export type ResponseType = 'success' | 'error';
export type DateString = Dayjs | null | string;

export interface AddNewReferral {
  status?: string;
  savedAsDraft: boolean;
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  sex: string;
  ageInMonths: string;
  dateOfBirth: DateString;
  eligibilityDate: DateString;
  address1: string;
  address2: string;
  zipCode: string | OptionType;
  city: string;
  state: string;
  referralDate?: DateString;
  referralType: string;
  elProgram: string;
  dphId: string | null;
  clientId: string | null;
  elContactNumber: string;
  remainingAssessmentHours: string;
  ifspDueDate: DateString;
  referralBeginDate: DateString;
  referralEndDate: DateString;
  beginDate: DateString;
  rollOverDate: DateString;
  createdBy: string;
  duplicateClient: ClientProgram | null;
  similarClients: SimilarClient[];
  parents: ReferralParent[];
  referralContact: {
    referralContactDate: DateString;
    referralContactPhone: string;
    referralContactFax: string;
    referralContactEmail: string;
    referralContactType: string;
    referralContactJobTitle: string;
    referralContactName: string;
    referralSource: string;
    referralReason: string;
    isDcf: boolean;
    notes: string;
  };
  isAddressSameAsClientAddress: boolean;
  referralDiagnosisCode: string | null;
}

export type FamilyContactType = {
  otherRelationshipName: string;
  contactType: null | string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  relationship: string;
  contactTypeOthers: string;
  street: string;
  apartment: string;
  zipCode: string | OptionType;
  city: string;
  state: string;
  birthPlace: string;
  maritalStatus: string;
  education: string;
  occupation: string;
  isFullTimeEmployee: boolean | null;
  startDate: Date | null | string | Dayjs;
  endDate: Date | null | string | Dayjs;
  isActive: boolean | null;
  homeNumber: string;
  dateOfBirth: null | string | Dayjs | Date;
  sex?: string;
  contactId: string;
  isEdit: boolean;
  links: string[];
  blockedFlag: boolean;
  isPrimary: boolean;
  notes: string;
  isHouseHoldMember: boolean;
  fax: string;
};

export type ProviderSpecialistType = {
  contactType: null | string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  street: string;
  apartment: string;
  zipCode: string | OptionType;
  city: string;
  state: string;
  designation: string;
  links: string[];
  startDate: Date | null | string | Dayjs;
  endDate: Date | null | string | Dayjs;
  isActive: boolean | null;
  areaServed: string;
  homeNumber: string;
  contactId: string;
  isEdit: boolean;
  blockedFlag: boolean;
  companyOrMedicalPracticeName: string;
  isHouseHoldMember: boolean;
  notes: string;
  fax: string;
};

export type SSPContactTypes = {
  contactType: null | string;
  sspContactType: string[];
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  street: string;
  apartment: string;
  zipCode: string | OptionType;
  city: string;
  state: string;
  links: string[];
  startDate: Date | null | string | Dayjs;
  endDate: null | Dayjs | string | Date;
  isActive: boolean | null;
  homeNumber: string;
  sspService: string;
  contactId: string;
  isEdit: boolean;
  blockedFlag: boolean;
  companyOrMedicalPracticeName: string;
  isHouseHoldMember: boolean;
  notes: string;
  fax: string;
};

export type ContactsList = {
  id: number | string;
  label: string;
  type: string;
  isPrimary: boolean;
  DOB: null | string | Dayjs;
  isLegal: boolean;
  contactType: string;
  sex: string;
  homeNumber: string;
  enrollmentId: string | null;
};

export type InsuranceDetails = {
  dph3rdPartyPayer: string | null;
  insuranceCompany: string | null;
  masterInsuranceId: string | null;
  dph3rdPartyPayerName: string | null;
  dphReasonCode: string | null;
  dphAddendum: string | null;
  coverageType2: null | string;
  subscriberName: string | ContactsList | null;
  subscriberType: string;
  subscriberDateOfBirth: DateString;
  subscriberSex: string | null;
  subscriberPhoneNumber: string | null;
  subscriberMemberId: string | null;
  patientRelationToInsured: string | null;
  effectiveStartDate: DateString;
  effectiveEndDate: DateString;
  isEdit: boolean;
  medicaidInfo: string | null;
};

export type InsuranceSignature = {
  signatureData: string;
  partitionKey: string;
  rowKey: string;
  id: string;
};

export type InsuranceOtherDetails = {
  insuranceId: string;
  consentToInsurance: string | null;
  parentSignature: InsuranceSignature | null;
  parentSignatureDate: string | null;
  parentSignatureVerbalConsent: boolean;
  secondaryParentSignature: InsuranceSignature | null;
  secondaryParentSignatureDate: string | null;
  secondaryParentSignatureVerbalConsent: boolean;
  isNameInInsuranceDifferent: boolean | null;
  clientDifferentName: string;
  insuranceDetails: InsuranceDetails[];
  updatedBy: string;
  modifiedOn: string | null;
  notes: string | null;
};

export type ContactTypes = {
  otherRelationshipName: string;
  contactType: string;
  contactId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  relationship: string;
  sex: string;
  contactTypeOthers: string;
  street: string;
  apartment: string;
  zipCode: string;
  city: string;
  state: string;
  birthPlace: string;
  maritalStatus: string;
  education: string;
  occupation: string;
  isFullTimeEmployee: boolean | null;
  startDate: Date | null | string | Dayjs;
  endDate: Date | null | string | Dayjs;
  isActive: boolean | null;
  homeNumber: string;
  dateOfBirth: null;
  designation: string;
  links: string[];
  sspService: string;
  sspContactType: string[];
  areaServed: string;
  isEdit: boolean;
  insuranceCompany: string;
  organization: string;
  notes: string;
  blockedFlag: boolean;
  isPrimary: boolean;
  referralContactDate: DateString;
  referralContactType: string;
  jobTitle: string;
  fax: string;
  referralSource: string;
  referralReason: string;
  referralEndDate: Date | null | string | Dayjs;
  companyOrMedicalPracticeName: string;
  isLegal: boolean;
  isHouseHoldMember: boolean;
};
export interface SchedulerEventType extends EventInput {
  id: string;
  start: string;
  end: string;
  owner: Employee;
  description?: string;
  holidayStart?: string;
  eventType?: string;
  day?: number;
  location?: string;
  otherDescription: string | null;
  appointmentType?: string;
  attendeeList: SchedulerAttendee[];
  clients?: Client[];
  attendees?: Employee[];
  occurrences?: ScheduleOccurance[];
  commonAppointmentId: string | null;
  allowUpdateDeleteActions: boolean;
  overlap: boolean | undefined;
  display: string | undefined;
  color: string | undefined;
}

export interface EventType extends EventInput {
  blockingType: string | null;
  description: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  occurrenceDays: string[];
}

export interface SchedulerAttendee {
  attendeeId: string;
  attendeeName: string;
  appointmentId: string;
  date: string | null;
  startTime: string | null;
  endTime: string | null;
  status: 'CREATE' | 'UPDATE' | 'DELETE' | 'NONE';
  allowUpdateDeleteActions: boolean;
  unavailableStatus?: boolean;
}

export interface ScheduleFormikOccurance {
  occurrenceId: number;
  attendeeList: SchedulerAttendee[];
}

export interface SchedulerFormikType {
  location: string;
  description: string;
  otherDescription: string | null;
  appointmentType: string;
  attendeeList: SchedulerAttendee[];
  clients: string[];
  attendees: string[];
  frequency: string;
  frequencyEndDate: string | null;
  occurrences: ScheduleFormikOccurance[];
  recurringEvent: boolean;
  owner: string;
  endDateTime?: string;
}

export interface EditSchedulerEventType {
  location: string;
  otherDescription: string | null;
  description: string;
  appointmentType: string;
  attendeeList: SchedulerAttendee[];
  clients: string[];
  attendees: string[];
  owner: string;
  recurringId: string | null;
  commonAppointmentId: string | null;
}

export interface AppointmentAvailabilityCheckResponse {
  attendeeList: SchedulerAttendee[] | null;
}

export type Order = 'asc' | 'desc';

export interface ChipData {
  key: string;
  label: string;
  status: 'default' | 'success' | 'error';
}

export interface Client {
  id: number;
  clientId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  sex: string;
  dateOfBirth: string;
  allowDelete: boolean | null;
  street: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  referralId: string | null;
  weaponSecure: boolean | null;
  weapons: boolean | null;
  eStreet: string | null;
  eAddress2: string | null;
  eCity: string | null;
  eState: string | null;
  eZipCode: string | null;
}

export interface SnackbarType {
  toasterId: string | null;
  defaultToasterMessage: string;
  toasterAction: AlertColor;
}

export interface ScheduleOccurance {
  start: DateString;
  end: DateString;
}

export type InsuranceContactType = {
  contactType: null | string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  startDate: Date | null | string | Dayjs;
  endDate: Date | null | string | Dayjs;
  isActive: boolean | null;
  contactId: string;
  isEdit: boolean;
  insuranceCompany: string;
  links: string[];
  blockedFlag: boolean;
  companyOrMedicalPracticeName: string;
  isHouseHoldMember: boolean;
  notes: string;
  fax: string;
};

export type ReferralContactType = {
  contactType: null | string;
  contactId: string;
  isEdit: boolean;
  firstName: string;
  referralContactDate: null | string | Dayjs;
  jobTitle: string;
  email: string;
  fax: string;
  phoneNumber: string;
  referralSource: string;
  referralReason: string;
  startDate: Date | null | string | Dayjs;
  endDate: Date | null | string | Dayjs;
  notes: string;
  isActive: boolean | null;
  companyOrMedicalPracticeName: string;
  isHouseHoldMember: boolean;
};

export type OtherContactType = {
  contactType: null | string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  startDate: Date | null | string | Dayjs;
  endDate: Date | null | string | Dayjs;
  isActive: boolean | null;
  contactId: string;
  isEdit: boolean;
  organization: string;
  notes: string;
  links: string[];
  blockedFlag: boolean;
  companyOrMedicalPracticeName: string;
  isHouseHoldMember: boolean;
  fax: string;
};

export type LeaSchoolContactType = {
  contactType: null | string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  street: string;
  apartment: string;
  zipCode: string | OptionType;
  city: string;
  state: string;
  links: string[];
  startDate: Date | null | string | Dayjs;
  endDate: Date | null | string | Dayjs;
  isActive: boolean | null;
  homeNumber: string;
  contactId: string;
  isEdit: boolean;
  blockedFlag: boolean;
  companyOrMedicalPracticeName: string;
  isHouseHoldMember: boolean;
  notes: string;
  fax: string;
};

export type ProgramManagmentType = {
  id: number;
  programId: string;
  programName: string;
  programCode: string;
  programDirector: string;
  phoneNumber1: string;
  phoneNumber2: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  state: string;
  faxNumber1: string;
  faxNumber2: string;
  acronym: string;
};

export type ProgramType = {
  id: string;
  programId: string;
  programName: string;
  programCode: string;
  programDirector: string;
  phoneNumber1: string;
  phoneNumber2: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  zipCode: string | OptionType;
  city: string;
  state: string;
  faxNumber1: string;
  faxNumber2: string;
  acronym: string;
  isEdit: boolean;
  programImageUrl?: string;
  website?: string;
};

export type EmployeeProgram = {
  id: string;
  program: string;
  startDate: string;
  endDate: string;
  acronym: string;
};

export type EmployeeLookUpType = {
  id: string;
  kcUsername: string;
  firstName: string;
  middleName: string;
  lastName: string;
  preferredName: string;
  email: string;
  phone1: string;
  phone2: string;
  workingHoursPerWeek: string;
  employeeRole: string;
  employeeType: string;
  startDate: string | Dayjs;
  endDate: string | Dayjs;
  primaryAddress1: string;
  primaryAddress2: string;
  primaryZipcode: string;
  primaryCity: string;
  primaryState: string;
  secondaryAddress1: string;
  secondaryAddress2: string;
  secondaryZipcode: string;
  secondaryCity: string;
  secondaryState: string;
  emergencyFirstName: string;
  emergencyLastName: string;
  emergencyPhone: string;
  emergencyEmail: string;
  emergencyAddress1: string;
  emergencyAddress2: string;
  emergencyZipcode: string;
  emergencyCity: string;
  emergencyState: string;
  programs: EmployeeProgram[];
};

export interface BusinessHour {
  daysOfWeek: number[];
  startTime: string;
  endTime: string;
}

export interface BusinessHourApiResponse {
  businessHours: BusinessHour[];
}

export interface ProgressNoteContact {
  contactId: string;
  name: string;
  homePhoneNumber: string;
  isPrimary: boolean;
  type: string;
  relationshipToClient: string;
  email: string;
}

export interface ProgressNoteAttendee {
  attendeeId: string;
  licenseNo: string;
  name: string;
  firstName: string;
  lastName: string;
}

export interface ProgressNoteVisit {
  clientVisitsIndex?: number;
  coTx: boolean;
  ifspMeeting: boolean;
  asessmentHomeVisit: boolean;
  supervisionCoTxHV: boolean;
  appointmentStage: string;
  startTime: string;
  duration: string;
  serviceCode: OptionType;
  settingCode: OptionType;
}

export interface ProgressNoteForm {
  id: string;
  type: string;
  name: string;
  isDeleted: boolean;
  isDefault?: boolean;
  status: string;
  version: number;
  createdBy: string;
  createdOn: string;
  responseUploadUrl: string;
  questionnaireId: string;
  questionnaireName: string;
}

export interface QuestionnaireResponsesType {
  responseId: string;
  formId: string;
}

export interface ProgressNoteClient {
  client: {
    firstName: string;
    lastName: string;
    clientId: string;
    sex: string;
    dphId: string;
    dob: string;
  };
  clientIndex?: number;
  progressNoteId?: string;
  cancelReason?: string;
  isSelected?: boolean;
  tpc: boolean;
  activitySheetStatus?: string;
  status: string;
  appointmentDate: string;
  appointmentType: string;
  othersPresentInVisit: string;
  appointmentId: string;
  commonAppointmentId?: string;
  billingCode: string;
  actualAppointmentTime: string;
  individualHrs: string;
  coTreatmentHrs: string;
  telehealth: boolean;
  telehealthType: string;
  clientLocationType: string;
  providerLocationType: string;
  visits: ProgressNoteVisit[];
  selectedContacts: string[];
  contact: ProgressNoteContact[];
  notes: string;
  questionnaireResponses?: QuestionnaireResponsesType[];
  forms?: ResponseList[];
  signature: {
    signatureValue: string;
    partitionkey: string;
    rowkey: string;
  };
  attendeeName: string;
  attendeeLicenseNo: string;
  attendeeList?: ProgressNoteAttendee[];
}

export interface GroupBillingType {
  appointmentId: string;
  attendeeId: string;
  groupBillingDuration: string;
  groupBillingStartTime: string;
}
export interface ProgressNoteType {
  programDetails: {
    name: string;
    imageUrl: string;
    email: string;
    phoneNumber: string;
    faxNumber: string;
    addressLine1: string;
    addressLine2: string;
    website: string;
  };
  groupBilling?: GroupBillingType;
  clients: ProgressNoteClient[];
}

export interface CreateForm {
  type: string;
  name: string;
  template: Template[];
}

export interface Notification {
  deviceToken: string | null;
  refreshExpiresIn: string | null;
}

export interface FormDataResponse {
  id: string | number;
  clientId?: string;
  name: string;
  url: string;
}

export interface CreateResponse {
  id: string;
  // formId: string;
  formId: string | number | null | undefined;
  response: Template[];
}

export interface CreateFormResponse {
  responseId: string;
  formId: string;
}

export interface QuestionnaireintakeResponse {
  id: string;
  name: string | number | undefined;
  defaultResponseId: string;
  questionnaireResponseId: string;
}

export type Template = {
  type?: string;
  required?: string;
  label?: string;
  name?: string;
  access?: string;
  subtype?: string;
  userData?: [];
  id?: string;
  question?: string;
  answer?: string;
};

export interface FormResponseList {
  forms: FormList[];
  totalFormsCount: number;
}

export interface FormList {
  id: string;
  type: string;
  name: string;
  isDeleted: boolean;
  isDefault: boolean;
  status: string;
  version: number;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
  templateUrl: string;
}

export interface ResponseList {
  id: string;
  type: string;
  name: string;
  isDeleted: boolean;
  isDefault: boolean;
  version: number;
  createdBy: string;
  createdOn: string;
  responseUploadUrl: string;
  questionnaireId: string;
  questionnaireName: string;
}

export interface NewFormresponse {
  formResponse: IntakeFormresponse[];
}

export type IntakeFormresponse = {
  forms: ResponseList[];
  id: string;
  name: string;
};

export type FormTypeBuilder = {
  name: string;
};

export type EditFormTypeBuilder = {
  name: string;
};

export interface GetByIdList {
  createdBy: string;
  createdOn: string;
  id: string;
  isDefault: boolean;
  isDeleted: boolean;
  name: string;
  status: string;
  templateUrl: string;
  type: string;
  version: number;
}

export interface CheckExistFormName {
  nameExists: boolean;
}
export type EmployeeType =
  | 'Hourly Non-Exempt'
  | 'Salaried Exempt'
  | 'Salaried Non-Exempt';

export interface InsuranceType {
  insuranceDetailsId: string;
  deleted: boolean;
  insuranceCompany: string;
  dph3rdPartyPayerName: string;
  coverageType2: string;
  subscriberName: string;
  subscriberDateOfBirth: string;
  insurancePolicyId: string;
  insuranceGroup: string;
  subscriberMemberId: string;
  subscriberEmployerOrUnionName: string;
  planOrProductName: string;
  effectiveStartDate: string | null;
  effectiveEndDate: string | null;
  eligibilityStatus: string | null;
  dph3rdPartyPayer: string;
}

export type ClientJourneyType = {
  backendId: string;
  journeyName: string;
  status: 'NotStarted' | 'InProgress' | 'Completed';
};

export type ProgressNoteFacheetType = {
  attendeeId: string | null;
  status: string | null;
  appointmentDate: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
};
export interface FaceSheetType {
  demographics: FaceSheetDemographicsType;
  parents: FaceSheetParentsType[];
  communicationLogs: CommunicationLog[];
  clientFiles: ClientFile[];
  insurances: InsuranceType[];
  appointments: SchedulerEventType[];
  transition: EICSForm;
  clientJourney: ClientJourneyType[];
  enrollmentStatus: string;
  progressNotes: ProgressNoteFacheetType[];
}

export type ReferralContactJsonType = {
  id?: string;
  referralContactName: string;
  referralContactDate: DateString;
  referralContactPhone: string;
  referralContactFax: string;
  referralContactEmail: string;
  referralContactType: string;
  referralContactJobTitle: string;
  referralSource: string;
  referralReason: string;
  isDcf: boolean;
  notes: string;
};

export type FaceSheetDemographicsType = {
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  ifspDueDate: string;
  ageInMonths: string | null;
  noOfAssessmentHoursUsed: string | null;
  remainingAssessmentHours: string | null;
  dphId: string | null;
  sex: string | null;
  isSecondaryProgramServices: boolean;
  eicsConsentToAccessInsurance: number;
  consentToAccessStartDate: string | null;
  serviceCoordinator: string | null;
  serviceCoordinatorHistory: ServiceCoordinatorsType[];
  intakeCoordinator: string | null;
  intakeCoordinatorHistory: IntakeCoordinatorsType[];
  diagnosis: Codes | null;
  rollOverDate: string | null;
  beginDate: Dayjs | null | string;
  assessmentHours: string | null;
  referralDate: string | null;
  referralEndDate: string | null;
  status: string;
  dateOfBirth: string;
  referralId: string;
  referralType: string | null;
  elProgram: string | null;
  elContactNumber: string | null;
  eligibilityDate: string | null;
  povertyLevel: string | null;
  isLegal?: boolean;
  address: {
    id: string | null;
    street: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
  };
  referralContact: ReferralContactJsonType[] | [];
};
export type ServiceCoordinatorsType = {
  id: string;
  firstName: string;
  lastName: string;
  startTime?: string;
  endTime?: string;
};

export type IntakeCoordinatorsType = {
  id: string;
  firstName: string;
  lastName: string;
  startTime?: string;
  endTime?: string;
};

export interface FaceSheetParentsType {
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  relationShipToClient: string | null;
  mobilePhoneNumber: string | null;
  homePhoneNumber: string | null;
  apartment: string | null;
  street: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
}

export interface SignatureType {
  partitionkey: string;
  rowkey: string;
  id: string;
}

export interface SignatureStateType {
  signaturedata: string;
  verbalConsent: boolean;
  firstName: string;
  lastName: string;
  timestamp: string;
  userId: string;
  partitionkey: string;
  rowkey: string;
  id: string;
}

export interface EmailGetList {
  programDetails: string;
  status: string;
  subject: string;
  body: string;
  createdBy: boolean;
  createdOn: string;
  modifiedBy: number;
  modifiedOn: string;
}

export interface EmailData {
  emailType: string;
  templateType: string;
  status: string;
  subject: string;
  body: string;
}
export interface ChangeSignatureBodyType {
  signatureData: string;
  partitionKey: string;
  rowKey: string;
  employeeId: string;
}

type DateStringOrNull = Date | string | null;

export interface EICSForm {
  dateOfBirth?: string;
  transitionPlan: null | string;
  transitionPlanDate: DateStringOrNull;
  noTransitionPlanReason: string;
  leaEligible: string | null;
  leaReferralConsent: string | null;
  leaReferralConsentDate: DateStringOrNull;
  leaReferralReason: string;
  tpcDate: DateStringOrNull;
  noTPCReason: string;
  outsideTPCReason: string;
  dischargeDate: DateStringOrNull;
  dischargeReason: string;
  signature: ConsentClientSignature;
  seaStatus?: string | null;
  includeDischargeInfoInNextEMR: boolean;
  allowApprove: boolean;
  appSource: string;
  dischargeApproved: boolean;
}

export interface EICSTransitionLookupValues {
  race: DropDownList[];
  ethnicity: DropDownList[];
  language: DropDownList[];
  leaReferralReason: DropDownList[];
  tpcReason: DropDownList[];
  transitionReason: DropDownList[];
  referralReason: DropDownList[];
  referralSource: DropDownList[];
  referralDischargeReason: DropDownList[];
  eicsPovertyLevel: DropDownList[];
}

export type DropDownList = {
  id: string;
  code: string;
  description: string;
};

export interface ResourceResponseList {
  resources: ResourceList[];
  totalResourcesCount: number;
  hardDelete: boolean;
}

export interface ResourceList {
  id: string;
  uiIdentifier: string;
  name: string;
  userId: string;
  uploadedOn: string;
  uploadedBy: string;
  resourceUploadId: string;
  resourceUrl: string;
}

export interface BDI2Data {
  doe?: string;
  notToCompleteBdi?: boolean;
  notificationDate?: string;
  isEligibilitySigned?: boolean;
  isEligible?: boolean;
  isAllowed?: boolean;
  readyToUpload?: boolean;
  completed?: boolean;
  uploadToTbr?: boolean;
  id?: string;
  dateOfAssessment: Date | string | null;
  status: string;
  examiners: Examiner[];
  subDomainsResults: SubDomainsResults;
  developmentQuotientResults: DevelopmentQuotientResults;
  type?: string;
}

export interface Examiner {
  userId: string | undefined;
  role: string | undefined;
}

export interface SubDomainsResults {
  selfCareScore: string;
  personalResponsibilityScore: string;
  adultInteractionScore: string;
  peerInteractionScore: string;
  selfConceptSocialRoleScore: string;
  respectiveCommunicationScore: string;
  expressiveCommunicationScore: string;
  grossMotorScore: string;
  fineMotorScore: string;
  perceptualMotorScore: string;
  attentionMemoryScore: string;
  reasoningAcademicSkillsScore: string;
  perceptionConceptsScore: string;
}

export interface DevelopmentQuotientResults {
  adaptiveDQScore: string;
  personalSocialDQScore: string;
  communicationDQScore: string;
  motorDQScore: string;
  cognitiveDQScore: string;
}

export const employeeRoles: OptionType[] = [
  { id: 'backend.role.service_coordinator', label: 'Service Coordinator' },
  { id: 'backend.role.clinician', label: 'Clinician' },
  {
    id: 'backend.role.clinical_supervisor_or_team_leader',
    label: 'Clinical Supervisor/Team Leader',
  },
  { id: 'backend.role.intake_coordinator', label: 'Intake Facilitator' },
  { id: 'backend.role.assessment_clinician', label: 'Assessment Clinician' },
  {
    id: 'backend.role.intake_or_evaluation_scheduler',
    label: 'Intake/Evaluation Scheduler',
  },
  {
    id: 'backend.role.program_biller_or_billing',
    label: 'Program Biller/Billing',
  },
  { id: 'backend.role.eics_data_entry', label: 'EICS Data Entry' },
  {
    id: 'backend.role.insurance_or_billing_contact',
    label: 'Insurance/Billing Contact',
  },
  {
    id: 'backend.role.insurance_verification',
    label: 'Insurance Verification',
  },
  { id: 'backend.role.assistant_director', label: 'Assistant Director' },
  { id: 'backend.role.program_director', label: 'Program Director' },
  { id: 'backend.role.service_verification', label: 'Service Verification' },
  { id: 'backend.role.payroll', label: 'Payroll' },
  { id: 'backend.role.central_billing', label: 'Central Billing' },
  { id: 'backend.role.central_leadership', label: 'Central Leadership' },
  { id: 'backend.role.system_admin_or_dba', label: 'System Admin/DBA' },
  { id: 'backend.role.office_manager', label: 'Office Manager' },
];

export interface ProgramConfigurationType {
  id: string;
  dueDay: string;
  mileageRate: string;
}

export interface IntakeType {
  intakeCurrentHealth: IntakeCurrentHealthInfoResponseType;
  intakeClientInfo: IntakeClientInfoResponseType;
  intakeFamily: IntakeFamilyHistoryInfoResponseType;
  intakePregnancy: IntakePregnancyInfoResponseType;
}

export interface OfflineData {
  referralId: string;
  faceSheet: FaceSheetType;
  consentList: ConsentList;
  consents: ConsentListData[];
  defaultClientConsent?: ConsentListData;
  defaultEmployeeConsent?: ConsentListData;
  ifspDetails: IfspContextDataType;
  communicationLogResponse: CommunicationLogResponse;
  contactList?: ContactTypes;
  employeeList?: EmployeeListType;
  webClientResponse: ClientsListType;
  intakeSiblings?: SiblingTypeResponse[];
  categoryCodes?: Codes[];
  clientLookup?: TCSLookUpResponse;
  intake: IntakeType;
  isLastSync: boolean;
}

export interface ProgramReferral {
  programId: string;
  referralId: string[];
}

export interface DashboardOfflineData {
  isLastSync: boolean;
  clients: OfflineData[];
  progressNotesDetails: ProgressNoteType[];
  upcomingEvents: UpcommingEventItem[];
  progressNotes: ProgressNoteItem[];
  codes: CodeListType;
  categoryCodes: Codes[];
  intakeEmployeeNameList: unknown;
  serviceEmployeeNameList: unknown;
  clientLookup: TCSLookUpResponse;
  programImageUrl: string;
}

export interface SyncOfflineData {
  isLastSync: boolean;
  clients: OfflineData[];
  progressNotesDetails: ProgressNoteType[];
}

export interface ProviderList {
  providerId: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: string;
  email: string;
  type: string;
  organizationName: string;
  organizationId: string;
}

export interface MasterProviderType {
  providers: ProviderList[];
  providerCount: number;
}

export interface RiskFactorType {
  id?: string;
  evaluationId?: string;
  evaluationType?: string;
  client: {
    name: string;
    sex: string;
    dphId: string;
    dateOfBirth: string;
    referralBeginDate: string;
    dateOfAssessment: string;
    intakeFacilitator: string;
    serviceCoordinator: {
      id: string;
      name: string;
    };
  };
  childCharacteristics: {
    gestationalAge: string;
    childInNICU: string;
    daysInNICU: string;
    birthWeight: string;
    weightInLbs: string;
    weightInOz: string;
    weightInGrams: string;
    childHospitalized: string;
    childDiagnosedAtBirth: string;
    smallSize: string;
    childChronicFeeding: string;
    childBloodLevel: string;
    childInsecureDifficulties: string;
    childNervousSystem: string;
    childMultipleTrauma: string;
    childDiagnosis: string;
  };
  familyCharacteristics: {
    birthMotherParity: string;
    mothersEducationYear: string;
    parentalChronicIllness: string;
    socialSupport: string;
    lackingAdequate: string;
    protectiveServiceInvestigation: string;
    affectingChild: string;
    abuseAffectingChild: string;
    homelessness: string;
    homelessnessInPast: string;
    siblingWithSEN: string;
  };
  parent: {
    name: string;
    contactType: string;
    relationship: string;
    phone: string;
    email: string;
    address: string;
  };
  program?: {
    name: string;
    imageUrl: string;
    email: string;
    phoneNumber: string;
    faxNumber: string;
    addressLine1: string;
    addressLine2: string;
    website: string;
    programDirector: string;
  };
}

export const progessNotesStatusFilter: OptionType[] = [
  { id: 'pending', label: 'Pending' },
  { id: 'In Progress', label: 'In Progress' },
  { id: 'Canceled', label: 'Canceled' },
  { id: 'Completed', label: 'Completed' },
];
