import { FC, useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import EditEmployeeScreen from '../pages/employees/EditEmployeeScreen';
import EmployeeListScreen from '../pages/employees/EmployeeListScreen';
import ClientsDashboardPage from '../pages/ClientsDashboardPage';
import SubHeaderComponent from '../layouts/header/SubHeaderComponent';
import ReferralDashboard from '../pages/referral/ReferralDashboard';
import SchedulerDashboard from '../pages/scheduler/SchedulerDashboard';
import AddNewReferralDetails from '../pages/referral/AddNewReferralDetails';
import AddNewProgram from '../pages/ProgramManagmentFiles/AddNewProgram';
import ProgramManagmentTable from '../pages/ProgramManagmentFiles/ProgramManagmentTable';
import EmployeeLookUpTable from '../pages/ProgramManagmentFiles/EmployeeLookUpTable';
import AddOrEditInsurance from '../pages/insurance/AddOrEditInsurance';
import InsuranceList from '../pages/insurance/InsuranceList';
import ProfileScreen from '../pages/profile/ProfileScreen';
import EmployeeActivityDashboard from '../pages/activitySheet/EmployeeActivityDashboard';
import ASQQuestionnaire from '../pages/questionnaire/ASQQuestionnaire';
import ASQQuestionnairesInfoSummary from '../pages/questionnaire/ASQQuestionnairesInfoSummary';
import UserGroupListGrid from '../pages/userGroups/UserGroupListGrid';
import AddUserGroup from '../pages/userGroups/AddUserGroup';
import AppSidebar from '../layouts/AppSidebar';
import SearchNotFound from '../components/formlib/SearchNotFound';
import ConsentDashBoard from '../pages/consent/ConsentDashBoard';
import AddProgramSetup from '../pages/ProgramManagmentFiles/AddProgramSetup';
import FaceSheetScreen from '../pages/faceSheet/FaceSheetScreen';
import ProgressNoteSetup from '../pages/ProgressNote/ProgressNoteSetup';
import OthersFormsList from '../pages/formBuilder/OthersFormsList';
import FormBuilder from '../pages/formBuilder/FormBuilder';
import FormRender from '../pages/formBuilder/FormRender';
import EditFormBuilder from '../pages/formBuilder/EditFormBuilder';
import IFSPScreen from '../pages/ifsp/IFSPScreen';
import CodelistSetup from '../pages/codeList/CodelistSetup';
import AddCategory from '../pages/codeList/AddCategory';
import { IfspContextProvider } from '../pages/ifsp/ifspContextApi/IfspContex';
import AddServiceCode from '../pages/codeList/serviceCodes/AddServiceCode';
import AddSettingCode from '../pages/codeList/settingCodes/AddSettingCode';
import EmailAndTextTemplate from '../pages/emailAndTextTemplate/EmailAndTextTemplate';
import EICSTBRTransitionAndDischargeInformation from '../pages/EICSTBRTransitionAndDischangreInformation/EICSTBRTransitionAndDischangreInformation';

import IntakeLandingPage from '../pages/intake/IntakeLandingPage';
import ReferralToSSP from '../pages/referralToSSP/ReferralToSSP';
import ActivitySheetOverview from '../pages/activitySheet/ActivitySheetOverview';
import ApprovalActivityScreen from '../pages/activitySheet/ApprovalActivityScreen';
import OrganizationList from '../pages/sspContactMaster/OrganizationList';
import ResourceRepository from '../pages/resourceRepository/ResourceRepository';

import TravelExpenses from '../pages/travelExpenses/TravelExpenses';
import ClinicalJudgementEligibility from '../pages/eligibility/ClinicalJudgementEligibility';
import EligibilityDeterminationForm from '../pages/eligibility/EligibilityDeterminationForm';
import CaseLoadList from '../pages/codeList/CaseLoadList';
import ReviewTravelExpense from '../pages/travelExpenses/ReviewTravelExpense';
import BDI2 from '../pages/BDI2/BDI2';
import NotificationList from '../pages/notificationCenter/NotificationList';
import TaskManagerList from '../pages/taskManager/TaskManagerList';
import LEAPreschoolForm from '../pages/referral/LEAPreschoolForm';
import { LookUpContext } from '../context/LookUpContextProvider';
import EmployeeDashboard from '../pages/dashboard/EmployeeDashboard';
import { STORAGE_USER_ID_KEY } from '../services/Constant';
import LEAReferralConsent from '../pages/consent/LEAReferralConsent';
import RiskFactor from '../pages/BDI2/RiskFactor';
import EicsTBRReferralToEvaluation from '../pages/eicsTBRReferralToEvaluation/EicsTBRReferralToEvaluation';
import SSPGrid from '../pages/referralToSSP/SSPList';
import ASQDashboard from '../pages/questionnaire/ASQDashboard';
import QuestionnaireClientInfo from '../pages/questionnaire/QuestionnaireClientInfo';
import { ConsentProvider } from '../pages/consent/ConsentProvider';
import ConsentReleaseInfo from '../pages/consent/ConsentReleaseInfo';
import ProgressNoteListView from '../pages/progressNoteList/ProgressNoteListView';
export const EMPLOYEES_ROUTE_NAME = '/employees';
export const EDIT_EMPLOYEE_ROUTE_NAME = '/employees/edit';
export const ADD_EDIT_INSURANCE_ROUTE_NAME = '/master/insurance/insurance-form';
export const INSURANCE_ROUTE_NAME = '/master/insurance';
export const PROFILE_ROUTE_NAME = '/profile';
export const ACTIVITY_SHEET_ROUTE_NAME = '/setup/weekly-activity-sheet';
export const ACTIVITY_APPROVAL_SHEET_ROUTE_NAME =
  '/setup/approval-activity-sheet';
export const ACTIVITY_APPROVAL_OVERVIEW_SHEET_ROUTE_NAME =
  '/setup/activity-sheet-overview';
export const QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME = '/asq-dashboard';
export const ASQ_QUESTIONNAIRES_CLIENT_INFO =
  '/asq-dashboard/asq-questionnaire';
export const ASQ_QUESTIONNAIRES = '/asq-dashboard/asq-questionnaire/assessment';
export const QUESTIONNAIRE_CLIENT_INFO_SUMMARY =
  '/asq-dashboard/asq-questionnaire/summary';
export const CLIENTS_DASHBOARD_ROUTE_NAME = '/clients-dashboard';
export const EMPLOYEE_DASHBOARD_ROUTE_NAME = '/employee/dashboard';
export const CLINICAL_JUDGEMENT_ROUTE = '/BDI-2/clinical-judgement-eligibility';
export const CLIENTS_ROUTE_NAME = '/clients';
export const REFERRAL_DASHBOARD_ROUTE_NAME = '/referral-dashboard';
export const REFERRAL_DASHBOARD_ADD_REFERRAL_ROUTE_NAME =
  '/referral-dashboard/add-referral';
export const PROGRAM_MANAGEMENT_ADD_NEW_PROGRAM_ROUTE_NAME =
  '/program-management/add-new-program';
export const PROGRAM_MANAGEMENT_SETUP_ROUTE_NAME = '/program-management/setup';
export const PROGRAM_MANAGEMENT_ROUTE_NAME = '/program-management';
export const FORM_BUILDER_LIST_DISPLAY = '/form-repository';
export const FORM_BUILDER_RESPONSE_LIST = '/form-response';
export const RESOURCE_REPOSITORY_LIST = '/resource-repository';
export const FORM_BUILDER_ROUTE = '/form-repository/create-form';
export const EDIT_FORM_BUILDER_ROUTE = '/form-repository/edit-form';
export const FORM_RENDER_ROUTE = '/form-response/render-form';
export const PROGRAM_MANAGEMENT_EMPLOYEE_LOOKUP_ROUTE_NAME =
  '/program-management/employee-lookup';
export const PROGRESS_NOTE_LIST_ROUTE_NAME = '/progress-note-dashboard';
export const SCHEDULER_ROUTE_NAME = '/scheduler';
export const SEARCH_ROUTE_NAME = '/search';
export const REFERRAL_DASHBOARD_EDIT_REFERRAL_ROUTE_NAME =
  '/referral-dashboard/edit-referral';
export const PROGRESS_NOTE_SETUP = '/progress-note/setup';
export const PROGRESS_NOTE_CLIENT_SETUP = '/client/progress-note/setup';
export const PROGRESS_NOTE_FACESHEET = '/client/progress-note';
export const CONSENT_DASHBOARD = '/consent-dashboard';
export const CONSENT_RELEASE_INFO = '/consent-dashboard/consent-form';
export const USER_GROUPS = '/user-groups';
export const ADD_USER_GROUPS = '/user-groups/add-group';
export const HOLIDAY_LIST_ROUTE_NAME = '/setup/holidays';
export const IFSP_ROUTE_NAME = '/ifsp';
export const CODE_LIST = '/setup/code-list';
export const DIAGNOSTIC_ADD_CATEGORY = '/setup/code-list/add-category';
export const FACESHEET = '/faceSheet';
export const SERVICE_ADD_CODES = '/setup/code-list/add-service-code';
export const SETTING_ADD_CODES = '/setup/code-list/add-setting-code';
export const EMAIL_TEXT_TEMPLATE = '/email-template';
export const EICS_TBR_FORM = '/eics-tbr-Transition';
export const INTAKE = '/intake';

export const REFERRAL_TO_SSP = '/ssp-dashboard/referral-to-ssp';
export const SSP_MASTER = '/ssp-contact-list';
export const ELIGIBILITY_DETERMINATION = '/BDI-2/eligibility-determination';
export const TRAVEL_EXPENSES = '/travel-expenses';
export const REVIEW_TRAVEL_EXPENSES = '/review-travel-expenses';
export const CASE_LOAD_LIST = '/caseload-list';
export const BATELLE_DEVELOPMENT_INVENTORY_2 = '/BDI-2';
export const LEA_PRESCHOOL_FORM = '/lea-referral';

export const NOTIFICATION_CENTER = '/notification-list';
export const TASK_MANAGER = '/task-manager';
export const LEA_REFERRAL_CONSENT = '/referral-consent';
export const RISK_FACTOR = '/BDI-2/risk-factor';
export const EICS_TBR = '/eics-tbr';
export const SSP_LIST = '/ssp-dashboard';

interface RoutingPropType {
  onLogout: () => void;
}

const ApplicationRoute: FC<RoutingPropType> = ({ onLogout }) => {
  const { updateLookup } = useContext(LookUpContext);

  useEffect(() => {
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (userId !== null) {
      updateLookup();
    }
  }, []);

  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <AppSidebar
            onLogout={onLogout}
            refreshToken={localStorage.getItem('refreshToken')}
          />
        }
      >
        <Route
          path="/"
          element={<Navigate to={EMPLOYEE_DASHBOARD_ROUTE_NAME} />}
        />
        <Route
          path={EMPLOYEE_DASHBOARD_ROUTE_NAME}
          element={<EmployeeDashboard />}
        />
        <Route
          path={CLIENTS_DASHBOARD_ROUTE_NAME}
          element={<ClientsDashboardPage />}
        />
        <Route
          path={CLINICAL_JUDGEMENT_ROUTE}
          element={<ClinicalJudgementEligibility />}
        />
        <Route path={CLIENTS_ROUTE_NAME} element={<SubHeaderComponent />} />
        <Route
          path={REFERRAL_DASHBOARD_ROUTE_NAME}
          element={<ReferralDashboard />}
        />
        <Route
          path={REFERRAL_DASHBOARD_ADD_REFERRAL_ROUTE_NAME}
          element={<AddNewReferralDetails />}
        />
        <Route
          path={REFERRAL_DASHBOARD_EDIT_REFERRAL_ROUTE_NAME}
          element={<AddNewReferralDetails />}
        />
        <Route path={EMPLOYEES_ROUTE_NAME} element={<EmployeeListScreen />} />
        <Route
          path={EDIT_EMPLOYEE_ROUTE_NAME}
          element={<EditEmployeeScreen />}
        />
        <Route
          path={PROGRAM_MANAGEMENT_ADD_NEW_PROGRAM_ROUTE_NAME}
          element={<AddNewProgram />}
        />
        <Route
          path={PROGRAM_MANAGEMENT_ROUTE_NAME}
          element={<ProgramManagmentTable />}
        />
        <Route path={FORM_BUILDER_LIST_DISPLAY} element={<OthersFormsList />} />
        <Route
          path={RESOURCE_REPOSITORY_LIST}
          element={<ResourceRepository />}
        />
        <Route path={FORM_BUILDER_ROUTE} element={<FormBuilder />} />
        <Route path={EDIT_FORM_BUILDER_ROUTE} element={<EditFormBuilder />} />
        <Route path={FORM_RENDER_ROUTE} element={<FormRender />} />
        <Route
          path={PROGRAM_MANAGEMENT_EMPLOYEE_LOOKUP_ROUTE_NAME}
          element={<EmployeeLookUpTable />}
        />
        <Route path={SCHEDULER_ROUTE_NAME} element={<SchedulerDashboard />} />
        <Route
          path={PROGRESS_NOTE_LIST_ROUTE_NAME}
          element={<ProgressNoteListView />}
        />
        <Route path={INSURANCE_ROUTE_NAME} element={<InsuranceList />} />
        <Route
          path={ADD_EDIT_INSURANCE_ROUTE_NAME}
          element={<AddOrEditInsurance />}
        />
        <Route path={PROFILE_ROUTE_NAME} element={<ProfileScreen />} />
        <Route
          path={ACTIVITY_SHEET_ROUTE_NAME}
          element={<EmployeeActivityDashboard />}
        />
        <Route
          path={ACTIVITY_APPROVAL_OVERVIEW_SHEET_ROUTE_NAME}
          element={<ActivitySheetOverview />}
        />
        <Route
          path={ACTIVITY_APPROVAL_SHEET_ROUTE_NAME}
          element={<ApprovalActivityScreen />}
        />
        <Route
          path={QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME}
          element={<ASQDashboard />}
        />
        <Route
          path={ASQ_QUESTIONNAIRES_CLIENT_INFO}
          element={<QuestionnaireClientInfo />}
        />
        <Route
          path={QUESTIONNAIRE_CLIENT_INFO_SUMMARY}
          element={<ASQQuestionnairesInfoSummary />}
        />
        <Route path={ASQ_QUESTIONNAIRES} element={<ASQQuestionnaire />} />
        <Route path={PROGRESS_NOTE_SETUP} element={<ProgressNoteSetup />} />
        <Route
          path={PROGRESS_NOTE_CLIENT_SETUP}
          element={<ProgressNoteSetup />}
        />

        <Route
          path={PROGRESS_NOTE_FACESHEET}
          element={<ProgressNoteListView />}
        />
        <Route
          path={CONSENT_DASHBOARD}
          element={
            <ConsentProvider>
              <ConsentDashBoard />
            </ConsentProvider>
          }
        />
        <Route
          path={CONSENT_RELEASE_INFO}
          element={
            <ConsentProvider>
              <ConsentReleaseInfo />
            </ConsentProvider>
          }
        />
        <Route path={SEARCH_ROUTE_NAME} element={<SearchNotFound />} />
        <Route path={USER_GROUPS} element={<UserGroupListGrid />}></Route>
        <Route path={ADD_USER_GROUPS} element={<AddUserGroup />}></Route>
        <Route
          path={PROGRAM_MANAGEMENT_SETUP_ROUTE_NAME}
          element={<AddProgramSetup />}
        />
        <Route
          path={IFSP_ROUTE_NAME}
          element={
            <IfspContextProvider>
              <IFSPScreen />
            </IfspContextProvider>
          }
        />

        <Route path={CODE_LIST} element={<CodelistSetup />} />
        <Route path={DIAGNOSTIC_ADD_CATEGORY} element={<AddCategory />} />
        <Route path={SERVICE_ADD_CODES} element={<AddServiceCode />} />
        <Route path={SETTING_ADD_CODES} element={<AddSettingCode />} />

        <Route path={FACESHEET} element={<FaceSheetScreen />} />
        <Route path={EMAIL_TEXT_TEMPLATE} element={<EmailAndTextTemplate />} />
        <Route path={INTAKE} element={<IntakeLandingPage />} />
        <Route path={REFERRAL_TO_SSP} element={<ReferralToSSP />} />
        <Route path={SSP_MASTER} element={<OrganizationList />} />
        <Route
          path={ELIGIBILITY_DETERMINATION}
          element={<EligibilityDeterminationForm />}
        />
        <Route path={TRAVEL_EXPENSES} element={<TravelExpenses />} />
        <Route
          path={REVIEW_TRAVEL_EXPENSES}
          element={<ReviewTravelExpense />}
        />

        <Route path={CASE_LOAD_LIST} element={<CaseLoadList />} />
        <Route path={BATELLE_DEVELOPMENT_INVENTORY_2} element={<BDI2 />} />
        <Route path={CASE_LOAD_LIST} element={<CaseLoadList />} />
        <Route path={NOTIFICATION_CENTER} element={<NotificationList />} />
        <Route path={TASK_MANAGER} element={<TaskManagerList />} />
        <Route
          path={EICS_TBR_FORM}
          element={<EICSTBRTransitionAndDischargeInformation />}
        />
        <Route path={LEA_PRESCHOOL_FORM} element={<LEAPreschoolForm />} />
        <Route path={LEA_REFERRAL_CONSENT} element={<LEAReferralConsent />} />
        <Route path={RISK_FACTOR} element={<RiskFactor />} />
        <Route path={EICS_TBR} element={<EicsTBRReferralToEvaluation />} />
        <Route path={SSP_LIST} element={<SSPGrid />} />
      </Route>
    </Routes>
  );
};

export { ApplicationRoute };
