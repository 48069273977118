import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import TitleText from '../TitleText';
import * as yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';
import { Formik } from 'formik';
import Textinput from '../Textinput';
import { nameValidation } from '../../../utils/formValidationUtil';
import DatePickerComponent from '../DatePickerComponent';
import CommentTextbox from '../CommentTextbox';
import { useIntl } from 'react-intl';
import ButtonComponent from '../ButtonComponent';
import SmallTypography from '../SmallTypography';
import { LoaderContext, LoaderContextType } from '../../../layouts/AppSidebar';
import { ReactComponent as Check } from '../../../assets/images/checkblue.svg';
import { ReactComponent as Cross } from '../../../assets/images/cross.svg';
import { ReactComponent as Tick } from '../../../assets/images/tick.svg';
import {
  Task,
  TaskResponse,
  acceptOrRejectLeaveApi,
  addTask,
  markAsCompleted,
  updateTask,
} from '../../../services/configApi/taskManager/taskManagerServices';
import { getAllEmployeeMinimalList } from '../../../services/ifspClient/IfspApi';
import { Client } from '../../../utils/type';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../../services/ApiResponseHandler';
import { checkPermissionForFeature } from '../../../utils/checkPermission';
import MediumTypography from '../MediumTypography';
import { datePickerMinDateMaxDateValidate } from '../../../utils/dateUtil';
import { getAllClients } from '../../../services/configApi/Clients';
import MultiSelectAutocompleteSearch, {
  MultiSelectOptioinType,
} from '../MultiSelectAutocompleteSearch';
import { Employee } from '../../../services/configApi/employees/employeeServices';

interface TaskManagerModalProps {
  open: boolean;
  taskResponse: TaskResponse | null;
  handleClose: () => void;
  onSuccess: () => void;
  onMarkAsComplete: () => void;
  onError: (errorId: string, errorMessage: string) => void;
}

export type TaskManagerType = {
  id: string;
  taskName: string;
  dueDate: Dayjs | string | null;
  status: string;
  employee?: Employee[];
  client: Client[];
  description: string;
  dateAdded?: Dayjs | string | null;
  leaveType?: string;
  leaveReason?: string;
};

const getMaxDate = () => {
  return dayjs().add(3, 'year');
};

const validationSchema = yup.object().shape({
  taskName: yup.string().required('fieldShouldNotBeEmpty'),
  dueDate: yup.date().required('fieldShouldNotBeEmpty'),
  employee: yup
    .array()
    .min(1, 'fieldShouldNotBeEmpty')
    .required('fieldShouldNotBeEmpty'),
  description: yup.string().required('fieldShouldNotBeEmpty'),
});

const validateForm = (value: TaskManagerType) => {
  const errors: Partial<TaskManagerType> = {};
  if (value.dueDate) {
    if (dayjs(value.dueDate).isAfter(dayjs(getMaxDate()))) {
      errors.dueDate = 'validDate';
    }
    if (datePickerMinDateMaxDateValidate(value.dueDate)) {
      errors.dueDate = 'datePickerMinDateMaxDateValidate';
    }
  }
  return errors;
};

const TaskManagerAddModal: FC<TaskManagerModalProps> = ({
  open,
  taskResponse,
  handleClose,
  onSuccess,
  onMarkAsComplete,
  onError,
}) => {
  const rctl = useIntl();
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [clients, setClients] = useState<Client[]>([]);
  const [employeeList, setEmployeeList] = useState<Employee[]>([]);
  const [taskManagerInitialValues, setTaskManagerInitialValues] =
    useState<TaskManagerType>({
      id: '',
      taskName: '',
      dueDate: null,
      status: '',
      employee: [],
      description: '',
      client: [],
      dateAdded: null,
    });

  const formatDateLeaveRequestApprovalDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };

    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
      date,
    );

    // Adjust the format to ensure it is "MM/DD/YYYY HH:mm"
    const [month, day, year] = formattedDate.split(/[/, ]/);
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

    return `${month}/${day}/${year} ${formattedTime}`;
  };

  const acceptOrRejectLeave = (status: string) => {
    toggleLoader(true);
    if (taskResponse) {
      acceptOrRejectLeaveApi(
        taskResponse,
        status,
        formatDateLeaveRequestApprovalDate(new Date()),
      )
        .then(() => {
          toggleLoader(false);
          onSuccess();
          handleClose();
        })
        .catch((error) => {
          toggleLoader(false);
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            onError(apiError.id, apiError.message);
          } else {
            onError('failedToMarkAsComplete', 'Failed mark as complete');
          }
        });
    }
  };

  const handleMarkAsComplete = () => {
    toggleLoader(true);
    if (taskResponse) {
      markAsCompleted(taskResponse.id)
        .then(() => {
          toggleLoader(false);
          onMarkAsComplete();
          handleClose();
        })
        .catch((error) => {
          toggleLoader(false);
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            onError(apiError.id, apiError.message);
          } else {
            onError('failedToMarkAsComplete', 'Failed mark as complete');
          }
        });
    }
  };
  const getAssignedEmployees = (employee: Employee[] | undefined) => {
    return employee && employee.length > 0 ? employee.map((emp) => emp.id) : [];
  };
  const onSaveAndSubmit = (createdTask: TaskManagerType) => {
    toggleLoader(true);
    if (taskResponse) {
      const task: Task = {
        name: createdTask.taskName,
        description: createdTask.description,
        createdBy: localStorage.getItem('userId'),
        assignedToId: createdTask.employee ? createdTask.employee[0].id : '',
        status: 'backend.task_status.open',
        taskType: taskResponse?.taskType ?? 'Default',
        dueDate: createdTask.dueDate,
        type: taskResponse?.type ?? 'FewStatus',
        userType: taskResponse?.userType ?? 'Employee',
        employeeId: taskResponse?.originId ?? localStorage.getItem('userId'),
        clientId: createdTask?.client?.[0]?.clientId ?? null,
      };
      task.id = taskResponse.id;
      updateTask(task)
        .then(() => {
          toggleLoader(false);
          onSuccess();
          handleClose();
        })
        .catch((error) => {
          toggleLoader(false);
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            onError(apiError.id, apiError.message);
          } else if (error.code === 1034) {
            onError('sameTaskExist', 'Failed to create task');
          } else {
            onError('failedToCreateTask', 'Failed to create task');
          }
        });
    } else {
      const task: Task = {
        name: createdTask.taskName,
        description: createdTask.description,
        createdBy: localStorage.getItem('userId'),
        assignedToIds: getAssignedEmployees(createdTask.employee),
        status: 'backend.task_status.open',
        taskType: 'Default',
        dueDate: createdTask.dueDate,
        type: 'FewStatus',
        userType: 'Employee',
        employeeId: localStorage.getItem('userId'),
        clientId: createdTask?.client?.[0]?.clientId ?? null,
      };
      addTask(task)
        .then(() => {
          toggleLoader(false);
          onSuccess();
          handleClose();
        })
        .catch((error) => {
          toggleLoader(false);
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            onError(apiError.id, apiError.message);
          } else if (error.code === 1034) {
            onError('sameTaskExist', 'Failed to create task');
          } else {
            onError('failedToCreateTask', 'Failed to create task');
          }
        });
    }
  };

  const getClients = (employeelist: Employee[]) => {
    getAllClients(false)
      .then((response) => {
        setClients(response);
        setTaskManagerInitialValues({
          id: taskResponse ? taskResponse.id : '',
          taskName: taskResponse ? taskResponse.name : '',
          dueDate: taskResponse ? taskResponse.dueDate : null,
          status: taskResponse ? taskResponse.status : '',
          employee: taskResponse
            ? employeelist.filter((emp) => emp.id === taskResponse.assignedToId)
            : [],
          description: taskResponse ? taskResponse.description : '',
          client: taskResponse?.clientId
            ? response.filter(
                (client) => client.clientId === taskResponse.clientId,
              )
            : [],
          dateAdded: null,
        });
        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          onError(apiError.id, apiError.message);
        } else {
          const responseError = error as ApiMessage;
          onError('failedToGetEmployee', responseError.message);
        }
      });
  };

  useEffect(() => {
    toggleLoader(true);
    getAllEmployeeMinimalList()
      .then((data) => {
        let employeelist: Employee[] = [];

        employeelist = data.map((employee) => {
          return {
            id: employee.id,
            name: employee.name,
            firstName: employee.firstName,
            lastName: employee.lastName,
            email: employee.email,
            disciplineType: employee.disciplineType,

            nameSuffix: '',
            clientId: '',
            kcUsername: '',
            middleName: '',
            preferredName: '',
            phone1: employee.phone,
            phone2: '',
            workingHoursPerWeek: 0,
            employeeRole: '',
            reportingTo: '',
            employeeType: '',
            startDate: '',
            endDate: '',
            status: '',
            primaryAddress1: '',
            primaryAddress2: '',
            primaryZipcode: '',
            primaryCity: '',
            primaryState: '',
            secondaryAddressRequired: false,
            secondaryAddress1: '',
            secondaryAddress2: '',
            secondaryZipcode: '',
            secondaryCity: '',
            secondaryState: '',
            emergencyFirstName: '',
            emergencyLastName: '',
            emergencyPhone: '',
            emergencyEmail: '',

            emergencyAddress1: '',
            emergencyAddress2: '',
            emergencyZipcode: '',
            emergencyCity: '',
            emergencyState: '',
            programs: [],
            modifiedOn: '',
            allowDelete: null,
            employeeCredentials: null,
            signature: '',
            pin: '',
            phoneNumberExtension: '',
            phoneNumberExtension2: '',
            defaultProgram: '',
            superProgramId: '',
            group: {
              id: '',
              groupName: '',
            },
          };
        });

        setEmployeeList(employeelist);

        getClients(employeelist);
      })
      .catch((error) => {
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          onError(apiError.id, apiError.message);
        } else {
          onError('failedToGetEmployee', 'Failed to get employee data');
        }
      });
  }, []);

  const enableOrDisableClientsDropdown = () => {
    if (taskResponse === undefined || taskResponse === null) {
      return false;
    } else if (taskResponse?.taskType == 'Default') {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Box component="main">
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
        <DialogTitle>
          <Grid>
            {taskResponse && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Box sx={{ flex: 1, minWidth: 0, marginRight: 2 }}>
                  <TitleText
                    label={taskResponse.name}
                    Sxprops={{
                      wordBreak: 'break-all',
                      whiteSpace: 'pre-line',
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    maxWidth: '50%',
                    minWidth: 0,
                  }}
                >
                  <SmallTypography
                    labelId={'taskOrigin'}
                    sxProps={{
                      fontSize: '14px',
                      color: '#97A6A5',
                      margin: '5px',
                      height: '100%',
                    }}
                  />
                  <SmallTypography
                    labelId={taskResponse.origin}
                    sxProps={{
                      fontSize: '14px',
                      wordBreak: 'break-word',
                      whiteSpace: 'pre-line',
                      overflowWrap: 'break-word',
                      margin: '5px 0px 5px 5px',
                    }}
                  />
                </Box>
              </Box>
            )}
            {!taskResponse && (
              <TitleText labelid="addTaskText" defaultlabel="Add New Task" />
            )}
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box component="div" className="mt-sm">
            <Formik
              initialValues={taskManagerInitialValues}
              enableReinitialize={true}
              validateOnChange
              validate={validateForm}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                onSaveAndSubmit(values);
              }}
            >
              {({ values, errors, touched, setFieldValue, handleSubmit }) => (
                <Box component="div">
                  <Box
                    style={
                      taskResponse?.status ===
                        'backend.task_status.completed' ||
                      (taskResponse &&
                        taskResponse !== null &&
                        !checkPermissionForFeature(
                          'backend.task_manager',
                          'editPermission',
                        ))
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                    onKeyDownCapture={
                      taskResponse?.status ===
                        'backend.task_status.completed' ||
                      (taskResponse &&
                        taskResponse !== null &&
                        !checkPermissionForFeature(
                          'backend.task_manager',
                          'editPermission',
                        ))
                        ? (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }
                        : undefined
                    }
                  >
                    <Grid container rowSpacing={'20px'} columnSpacing={'24px'}>
                      <Grid item xs={6}>
                        <Textinput
                          name="taskName"
                          labelid="taskNameText"
                          defaultlabelid="Task Name"
                          Value={values.taskName}
                          inputProps={{ maxLength: 100 }}
                          handlechange={(text) => {
                            setFieldValue('taskName', nameValidation(text));
                          }}
                        />
                        {errors.taskName && touched.taskName && (
                          <SmallTypography
                            sxProps={{ color: 'red' }}
                            labelId={errors.taskName}
                            defaultLabelId="Field should not be empty"
                          />
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <DatePickerComponent
                          name={`dueDate`}
                          labelid="dueDateText"
                          disabledDate={enableOrDisableClientsDropdown()}
                          defaultlabelid="Due Date"
                          maxDate={getMaxDate()}
                          disableFuture={false}
                          handlechange={(date: Dayjs | null) => {
                            const formattedDate =
                              dayjs(date).format('MM/DD/YYYY');
                            if (date === null) {
                              setFieldValue('dueDate', null);
                            } else {
                              setFieldValue('dueDate', formattedDate);
                            }
                          }}
                          value={dayjs(values.dueDate)}
                        />
                        {errors.dueDate && touched.dueDate && (
                          <SmallTypography
                            sxProps={{ color: 'red' }}
                            labelId={errors.dueDate}
                            defaultLabelId="Field should not be empty"
                          />
                        )}
                      </Grid>
                      <Grid item xs={6} className="multisearchHgt">
                        <MultiSelectAutocompleteSearch
                          labelId="employeeText"
                          defaultId="Employee"
                          onSelectItem={(value: MultiSelectOptioinType[]) => {
                            if (value) {
                              if (taskResponse && value.length > 0) {
                                setFieldValue('employee', [
                                  value[value.length - 1],
                                ]);
                              } else {
                                setFieldValue('employee', value);
                              }
                            }
                          }}
                          value={values.employee ? values.employee : []}
                          highlightSelectedValues={[]}
                          highlightSelectedValuesTooltipLabelId=""
                          data={employeeList}
                          hideOption={null}
                          optionHintId={
                            'AddNewSchedulerEvent.noCliniciansFoundInOptions'
                          }
                        />
                        {errors.employee && touched.employee && (
                          <SmallTypography
                            sxProps={{ color: 'red' }}
                            labelId={errors.employee}
                            defaultLabelId="Field should not be empty"
                          />
                        )}
                      </Grid>
                      <Grid item xs={6} className="multisearchHgt">
                        <MultiSelectAutocompleteSearch
                          labelId="clientText"
                          defaultId="Client"
                          value={values.client}
                          onSelectItem={(value: MultiSelectOptioinType[]) => {
                            if (value.length === 0) {
                              setFieldValue('client', []);
                            } else {
                              setFieldValue('client', [
                                value[value.length - 1],
                              ]);
                            }
                          }}
                          data={clients}
                          optionHintId={
                            'AddNewSchedulerEvent.noClientsFoundInOptions'
                          }
                        />
                      </Grid>
                      {taskResponse &&
                        taskResponse.taskType === 'Time Off Request' && (
                          <>
                            <Grid item xs={6}>
                              <Textinput
                                name="leaveType"
                                labelid="taskManagerLeaveType"
                                defaultlabelid="Leave Type"
                                disabled={true}
                                Value={taskResponse.leaveType}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Textinput
                                name="reason"
                                labelid="taskManagerReason"
                                defaultlabelid="Reason"
                                disabled={true}
                                Value={taskResponse.leaveReason}
                              />
                            </Grid>
                          </>
                        )}
                      <Grid item xs={12}>
                        <CommentTextbox
                          maxLength={200}
                          rows={2}
                          Value={values.description}
                          placeholder={rctl.formatMessage({
                            id: 'description',
                          })}
                          handlechange={(value) =>
                            setFieldValue('description', value)
                          }
                        />
                        {errors.description && touched.description && (
                          <SmallTypography
                            sxProps={{ color: 'red' }}
                            labelId={errors.description}
                            defaultLabelId="Field should not be empty"
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid
                    className="pt-md pb-xs"
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {taskResponse &&
                    taskResponse.taskType !== 'Time Off Request' &&
                    taskResponse.status !== 'backend.task_status.completed' ? (
                      <Grid item xs={6} container alignItems="center">
                        <Check
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={handleMarkAsComplete}
                        />
                        <Box onClick={handleMarkAsComplete}>
                          <SmallTypography
                            sxProps={{
                              color: 'rgba(0, 140, 130, 1)',
                              cursor: 'pointer',
                            }}
                            labelId={'markAsCompleted'}
                            defaultLabelId="Mark as completed"
                          />
                        </Box>
                      </Grid>
                    ) : (
                      <MediumTypography label=" " />
                    )}
                    {taskResponse &&
                    taskResponse.taskType === 'Time Off Request' &&
                    taskResponse.status !== 'backend.task_status.completed' &&
                    checkPermissionForFeature(
                      'backend.task_manager',
                      'editPermission',
                    ) ? (
                      <Grid item xs={6} container alignItems="center">
                        <Tick
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => acceptOrRejectLeave('Approved')}
                        />
                        <Box onClick={() => acceptOrRejectLeave('Approved')}>
                          <SmallTypography
                            sxProps={{
                              cursor: 'pointer',
                            }}
                            labelId={'accept'}
                            defaultLabelId="Accept"
                          />
                        </Box>
                        <Cross
                          style={{
                            cursor: 'pointer',
                            marginLeft: '10px',
                          }}
                          onClick={() => acceptOrRejectLeave('Rejected')}
                        />
                        <Box onClick={() => acceptOrRejectLeave('Rejected')}>
                          <SmallTypography
                            sxProps={{
                              cursor: 'pointer',
                            }}
                            labelId={'reject'}
                            defaultLabelId="Reject"
                          />
                        </Box>
                      </Grid>
                    ) : (
                      <MediumTypography label=" " />
                    )}
                    {(!taskResponse ||
                      taskResponse.status ===
                        'backend.task_status.completed') && (
                      <Grid item xs={6} container alignItems="center" />
                    )}
                    <Grid item xs={6} container justifyContent="flex-end">
                      <Grid container justifyContent="flex-end">
                        <Box>
                          <ButtonComponent
                            className="btn-primary btn-cancel"
                            variantType="contained"
                            labelId="Contacts.cancelbtn"
                            defaultLabelId="Cancel"
                            onClick={handleClose}
                          />
                          {((checkPermissionForFeature(
                            'backend.task_manager',
                            'editPermission',
                          ) &&
                            taskResponse &&
                            taskResponse !== null &&
                            taskResponse.status !==
                              'backend.task_status.completed') ||
                            (!taskResponse &&
                              checkPermissionForFeature(
                                'backend.task_manager',
                                'addPermission',
                              ))) && (
                            <ButtonComponent
                              className="btn-primary btn-submit ml-md"
                              variantType="contained"
                              type="submit"
                              labelId="Insurance.submit"
                              defaultLabelId="Save & Submit"
                              onClick={handleSubmit}
                            />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default TaskManagerAddModal;
