import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as Expandlesssvg } from '../assets/images/ExpandLess.svg';
import { ReactComponent as Expandmoresvg } from '../assets/images/ExpandMore.svg';
import { SubMenu } from '../layouts/Sidebar';
import CustomListButton from './formlib/CustomListButton';
import { useLocation } from 'react-router';
import { ReactComponent as Settingssvg } from '../assets/images/settings.svg';
import { ReactComponent as Preferencesvg } from '../assets/images/sliders.svg';

export const getNestedMenuIcons = (type: string) => {
  switch (type) {
    case 'setup':
      return <Settingssvg />;
    case 'preference':
      return <Preferencesvg />;
  }
};

interface SubMenuProps {
  headerName: string;
  defaultLabel: string;
  subMenus: SubMenu[];
  parentExpand: boolean;
  leftIcon: string;
}
export const SidebarSubMenu: FC<SubMenuProps> = ({
  headerName,
  defaultLabel,
  subMenus,
  parentExpand,
  leftIcon,
}) => {
  const [opendropdown, setOpendropdown] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    subMenus.map((item: SubMenu) => {
      if (pathname.includes(item.to)) {
        setOpendropdown(true);
      }
    });
  }, []);

  return (
    <>
      <List
        style={{
          backgroundColor: '#163332',
          color: '#fff',
          paddingBottom: '0px',
          paddingTop: '0px',
        }}
      >
        <ListItem
          key={headerName}
          disablePadding
          sx={{ display: 'block' }}
          className="sidebarList"
        >
          <ListItemButton
            sx={{
              minHeight: 32,
              justifyContent: parentExpand ? 'initial' : 'center',
              paddingLeft: '0px',
              paddingRight: '0px',
            }}
            onClick={() => {
              setOpendropdown(!opendropdown);
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
              }}
            >
              <Box
                style={{ display: parentExpand ? 'none' : 'block' }}
                className="ml-xs"
              >
                {getNestedMenuIcons(leftIcon)}
              </Box>
            </ListItemIcon>
            <ListItemText
              className="sideListItemText"
              sx={{
                display: parentExpand ? 'block' : 'none',
                marginTop: '0px',
                marginBottom: '0px',
              }}
            >
              <CustomListButton
                to={pathname}
                leftIcon={getNestedMenuIcons(leftIcon)}
                labelId={headerName}
                defaultMessage={defaultLabel}
                selected={false}
                onClick={() => {
                  setOpendropdown(!opendropdown);
                }}
              />
            </ListItemText>

            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: parentExpand ? 1 : 'auto',
                justifyContent: 'center',
              }}
            >
              {parentExpand &&
                (opendropdown ? <Expandlesssvg /> : <Expandmoresvg />)}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </List>

      <Collapse
        in={opendropdown}
        timeout="auto"
        unmountOnExit
        sx={{ display: parentExpand ? 'block' : 'none' }}
      >
        {subMenus.map((item: SubMenu) => (
          <ListItem
            key={item.id}
            disablePadding
            sx={{ display: 'block', margin: '0px 0px 0px 30px', width: 'auto' }}
            className="sidebarList"
          >
            <ListItemText
              className="textIconSidebar"
              sx={{
                minWidth: 0,
                mr: parentExpand ? 3 : 'auto',
                justifyContent: 'center',
                color: '#97A6A5',
              }}
            >
              <CustomListButton
                to={item.to}
                leftIcon={getNestedMenuIcons(item.leftIcon)}
                labelId={item.labelId}
                selected={pathname.includes(item.to)}
                onClick={() => {
                  sessionStorage.clear();
                  localStorage.removeItem('referralId');
                  localStorage.removeItem('defaultReferralId');
                  localStorage.removeItem('clientReferralDetails');
                  localStorage.removeItem('Demographics');
                }}
              />
            </ListItemText>
          </ListItem>
        ))}
      </Collapse>
    </>
  );
};

export default SidebarSubMenu;
