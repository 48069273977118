import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import TitleText from '../../components/formlib/TitleText';
import ClientInfoFaceSheet from './ClientInfoFaceSheet';
import CommunicationFaceSheet from './CommunicationFaceSheet';
import ClientFilesFaceSheet from './ClientFilesFaceSheet';
import InsuranceFaceSheet from './InsuranceFaceSheet';
import UpcomingEventsFaceSheet from './UpcomingEventsFaceSheet';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import { useNavigate } from 'react-router';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import {
  ReferralResponseType,
  deleteSession,
  getAllReferrals,
  getFacesheetDetails,
  getListByRole,
  syncOfflineData,
  updateFacesheetDetails,
} from '../../services/configApi/Clients';
import {
  DateString,
  FaceSheetType,
  IntakeCoordinatorsType,
  OptionType,
  ReferralContactJsonType,
  ResourceRepoDTO,
  ServiceCoordinatorsType,
} from '../../utils/type';
import {
  AlertColor,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  autocompleteClasses,
} from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as Close } from '../../assets/images/x.svg';
import { ReactComponent as Document } from '../../assets/images/documentUpload.svg';
import moment from 'moment';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import AutocompleteSearch from '../../components/formlib/AutocompleteSearch';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import { useIntl } from 'react-intl';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { Formik, FormikErrors, FormikProps } from 'formik';
import * as yup from 'yup';
import DurationPicker from '../../components/formlib/DurationPicker';
import {
  formatStringDateInDecimals,
  convertTimeDuration,
  formatTimeDurationInDecimals,
  datePickerMinDateMaxDateValidate,
} from '../../utils/dateUtil';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import { CLIENTS_DASHBOARD_ROUTE_NAME } from '../../routes/Routing';
import ClientJourneyWrapper from './ClientJourneyWrapper';
import { createclientsyncobj, deleteData } from '../../services/Offline';
import TransitionFaceSheet from './TransitionFaceSheet';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import {
  EMAIL_REGEX,
  PREVIEW_DOCUMENT_EXTENSIONS,
  REFERRAL_ID_KEY,
} from '../../services/Constant';
import { getResponseDB } from '../../services/storageDB';
import { LookUpContext } from '../../context/LookUpContextProvider';

import { ReactComponent as DownloadIcon } from '../../assets/images/offlineDownload.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/offlineDelete.svg';
import { ReactComponent as SyncIcon } from '../../assets/images/offlineSync.svg';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
import CustomRadioButton, {
  RadioButtonData,
} from '../../components/formlib/CustomRadioButton';
import _ from 'lodash';
import { formatName } from '../../utils/nameUtils';
import {
  DiagnosisCode,
  getCodesAndDescriptionNew,
} from '../../services/configApi/codelist/diagnosticCodes';
import ProgressNotesFaceSheet from './ProgressNotesFaceSheet';
import { referralTypeOptions } from '../referral/AddNewReferralDetails';
import PovertyJsonData from '../intake/IntakeJSON/FamilyHistory/Poverty.json';
import Textinput from '../../components/formlib/Textinput';
import {
  formatPhoneNumber,
  nameValidation,
} from '../../utils/formValidationUtil';
import CustomCheckBox from '../../components/formlib/CustomCheckBox';
import CommentTextbox from '../../components/formlib/CommentTextbox';
import { ReactComponent as DeleteIconBtn } from '../../assets/images/deleteAlertIcon.svg';
import TextWithIcon from '../../components/formlib/TextWithIcon';
import ModalPopup from '../../components/formlib/ModalPopup';
import { extractFileExtension } from '../../utils/fileUtils';
import FilePreviewDialog from '../../components/formlib/modal/FilePreviewDialog';

const cardStyle = { background: 'white', borderRadius: '6px', padding: '16px' };

export interface DiagnosisOptionType {
  id: string;
  label: string;
  isDeleted: boolean;
}

export interface FaceSheetFormType {
  intakeCoordinator: string | null;
  serviceCoordinator: string | null;
  referralDiagnosisCode: string | null;
  beginDate: DateString;
  rollOverDate: string | null;
  assessmentHours: string | null;
  noOfAssessmentHoursUsed: string | null;
  isSecondaryProgramServices: boolean;
  eicsConsentToAccessInsurance: number;
  consentToAccessStartDate: string | null;
  referralBeginDate: DateString;
  ifspDueDate: string | null;
  referralType: string | null;
  elProgram: string | null;
  elContactNumber: string | null;
  remainingAssessmentHours: string | null;
  eligibilityDate: DateString;
  povertyLevel: string | null;
  referralContact: ReferralContactJsonType[] | [];
}

const nullInitialValues: FaceSheetFormType = {
  intakeCoordinator: '',
  serviceCoordinator: '',
  referralDiagnosisCode: '',
  beginDate: null,
  rollOverDate: '',
  assessmentHours: '',
  noOfAssessmentHoursUsed: '',
  isSecondaryProgramServices: false,
  eicsConsentToAccessInsurance: 0,
  consentToAccessStartDate: dayjs().format('MM/DD/YYYY'),
  referralBeginDate: null,
  ifspDueDate: '',
  referralType: 'New',
  elProgram: '',
  elContactNumber: '',
  remainingAssessmentHours: '',
  eligibilityDate: null,
  povertyLevel: '',
  referralContact: [
    {
      referralContactDate: null,
      referralContactPhone: '',
      referralContactFax: '',
      referralContactEmail: '',
      referralContactType: 'Referral Contact',
      referralContactJobTitle: '',
      referralContactName: '',
      referralSource: '',
      referralReason: '',
      isDcf: false,
      notes: '',
    },
  ],
};

const referralContactSchema = yup.object().shape({
  referralContactName: yup.string().nullable(),
  referralContactJobTitle: yup.string().nullable(),
  referralisDcf: yup.boolean().nullable(),
  referralContactDate: yup
    .date()
    .required('AddNewReferralDetails.referralContactDate.Validation')
    .nullable(),
  referralSource: yup.string().required('referralSource.Validation').nullable(),
  referralReason: yup.string().required('referralReason.Validation').nullable(),
  referralContactPhone: yup.string().nullable(),
  referralContactEmail: yup
    .string()
    .matches(EMAIL_REGEX, 'EmailFormat.Validation')
    .nullable(),
  referralContactFax: yup.string().min(12, 'FaxFormat.Validation').nullable(),
  referralContactType: yup.string().nullable(),
  notes: yup.string().nullable(),
});

const validationSchema = yup.object().shape({
  intakeCoordinator: yup.string().nullable(),
  serviceCoordinator: yup.string().nullable(),
  assessmentHours: yup.string().nullable(),
  noOfAssessmentHoursUsed: yup.string().nullable(),
  isSecondaryProgramServices: yup.boolean().default(false),
  eicsConsentToAccessInsurance: yup.number().default(0),
  consentToAccessStartDate: yup.string().nullable(),
  referralBeginDate: yup
    .date()
    .required('AddNewReferralDetails.referralBeginDate.Validation')
    .nullable(),
  ifspDueDate: yup.string().nullable(),
  referralType: yup.string().nullable(),
  elProgram: yup.string().when('referralType', {
    is: 'Incoming Transfer',
    then: yup
      .string()
      .required('AddNewReferralDetails.elProgram.Validation')
      .nullable(),
    otherwise: yup.string().nullable(),
  }),
  elContactNumber: yup.string().when('referralType', {
    is: 'Incoming Transfer',
    then: yup
      .string()
      .min(12, 'PhoneNumber.Validation')
      .required('AddNewReferralDetails.elContactNumber.Validation')
      .nullable(),
    otherwise: yup.string().nullable(),
  }),
  remainingAssessmentHours: yup
    .string()
    .when('referralType', {
      is: 'Incoming Transfer',
      then: yup
        .string()
        .required('AddNewReferralDetails.noOfAssessmentHoursUsed.Validation')
        .nullable(),
    })
    .nullable(),
  beginDate: yup.date().when('referralType', {
    is: 'Incoming Transfer',
    then: yup
      .date()
      .required('AddNewReferralDetails.beginDate.Validation')
      .nullable(),
    otherwise: yup.date().nullable(),
  }),
  rollOverDate: yup.date().when('referralType', {
    is: 'Incoming Transfer',
    then: yup.date().nullable(),
    otherwise: yup.date().nullable(),
  }),
  eligibilityDate: yup.date().nullable(),
  povertyLevel: yup.string().nullable(),
  referralContact: yup.array().of(referralContactSchema),
});

export const eicsConcentValues: RadioButtonData[] = [
  { defaultLabel: 'Granted', labelId: 'grantedText', value: '1' },
  { defaultLabel: 'Denied', labelId: 'deniedText', value: '2' },
  { defaultLabel: 'Unknown', labelId: 'RiskFactor.Unknown', value: '0' },
];

const dateExtension = (date: string) => {
  const originalDate = new Date(date);
  originalDate.setDate(originalDate.getDate() + 45);
  const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
  const day = originalDate.getDate().toString().padStart(2, '0');
  const year = originalDate.getFullYear();
  return `${month}/${day}/${year}`;
};

const FaceSheetScreen = () => {
  const navigation = useNavigate();
  const rctl = useIntl();
  const clientId = localStorage.getItem('ClientId');
  const referralId = localStorage.getItem(REFERRAL_ID_KEY);
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [faceSheetDetails, setFaceSheetDetails] = useState<FaceSheetType>();
  const [servicedialogFlag, setServiceDialogFlag] = useState(false);
  const [intakedialogFlag, setIntakeDialogFlag] = useState(false);
  const [formdialogFlag, setFormDialogFlag] = useState(false);
  const [faceSheetForm, setFaceSheetForm] =
    useState<FaceSheetFormType>(nullInitialValues);
  const faceSheetFormBkp = useRef<FaceSheetFormType>({ ...nullInitialValues });
  const formikRef = useRef<FormikProps<FaceSheetFormType>>(null);

  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');

  const [selectedDphId, setSelectedDphId] = React.useState(referralId);

  const [serviceCoordinatorsList, setServiceCoordinatorsList] = useState<
    OptionType[]
  >([]);
  const [intakeCoordinatorsList, setIntakeCoordinatorsList] = useState<
    OptionType[]
  >([]);

  const [selectedCoordinator, setSelectedServiceCoordinator] = useState<
    OptionType | null | undefined
  >();

  const [selectedIntakeCoordinator, setSelectedIntakeCoordinator] = useState<
    OptionType | null | undefined
  >();
  const [resourceDto, setResourceData] = useState<ResourceRepoDTO>();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<ResourceRepoDTO>();
  setTimeout(() => window.dispatchEvent(new Event('resize')), 100);

  const [dphIdValues, setDphIdValues] = useState<OptionType[]>([]);
  const [makedownload, setMakedownload] = useState(false);
  const [deletedownload, setDeleteMakedownload] = useState(false);
  const [sync, setSync] = useState(false);
  const { lookups, lookupsDemographics, updateDemographics } =
    useContext(LookUpContext);

  const [selectedDiagnosisCodesList, setSelectedDiagnosisCodesList] =
    useState<OptionType | null>(null);
  const [diagnosisCodesList, setDiagnosisCodesList] = useState<
    DiagnosisOptionType[]
  >([]);

  const [referralReasons, setReferralReasons] = useState<OptionType[]>([]);
  const [referralSources, setReferralSources] = useState<OptionType[]>([]);

  const [cancelModal, setCancelModal] = useState<boolean>(false);

  const validateForm = (value: FaceSheetFormType) => {
    const errors: Partial<FaceSheetFormType> = {};

    value.referralContact.forEach((contact, index) => {
      if (contact.referralContactDate) {
        // Check if referralContactDate is before date of birth or after today's date
        if (
          dayjs(contact.referralContactDate).isBefore(
            faceSheetDetails?.demographics.dateOfBirth,
          ) ||
          dayjs(contact.referralContactDate).isAfter(
            dayjs().format('MM/DD/YYYY'),
          )
        ) {
          errors.referralContact = errors.referralContact || [];
          errors.referralContact[index] = errors.referralContact[index] || {
            referralContactDate: '',
            referralContactPhone: '',
            referralContactFax: '',
            referralContactEmail: '',
            referralContactType: '',
            referralContactJobTitle: '',
            referralContactName: '',
            referralSource: '',
            referralReason: '',
            isDcf: false,
            notes: '',
          };
          errors.referralContact[index].referralContactDate = 'validDate';
        }

        if (datePickerMinDateMaxDateValidate(contact.referralContactDate)) {
          errors.referralContact = errors.referralContact || [];
          errors.referralContact[index] = errors.referralContact[index] || {
            referralContactDate: '',
            referralContactPhone: '',
            referralContactFax: '',
            referralContactEmail: '',
            referralContactType: '',
            referralContactJobTitle: '',
            referralContactName: '',
            referralSource: '',
            referralReason: '',
            isDcf: false,
            notes: '',
          };

          errors.referralContact[index].referralContactDate =
            'datePickerMinDateMaxDateValidate';
        }
      }
    });

    if (value.referralBeginDate) {
      if (datePickerMinDateMaxDateValidate(value.referralBeginDate)) {
        errors.referralBeginDate = 'datePickerMinDateMaxDateValidate';
      }
      if (
        dayjs(value.referralBeginDate).isAfter(dayjs().format('MM/DD/YYYY'))
      ) {
        errors.referralBeginDate = 'validDate';
      }
    }
    if (datePickerMinDateMaxDateValidate(value.beginDate)) {
      errors.beginDate = 'datePickerMinDateMaxDateValidate';
    }
    return errors;
  };

  useEffect(() => {
    if (lookups === null) {
      return;
    }
    setReferralReasons(() => {
      return lookups.referralReason.map((reason) => {
        return {
          label: reason.description,
          id: reason.code,
        };
      });
    });
    setReferralSources(() => {
      return lookups.referralSource.map((source) => {
        return {
          label: source.description,
          id: source.code,
        };
      });
    });
  }, [lookups]);

  useEffect(() => {
    const handleDiagnosisDataGetAPI = async () => {
      toggleLoader(true);
      getCodesAndDescriptionNew()
        .then(async (response: DiagnosisCode[]) => {
          const diagnosisCodeList: DiagnosisOptionType[] = [];
          if (response.length > 0) {
            response.forEach((codeDetails) => {
              diagnosisCodeList.push({
                id: codeDetails.code,
                label: codeDetails.oneYearEligibility
                  ? `${codeDetails.code} - * ${codeDetails.description}`
                  : `${codeDetails.code} - ${codeDetails.description}`,
                isDeleted: codeDetails.isDeleted
                  ? codeDetails.isDeleted
                  : false,
              });
            });

            await fetchFaceSheetDetails(diagnosisCodeList);
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId(error);
            setToastrDefaultMessage(error);
          }
        });
    };

    handleDiagnosisDataGetAPI();
  }, []);

  useEffect(() => {
    if (formikRef.current?.values?.referralDiagnosisCode) {
      const data = diagnosisCodesList.filter((obj) => {
        const medicalDiagnosis =
          formikRef.current?.values?.referralDiagnosisCode ?? '';
        return obj.label.includes(medicalDiagnosis);
      });
      if (formikRef.current)
        formikRef.current.setFieldValue(
          'referralDiagnosisCode',
          data[0]?.label,
        );
    }
  }, [diagnosisCodesList]);

  const handlePreviewClick = (fileItem: ResourceRepoDTO | undefined) => {
    setShowPreview(true);
    setSelectedFile(fileItem);
  };

  useEffect(() => {
    if (makedownload) {
      toggleLoader(true);
      const handleFaceSheetList = async () => {
        await fetchFaceSheetDetails(diagnosisCodesList);
      };
      handleFaceSheetList();
    }
  }, [makedownload]);

  useEffect(() => {
    toggleLoader(true);

    const getAllReferralsBasedOnSelectedClient = async () => {
      if (localStorage.getItem('defaultReferralId') === null) {
        const getReferralId = localStorage.getItem('referralId');
        if (getReferralId)
          localStorage.setItem('defaultReferralId', getReferralId);
      }

      const defaultReferralId = localStorage.getItem('defaultReferralId');
      if (defaultReferralId) {
        await fetchRefferralDetails(defaultReferralId);
      }
    };
    getAllReferralsBasedOnSelectedClient();
    makeDownloadAndSync();
  }, []);

  const fetchRefferralDetails = async (defaultReferralId: string) => {
    toggleLoader(true);
    if (clientId) {
      await getAllReferrals(clientId, defaultReferralId)
        .then(async (response: ReferralResponseType[]) => {
          if (response) {
            localStorage.setItem(
              'clientReferralDetails',
              JSON.stringify(response),
            );

            const referralDetails = JSON.stringify(response);

            if (referralDetails) {
              const parsedReferralDetails = JSON.parse(referralDetails);
              setDphIdValues(() => {
                return parsedReferralDetails.map((d: ReferralResponseType) => {
                  return {
                    label:
                      d.dphId === null || d.dphId === ''
                        ? 'Yet to receive'
                        : d.dphId,
                    id: d.referralId,
                  };
                });
              });
            }
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('clientLoadError');
            setToastrDefaultMessage('Failed to get client details');
          }
        });
    }
  };

  const makeDownloadAndSync = async () => {
    // const referralIdAdded = (await getResponseDB('referralIds')) as string[];
    // if (referralIdAdded) {
    //   if (referralIdAdded.length <= 2) {
    //     setMakedownload(true);
    //     setDeleteMakedownload(false);
    //     setSync(false);
    //   } else {
    //     setMakedownload(false);
    //   }
    //   const referralid: string = localStorage.getItem('referralId') || '';
    //   if (referralIdAdded.includes(referralid)) {
    //     setDeleteMakedownload(true);
    //     setSync(true);
    //     setMakedownload(false);
    //   }
    // } else {
    //   setMakedownload(true);
    //   setSync(false);
    //   setDeleteMakedownload(false);
    // }

    // // check if application is not running in localhost and disable all
    // if (
    //   window.location.hostname !== 'localhost' &&
    //   window.location.hostname !== '127.0.0.1'
    // ) {
    //   setMakedownload(false);
    //   setSync(false);
    //   setDeleteMakedownload(false);
    // }
    setMakedownload(false);
    setSync(false);
    setDeleteMakedownload(false);
  };

  const handleRolesList = (faceSheetResponseDetails: FaceSheetType) => {
    const handleServiceCoordinatorsList = async () => {
      const serviceCoordinatorsDetails = await getListByRole(
        'backend.role.service_coordinator',
      );
      const serviceCoList: OptionType[] = [];
      serviceCoordinatorsDetails.forEach((coordinator) => {
        serviceCoList.push({
          id: coordinator.id,
          label: `${coordinator.firstName} ${coordinator.lastName}`,
        });
      });
      setServiceCoordinatorsList(serviceCoList);

      const selected: OptionType | undefined = serviceCoList.find(
        (s) =>
          s.id === faceSheetResponseDetails.demographics.serviceCoordinator,
      );
      if (selected) {
        setSelectedServiceCoordinator(selected);
      } else {
        setSelectedServiceCoordinator(null);
      }
    };
    handleServiceCoordinatorsList();

    const handleIntakeCoordinatorsList = async () => {
      const intakeCoordinatorsDetails = await getListByRole(
        'backend.role.intake_coordinator',
      );
      const intakeCoList: OptionType[] = [];
      intakeCoordinatorsDetails.forEach((coordinator) => {
        intakeCoList.push({
          id: coordinator.id,
          label: `${coordinator.firstName} ${coordinator.lastName}`,
        });
      });
      setIntakeCoordinatorsList(intakeCoList);

      const selected: OptionType | undefined = intakeCoList.find(
        (s) => s.id === faceSheetResponseDetails.demographics.intakeCoordinator,
      );
      if (selected) {
        setSelectedIntakeCoordinator(selected);
      } else {
        setSelectedIntakeCoordinator(null);
      }
    };
    handleIntakeCoordinatorsList();
  };

  const handleServiceDailog = () => {
    if (
      faceSheetDetails &&
      faceSheetDetails.demographics.serviceCoordinatorHistory.length > 0
    )
      setServiceDialogFlag(true);
  };

  const handleIntakeDailog = () => {
    if (
      faceSheetDetails &&
      faceSheetDetails.demographics.intakeCoordinatorHistory.length > 0
    )
      setIntakeDialogFlag(true);
  };

  const handleFormDailog = async () => {
    await fetchFaceSheetDetails(diagnosisCodesList);
    setFormDialogFlag(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleFormSubmit = (formSubmitValues: FaceSheetFormType) => {
    const assessmentHoursConversion = convertTimeDuration(
      formSubmitValues.assessmentHours,
      false,
    );
    const usedHoursConversion = convertTimeDuration(
      formSubmitValues.noOfAssessmentHoursUsed,
      false,
    );
    const updatedValues: FaceSheetFormType = {
      ...formSubmitValues,
      assessmentHours: assessmentHoursConversion,
      noOfAssessmentHoursUsed: usedHoursConversion,
      consentToAccessStartDate:
        formSubmitValues.consentToAccessStartDate !== null
          ? formSubmitValues.consentToAccessStartDate
          : dayjs().format('MM/DD/YYYY'),
    };

    const params = {
      intakeCoordinator: updatedValues.intakeCoordinator,
      serviceCoordinator: updatedValues.serviceCoordinator,
      isSecondaryProgramServices: updatedValues.isSecondaryProgramServices,
      eicsConsentToAccessInsurance: updatedValues.eicsConsentToAccessInsurance,
      consentToAccessStartDate: updatedValues.consentToAccessStartDate,
      referral: {
        referralBeginDate: updatedValues.referralBeginDate,
        ifspDueDate: updatedValues.ifspDueDate,
        referralType: updatedValues.referralType,
        remainingAssessmentHours: updatedValues.remainingAssessmentHours,
        eligibilityDate: updatedValues.eligibilityDate,
        povertyLevel: updatedValues.povertyLevel,
        referralDiagnosisCode: updatedValues.referralDiagnosisCode,
        beginDate: updatedValues.beginDate,
        rollOverDate: updatedValues.rollOverDate,
        assessmentHours: updatedValues.assessmentHours,
        noOfAssessmentHoursUsed: updatedValues.noOfAssessmentHoursUsed,
        elProgram: updatedValues.elProgram,
        elContactNumber: updatedValues.elContactNumber,
        referralContact: updatedValues.referralContact,
      },
    };

    if (params) {
      if (clientId) {
        toggleLoader(true);
        updateFacesheetDetails(params, clientId)
          .then(async (response) => {
            if (response) {
              await fetchFaceSheetDetails(diagnosisCodesList);
              setOpen(true);
              setToastrVariable('success');
              setToastrId('updateSuccessMessage');
              setToastrDefaultMessage('Updated Successfully');
              setFormDialogFlag(false);
            }
            toggleLoader(false);
          })
          .catch((error) => {
            toggleLoader(false);
            setOpen(true);
            setToastrVariable('error');
            const apiError = error as ApiError;
            if (isCustomError(error)) {
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else if (error.code === 1056) {
              setToastrId('Facesheet.apiErrorMsg');
              setToastrDefaultMessage(apiError.message);
            } else {
              setToastrId('updateFailureMessage');
              setToastrDefaultMessage('Failed to update details');
            }
          });
      }
    }
  };

  const syncData = async () => {
    const referralid: string = localStorage.getItem('referralId') || '';
    const result = await createclientsyncobj(referralid);
    toggleLoader(true);
    syncOfflineData(result)
      .then(async (response) => {
        if (response) {
          const selectedProgram: string | null =
            localStorage.getItem('selectedProgram');
          let programId: string = '';
          if (selectedProgram !== null) {
            programId = JSON.parse(selectedProgram).id;
          }

          await deleteData(programId, referralid).then(async () => {
            const referralIds = (await getResponseDB(
              'referralIds',
            )) as string[];
            if (referralIds) {
              if (referralIds.length === 0) {
                nullifyToken();
              }
            } else {
              nullifyToken();
            }
            makeDownloadAndSync();
          });

          setOpen(true);
          setToastrVariable('success');
          setToastrId('synced');
          setToastrDefaultMessage('Data synced successfully');
        }
      })
      .catch(() => {
        setOpen(true);
        setToastrVariable('error');
        setToastrId('syncfail');
        setToastrDefaultMessage('Failed to sync data');
      })
      .finally(() => {
        makeDownloadAndSync();
        toggleLoader(false);
      });
  };

  const fetchOfflineData = async () => {
    // toggleLoader(true);
    // await getOfflineData()
    //   .then(async (response) => {
    //     if (response) {
    //       await saveOfflineDataClient(response, referralId ? referralId : '');
    //       const offlineToken: string =
    //         localStorage.getItem('offlineToken') || '';
    //       localStorage.setItem('refreshToken', offlineToken);
    //       setSync(true);
    //       setMakedownload(false);
    //       setOpen(true);
    //       setToastrVariable('success');
    //       setToastrId('offlinedownload');
    //       setToastrDefaultMessage('Offline data downloaded');
    //     }
    //   })
    //   .catch((error) => {
    //     setOpen(true);
    //     setToastrVariable('error');
    //     const apiError = error as ApiError;
    //     setToastrId(apiError.id);
    //     setToastrDefaultMessage(apiError.message);
    //   })
    //   .finally(async () => {
    //     makeDownloadAndSync();
    //     toggleLoader(false);
    //   });
  };

  const deleteOfflineData = async () => {
    toggleLoader(true);

    const selectedProgram: string | null =
      localStorage.getItem('selectedProgram');
    let programId: string = '';
    if (selectedProgram !== null) {
      programId = JSON.parse(selectedProgram).id;
    }
    const referralid: string = localStorage.getItem('referralId') || '';
    const referralRemaining = (await getResponseDB('referralIds')) as string[];
    let sessionIntimation: boolean = false;
    if (referralRemaining && referralRemaining.length === 1) {
      sessionIntimation = true;
    }

    if (sessionIntimation) {
      await deleteSession()
        .then(async (response) => {
          if (response) {
            deleteReferralData(programId, referralid);
          } else {
            setOpen(true);
            setToastrVariable('error');
            setToastrId('deleteSession');
            setToastrDefaultMessage(
              "Couldn't deleted offline data, please try again later.",
            );
            toggleLoader(false);
          }
        })
        .catch(() => {
          setOpen(true);
          setToastrVariable('error');
          setToastrId('deleteSession');
          setToastrDefaultMessage(
            "Couldn't deleted offline data, please try again later.",
          );
          toggleLoader(false);
        });
    } else {
      deleteReferralData(programId, referralid);
    }
  };

  const deleteReferralData = async (programId: string, referralid: string) => {
    await deleteData(programId, referralid).then(async () => {
      const referralIds = (await getResponseDB('referralIds')) as string[];
      if (referralIds) {
        if (referralIds.length === 0) {
          nullifyToken();
        }
      } else {
        nullifyToken();
      }
      makeDownloadAndSync();
      setOpen(true);
      setToastrVariable('success');
      setToastrId('deletedownload');
      setToastrDefaultMessage('Deleted offline data');
      toggleLoader(false);
    });
  };

  const nullifyToken = () => {
    localStorage.setItem('accessToken', 'EXPIRE SESSION');
    localStorage.setItem('refreshToken', 'EXPIRE SESSION');
    localStorage.setItem('offlineToken', 'EXPIRE SESSION');
  };

  const fetchFaceSheetDetails = async (
    filteredDiagnosisCodeList: DiagnosisOptionType[],
  ) => {
    if (clientId) {
      toggleLoader(true);
      await getFacesheetDetails(clientId)
        .then((response) => {
          if (response) {
            // update the re-evaluation data
            const data = response;
            if (data.demographics.resourceDto) {
              setResourceData(data.demographics.resourceDto);
            }
            const journeyCounts: { [key: string]: number } = {};

            data.clientJourney.forEach((item) => {
              // Count the occurrences of each journey name
              journeyCounts[item.journeyName] =
                (journeyCounts[item.journeyName] || 0) + 1;

              // If it's the 2nd or subsequent occurrence of "backend.journey_name.evaluation", update to "backend.journey_name.re-evaluation"
              if (
                item.journeyName === 'backend.journey_name.evaluation' &&
                journeyCounts[item.journeyName] > 1
              ) {
                item.journeyName = 'backend.journey_name.re-evaluation';
              }
            });

            data.demographics.consentToAccessStartDate =
              response.demographics.consentToAccessStartDate === null &&
              response.demographics.eicsConsentToAccessInsurance === 0
                ? response.demographics.referralDate
                : response.demographics.consentToAccessStartDate;
            setFaceSheetDetails(data);
            updateDemographics(data.demographics);

            handleRolesList(response);

            if (response.demographics) {
              const convertedAssessmentHours = convertTimeDuration(
                response.demographics.assessmentHours,
                true,
              );
              const convertedUsedAssessmentHours = convertTimeDuration(
                response.demographics.noOfAssessmentHoursUsed,
                true,
              );
              const responseValues = {
                intakeCoordinator: response.demographics.intakeCoordinator,
                serviceCoordinator: response.demographics.serviceCoordinator,
                referralDiagnosisCode:
                  response.demographics.diagnosis?.code || '',
                beginDate: response.demographics.beginDate,
                rollOverDate: response.demographics.rollOverDate,
                assessmentHours: convertedAssessmentHours,
                noOfAssessmentHoursUsed: convertedUsedAssessmentHours,
                isSecondaryProgramServices:
                  response.demographics.isSecondaryProgramServices,
                eicsConsentToAccessInsurance:
                  response.demographics.eicsConsentToAccessInsurance,
                consentToAccessStartDate:
                  response.demographics.consentToAccessStartDate === null &&
                  response.demographics.eicsConsentToAccessInsurance === 0
                    ? response.demographics.referralDate
                    : response.demographics.consentToAccessStartDate,
                referralBeginDate: response.demographics.referralDate,
                ifspDueDate: response.demographics.ifspDueDate,
                referralType: response.demographics.referralType,
                elProgram: response.demographics.elProgram,
                elContactNumber: response.demographics.elContactNumber,
                remainingAssessmentHours:
                  response.demographics.remainingAssessmentHours,
                eligibilityDate: response.demographics.eligibilityDate ?? null,
                povertyLevel: response.demographics.povertyLevel,
                referralContact: response.demographics.referralContact ?? [
                  {
                    id: '',
                    referralContactDate: null,
                    referralContactPhone: '',
                    referralContactFax: '',
                    referralContactEmail: '',
                    referralContactType: 'Referral Contact',
                    referralContactJobTitle: '',
                    referralContactName: '',
                    referralSource: '',
                    referralReason: '',
                    isDcf: false,
                    notes: '',
                  },
                ],
              };
              setFaceSheetForm({ ...responseValues });

              faceSheetFormBkp.current = JSON.parse(
                JSON.stringify(responseValues),
              );

              const filteredDiagnosisList: DiagnosisOptionType[] = [];

              filteredDiagnosisCodeList.forEach((codeDetails) => {
                if (codeDetails.id === response.demographics.diagnosis?.code) {
                  filteredDiagnosisList.push(codeDetails);
                }
                if (!codeDetails.isDeleted) {
                  filteredDiagnosisList.push(codeDetails);
                }
              });

              // remove duplicates in filteredDiagnosisList
              const uniqueDiagnosisList = filteredDiagnosisList.filter(
                (item, index) => {
                  return filteredDiagnosisList.indexOf(item) === index;
                },
              );

              uniqueDiagnosisList.sort((a, b) => {
                if (a.id < b.id) return -1;
                if (a.id > b.id) return 1;
                return 0;
              });

              const soertedLIst = uniqueDiagnosisList;

              setDiagnosisCodesList(soertedLIst);

              const selected: OptionType | undefined = uniqueDiagnosisList.find(
                (d) => d.id === response.demographics.diagnosis?.code,
              );
              if (selected) setSelectedDiagnosisCodesList(selected);
            }
          }
          toggleLoader(false);
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('Client.error');
            setToastrDefaultMessage(error);
          }
        });
    }
  };

  function getMaxValue(valueHrs: string | null) {
    if (valueHrs) {
      let hours;
      let minutes;
      if (valueHrs.includes('.')) {
        hours = valueHrs.split('.')[0];
        minutes = valueHrs.split('.')[1];
      } else {
        hours = valueHrs.split(':')[0];
        minutes = valueHrs.split(':')[1];
      }

      if (minutes === '00') {
        return { hour: +hours, minutes: 0 };
      } else if (minutes === '25') {
        return { hour: +hours, minutes: 25 };
      } else if (minutes === '50') {
        return { hour: +hours, minutes: 50 };
      } else if (minutes === '75') {
        return { hour: +hours, minutes: 75 };
      }
    }
    return { hour: 0, minutes: 0 };
  }

  const handleAddLink = () => {
    const newReferralContact = {
      id: '',
      referralContactDate: null,
      referralContactPhone: '',
      referralContactFax: '',
      referralContactEmail: '',
      referralContactType: 'Referral Contact',
      referralContactJobTitle: '',
      referralContactName: '',
      referralSource: '',
      referralReason: '',
      isDcf: false,
      notes: '',
    };

    const updatedReferralContact = {
      ...faceSheetForm,
      referralContact: [...faceSheetForm.referralContact, newReferralContact],
    };

    formikRef.current?.setValues(updatedReferralContact);

    setFaceSheetForm(updatedReferralContact);
  };

  const handleDeleteLink = (ind: number) => {
    if (faceSheetForm.referralContact.length > 1) {
      const updatedReferralContact = [...faceSheetForm.referralContact]; // Correctly copy the array
      updatedReferralContact.splice(ind, 1); // Remove the element at index 'ind'

      const updatedDataView = {
        ...faceSheetForm,
        referralContact: updatedReferralContact,
      };

      formikRef.current?.setValues(updatedDataView);
      setFaceSheetForm(updatedDataView);
    }
  };

  const handleValueUpdates = (
    indexNo: number,
    name: keyof ReferralContactJsonType,
    value: string | null | boolean,
  ) => {
    const updatedReferralContact = [...faceSheetForm.referralContact];
    (updatedReferralContact[indexNo][name] as string | null | boolean) = value;
    const updatedDataView = {
      ...faceSheetForm,
      referralContact: updatedReferralContact,
    };

    setFaceSheetForm(updatedDataView);
  };

  const handleCloseModal = () => {
    formikRef.current?.setValues(faceSheetFormBkp.current, true);
    setSelectedIntakeCoordinator(null);
    setSelectedServiceCoordinator(null);
    setSelectedDiagnosisCodesList(null);
    setFaceSheetForm(faceSheetFormBkp.current);
    setFormDialogFlag(false);
  };

  return (
    <Box component="main">
      {selectedFile && showPreview && (
        <FilePreviewDialog
          url={selectedFile.resourceUrl}
          fileName={selectedFile.name.split('/').pop()}
          onClose={() => {
            setShowPreview(false);
            setSelectedFile(undefined);
          }}
        />
      )}
      <Box component="section">
        <SnackBarComponent
          open={open}
          handleClose={handleClose}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />

        {faceSheetDetails && (
          <Dialog open={servicedialogFlag} fullWidth maxWidth="sm">
            <DialogTitle>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center', // Align items vertically in the center
                }}
              >
                <MediumTypography
                  labelid={'Facesheet.serviceCoordinators'}
                  sxProps={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    marginTop: '8px',
                  }}
                  defaultlabel="Service Coordinators"
                />
                <Close
                  style={{
                    marginLeft: 'auto', // Move the Close button to the right side
                    marginTop: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setServiceDialogFlag(false)}
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              {faceSheetDetails.demographics.serviceCoordinatorHistory.map(
                (eventProps: ServiceCoordinatorsType) => {
                  return (
                    <Box sx={{ marginY: '8px' }} key={eventProps.id}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            fontFamily: 'Lato-Regular',
                            color: '#2A4241',
                          }}
                        >
                          {eventProps.firstName} {eventProps.lastName}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            fontFamily: 'Lato-Regular',
                            color: '#97A6A5',
                          }}
                        >
                          {moment(eventProps.startTime, 'YYYY-MM-DD HH:mm:ss')
                            .utc(true)
                            .local()
                            .format('MMM DD, YYYY')}{' '}
                          -{' '}
                          {eventProps.endTime
                            ? moment(eventProps.endTime, 'YYYY-MM-DD HH:mm:ss')
                                .utc(true)
                                .local()
                                .format('MMM DD, YYYY')
                            : 'Present'}
                        </Typography>
                      </Box>
                      <Box
                        className="horizontalLine"
                        sx={{ marginY: '12px' }}
                      />
                    </Box>
                  );
                },
              )}
            </DialogContent>
          </Dialog>
        )}

        {faceSheetDetails && (
          <Dialog open={intakedialogFlag} fullWidth maxWidth="sm">
            <DialogTitle>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center', // Align items vertically in the center
                }}
              >
                <MediumTypography
                  labelid={'Facesheet.intakeFacilitators'}
                  sxProps={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    marginTop: '8px',
                  }}
                  defaultlabel="Intake Facilitators"
                />
                <Close
                  style={{
                    marginLeft: 'auto', // Move the Close button to the right side
                    marginTop: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setIntakeDialogFlag(false)}
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              {faceSheetDetails.demographics.intakeCoordinatorHistory.map(
                (eventProps: IntakeCoordinatorsType) => {
                  return (
                    <Box sx={{ marginY: '8px' }} key={eventProps.id}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            fontFamily: 'Lato-Regular',
                            color: '#2A4241',
                          }}
                        >
                          {eventProps.firstName} {eventProps.lastName}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            fontFamily: 'Lato-Regular',
                            color: '#97A6A5',
                          }}
                        >
                          {moment(eventProps.startTime, 'YYYY-MM-DD HH:mm:ss')
                            .utc(true)
                            .local()
                            .format('MMM DD, YYYY')}{' '}
                          -{' '}
                          {eventProps.endTime
                            ? moment(eventProps.endTime, 'YYYY-MM-DD HH:mm:ss')
                                .utc(true)
                                .local()
                                .format('MMM DD, YYYY')
                            : 'Present'}
                        </Typography>
                      </Box>

                      <Box
                        className="horizontalLine"
                        sx={{ marginY: '12px' }}
                      />
                    </Box>
                  );
                },
              )}
            </DialogContent>
          </Dialog>
        )}

        {faceSheetDetails && (
          <Dialog open={formdialogFlag} fullWidth maxWidth="md">
            <DialogTitle>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center', // Align items vertically in the center
                }}
              >
                <Tooltip
                  title={formatName(
                    faceSheetDetails.demographics.firstName,
                    faceSheetDetails.demographics.middleName,
                    faceSheetDetails.demographics.lastName,
                    faceSheetDetails.demographics.suffix,
                  )}
                  arrow
                >
                  <Box>
                    <MediumTypography
                      label={formatName(
                        faceSheetDetails.demographics.firstName,
                        faceSheetDetails.demographics.middleName,
                        faceSheetDetails.demographics.lastName,
                        faceSheetDetails.demographics.suffix,
                      )}
                      sxProps={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        maxWidth: '500px',
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>
            </DialogTitle>
            <Formik
              innerRef={formikRef}
              initialValues={faceSheetForm}
              enableReinitialize={true}
              validateOnBlur={true}
              validateOnChange={false}
              validationSchema={validationSchema}
              validate={validateForm}
              onSubmit={(values) => {
                handleFormSubmit(values);
              }}
            >
              {({
                values,
                setFieldValue,
                errors,
                touched,
                handleSubmit,
                handleBlur,
              }) => (
                <>
                  <DialogContent onBlur={handleBlur}>
                    <Box component="div" onBlur={handleBlur}>
                      <Grid container direction={'row'} spacing={2}>
                        <Grid item xs={6} style={{ paddingTop: '12px' }}>
                          <CheckBoxComponent
                            ischecked={values.isSecondaryProgramServices}
                            labelid="secondaryProgramServiceText"
                            defaultlabelid="Secondary Program Service"
                            CheckHandleChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              setFieldValue(
                                'isSecondaryProgramServices',
                                event.target.checked,
                              );
                            }}
                            fontWeight={500}
                            fontFamily={'Lato-Regular'}
                          />
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                          <AutocompleteSearch
                            labelId={'Facesheet.intakeFacilitator'}
                            defaultId="Intake Facilitator"
                            optionHintId={'nointakeDropdownTextId'}
                            sxProps={{
                              [`& .${autocompleteClasses.popupIndicator}`]: {
                                transform: 'none',
                              },
                            }}
                            data={intakeCoordinatorsList}
                            selectedValue={
                              selectedIntakeCoordinator
                                ? selectedIntakeCoordinator
                                : { id: '', label: '' }
                            }
                            onSelectItem={(value: OptionType | null) => {
                              if (value) {
                                setSelectedIntakeCoordinator(value);
                                setFieldValue('intakeCoordinator', value.id);
                              }
                            }}
                            disableLabel={false}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <AutocompleteSearch
                            labelId={'Facesheet.serviceCoordinator'}
                            defaultId="Service Coordinator"
                            optionHintId={'noserviceDropdownTextId'}
                            sxProps={{
                              [`& .${autocompleteClasses.popupIndicator}`]: {
                                transform: 'none',
                              },
                            }}
                            data={serviceCoordinatorsList}
                            selectedValue={
                              selectedCoordinator
                                ? selectedCoordinator
                                : { id: '', label: '' }
                            }
                            onSelectItem={(value: OptionType | null) => {
                              if (value) {
                                setSelectedServiceCoordinator(value);
                                setFieldValue('serviceCoordinator', value.id);
                              }
                            }}
                            disableLabel={false}
                          />
                        </Grid>
                      </Grid>
                      {/* Hidden */}
                      <Grid
                        container
                        direction={'row'}
                        className="pt-md"
                        spacing={2}
                        sx={{ display: 'none' }}
                      >
                        <Grid item xs={4} lg={4}>
                          <DatePickerComponent
                            name="beginDate"
                            value={dayjs(values.beginDate)}
                            labelid="Facesheet.beginDate"
                            defaultlabelid={'Assessment Begin Date'}
                            maxDate={dayjs()}
                            handlechange={(date: Dayjs | null) => {
                              const nextYear = dayjs(date)
                                .add(1, 'year')
                                .format('MM/DD/YYYY');
                              const formattedDate =
                                dayjs(date).format('MM/DD/YYYY');
                              if (date === null) {
                                formikRef.current?.setValues({
                                  ...values,
                                  beginDate: null,
                                  rollOverDate: null,
                                });
                              } else {
                                formikRef.current?.setValues({
                                  ...values,
                                  beginDate: formattedDate as DateString,
                                  rollOverDate: nextYear,
                                });
                              }
                            }}
                          />
                          {values.rollOverDate && (
                            <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                              <MediumTypography
                                label={`${rctl.formatMessage({
                                  id: 'Facesheet.rolloverDate',
                                })}:`}
                                sxProps={{
                                  color: '#97A6A5',
                                  fontSize: '12px',
                                  mt: '4px',
                                }}
                              />
                              <MediumTypography
                                label={`${values.rollOverDate}`}
                                sxProps={{
                                  color: '#2A4241',
                                  fontSize: '12px',
                                  ml: '4px',
                                  mt: '4px',
                                }}
                              />
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={4} lg={4}>
                          <DurationPicker
                            labelId="Facesheet.noOfAssessmentHoursText"
                            defaultLabel="Assessment Hrs"
                            maxDuration={{ hour: 20, minutes: 0 }}
                            duration={formatStringDateInDecimals(
                              values.assessmentHours,
                            )}
                            required={true}
                            interval={25}
                            setDuration={(duration) => {
                              setFieldValue(
                                'assessmentHours',
                                formatTimeDurationInDecimals(duration),
                              );
                              setFieldValue('noOfAssessmentHoursUsed', '0.00');
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} lg={4}>
                          <DurationPicker
                            labelId="Facesheet.usedAssessmentHoursText"
                            defaultLabel="Used Assessment Hrs"
                            maxDuration={getMaxValue(values.assessmentHours)}
                            duration={formatStringDateInDecimals(
                              values.noOfAssessmentHoursUsed,
                            )}
                            required={true}
                            interval={25}
                            setDuration={(duration) => {
                              setFieldValue(
                                'noOfAssessmentHoursUsed',
                                formatTimeDurationInDecimals(duration),
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                      {/* Hidden */}

                      <Grid
                        container
                        direction={'row'}
                        className="mt-sm"
                        spacing={2}
                      >
                        <Grid item xs={6} lg={6}>
                          <DatePickerComponent
                            value={dayjs(values.referralBeginDate)}
                            labelid="AddNewReferralDetails.referralBeginDate"
                            defaultlabelid="Referral Begin Date"
                            maxDate={dayjs()}
                            handlechange={(date: Dayjs | null) => {
                              const formattedDate =
                                dayjs(date).format('MM/DD/YYYY');
                              if (date === null) {
                                formikRef.current?.setValues({
                                  ...values,
                                  referralBeginDate: null,
                                  ifspDueDate: null,
                                });
                              } else {
                                formikRef.current?.setValues({
                                  ...values,
                                  referralBeginDate: formattedDate,
                                  ifspDueDate: dateExtension(formattedDate),
                                });
                              }
                            }}
                            required={true}
                          />
                          {values.ifspDueDate && (
                            <MediumTypography
                              label={`${rctl.formatMessage({
                                id: 'AddNewReferralDetails.ifspDueDate',
                              })}: ${values.ifspDueDate}`}
                              fontweight={600}
                              sxProps={{
                                color: '#2A4241',
                                fontWeight: 'bold',
                                fontSize: '14px',
                                mt: '4px',
                              }}
                            />
                          )}
                          {errors.referralBeginDate &&
                            touched.referralBeginDate && (
                              <MediumTypography
                                labelid={errors.referralBeginDate}
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        direction={'row'}
                        className="pt-md"
                        spacing={2}
                      >
                        <Grid item xs={12} lg={12}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <MediumTypography
                              labelid="AddNewReferralDetails.referralType"
                              defaultlabel="Referral Type"
                              sxProps={{
                                color: '#2A4241',
                                fontWeight: 'bold',
                                fontSize: '14px',
                                marginRight: '16px',
                              }}
                            />
                            <CustomRadioButton
                              data={referralTypeOptions}
                              value={values.referralType}
                              onSelectItem={(value) => {
                                if (value !== null) {
                                  setFieldValue('referralType', value);
                                  if (value !== 'Incoming Transfer') {
                                    setFieldValue('elProgram', '');
                                    setFieldValue('elContactNumber', '');
                                    setFieldValue(
                                      'remainingAssessmentHours',
                                      '',
                                    );
                                    setFieldValue('beginDate', null);
                                    setFieldValue('eligibilityDate', '');
                                    setFieldValue('rollOverDate', null);
                                  }
                                }
                              }}
                            />
                          </Box>
                        </Grid>
                        {values.referralType === 'Incoming Transfer' && (
                          <>
                            <Grid item xs={6} lg={6}>
                              <Textinput
                                name="elProgram"
                                Value={values.elProgram}
                                labelid="AddNewReferralDetails.elProgram"
                                defaultlabelid="EI Program"
                                inputProps={{
                                  maxLength: 100,
                                }}
                                handlechange={(value: string) => {
                                  setFieldValue('elProgram', value);
                                }}
                                Required={true}
                              />
                              {errors.elProgram && touched.elProgram && (
                                <MediumTypography
                                  labelid={errors.elProgram}
                                  className="errorText-md"
                                />
                              )}
                            </Grid>
                            <Grid item xs={6} lg={6}>
                              <Textinput
                                name="elContactNumber"
                                Value={values.elContactNumber}
                                labelid="AddNewReferralDetails.elContactNumber"
                                defaultlabelid="EI Contact Number"
                                handlechange={(value: string) => {
                                  setFieldValue(
                                    'elContactNumber',
                                    formatPhoneNumber(value),
                                  );
                                }}
                                Required={true}
                              />
                              {errors.elContactNumber &&
                                touched.elContactNumber && (
                                  <MediumTypography
                                    labelid={errors.elContactNumber}
                                    className="errorText-md"
                                  />
                                )}
                            </Grid>
                            <Grid item xs={6} lg={6}>
                              <DurationPicker
                                labelId="AddNewReferralDetails.assessmentHoursText"
                                defaultLabel="Remaining Assessment Hours"
                                maxDuration={{ hour: 20, minutes: 0 }}
                                duration={formatStringDateInDecimals(
                                  values.remainingAssessmentHours,
                                )}
                                required={true}
                                interval={25}
                                setDuration={(duration) => {
                                  if (
                                    duration.hour === 0 &&
                                    duration.minutes === 0
                                  ) {
                                    setFieldValue(
                                      'remainingAssessmentHours',
                                      null,
                                    );
                                  } else {
                                    setFieldValue(
                                      'remainingAssessmentHours',
                                      formatTimeDurationInDecimals(duration),
                                    );
                                  }
                                }}
                              />
                              {errors.remainingAssessmentHours &&
                                touched.remainingAssessmentHours && (
                                  <MediumTypography
                                    labelid={errors.remainingAssessmentHours}
                                    className="errorText-md"
                                  />
                                )}
                            </Grid>
                            <Grid item xs={6} lg={6}>
                              <DatePickerComponent
                                value={dayjs(values.beginDate)}
                                labelid="AddNewReferralDetails.beginDate"
                                defaultlabelid="Begin Date"
                                handlechange={(date: Dayjs | null) => {
                                  const formattedDate =
                                    dayjs(date).format('MM/DD/YYYY');
                                  if (date === null) {
                                    formikRef.current?.setValues({
                                      ...values,
                                      beginDate: null,
                                      rollOverDate: null,
                                    });
                                  } else {
                                    formikRef.current?.setValues({
                                      ...values,
                                      beginDate: formattedDate,
                                      rollOverDate: dayjs(date)
                                        .add(1, 'year')
                                        .format('MM/DD/YYYY'),
                                    });
                                  }
                                }}
                                required={true}
                              />
                              {values.rollOverDate && (
                                <MediumTypography
                                  label={`${rctl.formatMessage({
                                    id: 'AddNewReferralDetails.rollOverDate',
                                  })}: ${values.rollOverDate}`}
                                  fontweight={600}
                                  sxProps={{
                                    color: '#2A4241',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    mt: '4px',
                                  }}
                                />
                              )}
                              {errors.beginDate && touched.beginDate && (
                                <MediumTypography
                                  labelid={errors.beginDate}
                                  className="errorText-md"
                                />
                              )}
                            </Grid>
                            <Grid item xs={6} lg={6}>
                              <DatePickerComponent
                                value={dayjs(values.eligibilityDate)}
                                labelid="AddNewReferralDetails.eligibilityDate"
                                defaultlabelid="Eligibility Date Date"
                                handlechange={(date: Dayjs | null) => {
                                  const formattedDate =
                                    dayjs(date).format('MM/DD/YYYY');
                                  if (date === null) {
                                    formikRef.current?.setValues({
                                      ...values,
                                      eligibilityDate: null,
                                    });
                                  } else {
                                    formikRef.current?.setValues({
                                      ...values,
                                      eligibilityDate: formattedDate,
                                    });
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={6} lg={6}></Grid>
                          </>
                        )}
                      </Grid>

                      <Grid
                        container
                        className="pt-md"
                        direction={'row'}
                        spacing={2}
                      >
                        <Grid item xs={6}>
                          <AutocompleteSearch
                            textDecoration={
                              selectedDiagnosisCodesList?.label.includes('- *')
                                ? 'underline'
                                : 'none'
                            }
                            labelId={'referralDiagnosisCodeText'}
                            defaultId="Referral Diagnosis Code"
                            optionHintId={'noReferralDropdownTextId'}
                            renderOption={(props, option: OptionType) => {
                              const parts = option.label.split(' - ');
                              let beforeHyphen = '';
                              let afterHyphen = '';

                              if (parts.length > 1) {
                                beforeHyphen = parts[0];
                                afterHyphen = parts?.slice(1)?.join(' - ');
                              } else {
                                beforeHyphen = option.label;
                              }

                              const underlineStyle = afterHyphen.includes('*')
                                ? 'underline'
                                : 'none';

                              return (
                                <li {...props}>
                                  {parts.length > 1 ? (
                                    <>
                                      <span>{beforeHyphen} - </span>
                                      <span
                                        style={{
                                          textDecoration: underlineStyle,
                                        }}
                                      >
                                        {afterHyphen}
                                      </span>
                                    </>
                                  ) : (
                                    <span
                                      style={{
                                        textDecoration: underlineStyle,
                                      }}
                                    >
                                      {beforeHyphen}
                                    </span>
                                  )}
                                </li>
                              );
                            }}
                            sxProps={{
                              [`& .${autocompleteClasses.popupIndicator}`]: {
                                transform: 'none',
                              },
                              backgroundColor: 'white',
                            }}
                            data={diagnosisCodesList}
                            selectedValue={
                              selectedDiagnosisCodesList
                                ? selectedDiagnosisCodesList
                                : { id: '', label: '' }
                            }
                            onSelectItem={(value: OptionType | null) => {
                              if (value === null) {
                                setSelectedDiagnosisCodesList(null);
                                setFieldValue('referralDiagnosisCode', '');
                              } else {
                                setSelectedDiagnosisCodesList(value);
                                setFieldValue(
                                  'referralDiagnosisCode',
                                  value.id,
                                );
                              }
                            }}
                            disableLabel={false}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        direction={'row'}
                        spacing={2}
                        className="mt-sm"
                      >
                        <Grid item xs={12} lg={12}>
                          <Box>
                            <MediumTypography
                              labelid="eicsConsentToAccessInsuranceText"
                              defaultlabel="EICS Consent To Access Insurance"
                              sxProps={{
                                fontWeight: 'bold',
                                fontSize: '14px',
                                marginBottom: '8px',
                              }}
                            />
                            <CustomRadioButton
                              data={eicsConcentValues}
                              value={values.eicsConsentToAccessInsurance.toString()}
                              onSelectItem={(value) => {
                                if (value !== null) {
                                  setFieldValue(
                                    'eicsConsentToAccessInsurance',
                                    parseInt(value),
                                  );
                                  setFieldValue(
                                    'consentToAccessStartDate',
                                    dayjs().format('MM/DD/YYYY'),
                                  );
                                }
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={4} lg={4} sx={{ marginTop: '-8px' }}>
                          <DatePickerComponent
                            labelid="consentDateText"
                            defaultlabelid="Insurance Consent Date"
                            value={dayjs(values.consentToAccessStartDate)}
                            disabledDate={true}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        direction={'row'}
                        spacing={2}
                        className="pt-md"
                      >
                        <Grid item xs={12} lg={12}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <MediumTypography
                              className="mb-sm"
                              labelid="Facesheet.povertyLevelTitle"
                              defaultlabel="Poverty Level"
                              sxProps={{
                                color: '#2A4241',
                                fontWeight: 'bold',
                                fontSize: '14px',
                                marginRight: '16px',
                              }}
                            />
                            {resourceDto?.resourceUrl && (
                              <Document
                                style={{
                                  width: '15px',
                                  marginBottom: 'auto',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  const extension = resourceDto?.resourceUrl
                                    ? extractFileExtension(
                                        resourceDto.resourceUrl,
                                      ).toLowerCase()
                                    : '';
                                  if (
                                    PREVIEW_DOCUMENT_EXTENSIONS.includes(
                                      extension,
                                    )
                                  ) {
                                    handlePreviewClick(resourceDto);
                                  }
                                }}
                              />
                            )}
                          </Box>
                          <CustomRadioButton
                            data={PovertyJsonData}
                            value={values.povertyLevel}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('povertyLevel', value);
                              }
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        direction={'row'}
                        spacing={2}
                        className="pt-md pb-md"
                      >
                        <Grid item xs={12} lg={12}>
                          <MediumTypography
                            labelid="AddNewReferralDetails.referalContactText"
                            defaultlabel="Referral Contact"
                            sxProps={{
                              color: '#2A4241',
                              fontWeight: 'bold',
                              fontSize: '14px',
                              marginRight: '16px',
                            }}
                          />
                        </Grid>
                      </Grid>

                      {faceSheetForm.referralContact.map((__, index) => (
                        <>
                          <Box
                            className="p-md gray3 borderRadius4"
                            sx={{
                              border: '1px solid #00C6B8',
                            }}
                          >
                            <Grid
                              container
                              direction={'row'}
                              spacing={2}
                              key={index}
                            >
                              <Grid item xs={6} lg={6}>
                                <Textinput
                                  name="referralContactName"
                                  labelid="AddNewReferralDetails.Name"
                                  defaultlabelid="Name"
                                  Value={
                                    values.referralContact[index]
                                      .referralContactName
                                  }
                                  inputProps={{
                                    maxLength: 50,
                                  }}
                                  handlechange={(value: string) => {
                                    const validatedValue =
                                      nameValidation(value);
                                    if (validatedValue !== undefined) {
                                      setFieldValue(
                                        `referralContact[${index}].referralContactName`,
                                        validatedValue,
                                      );
                                      handleValueUpdates(
                                        index,
                                        'referralContactName',
                                        validatedValue,
                                      );
                                    }
                                  }}
                                  sxProps={{
                                    backgroundColor: 'white',
                                  }}
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                />
                              </Grid>

                              <Grid item xs={6} lg={6}>
                                <Textinput
                                  name="referralContactJobTitle"
                                  Value={
                                    values.referralContact[index]
                                      .referralContactJobTitle
                                  }
                                  labelid="AddNewReferralDetails.JobTitle"
                                  defaultlabelid="Job Title"
                                  inputProps={{
                                    maxLength: 50,
                                  }}
                                  handlechange={(value: string) => {
                                    const validatedValue =
                                      nameValidation(value);
                                    if (validatedValue !== undefined) {
                                      setFieldValue(
                                        `referralContact[${index}].referralContactJobTitle`,
                                        validatedValue,
                                      );
                                      handleValueUpdates(
                                        index,
                                        'referralContactJobTitle',
                                        validatedValue,
                                      );
                                    }
                                  }}
                                  sxProps={{
                                    backgroundColor: 'white',
                                  }}
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                />
                              </Grid>
                              <Grid item xs={6} lg={6} className="pt-none">
                                <Box component="div" className="flex__">
                                  <CustomCheckBox
                                    value={values.referralContact[index].isDcf}
                                    style={{ marginTop: '14px' }}
                                    onCheckBoxClick={(value) => {
                                      setFieldValue(
                                        `referralContact[${index}].isDcf`,
                                        value,
                                      );
                                      handleValueUpdates(index, 'isDcf', value);
                                    }}
                                  />
                                  <MediumTypography
                                    labelid="AddNewReferralDetails.DCF"
                                    defaultlabel="DCF"
                                    sxProps={{
                                      color: '#2A4241',
                                      fontWeight: 'bold',
                                      fontSize: '16px',
                                      marginTop: '20px',
                                    }}
                                  />
                                </Box>
                              </Grid>

                              <Grid item xs={6} lg={6}></Grid>

                              <Grid item xs={6} lg={6}>
                                <DatePickerComponent
                                  value={
                                    dayjs(
                                      values.referralContact[index]
                                        .referralContactDate,
                                    ) ?? null
                                  }
                                  labelid="AddNewReferralDetails.referralDate"
                                  disableFuture={true}
                                  minDate={dayjs(
                                    faceSheetDetails.demographics.dateOfBirth,
                                  )}
                                  defaultlabelid="Referral Contact Date"
                                  handlechange={(date: Dayjs | null) => {
                                    const formattedDate =
                                      dayjs(date).format('MM/DD/YYYY');
                                    if (date === null) {
                                      setFieldValue(
                                        `referralContact[${index}].referralContactDate`,
                                        null,
                                      );
                                      handleValueUpdates(
                                        index,
                                        'referralContactDate',
                                        null,
                                      );
                                    } else {
                                      setFieldValue(
                                        `referralContact[${index}].referralContactDate`,
                                        formattedDate,
                                      );
                                      handleValueUpdates(
                                        index,
                                        'referralContactDate',
                                        formattedDate,
                                      );
                                    }
                                  }}
                                  required={true}
                                  className="bg__white"
                                />
                                {errors.referralContact &&
                                  errors.referralContact[index] &&
                                  (
                                    errors.referralContact[
                                      index
                                    ] as FormikErrors<ReferralContactJsonType>
                                  ).referralContactDate &&
                                  touched.referralContact &&
                                  touched.referralContact[index] &&
                                  touched.referralContact[index]
                                    .referralContactDate && (
                                    <MediumTypography
                                      labelid={
                                        (
                                          errors.referralContact[
                                            index
                                          ] as FormikErrors<ReferralContactJsonType>
                                        ).referralContactDate
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={6} lg={6}>
                                <DropdownComponent
                                  Required
                                  names={referralSources}
                                  labelid="AddNewReferralDetails.referralSource"
                                  defaultlabelid="Referral Source"
                                  value={
                                    values.referralContact[index].referralSource
                                  }
                                  handleChange={(value) => {
                                    setFieldValue(
                                      `referralContact[${index}].referralSource`,
                                      value,
                                    );
                                    handleValueUpdates(
                                      index,
                                      'referralSource',
                                      value,
                                    );
                                  }}
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                />
                                {errors.referralContact &&
                                  errors.referralContact[index] &&
                                  (
                                    errors.referralContact[
                                      index
                                    ] as FormikErrors<ReferralContactJsonType>
                                  ).referralSource &&
                                  touched.referralContact &&
                                  touched.referralContact[index] &&
                                  touched.referralContact[index]
                                    .referralSource && (
                                    <MediumTypography
                                      labelid={
                                        (
                                          errors.referralContact[
                                            index
                                          ] as FormikErrors<ReferralContactJsonType>
                                        ).referralSource
                                      }
                                      defaultlabel="Referral Source is Required"
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>

                              <Grid item xs={6} lg={6}>
                                <DropdownComponent
                                  Required
                                  names={referralReasons}
                                  labelid="AddNewReferralDetails.referralReason"
                                  defaultlabelid="Referral Reason"
                                  value={
                                    values.referralContact[index].referralReason
                                  }
                                  handleChange={(value) => {
                                    setFieldValue(
                                      `referralContact[${index}].referralReason`,
                                      value,
                                    );
                                    handleValueUpdates(
                                      index,
                                      'referralReason',
                                      value,
                                    );
                                  }}
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                />

                                {errors.referralContact &&
                                  errors.referralContact[index] &&
                                  (
                                    errors.referralContact[
                                      index
                                    ] as FormikErrors<ReferralContactJsonType>
                                  ).referralReason &&
                                  touched.referralContact &&
                                  touched.referralContact[index] &&
                                  touched.referralContact[index]
                                    .referralReason && (
                                    <MediumTypography
                                      labelid={
                                        (
                                          errors.referralContact[
                                            index
                                          ] as FormikErrors<ReferralContactJsonType>
                                        ).referralReason
                                      }
                                      defaultlabel="Referral Reason is Required"
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={6} lg={6}>
                                <Textinput
                                  name="referralContactEmail"
                                  Value={
                                    values.referralContact[index]
                                      .referralContactEmail
                                  }
                                  labelid="AddNewReferralDetails.Email"
                                  defaultlabelid="Email"
                                  Required={false}
                                  inputProps={{
                                    maxLength: 50,
                                  }}
                                  handlechange={(value: string) => {
                                    setFieldValue(
                                      `referralContact[${index}].referralContactEmail`,
                                      value,
                                    );
                                    handleValueUpdates(
                                      index,
                                      'referralContactEmail',
                                      value,
                                    );
                                  }}
                                  sxProps={{
                                    backgroundColor: 'white',
                                  }}
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                />
                              </Grid>

                              <Grid item xs={6} lg={6}>
                                <Textinput
                                  name="referralContactPhone"
                                  Value={
                                    values.referralContact[index]
                                      .referralContactPhone
                                  }
                                  labelid="AddNewReferralDetails.referralPhone"
                                  defaultlabelid="Phone"
                                  inputProps={{
                                    maxLength: 50,
                                  }}
                                  handlechange={(value: string) => {
                                    setFieldValue(
                                      `referralContact[${index}].referralContactPhone`,
                                      formatPhoneNumber(value),
                                    );
                                    handleValueUpdates(
                                      index,
                                      'referralContactPhone',
                                      value,
                                    );
                                  }}
                                  sxProps={{
                                    backgroundColor: 'white',
                                  }}
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                />
                              </Grid>
                              <Grid item xs={6} lg={6}>
                                <Textinput
                                  name="referralContactFax"
                                  Value={
                                    values.referralContact[index]
                                      .referralContactFax
                                  }
                                  labelid="AddNewReferralDetails.Fax"
                                  defaultlabelid="Fax"
                                  Required={false}
                                  inputProps={{
                                    maxLength: 50,
                                  }}
                                  handlechange={(value: string) => {
                                    setFieldValue(
                                      `referralContact[${index}].referralContactFax`,
                                      formatPhoneNumber(value),
                                    );
                                    handleValueUpdates(
                                      index,
                                      'referralContactFax',
                                      value,
                                    );
                                  }}
                                  sxProps={{
                                    backgroundColor: 'white',
                                  }}
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                />
                              </Grid>

                              <Grid item xs={12} lg={12}>
                                <CommentTextbox
                                  name="notes"
                                  placeholder={rctl.formatMessage({
                                    id: 'AddNewReferralDetails.notes',
                                    defaultMessage: 'Notes',
                                  })}
                                  Value={values.referralContact[index].notes}
                                  maxLength={2000}
                                  handlechange={(value: string) => {
                                    setFieldValue(
                                      `referralContact[${index}].notes`,
                                      value,
                                    );
                                    handleValueUpdates(index, 'notes', value);
                                  }}
                                  placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                                />
                              </Grid>
                            </Grid>
                          </Box>

                          <Box className="flex__">
                            {index === values.referralContact.length - 1 &&
                              values.referralContact.length < 3 && (
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                  className="mt-sm mb-sm"
                                >
                                  <Grid item>
                                    <ButtonComponent
                                      className="btn-add-code"
                                      variantType="contained"
                                      type="submit"
                                      labelId="Facesheet.anotherReferral"
                                      defaultLabelId="+ Add Another Referral Contact"
                                      onClick={() => handleAddLink()}
                                    />
                                  </Grid>
                                </Grid>
                              )}

                            {values.referralContact.length > 1 &&
                            (values.referralContact[index].id === '' ||
                              values.referralContact[index].id === null) ? (
                              <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                                className="mt-sm mb-sm"
                              >
                                <Grid item>
                                  <TextWithIcon
                                    LeftIcon={<DeleteIconBtn />}
                                    labelId="deleteText"
                                    defaultLabel="Delete"
                                    sxProps={{
                                      color: '#EB4C60',
                                      cursor: 'pointer',
                                      textDecoration: 'underline',
                                      fontSize: '14px',
                                      fontFamily: 'Lato-Regular',
                                      textTransform: 'none',
                                    }}
                                    onClick={() => handleDeleteLink(index)}
                                  />
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                                className="mt-sm mb-sm"
                              ></Grid>
                            )}
                          </Box>
                        </>
                      ))}
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Box
                      sx={{ margin: '12px' }}
                      display={'flex'}
                      justifyContent={'flex-end'}
                    >
                      <ButtonComponent
                        className="btn-primary btn-cancel alrt_Width_btn mr-md"
                        variantType="contained"
                        labelId={'cancelText'}
                        defaultLabelId={'Cancel'}
                        onClick={() =>
                          _.isEqual(faceSheetFormBkp.current, values)
                            ? setFormDialogFlag(false)
                            : setCancelModal(true)
                        }
                      />

                      <ButtonComponent
                        variantType="contained"
                        type="submit"
                        className="btn-primary btn-submit alrt_Width_btn"
                        labelId={'saveText'}
                        defaultLabelId={'Save'}
                        onClick={handleSubmit}
                        disabled={
                          _.isEqual(faceSheetFormBkp.current, values)
                            ? true
                            : false
                        }
                      />
                    </Box>
                  </DialogActions>
                </>
              )}
            </Formik>
          </Dialog>
        )}

        <Box className="rowContainer">
          <CustomBackArrow
            onClick={async () => {
              localStorage.removeItem('Demographics');
              if (lookupsDemographics) {
                updateDemographics({
                  ...lookupsDemographics,
                  firstName: '',
                  middleName: '',
                  lastName: '',
                  ageInMonths: null,
                  sex: null,
                  dateOfBirth: '',
                });
              }
              navigation(CLIENTS_DASHBOARD_ROUTE_NAME);
            }}
          />
          <Box
            component="div"
            className="ml-md"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center', // Center items vertically
              width: '100%',
            }}
          >
            <Box>
              <TitleText
                labelid="Facesheet.Titletext"
                defaultlabel="Facesheet"
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {makedownload && (
                <Box
                  onClick={fetchOfflineData}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <DownloadIcon style={{ marginRight: '8px' }} />
                  <MediumTypography
                    labelid={'offlinedownloadtxt'}
                    defaultlabel={'Download offline'}
                    sxProps={{
                      color: '#008C82',
                      fontSize: '14px',
                      textAlign: 'justify',
                      marginRight: '24px',
                      whiteSpace: 'nowrap',
                    }}
                  />
                </Box>
              )}
              {deletedownload && (
                <Box
                  onClick={deleteOfflineData}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <DeleteIcon style={{ marginRight: '8px' }} />
                  <MediumTypography
                    labelid={'offlinedeletetxt'}
                    defaultlabel={'Delete offline'}
                    sxProps={{
                      color: '#008C82',
                      fontSize: '14px',
                      textAlign: 'justify',
                      marginRight: '24px',
                      whiteSpace: 'nowrap',
                    }}
                  />
                </Box>
              )}
              {sync && (
                <Box
                  onClick={syncData}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SyncIcon style={{ marginRight: '8px' }} />

                  <MediumTypography
                    labelid={'offlinesynctxt'}
                    defaultlabel={'Sync'}
                    sxProps={{
                      color: '#008C82',
                      fontSize: '14px',
                      textAlign: 'justify',
                      marginRight: '24px',
                      whiteSpace: 'nowrap',
                    }}
                  />
                </Box>
              )}
              <MediumTypography
                labelid={'Facesheet.DPHID'}
                defaultlabel={'Enrollment ID'}
                sxProps={{
                  color: '#00C6B8',
                  fontSize: '14px',
                  textAlign: 'justify',
                  marginRight: '5px',
                  whiteSpace: 'nowrap',
                }}
              />
              <DropdownComponent
                names={dphIdValues}
                labelid={''}
                value={selectedDphId ? selectedDphId : ''}
                defaultlabelid=""
                handleChange={async (e) => {
                  if (e) {
                    setSelectedDphId(e);
                    localStorage.setItem(REFERRAL_ID_KEY, e);
                    await fetchFaceSheetDetails(diagnosisCodesList);
                  }
                }}
              />
            </Box>
          </Box>
        </Box>

        {faceSheetDetails && (
          <Grid
            container
            columnSpacing={'12px'}
            rowSpacing={'12px'}
            sx={{ marginBottom: '24px' }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {/* // This is Commented as Temproary for reverting the feature */}
              {/* {faceSheetDetails.demographics &&
                faceSheetDetails.demographics.appSource === 'TBR' && (
                  <Box
                    style={{
                      ...cardStyle,
                      padding: '0px',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      position: 'relative',
                    }}
                  >
                    {faceSheetDetails && (
                      <ClientJourneyWrapper
                        data={faceSheetDetails.clientJourney}
                        ifspDueDate={faceSheetDetails.demographics.ifspDueDate}
                      />
                    )}
                    <Box
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(42, 66, 65, 0.6)', // Semi-transparent dark overlay
                        zIndex: 1, // Ensures it appears above the component
                        borderRadius: '6px',
                      }}
                    />
                    <Box
                      style={{
                        position: 'absolute',
                        top: '50%', // Center vertically
                        left: '50%', // Center horizontally
                        transform: 'translate(-50%, -50%)', // Proper centering
                        display: 'flex',
                        gap: '16px', // Space between badges
                        zIndex: 2, // Ensure badges appear above overlay
                        flexWrap: 'wrap', // Allow wrapping if there's not enough space
                        justifyContent: 'center', // Center badges horizontally
                        alignItems: 'center', // Center badges vertically
                      }}
                    >
                      <Box
                        style={{
                          backgroundColor: '#89f0ea', // Badge background color
                          color: '#000', // Badge text color
                          padding: '16px 32px',
                          borderRadius: '75px',
                          textAlign: 'center',
                        }}
                      >
                        <MediumTypography
                          labelid={'Facesheet.SSC'}
                          defaultlabel={'Secondary Services Client'}
                          fontweight={600}
                          lineHeight="14px"
                        />
                      </Box>
                      <Box
                        style={{
                          backgroundColor: '#89f0ea', // Different badge background color
                          color: '#000', // Different badge text color
                          padding: '16px 32px',
                          borderRadius: '75px',
                          textAlign: 'center',
                        }}
                      >
                        <MediumTypography
                          labelid={'Facesheet.SSC'}
                          defaultlabel={'Client Created in TBR'}
                          fontweight={600}
                          lineHeight="14px"
                        />
                      </Box>
                    </Box>
                  </Box>
                )} */}

              {/* {faceSheetDetails.demographics &&
                faceSheetDetails.demographics.appSource !== 'TBR' && ( */}
              <Box
                style={{
                  ...cardStyle,
                  padding: '0px',
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  position: 'relative',
                }}
              >
                {faceSheetDetails && (
                  <ClientJourneyWrapper
                    data={faceSheetDetails.clientJourney}
                    ifspDueDate={faceSheetDetails.demographics.ifspDueDate}
                  />
                )}
              </Box>
              {/* )} */}
            </Grid>

            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Box style={cardStyle}>
                {faceSheetDetails &&
                  serviceCoordinatorsList &&
                  intakeCoordinatorsList && (
                    <ClientInfoFaceSheet
                      data={faceSheetDetails}
                      handleServiceDailog={handleServiceDailog}
                      handleIntakeDailog={handleIntakeDailog}
                      handleFormDailog={handleFormDailog}
                      handleSSPProviders={() => ({})}
                      serviceCoordinatorsList={serviceCoordinatorsList}
                      intakeCoordinatorsList={intakeCoordinatorsList}
                    />
                  )}
              </Box>
              <Box
                sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <Box
                  style={{
                    ...cardStyle,
                    width: '50%',
                    marginTop: '12px',
                    marginRight: '12px',
                    height: '100%',
                    minHeight: '200px',
                    maxHeight: 'max-content',
                  }}
                >
                  {faceSheetDetails && (
                    <CommunicationFaceSheet
                      data={faceSheetDetails.communicationLogs}
                    />
                  )}
                </Box>
                <Box
                  style={{
                    ...cardStyle,
                    width: '50%',
                    marginTop: '12px',
                    height: '100%',
                    minHeight: '200px',
                    maxHeight: 'max-content',
                  }}
                >
                  {faceSheetDetails && (
                    <ClientFilesFaceSheet data={faceSheetDetails.clientFiles} />
                  )}
                </Box>
              </Box>
              <Box
                style={{
                  ...cardStyle,
                  marginTop: '12px',
                  height: '100%',
                  minHeight: '225px',
                  maxHeight: 'max-content',
                }}
              >
                {faceSheetDetails && (
                  <TransitionFaceSheet data={faceSheetDetails.transition} />
                )}
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box
                style={{
                  ...cardStyle,
                  backgroundColor: '#00C6B8',
                }}
              >
                {faceSheetDetails && (
                  <UpcomingEventsFaceSheet
                    data={faceSheetDetails.appointments}
                  />
                )}
              </Box>
              <Box style={{ ...cardStyle, marginTop: '12px' }}>
                {faceSheetDetails && (
                  <InsuranceFaceSheet data={faceSheetDetails.insurances} />
                )}
              </Box>
              <Box style={{ ...cardStyle, marginTop: '12px' }}>
                {faceSheetDetails && (
                  <ProgressNotesFaceSheet
                    data={faceSheetDetails.progressNotes}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        )}

        {cancelModal && (
          <ModalPopup
            open={cancelModal}
            onCancel={() => {
              setCancelModal(false);
            }}
            description="formUnsavedChangesMessage"
            onOk={() => {
              handleCloseModal();
              setCancelModal(false);
            }}
            labelId1="Clientpage.cancelbtn"
            negativeActionLabel="cancelText"
            labelId2="Clientpage.Okbtn"
            positiveActionLabel="deleteText"
          />
        )}
      </Box>
    </Box>
  );
};

export default FaceSheetScreen;
