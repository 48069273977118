import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Grid,
  Card,
  Box,
  AlertColor,
  autocompleteClasses,
} from '@mui/material';
import '../layouts/header/DashboardHeadercomponent.css';
import Textinput from '../components/formlib/Textinput';
import SwitchComponent from '../components/formlib/SwitchComponent';
import DatePickerComponent from '../components/formlib/DatePickerComponent';
import RadioComponent from '../components/formlib/RadioComponent';
import SelectComponent from '../components/formlib/SelectComponent';
import * as yup from 'yup';
import { Formik, FormikProps } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import { PaletteColorOptions } from '@mui/material/styles';
import MediumTypography from '../components/formlib/MediumTypography';
import CommentTextbox from '../components/formlib/CommentTextbox';
import CheckBoxComponent from '../components/formlib/CheckBoxComponent';
import { Tooltip } from '@mui/material';
import {
  AddNewClient,
  FaceSheetDemographicsType,
  OptionType,
} from '../utils/type';
import {
  deleteClientProfilePicture,
  updateClientdata,
  uploadProfilePic,
  viewClientDetails,
} from '../services/configApi/User';
import SnackBarComponent from '../components/formlib/SnackBarComponent';
import ButtonComponent from '../components/formlib/ButtonComponent';
import ModalPopup from '../components/formlib/ModalPopup';
import { TabProps } from '../components/formlib/TabNavigationComponent';
import { Gender } from './Dropdown';
import AlertDialogSlide from '../components/formlib/AlertDialogSlide';
import './ClientsDashboardPage.css';
import AddressForm from '../components/formlib/AddressForm';
import ReactImagePickerEditor from '../components/imagepickers/ImagePicker';
import { ImagePickerConf } from '../components/imagepickers/imagepicker.models';
import { dataURItoFile } from '../utils/fileUtils';
import { useIntl } from 'react-intl';
import {
  calculateMonths,
  datePickerMinDateMaxDateValidate,
  threeYearsAgo,
} from '../utils/dateUtil';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../services/ApiResponseHandler';
import _ from 'lodash';
import {
  convertGenderToBackend,
  convertGenderToFrontend,
} from '../utils/genderUtil';
import { checkPermissionForFeature } from '../utils/checkPermission';
import {
  LookUpContext,
  LookUpContextType,
} from '../context/LookUpContextProvider';
import MultipleAutocompleteSearch from '../components/formlib/MultipleAutocompleteSearch';
import DropdownComponent from '../components/formlib/DropdownComponent';
import CustomRadioButton from '../components/formlib/CustomRadioButton';
import { EICSRadioData } from './intake/IntakeClientsInfo';
import { IntakeRadioData } from './intake/IntakePregnancyInfo';
import { LoaderContext, LoaderContextType } from '../layouts/AppSidebar';
import AutocompleteSearch from '../components/formlib/AutocompleteSearch';
import {
  Codes,
  getCodesAndDescription,
} from '../services/configApi/codelist/diagnosticCodes';
import SimilarClientsUpdateModal from './referral/SimilarClientsUpdateModal';
import { SimilarClient } from './referral/type';

declare module '@mui/material/styles' {
  interface CustomPalette {
    pink: PaletteColorOptions;
    normal: PaletteColorOptions;
  }
  interface PaletteOptions extends CustomPalette {
    pink: PaletteColorOptions;
    normal: PaletteColorOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    pink: true;
    normal: true;
  }
}
interface AddressProps {
  updateData: (
    newData: AddNewClient,
    tabposition: number,
    flag: boolean,
  ) => void;
  setTabProp: React.Dispatch<React.SetStateAction<TabProps>>;
}
const intialState: AddNewClient = {
  id: 0,
  firstName: '',
  middleName: '',
  lastName: '',
  nickName: '',
  nickNameWithheldIndicator: false,
  dateOfBirth: null,
  sex: '',
  race: '',
  ethnicity: '',
  multipleBirth: '',
  childBorn: '',
  childAdopted: '',
  primarySpokenLanguage: '',
  primarySpokenLanguageOther: '',
  primaryWrittenLanguage: '',
  primaryWrittenLanguageOther: '',
  languageForPriorWrittenNotice: '',
  languageForPriorWrittenNoticeOther: '',
  smokingInTheHouse: null,
  petsInTheHouse: null,
  weapons: null,
  weaponSecure: null,
  street: '',
  address2: '',
  city: '',
  zipCode: { id: '', label: '' },
  state: '',
  dphId: null,
  mailingAddressFlag: false,
  mStreet: '',
  mAddress2: '',
  mZipCode: { id: '', label: '' },
  mCity: '',
  mState: '',
  remainingAssessmentHours: '',
  evaluationAddressFlag: false,
  eCity: '',
  eState: '',
  eStreet: '',
  eAddress2: '',
  eZipCode: { id: '', label: '' },
  enterNote: '',
  referralDate: null,
  ifspDueDate: null,
  clientId: '',
  clientUuid: '',
  appSource: '',
  version: 0,
  referralId: '',
  profileUploadId: null,
  profileImageUrl: null,
  evaluationStatus: false,
  consentToShare: false,
  billingDiagnosisCode: null,
  similarClients: [],
};

const validationSchema = yup.object().shape({
  firstName: yup.string().required('Clientpage.First.validation'),
  middleName: yup.string(),
  lastName: yup.string().required('Clientpage.last.validation').nullable(),
  nickName: yup.string().nullable(),
  nickNameWithheldIndicator: yup.boolean().nullable(),
  multipleBirth: yup.string().nullable(),
  childBorn: yup.string().nullable(),
  childAdopted: yup.string().nullable(),
  dateOfBirth: yup
    .date()
    .required('Clientpage.dateOfBirth.validation')
    .nullable(),
  consentToShare: yup.boolean().default(false),
  billingDiagnosisCode: yup.string().nullable(),
  sex: yup.string().required('Clientpage.Gender.validation').nullable(),
  race: yup.string().nullable(),
  ethnicity: yup.string().nullable(),
  primarySpokenLanguage: yup.string().nullable(),
  primarySpokenLanguageOther: yup.string().when('primarySpokenLanguage', {
    is: 'OTHER',
    then: yup
      .string()
      .required('Clientpage.PrimarySpoken.validation')
      .nullable(),
    otherwise: yup.string().nullable(),
  }),
  primaryWrittenLanguage: yup.string().nullable(),
  primaryWrittenLanguageOther: yup.string().when('primaryWrittenLanguage', {
    is: 'OTHER',
    then: yup
      .string()
      .required('Clientpage.PrimaryWritten.validation')
      .nullable(),
    otherwise: yup.string().nullable(),
  }),
  languageForPriorWrittenNotice: yup.string().nullable(),
  languageForPriorWrittenNoticeOther: yup
    .string()
    .when('languageForPriorWrittenNotice', {
      is: 'OTHER',
      then: yup
        .string()
        .required('Clientpage.PriorNotice.validation')
        .nullable(),
      otherwise: yup.string().nullable(),
    }),
  smokingInTheHouse: yup.boolean().nullable(),
  petsInTheHouse: yup.boolean().nullable(),
  weapons: yup.boolean().nullable(),
  weaponSecure: yup.boolean().when('weapons', {
    is: true,
    then: yup.boolean().nullable(),
    otherwise: yup.boolean().nullable(),
  }),
  enterNote: yup.string().nullable(),
  referralDate: yup
    .date()
    .required('AddNewReferralDetails.referralDate.Validation')
    .nullable(),
  ifspDueDate: yup
    .date()
    .required('AddNewReferralDetails.ifspDueDate.Validation')
    .nullable(),
  street: yup.string().required('addressLine1RequiredMessage').nullable(),
  address2: yup.string().nullable(),
  state: yup.string().required('stateRequiredMessage').nullable(),
  zipCode: yup
    .object()
    .shape({
      label: yup.string().required('zipCodeRequiredMessage'),
    })
    .nullable(),
  city: yup.string().required('cityRequiredMessage').nullable(),
  mailingAddressFlag: yup.boolean().nullable(),
  mStreet: yup.string().when('mailingAddressFlag', {
    is: true,
    then: yup.string().required('addressLine1RequiredMessage'),
    otherwise: yup.string().nullable(),
  }),
  mAddress2: yup.string().when('mailingAddressFlag', {
    is: true,
    then: yup.string().nullable(),
    otherwise: yup.string().nullable(),
  }),
  mCity: yup.string().when('mailingAddressFlag', {
    is: true,
    then: yup.string().required('Clientpage.City.validation'),
    otherwise: yup.string().nullable(),
  }),
  mState: yup.string().when('mailingAddressFlag', {
    is: true,
    then: yup.string().required('Clientpage.State.validation'),
    otherwise: yup.string().nullable(),
  }),
  mZipCode: yup.object().when('mailingAddressFlag', {
    is: true,
    then: yup
      .object()
      .shape({
        label: yup.string().required('Clientpage.Zipcode.validation'),
      })
      .nullable(),
    otherwise: yup.object().nullable(),
  }),
  eStreet: yup.string().required('addressLine1RequiredMessage').nullable(),
  eAddress2: yup.string().nullable(),
  eCity: yup.string().required('Clientpage.City.validation').nullable(),
  eState: yup.string().required('Clientpage.State.validation').nullable(),
  eZipCode: yup
    .object()
    .shape({
      label: yup.string().required('Clientpage.Zipcode.validation'),
    })
    .nullable(),
});

const config: ImagePickerConf = {
  borderRadius: '100%',
  language: 'en',
  objectFit: 'cover',
  aspectRatio: 1,
  compressInitial: null,
  hideDownloadBtn: true,
  hideAddBtn: true,
  hideEditBtn: true,
  hideDeleteBtn: true,
  type: 'ProfilePicture',
};

const ClientPage: React.FC<AddressProps> = ({ updateData }: AddressProps) => {
  const [imageSrc, setImageSrc] = React.useState<string | null>('');
  const location = useLocation();
  const rctl = useIntl();
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [clientDetails, setClientDetails] =
    React.useState<AddNewClient>(intialState);
  const [renderView, setrenderView] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [weaponAlert, setWeaponAlert] = React.useState(false);
  const formRef = React.useRef<FormikProps<AddNewClient>>(null);
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [btnloader, setBtnloader] = React.useState(false);
  const [addressCheckBox, setAddressCheckBox] = React.useState<boolean>(false);
  const [mailingAddressCheckBox, setMailingAddressCheckBox] =
    React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isScreenLocked, setIsScreenLocked] = React.useState<boolean>(false);

  const [raceValues, setRaceValues] = React.useState<OptionType[]>([]);
  const [ethnicityValues, setEthnicityValues] = React.useState<OptionType[]>(
    [],
  );
  const [languageValues, setLanguageValues] = React.useState<OptionType[]>([]);
  const [selectedDiagnosisCodesList, setSelectedDiagnosisCodesList] =
    React.useState<OptionType | null>(null);
  const [diagnosisCodesList, setDiagnosisCodesList] = React.useState<
    OptionType[]
  >([]);
  const { lookups } = React.useContext(LookUpContext) as LookUpContextType;
  const [showSimilarClientsModal, setShowSimilarClientsModal] =
    React.useState<boolean>(false);
  const [similarClients, setSimilarClients] = React.useState<SimilarClient[]>(
    [],
  );

  React.useEffect(() => {
    if (diagnosisCodesList.length > 0) {
      const billingDiagnosisCode = clientDetails.billingDiagnosisCode;
      if (billingDiagnosisCode) {
        const code = diagnosisCodesList.find(
          (item) => item.id === billingDiagnosisCode,
        );
        if (code) {
          setSelectedDiagnosisCodesList(code);
        }
      }
    }
  }, [diagnosisCodesList, clientDetails]);

  React.useEffect(() => {
    const handleDiagnosisDataGetAPI = async () => {
      getCodesAndDescription()
        .then((response: Codes[]) => {
          const diagnosisCodeList: OptionType[] = [];
          if (response.length > 0) {
            response.forEach((codeDetails) => {
              diagnosisCodeList.push({
                id: codeDetails.code,
                label: codeDetails.isUnderlined
                  ? `${codeDetails.code} - * ${codeDetails.description}`
                  : `${codeDetails.code} - ${codeDetails.description}`,
              });
            });
            setDiagnosisCodesList(diagnosisCodeList);
          }
        })
        .catch(() => {});
    };

    handleDiagnosisDataGetAPI();
  }, []);

  React.useEffect(() => {
    if (lookups === null) {
      return;
    }
    setRaceValues(() => {
      return lookups.race.map((r) => {
        return {
          label: r.description,
          id: r.code,
        };
      });
    });
    setEthnicityValues(() => {
      return lookups.ethnicity.map((e) => {
        return {
          label: e.description,
          id: e.code,
        };
      });
    });
    setLanguageValues(() => {
      return lookups.language.map((lang) => {
        return {
          label: lang.description,
          id: lang.code,
        };
      });
    });
  }, [lookups]);

  React.useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);
  const parentRef = React.useRef<HTMLDivElement>(null);

  const { updateDemographics } = React.useContext(
    LookUpContext,
  ) as LookUpContextType;

  const validateForm = (value: AddNewClient) => {
    const errors: Partial<AddNewClient> = {};
    if (
      value.dateOfBirth &&
      !dayjs(clientDetails.dateOfBirth).isSame(dayjs(value.dateOfBirth))
    ) {
      if (
        dayjs(value.dateOfBirth).isBefore(
          dayjs(threeYearsAgo()).format('MM/DD/YYYY'),
        )
      ) {
        errors.dateOfBirth = 'validDate';
      }
    }
    if (datePickerMinDateMaxDateValidate(value.dateOfBirth)) {
      errors.dateOfBirth = 'datePickerMinDateMaxDateValidate';
    }
    return errors;
  };

  const uploadProfilePicture = (
    localStorageClientId: string,
    values: AddNewClient,
  ) => {
    const file: File | null = dataURItoFile(imageSrc);
    if (file !== null) {
      toggleLoader(true);
      setBtnloader(true);
      uploadProfilePic(localStorageClientId, file)
        .then((response) => {
          values.profileUploadId = response.id;
          updateClient(localStorageClientId, values, false);
        })
        .finally(() => {
          setIsSubmitting(false);
        })
        .catch(async (error) => {
          formRef.current?.setValues({
            ...values,
            version: values.version,
            zipCode: { id: 'aa', label: values.zipCode } as OptionType,
            eZipCode: { id: 'aa', label: values.eZipCode } as OptionType,
            mZipCode: { id: 'aa', label: values.mZipCode } as OptionType,
          });

          setOpen(true);
          toggleLoader(false);
          setBtnloader(false);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('Client.failupdate');
            setToastrDefaultMessage('Failed to update user');
          }
        });
    } else {
      toggleLoader(true);
      setBtnloader(true);
      updateClient(localStorageClientId, values, false);
    }
  };

  const formhandleSubmit = (values: AddNewClient) => {
    if (values !== undefined || null) {
      values.dateOfBirth = dayjs(values.dateOfBirth).format('MM/DD/YYYY');
    }
    values.firstName = values.firstName?.trim();
    values.middleName = values.middleName?.trim();
    values.lastName = values.lastName?.trim();
    values.sex =
      values.sex?.length === 1
        ? values.sex
        : convertGenderToBackend(values.sex !== null ? values.sex : '') ?? null;
    values.zipCode = (values.zipCode as OptionType).label;
    values.eZipCode = (values.eZipCode as OptionType).label;
    if (values.mZipCode !== null) {
      values.mZipCode = (values.mZipCode as OptionType).label;
    }
    const clientId = localStorage.getItem('ClientId');
    if (clientId !== null) {
      values.appSource = 'TCS';
      if (imageSrc === null && values.profileImageUrl !== null) {
        //remove client profile picture
        deleteProfilePicture(clientId, values);
      } else {
        uploadProfilePicture(clientId, values);
      }
    }
  };

  const deleteProfilePicture = (
    localStorageClientId: string,
    values: AddNewClient,
  ) => {
    setBtnloader(true);
    deleteClientProfilePicture(localStorageClientId)
      .then(() => {
        values.profileUploadId = '';
        values.profileImageUrl = '';
        updateClient(localStorageClientId, values, false);
      })
      .finally(() => {
        setIsSubmitting(false);
      })
      .catch((error) => {
        formRef.current?.setValues({
          ...values,
          version: values.version,
          zipCode: { id: 'aa', label: values.zipCode } as OptionType,
          eZipCode: { id: 'aa', label: values.eZipCode } as OptionType,
          mZipCode: { id: 'aa', label: values.mZipCode } as OptionType,
        });
        setOpen(true);
        toggleLoader(false);
        setBtnloader(false);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('Client.failupdate');
          setToastrDefaultMessage('Failed to update user');
        }
      });
  };

  const updateClient = (
    localStorageClientId: string,
    values: AddNewClient,
    skipSimilarClientCheck: boolean,
  ) => {
    if (!isSubmitting) {
      setIsSubmitting(true);

      updateClientdata(localStorageClientId, values, skipSimilarClientCheck)
        .then((response) => {
          setBtnloader(false);
          toggleLoader(false);
          if (response.similarClients && response.similarClients?.length > 0) {
            setShowSimilarClientsModal(true);
            setSimilarClients(response.similarClients);
          } else {
            updateStorageDemographics(response);
            formRef.current?.setValues({
              ...values,
              profileUploadId: response.profileUploadId,
              profileImageUrl: response.profileImageUrl,
              version: response.version,
              sex:
                convertGenderToFrontend(
                  response.sex !== null ? response.sex : '',
                ) ?? null,
              zipCode: { id: 'aa', label: response.zipCode } as OptionType,
              eZipCode: { id: 'aa', label: values.eZipCode } as OptionType,
              mZipCode: { id: 'aa', label: values.mZipCode } as OptionType,
            });
            setClientDetails({
              ...values,
              profileUploadId: response.profileUploadId,
              profileImageUrl: response.profileImageUrl,
              version: response.version,
              zipCode: { id: 'aa', label: response.zipCode } as OptionType,
              eZipCode: { id: 'aa', label: values.eZipCode } as OptionType,
              mZipCode: { id: 'aa', label: values.mZipCode } as OptionType,
            });
            response.zipCode =
              response.zipCode !== null
                ? { id: 'aa', label: response.zipCode as string }
                : { id: 'aa', label: '' };
            setOpen(true);
            updateData(response, 0, false);
            setToastrVariable('success');
            setToastrDefaultMessage('Client Updated Successfully!');
            setToastrId('Client.updatetoastr');
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        })
        .catch((error) => {
          formRef.current?.setValues({
            ...values,
            version: values.version,
            zipCode: { id: 'aa', label: values.zipCode } as OptionType,
            eZipCode: { id: 'aa', label: values.eZipCode } as OptionType,
            mZipCode: { id: 'aa', label: values.mZipCode } as OptionType,
          });
          setOpen(true);
          toggleLoader(false);
          setBtnloader(false);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            const response = error as ApiMessage;
            if (response.code === 1038) {
              setToastrId('DuplicateClientModal.header');
              setToastrDefaultMessage('This Client already exists in TCS');
            } else {
              setToastrId('Client.failupdate');
              setToastrDefaultMessage('Failed to update user');
            }
          }
        });
    }
  };

  const updateStorageDemographics = (response: AddNewClient) => {
    const demographicsDetails: FaceSheetDemographicsType = {
      firstName: response.firstName,
      middleName: response.middleName,
      lastName: response.lastName,
      ifspDueDate: dayjs(response.ifspDueDate, 'MM/DD/YYYY').format(
        'MM/DD/YYYY',
      ),
      ageInMonths: response.dateOfBirth
        ? calculateMonths(response.dateOfBirth as string)
        : null,
      diagnosis: null,
      noOfAssessmentHoursUsed: null,
      remainingAssessmentHours: null,
      dphId: response.dphId,
      sex: response.sex,
      serviceCoordinator: null,
      serviceCoordinatorHistory: [],
      intakeCoordinator: null,
      intakeCoordinatorHistory: [],
      rollOverDate: null,
      beginDate: null,
      assessmentHours: null,
      referralDate: null,
      referralEndDate: null,
      status: '',
      referralType: null,
      elProgram: null,
      elContactNumber: null,
      eligibilityDate: null,
      povertyLevel: null,
      dateOfBirth: dayjs(response.dateOfBirth, 'MM/DD/YYYY').format(
        'MM/DD/YYYY',
      ),
      referralId: response.referralId,
      address: {
        id: null,
        street: response.street,
        address2: response.address2,
        city: response.city,
        state: response.state,
        zipCode: response.zipCode as string,
      },
      isSecondaryProgramServices: false,
      eicsConsentToAccessInsurance: 0,
      consentToAccessStartDate: null,
      referralContact: [],
    };
    updateDemographics(demographicsDetails);
  };
  const getClientById = (clientId: string) => {
    toggleLoader(true);
    viewClientDetails(clientId)
      .then((response: AddNewClient) => {
        updateStorageDemographics(response);

        localStorage.setItem(
          'clientName',
          response.firstName.concat(' ').concat(response.lastName),
        );
        response.zipCode =
          response.zipCode !== null
            ? { id: 'aa', label: response.zipCode as string }
            : { id: 'aa', label: '' };
        response.eZipCode =
          response.eZipCode !== null
            ? { id: 'aa', label: response.eZipCode as string }
            : { id: 'aa', label: '' };
        response.mZipCode =
          response.mZipCode !== null
            ? { id: 'aa', label: response.mZipCode as string }
            : { id: 'aa', label: '' };
        response.sex =
          convertGenderToFrontend(response.sex !== null ? response.sex : '') ??
          null;
        setClientDetails(response);
        setrenderView(true);
        toggleLoader(false);
        updateData(response, 0, location?.state?.isCreate);
        setAddressCheckBox(response.evaluationAddressFlag);
        setMailingAddressCheckBox(response.mailingAddressFlag);
      })
      .catch((error) => {
        setOpen(true);
        toggleLoader(false);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('Client.getFailedError');
          setToastrDefaultMessage('Failed to get client details');
        }
      });
  };

  const clearForm = () => {
    formRef.current?.setValues(formRef.current?.initialValues);
    setOpenModal(false);
  };

  const navigate = useNavigate();
  React.useEffect(() => {
    const clientId = localStorage.getItem('ClientId');
    if (clientId !== null) {
      setrenderView(false);
      getClientById(clientId);
    } else {
      setrenderView(true);
    }
    setAddressCheckBox(false);
    setMailingAddressCheckBox(false);
  }, []);

  React.useEffect(() => {
    if (addressCheckBox) {
      formRef.current?.setValues((values) => ({
        ...values,
        eZipCode: values.zipCode,
        eState: values.state,
        eCity: values.city,
        eStreet: values.street,
        eAddress2: values.address2,
      }));
    } else {
      formRef.current?.setValues((values) => ({
        ...values,
        eZipCode: { id: '', label: '' },
        eState: '',
        eCity: '',
        eStreet: '',
        eAddress2: '',
      }));
    }
  }, [addressCheckBox]);

  React.useEffect(() => {
    if (mailingAddressCheckBox) {
      formRef.current?.setValues((values) => ({
        ...values,
        mZipCode: values.zipCode,
        mState: values.state,
        mCity: values.city,
        mStreet: values.street,
        mAddress2: values.address2,
      }));
    } else {
      formRef.current?.setValues((values) => ({
        ...values,
        mZipCode: { id: '', label: '' },
        mState: '',
        mCity: '',
        mStreet: '',
        mAddress2: '',
      }));
    }
  }, [mailingAddressCheckBox]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const nameValidation = (value: string): string | undefined => {
    const trimmedValue = value?.replace(/^\s+/, '');
    const regex = /^[A-Za-z,'. -]*$/;
    if (regex.test(trimmedValue)) {
      return trimmedValue;
    }
  };
  return (
    <>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      {showSimilarClientsModal && (
        <SimilarClientsUpdateModal
          showModal={showSimilarClientsModal}
          onModalClose={() => {
            setShowSimilarClientsModal(false);
          }}
          data={similarClients}
          updateClient={() => {
            const values = JSON.parse(
              JSON.stringify(formRef.current?.values),
            ) as AddNewClient;

            if (values !== undefined || null) {
              values.dateOfBirth = dayjs(values.dateOfBirth).format(
                'MM/DD/YYYY',
              );
            }
            values.firstName = values.firstName?.trim();
            values.middleName = values.middleName?.trim();
            values.lastName = values.lastName?.trim();
            values.sex =
              values.sex?.length === 1
                ? values.sex
                : convertGenderToBackend(
                    values.sex !== null ? values.sex : '',
                  ) ?? null;
            values.zipCode = (values.zipCode as OptionType).label;
            values.eZipCode = (values.eZipCode as OptionType).label;
            if (values.mZipCode !== null) {
              values.mZipCode = (values.mZipCode as OptionType).label;
            }
            const clientId = localStorage.getItem('ClientId');
            if (clientId !== null) {
              setShowSimilarClientsModal(false);
              toggleLoader(true);
              setBtnloader(true);
              updateClient(clientId, values, true);
            }
          }}
        />
      )}
      {renderView && (
        <Box>
          <div
            ref={parentRef}
            style={{
              pointerEvents: isScreenLocked ? 'none' : 'auto',
              opacity: isScreenLocked ? '0.5' : '1 ',
            }}
            onKeyDownCapture={(e) => {
              if (isScreenLocked) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            onFocus={() => {
              if (isScreenLocked) {
                if (parentRef.current) parentRef.current.focus();
              }
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: '#E7F0F0',
                padding: '0px 0px !important',
              }}
            >
              <Formik
                innerRef={formRef}
                validate={validateForm}
                initialValues={{
                  id: clientDetails.id,
                  firstName: clientDetails?.firstName,
                  middleName: clientDetails?.middleName,
                  lastName: clientDetails.lastName,
                  nickName: clientDetails.nickName,
                  nickNameWithheldIndicator:
                    clientDetails.nickNameWithheldIndicator,
                  dateOfBirth: clientDetails.dateOfBirth
                    ? dayjs(clientDetails.dateOfBirth).format('MM/DD/YYYY')
                    : null,
                  sex: clientDetails.sex,
                  race: clientDetails.race.toString(),
                  ethnicity: clientDetails.ethnicity,
                  multipleBirth: clientDetails.multipleBirth,
                  childBorn: clientDetails.childBorn,
                  childAdopted: clientDetails.childAdopted,
                  primarySpokenLanguage: clientDetails.primarySpokenLanguage,
                  primarySpokenLanguageOther:
                    clientDetails.primarySpokenLanguageOther,
                  primaryWrittenLanguage: clientDetails.primaryWrittenLanguage,
                  primaryWrittenLanguageOther:
                    clientDetails.primaryWrittenLanguageOther,
                  languageForPriorWrittenNotice:
                    clientDetails.languageForPriorWrittenNotice,
                  languageForPriorWrittenNoticeOther:
                    clientDetails.languageForPriorWrittenNoticeOther,
                  smokingInTheHouse: clientDetails.smokingInTheHouse,
                  petsInTheHouse: clientDetails.petsInTheHouse,
                  weapons: clientDetails.weapons,
                  weaponSecure: clientDetails.weaponSecure,
                  street: clientDetails.street,
                  address2: clientDetails.address2,
                  city: clientDetails.city,
                  zipCode: clientDetails.zipCode,
                  state: clientDetails.state,
                  dphId: clientDetails.dphId,
                  mailingAddressFlag: clientDetails.mailingAddressFlag,
                  mStreet: clientDetails.mStreet,
                  mAddress2: clientDetails.mAddress2,
                  mZipCode: clientDetails.mZipCode,
                  mCity: clientDetails.mCity,
                  mState: clientDetails.mState,
                  evaluationAddressFlag: clientDetails.evaluationAddressFlag,
                  eStreet: clientDetails.eStreet,
                  eAddress2: clientDetails.eAddress2,
                  eZipCode: clientDetails.eZipCode,
                  eCity: clientDetails.eCity,
                  eState: clientDetails.eState,
                  enterNote: clientDetails.enterNote,
                  referralDate: clientDetails.referralDate,
                  ifspDueDate: clientDetails.ifspDueDate,
                  clientId: clientDetails.clientId,
                  clientUuid: clientDetails.clientUuid,
                  appSource: clientDetails.appSource
                    ? clientDetails.appSource
                    : '',
                  version: clientDetails.version,
                  referralId: clientDetails.referralId,
                  remainingAssessmentHours:
                    clientDetails.remainingAssessmentHours,
                  profileUploadId: clientDetails.profileUploadId,
                  profileImageUrl: clientDetails.profileImageUrl,
                  evaluationStatus: clientDetails.evaluationStatus,
                  consentToShare: clientDetails.consentToShare,
                  billingDiagnosisCode: clientDetails.billingDiagnosisCode,
                  similarClients: clientDetails.similarClients,
                }}
                validateOnBlur={true}
                validateOnChange={false}
                validateOnMount={true}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  const request = JSON.parse(
                    JSON.stringify(values),
                  ) as AddNewClient;
                  formhandleSubmit(request);
                }}
              >
                {({
                  setFieldValue,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                  initialValues,
                  handleBlur,
                }) => (
                  <Box onBlur={handleBlur}>
                    <Card sx={{ padding: '24px', my: '0px', ml: '0px' }}>
                      <Grid
                        container
                        rowSpacing={'24px'}
                        columnSpacing={'50px'}
                      >
                        <Grid item xs={9} lg={9}>
                          <Grid
                            container
                            rowSpacing={'24px'}
                            columnSpacing={'50px'}
                          >
                            <Grid item xs={6} lg={6}>
                              <Textinput
                                name="firstName"
                                labelid="Clientpage.First"
                                defaultlabelid="First Name"
                                Required={true}
                                inputProps={{
                                  maxLength: 40,
                                }}
                                handlechange={(value: string) => {
                                  const inputValue = value;
                                  const validatedValue =
                                    nameValidation(inputValue);
                                  if (validatedValue !== undefined) {
                                    setFieldValue('firstName', validatedValue);
                                  }
                                }}
                                Value={values.firstName}
                              />
                              {errors.firstName && touched.firstName && (
                                <MediumTypography
                                  labelid={errors.firstName}
                                  textColor="red"
                                  marginTop="8px"
                                />
                              )}
                            </Grid>
                            <Grid item xs={6} lg={6}>
                              <Textinput
                                name="middleName"
                                labelid="Clientpage.Middle"
                                defaultlabelid="Middle Name"
                                inputProps={{
                                  maxLength: 20,
                                }}
                                handlechange={(value: string) => {
                                  const inputValue = value;
                                  const validatedValue =
                                    nameValidation(inputValue);
                                  if (validatedValue !== undefined) {
                                    setFieldValue('middleName', validatedValue);
                                  }
                                }}
                                Value={values.middleName}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Textinput
                                name="lastName"
                                inputProps={{
                                  maxLength: 40,
                                }}
                                labelid="Clientpage.Last"
                                defaultlabelid="Last Name"
                                Required={true}
                                handlechange={(value: string) => {
                                  const inputValue = value;
                                  const validatedValue =
                                    nameValidation(inputValue);
                                  if (validatedValue !== undefined) {
                                    setFieldValue('lastName', validatedValue);
                                  }
                                }}
                                Value={values.lastName}
                              />
                              {errors.lastName && touched.lastName && (
                                <MediumTypography
                                  labelid={errors.lastName}
                                  textColor="red"
                                  marginTop="8px"
                                />
                              )}
                            </Grid>
                            <Grid item xs={6}>
                              <Textinput
                                name="nickName"
                                inputProps={{
                                  maxLength: 30,
                                }}
                                labelid="Clientpage.Nick"
                                defaultlabelid="Nick Name"
                                handlechange={(value: string) => {
                                  const inputValue = value;
                                  const validatedValue =
                                    nameValidation(inputValue);
                                  if (validatedValue !== undefined) {
                                    setFieldValue('nickName', validatedValue);
                                  }
                                  if (inputValue.length <= 0) {
                                    setFieldValue(
                                      'nickNameWithheldIndicator',
                                      false,
                                    );
                                  }
                                }}
                                Value={
                                  values.nickName !== null
                                    ? values.nickName
                                    : ''
                                }
                              />
                              {errors.nickName && touched.nickName && (
                                <MediumTypography
                                  labelid={errors.nickName as string}
                                  textColor="red"
                                  marginTop="8px"
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} className="pt-none">
                              <SwitchComponent
                                toggle={values.nickNameWithheldIndicator}
                                name="nickNameWithheldIndicator"
                                labelid="Clientpage.Indicator"
                                defaultlabelid="Name Withheld Indicator"
                                handlechange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  setFieldValue(
                                    'nickNameWithheldIndicator',
                                    e.target.checked,
                                  );
                                }}
                              />
                              {errors.nickNameWithheldIndicator &&
                                touched.nickNameWithheldIndicator && (
                                  <MediumTypography
                                    labelid={errors.nickNameWithheldIndicator}
                                    textColor="red"
                                    marginTop="0px"
                                  />
                                )}
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            item
                            rowSpacing={'24px'}
                            columnSpacing={'50px'}
                          >
                            <Grid item xs={6} lg={6}>
                              <DatePickerComponent
                                name="dateOfBirth"
                                labelid="Clientpage.dateofbirth"
                                minDate={threeYearsAgo()}
                                disableFuture={true}
                                defaultlabelid="Date of Birth"
                                disabledDate={
                                  values.evaluationStatus === null
                                    ? false
                                    : values.evaluationStatus
                                }
                                value={dayjs(values.dateOfBirth) as Dayjs}
                                handlechange={(datevalue: Dayjs | null) => {
                                  const formattedDate =
                                    dayjs(datevalue).format('MM/DD/YYYY');
                                  setFieldValue('referralDate', null);
                                  setFieldValue('ifspDueDate', null);
                                  if (formRef.current !== null) {
                                    if (datevalue === null) {
                                      formRef.current.setValues({
                                        ...values,
                                        dateOfBirth: null,
                                      });
                                    } else {
                                      formRef.current.setValues({
                                        ...values,
                                        dateOfBirth: formattedDate,
                                      });
                                    }
                                  }
                                }}
                              />
                              {errors.dateOfBirth && touched.dateOfBirth && (
                                <MediumTypography
                                  labelid={errors.dateOfBirth}
                                  textColor="red"
                                  marginTop="8px"
                                />
                              )}
                            </Grid>
                            <Grid item xs={6} lg={6}>
                              <SelectComponent
                                labelid="Clientpage.Sex"
                                defaultlabelid="sex"
                                name="sex"
                                value={
                                  values.sex && values.sex?.length > 1
                                    ? values.sex
                                    : convertGenderToFrontend(
                                        values.sex !== null ? values.sex : '',
                                      )
                                }
                                handleChange={(value: string) => {
                                  setFieldValue('sex', value);
                                }}
                                names={Gender}
                              />
                              {errors.sex && touched.sex && (
                                <MediumTypography
                                  labelid={errors.sex}
                                  textColor="red"
                                  marginTop="8px"
                                />
                              )}
                            </Grid>
                            <Grid item xs={6} lg={6}>
                              <MultipleAutocompleteSearch
                                labelId="Clientpage.Race"
                                defaultId="Race"
                                data={raceValues}
                                type="string"
                                onSelectItem={(value: OptionType[] | null) => {
                                  if (value) {
                                    const selectedRaceIds = value.map(
                                      (r) => r.id,
                                    );

                                    setFieldValue(
                                      'race',
                                      selectedRaceIds.toString(),
                                    );
                                  }
                                }}
                                disableLabel={true}
                                optionHintId={'No Race found'}
                                sxProps={{
                                  [`& .${autocompleteClasses.popupIndicator}`]:
                                    {
                                      transform: 'none',
                                      marginTop: '2px',
                                    },
                                  backgroundColor: '#fff',
                                }}
                                value={values.race
                                  .split(',') // Split the string by comma
                                  .map((r) => ({
                                    id: r,
                                    label:
                                      raceValues.find(
                                        (raceValue) => raceValue.id === r,
                                      )?.label || '', // Set label to empty string if not found
                                  }))
                                  .filter(
                                    (
                                      option,
                                    ): option is {
                                      id: string;
                                      label: string;
                                    } => option.label !== '', // Filter out options with empty labels
                                  )}
                              />
                              {errors.race && touched.race && (
                                <MediumTypography
                                  labelid={errors.race}
                                  textColor="red"
                                  marginTop="8px"
                                />
                              )}
                            </Grid>
                            <Grid item xs={6} lg={6}>
                              <DropdownComponent
                                names={ethnicityValues}
                                labelid={
                                  'IntakeFamilyHistory.EthnicityPlaceholder'
                                }
                                value={values.ethnicity}
                                defaultlabelid="Ethnicity"
                                handleChange={(e) => {
                                  if (e) {
                                    setFieldValue('ethnicity', e);
                                  }
                                }}
                              />
                              {errors.ethnicity && touched.ethnicity && (
                                <MediumTypography
                                  labelid={errors.ethnicity}
                                  textColor="red"
                                  marginTop="8px"
                                />
                              )}
                            </Grid>

                            <Grid item xs={4} lg={4}>
                              <DropdownComponent
                                names={languageValues}
                                labelid={'Clientpage.primarySpokenLanguage'}
                                value={values.primarySpokenLanguage}
                                defaultlabelid="Primary Spoken Language"
                                handleChange={(e) => {
                                  if (e) {
                                    setFieldValue('primarySpokenLanguage', e);
                                  }
                                }}
                              />
                              {errors.primarySpokenLanguage &&
                                touched.primarySpokenLanguage && (
                                  <MediumTypography
                                    labelid={errors.primarySpokenLanguage}
                                    textColor="red"
                                    marginTop="8px"
                                  />
                                )}
                            </Grid>

                            <Grid item xs={4} lg={4}>
                              <DropdownComponent
                                names={languageValues}
                                labelid={'Clientpage.PrimaryWritten'}
                                value={values.primaryWrittenLanguage}
                                defaultlabelid="Primary Written Language"
                                handleChange={(e) => {
                                  if (e) {
                                    setFieldValue('primaryWrittenLanguage', e);
                                  }
                                }}
                              />

                              {errors.primaryWrittenLanguage &&
                                touched.primaryWrittenLanguage && (
                                  <MediumTypography
                                    labelid={errors.primaryWrittenLanguage}
                                    textColor="red"
                                    marginTop="8px"
                                  />
                                )}
                            </Grid>

                            <Grid item xs={4} lg={4}>
                              <DropdownComponent
                                names={languageValues}
                                labelid={'Clientpage.Prior'}
                                value={values.languageForPriorWrittenNotice}
                                defaultlabelid="Language for Prior Written Notice"
                                handleChange={(e) => {
                                  if (e) {
                                    setFieldValue(
                                      'languageForPriorWrittenNotice',
                                      e,
                                    );
                                  }
                                }}
                              />
                              {errors.languageForPriorWrittenNotice &&
                                touched.languageForPriorWrittenNotice && (
                                  <MediumTypography
                                    labelid={
                                      errors.languageForPriorWrittenNotice
                                    }
                                    textColor="red"
                                    marginTop="8px"
                                  />
                                )}
                            </Grid>

                            <Grid
                              container
                              item
                              rowSpacing={'24px'}
                              columnSpacing={'50px'}
                            >
                              {values.primarySpokenLanguage === 'OTHER' && (
                                <Grid item xs={4} lg={4}>
                                  <Textinput
                                    name="primarySpokenLanguageOther"
                                    labelid="Clientpage.PrimaryOther"
                                    defaultlabelid="Other Spoken Language"
                                    Value={values.primarySpokenLanguageOther}
                                    handlechange={(text: string) => {
                                      setFieldValue(
                                        'primarySpokenLanguageOther',
                                        text,
                                      );
                                    }}
                                    inputProps={{ maxLength: 100 }}
                                    sxProps={{ backgroundColor: 'white' }}
                                    Required
                                  />
                                  {errors.primarySpokenLanguageOther &&
                                    touched.primarySpokenLanguageOther && (
                                      <MediumTypography
                                        labelid={
                                          errors.primarySpokenLanguageOther
                                        }
                                        defaultlabel="Is Required"
                                        className="errorText-md"
                                      />
                                    )}
                                </Grid>
                              )}

                              {values.primaryWrittenLanguage === 'OTHER' && (
                                <>
                                  {values.primarySpokenLanguage !== 'OTHER' && (
                                    <Grid item xs={4} lg={4}></Grid>
                                  )}
                                  <Grid item xs={4} lg={4}>
                                    <Textinput
                                      name="primaryWrittenLanguageOther"
                                      labelid="Clientpage.PrimaryWrittenOther"
                                      defaultlabelid="Other Written Language"
                                      Value={values.primaryWrittenLanguageOther}
                                      handlechange={(text: string) => {
                                        setFieldValue(
                                          'primaryWrittenLanguageOther',
                                          text,
                                        );
                                      }}
                                      inputProps={{ maxLength: 100 }}
                                      sxProps={{ backgroundColor: 'white' }}
                                      Required
                                    />
                                    {errors.primaryWrittenLanguageOther &&
                                      touched.primaryWrittenLanguageOther && (
                                        <MediumTypography
                                          labelid={
                                            errors.primaryWrittenLanguageOther
                                          }
                                          defaultlabel="Is Required"
                                          className="errorText-md"
                                        />
                                      )}
                                  </Grid>
                                </>
                              )}

                              {values.languageForPriorWrittenNotice ===
                                'OTHER' && (
                                <>
                                  {values.primaryWrittenLanguage !==
                                    'OTHER' && <Grid item xs={4} lg={4}></Grid>}
                                  {values.primaryWrittenLanguage !== 'OTHER' &&
                                    values.primarySpokenLanguage !==
                                      'OTHER' && (
                                      <Grid item xs={4} lg={4}></Grid>
                                    )}
                                  <Grid item xs={4} lg={4}>
                                    <Textinput
                                      name="languageForPriorWrittenNoticeOther"
                                      labelid="Clientpage.PriorOther"
                                      defaultlabelid="Other Prior Written Notice Language"
                                      Value={
                                        values.languageForPriorWrittenNoticeOther
                                      }
                                      handlechange={(text: string) => {
                                        setFieldValue(
                                          'languageForPriorWrittenNoticeOther',
                                          text,
                                        );
                                      }}
                                      inputProps={{ maxLength: 100 }}
                                      sxProps={{ backgroundColor: 'white' }}
                                      Required
                                    />

                                    {errors.languageForPriorWrittenNoticeOther &&
                                      touched.languageForPriorWrittenNoticeOther && (
                                        <MediumTypography
                                          labelid={
                                            errors.languageForPriorWrittenNoticeOther
                                          }
                                          defaultlabel="Is Required"
                                          className="errorText-md"
                                        />
                                      )}
                                  </Grid>
                                </>
                              )}
                            </Grid>

                            <Grid item xs={3} lg={3}>
                              <MediumTypography
                                labelid="Clientpage.MultipleBirth"
                                defaultlabel="Multiple Birth"
                              />
                              <CustomRadioButton
                                data={IntakeRadioData}
                                value={values.multipleBirth}
                                onSelectItem={(value) => {
                                  if (value) {
                                    setFieldValue('multipleBirth', value);
                                  }
                                }}
                              />
                              {errors.multipleBirth &&
                                touched.multipleBirth && (
                                  <MediumTypography
                                    labelid={errors.multipleBirth}
                                    textColor="red"
                                    marginTop="8px"
                                  />
                                )}
                            </Grid>
                            <Grid item xs={4} lg={4}>
                              <MediumTypography label="Was this child born in MA?" />
                              <CustomRadioButton
                                data={EICSRadioData}
                                value={values.childBorn}
                                onSelectItem={(value) => {
                                  if (value) {
                                    setFieldValue('childBorn', value);
                                  }
                                }}
                              />
                              {errors.childBorn && touched.childBorn && (
                                <MediumTypography
                                  labelid={errors.childBorn}
                                  className="errorText-md"
                                />
                              )}
                            </Grid>
                            <Grid item xs={4} lg={4}>
                              <MediumTypography label="Is this child adopted?" />
                              <CustomRadioButton
                                data={EICSRadioData}
                                value={values.childAdopted}
                                onSelectItem={(value) => {
                                  if (value) {
                                    setFieldValue('childAdopted', value);
                                  }
                                }}
                              />
                              {errors.childAdopted && touched.childAdopted && (
                                <MediumTypography
                                  labelid={errors.childAdopted}
                                  className="errorText-md"
                                />
                              )}
                            </Grid>

                            <Grid item xs={8} lg={8}>
                              <AutocompleteSearch
                                textDecoration={
                                  selectedDiagnosisCodesList?.label.includes(
                                    '- *',
                                  )
                                    ? 'underline'
                                    : 'none'
                                }
                                labelId={'billingDiagnosisCodeText'}
                                defaultId="Billing Diagnosis Code"
                                optionHintId={'noBillingDiagnosisCodesFound'}
                                renderOption={(props, option: OptionType) => {
                                  const parts = option.label.split(' - ');
                                  let beforeHyphen = '';
                                  let afterHyphen = '';

                                  if (parts.length > 1) {
                                    beforeHyphen = parts[0];
                                    afterHyphen = parts?.slice(1)?.join(' - ');
                                  } else {
                                    beforeHyphen = option.label;
                                  }

                                  const underlineStyle = afterHyphen.includes(
                                    '*',
                                  )
                                    ? 'underline'
                                    : 'none';

                                  return (
                                    <li {...props}>
                                      {parts.length > 1 ? (
                                        <>
                                          <span
                                            style={{ whiteSpace: 'nowrap' }}
                                          >
                                            {beforeHyphen} -{' '}
                                          </span>
                                          <Tooltip title={afterHyphen}>
                                            <span
                                              className="ellipsis_txt_profile"
                                              style={{
                                                textDecoration: underlineStyle,
                                                maxWidth: '500px',
                                              }}
                                            >
                                              {afterHyphen}
                                            </span>
                                          </Tooltip>
                                        </>
                                      ) : (
                                        <span
                                          style={{
                                            textDecoration: underlineStyle,
                                          }}
                                        >
                                          {beforeHyphen}
                                        </span>
                                      )}
                                    </li>
                                  );
                                }}
                                data={diagnosisCodesList}
                                selectedValue={selectedDiagnosisCodesList}
                                onSelectItem={(value: OptionType | null) => {
                                  if (value === null) {
                                    setSelectedDiagnosisCodesList(null);
                                    setFieldValue('billingDiagnosisCode', null);
                                  } else {
                                    setSelectedDiagnosisCodesList(value);
                                    setFieldValue(
                                      'billingDiagnosisCode',
                                      value.id,
                                    );
                                  }
                                }}
                                disableLabel={false}
                              />
                            </Grid>

                            <Grid item xs={4} lg={4}></Grid>

                            <Grid item xs={4} lg={4}>
                              <CheckBoxComponent
                                ischecked={values.consentToShare}
                                labelid="consentToShareText"
                                defaultlabelid="Consent To Share"
                                CheckHandleChange={(
                                  event: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  setFieldValue(
                                    'consentToShare',
                                    event.target.checked,
                                  );
                                }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <MediumTypography
                                fontweight={600}
                                labelid="Clientpage.HomeSafety"
                                defaultlabel="Home Visiting Safety Risks"
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <RadioComponent
                                value={values.smokingInTheHouse}
                                name="smokingInTheHouse"
                                handleChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  setFieldValue(
                                    'smokingInTheHouse',
                                    JSON.parse(e.target.value),
                                  );
                                }}
                                labelid="Clientpage.Smoking"
                                defaultlabelid="Smoking in the home"
                                labelidA="Clientpage.RadiolabelA"
                                defaultlabelidA="Yes"
                                labelidB="Clientpage.RadiolabelB"
                                defaultlabelidB="No"
                              />
                              {errors.smokingInTheHouse &&
                                touched.smokingInTheHouse && (
                                  <MediumTypography
                                    labelid={errors.smokingInTheHouse}
                                    textColor="red"
                                    marginTop="8px"
                                  />
                                )}
                            </Grid>
                            <Grid item xs={3}>
                              <RadioComponent
                                value={values.petsInTheHouse}
                                name="petsInTheHouse"
                                handleChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  setFieldValue(
                                    'petsInTheHouse',
                                    JSON.parse(e.target.value),
                                  );
                                }}
                                labelid="Clientpage.Pets"
                                defaultlabelid="Pets in the home"
                                labelidA="Clientpage.RadiolabelA"
                                defaultlabelidA="Yesss"
                                labelidB="Clientpage.RadiolabelB"
                                defaultlabelidB="Noss"
                              />
                              {errors.petsInTheHouse &&
                                touched.petsInTheHouse && (
                                  <MediumTypography
                                    labelid={errors.petsInTheHouse}
                                    textColor="red"
                                    marginTop="8px"
                                  />
                                )}
                            </Grid>
                            <Grid item xs={5}>
                              <RadioComponent
                                value={values.weapons}
                                name="weapons"
                                handleChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  if (formRef.current !== null) {
                                    if (!values.weapons) {
                                      setFieldValue('weaponSecure', null);
                                    }
                                    formRef.current.setValues({
                                      ...values,
                                      weapons: JSON.parse(e.target.value),
                                      weaponSecure: null,
                                    });
                                  }
                                }}
                                labelid="Clientpage.weapons"
                                defaultlabelid="Guns/Weapons/Firearms in the home"
                                labelidA="Clientpage.RadiolabelA"
                                defaultlabelidA="Yes"
                                labelidB="Clientpage.RadiolabelB"
                                defaultlabelidB="No"
                              />
                              {errors.weapons && touched.weapons && (
                                <MediumTypography
                                  labelid={errors.weapons}
                                  textColor="red"
                                  marginTop="8px"
                                />
                              )}
                            </Grid>
                            {values.weapons && (
                              <Grid item xs={12}>
                                <RadioComponent
                                  value={values.weaponSecure}
                                  name="weaponSecure"
                                  handleChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    setWeaponAlert(!JSON.parse(e.target.value));
                                    setFieldValue(
                                      'weaponSecure',
                                      JSON.parse(e.target.value),
                                    );
                                    if (!JSON.parse(e.target.value)) {
                                      setFieldValue(
                                        'evaluationAddressFlag',
                                        false,
                                      );
                                      setClientDetails({
                                        ...clientDetails,
                                        evaluationAddressFlag: false,
                                      });
                                      setAddressCheckBox(false);
                                    }
                                  }}
                                  labelid="Clientpage.weaponSecure"
                                  defaultlabelid="Are the weapons stored and secured"
                                  labelidA="Clientpage.RadiolabelA"
                                  defaultlabelidA="Yes"
                                  labelidB="Clientpage.RadiolabelB"
                                  defaultlabelidB="No"
                                />
                                {errors.weaponSecure &&
                                  touched.weaponSecure && (
                                    <MediumTypography
                                      labelid={errors.weaponSecure}
                                      textColor="red"
                                      marginTop="8px"
                                    />
                                  )}
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <CommentTextbox
                                maxLength={2000}
                                rows={4}
                                name="enterNote"
                                placeholder={rctl.formatMessage({
                                  id: 'CommunicationLogPage.enterNote',
                                })}
                                Required={false}
                                height="60px"
                                Value={values.enterNote}
                                handlechange={(value: string) => {
                                  setFieldValue('enterNote', value);
                                }}
                              />
                              {errors.enterNote && touched.enterNote && (
                                <MediumTypography
                                  labelid={errors.enterNote}
                                  textColor="red"
                                  marginTop="8px"
                                />
                              )}
                            </Grid>

                            <Grid item xs={12}>
                              <AddressForm
                                headerTitleId="Clientpage.HomeAddress"
                                address1PlaceholderId="address1Text"
                                address2PlaceholderId="address2Text"
                                zipCodePlaceholderId="Clientpage.Zip"
                                cityPlaceholderId="Clientpage.City"
                                statePlaceholderId="Clientpage.State"
                                value={{
                                  address1: values.street,
                                  address2: values.address2,
                                  zipCode: values.zipCode,
                                  city: values.city,
                                  state: values.state,
                                }}
                                isClient={true}
                                disableForm={false}
                                formRequired={true}
                                formikErrors={{
                                  address1: errors.street,
                                  address2: errors.address2,
                                  zipCode: errors.zipCode,
                                  city: errors.city,
                                  state: errors.state,
                                }}
                                formikTouched={{
                                  address1: touched.street,
                                  address2: touched.address2,
                                  zipCode: touched.zipCode,
                                  city: touched.city,
                                  state: touched.state,
                                }}
                                handleAddressUpdate={(newAddress) => {
                                  setFieldValue('street', newAddress.address1);
                                  setFieldValue(
                                    'address2',
                                    newAddress.address2,
                                  );
                                  setFieldValue(
                                    'zipCode',
                                    newAddress.zipCode as OptionType,
                                  );
                                  setFieldValue('city', newAddress.city);
                                  setFieldValue('state', newAddress.state);

                                  if (values.evaluationAddressFlag) {
                                    setFieldValue(
                                      'eStreet',
                                      newAddress.address1,
                                    );
                                    setFieldValue(
                                      'eAddress2',
                                      newAddress.address2,
                                    );
                                    setFieldValue(
                                      'eZipCode',
                                      newAddress.zipCode as OptionType,
                                    );
                                    setFieldValue('eCity', newAddress.city);
                                    setFieldValue('eState', newAddress.state);
                                  }

                                  if (values.mailingAddressFlag) {
                                    setFieldValue(
                                      'mStreet',
                                      newAddress.address1,
                                    );
                                    setFieldValue(
                                      'mAddress2',
                                      newAddress.address2,
                                    );
                                    setFieldValue(
                                      'mZipCode',
                                      newAddress.zipCode as OptionType,
                                    );
                                    setFieldValue('mCity', newAddress.city);
                                    setFieldValue('mState', newAddress.state);
                                  }

                                  if (formRef.current !== null) {
                                    let client: AddNewClient = {
                                      ...values,
                                      street: newAddress.address1,
                                      address2: newAddress.address2,
                                      zipCode: newAddress.zipCode,
                                      city: newAddress.city,
                                      state: newAddress.state,
                                    };
                                    if (values.evaluationAddressFlag) {
                                      client = {
                                        ...client,
                                        eStreet: newAddress.address1,
                                        eAddress2: newAddress.address2,
                                        eZipCode: newAddress.zipCode,
                                        eCity: newAddress.city,
                                        eState: newAddress.state,
                                      };
                                    }
                                    if (values.mailingAddressFlag) {
                                      client = {
                                        ...client,
                                        mStreet: newAddress.address1,
                                        mAddress2: newAddress.address2,
                                        mZipCode: newAddress.zipCode,
                                        mCity: newAddress.city,
                                        mState: newAddress.state,
                                      };
                                    }
                                    formRef.current.setValues(client);
                                    setClientDetails(client);
                                  }
                                }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <CheckBoxComponent
                                name="evaluationAddressFlag"
                                defaultlabelid="If Evaluation Address is same as Home Address"
                                labelid="Clientpage.ischeckedevalution"
                                ischecked={values.evaluationAddressFlag}
                                CheckHandleChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  setFieldValue(
                                    'evaluationAddressFlag',
                                    e.target.checked,
                                  );
                                  setClientDetails({
                                    ...clientDetails,
                                    evaluationAddressFlag: e.target.checked,
                                  });
                                  setAddressCheckBox(e.target.checked);
                                }}
                                disabled={
                                  values.weaponSecure === false ? true : false
                                }
                              />
                            </Grid>
                            <Grid container item>
                              <AddressForm
                                headerTitleId="Clientpage.EvaluationAddress"
                                address1PlaceholderId="address1Text"
                                address2PlaceholderId="address2Text"
                                zipCodePlaceholderId="Clientpage.Zip"
                                cityPlaceholderId="Clientpage.City"
                                statePlaceholderId="Clientpage.State"
                                disableForm={values.evaluationAddressFlag}
                                value={{
                                  address1: values.evaluationAddressFlag
                                    ? values.street
                                    : values.eStreet,
                                  address2: values.evaluationAddressFlag
                                    ? values.address2
                                    : values.eAddress2,
                                  zipCode: (values.evaluationAddressFlag
                                    ? values.zipCode
                                    : values.eZipCode) as OptionType,
                                  city: values.evaluationAddressFlag
                                    ? values.city
                                    : values.eCity,
                                  state: values.evaluationAddressFlag
                                    ? values.state
                                    : values.eState,
                                }}
                                isClient={true}
                                formRequired={true}
                                formikErrors={{
                                  address1: errors.eStreet,
                                  address2: errors.eAddress2,
                                  zipCode: errors.eZipCode,
                                  city: errors.eCity,
                                  state: errors.eState,
                                }}
                                formikTouched={{
                                  address1: touched.eStreet,
                                  address2: touched.eAddress2,
                                  zipCode: touched.eZipCode,
                                  city: touched.eCity,
                                  state: touched.eState,
                                }}
                                handleAddressUpdate={(newAddress) => {
                                  formRef.current?.setValues({
                                    ...values,
                                    eStreet: newAddress.address1,
                                    eAddress2: newAddress.address2,
                                    eZipCode: newAddress.zipCode,
                                    eCity: newAddress.city,
                                    eState: newAddress.state,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}></Grid>
                          </Grid>

                          <Grid xs={12}>
                            <CheckBoxComponent
                              name="mailingAddressFlag"
                              defaultlabelid="If Mailing Address is same as Home Address"
                              labelid="Clientpage.ischecked"
                              ischecked={values.mailingAddressFlag}
                              CheckHandleChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                setFieldValue(
                                  'mailingAddressFlag',
                                  event.target.checked,
                                );
                                setClientDetails({
                                  ...clientDetails,
                                  mailingAddressFlag: event.target.checked,
                                });
                                setMailingAddressCheckBox(event.target.checked);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AddressForm
                              headerTitleId="Clientpage.Mailing"
                              address1PlaceholderId="address1Text"
                              address2PlaceholderId="address2Text"
                              zipCodePlaceholderId="Clientpage.Zip"
                              cityPlaceholderId="Clientpage.City"
                              statePlaceholderId="Clientpage.State"
                              disableForm={values.mailingAddressFlag}
                              formRequired={
                                values.mailingAddressFlag ? true : false
                              }
                              value={{
                                address1: values.mailingAddressFlag
                                  ? values.street
                                  : values.mStreet,
                                address2: values.mailingAddressFlag
                                  ? values.address2
                                  : values.mAddress2,
                                zipCode: (values.mailingAddressFlag
                                  ? values.zipCode
                                  : values.mZipCode) as OptionType,
                                city: values.mailingAddressFlag
                                  ? values.city
                                  : values.mCity,
                                state: values.mailingAddressFlag
                                  ? values.state
                                  : values.mState,
                              }}
                              isClient={true}
                              formikErrors={{
                                address1: errors.mStreet,
                                address2: errors.mAddress2,
                                zipCode: errors.mZipCode,
                                city: errors.mCity,
                                state: errors.mState,
                              }}
                              formikTouched={{
                                address1: touched.mStreet,
                                address2: touched.mAddress2,
                                zipCode: touched.mZipCode,
                                city: touched.mCity,
                                state: touched.mState,
                              }}
                              handleAddressUpdate={(newAddress) => {
                                formRef.current?.setValues({
                                  ...values,
                                  mStreet: newAddress.address1,
                                  mAddress2: newAddress.address2,
                                  mZipCode: newAddress.zipCode,
                                  mCity: newAddress.city,
                                  mState: newAddress.state,
                                });
                              }}
                            />
                          </Grid>

                          <Grid item xs={7.5}></Grid>
                        </Grid>
                        <Grid item xs={3} lg={3} sx={{ display: 'none' }}>
                          <ReactImagePickerEditor
                            config={config}
                            imageSrcProp={
                              clientDetails.profileImageUrl !== null
                                ? clientDetails.profileImageUrl
                                : undefined
                            }
                            imageChanged={(newDataUri: string | null) => {
                              setImageSrc(newDataUri);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Card>
                    <Box className="mt-md mb-lg">
                      <Grid
                        className="flex__ justifyContent-FlexEnd"
                        container
                        direction="row"
                        alignItems="right"
                      >
                        <Grid item>
                          <ButtonComponent
                            className="btn-primary btn-cancel btn_width_90px mr-md"
                            variantType="contained"
                            labelId="Clientpage.cancelbtn"
                            disabled={btnloader}
                            defaultLabelId="Cancel"
                            onClick={() => {
                              if (!_.isEqual(initialValues, values)) {
                                setOpenModal(true);
                              } else {
                                navigate(-1);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <ButtonComponent
                            className="btn-primary btn-submit"
                            variantType="contained"
                            type="submit"
                            labelId={'Insurance.save'}
                            defaultLabelId="Save"
                            loading={btnloader}
                            onClick={handleSubmit}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )}
              </Formik>
            </Box>
          </div>
        </Box>
      )}
      <ModalPopup
        open={openModal}
        maxWidth={'xs'}
        description="formUnsavedChangesMessage"
        onCancel={() => setOpenModal(false)}
        onOk={() => clearForm()}
        labelId1="Clientpage.Nobtn"
        negativeActionLabel="No"
        labelId2="Clientpage.Yesbtn"
        positiveActionLabel="Yes"
      />
      <AlertDialogSlide
        open={weaponAlert}
        description="Clientpage.WeaponsAlert"
        onOk={() => setWeaponAlert(false)}
        labelId2="Clientpage.Okbtn"
        contentdescription="Clientpage.WeaponsAlertContent"
      />
    </>
  );
};
export default ClientPage;
