import {
  AlertColor,
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
} from '@mui/material';
import TitleText from '../../components/formlib/TitleText';
import ProgramPanel from '../../components/forms/ProgramPanel';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  AreaOfConcerns,
  ClinicalEligibilty,
  ClinicalJudgement,
  Tools,
} from './types';
import KeyValueComponent, {
  DataJsonType,
} from '../../components/forms/KeyValueComponent';
import MediumTypography from '../../components/formlib/MediumTypography';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import SelectComponent from '../../components/formlib/SelectComponent';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import CommentTextbox from '../../components/formlib/CommentTextbox';
import { useIntl } from 'react-intl';
import AreaOfConcern from './AreaOfConcern';
import { ReactComponent as AddNew } from '../../assets/images/Plus.svg';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { ReactComponent as DeleteAlertIcon } from '../../assets/images/deleteAlertIcon.svg';
import {
  EmployeeListType,
  getAllEmployeeMinimalList,
} from '../../services/ifspClient/IfspApi';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import {
  deleteConcernById,
  deleteTeamMemberById,
  getClientClinicalJudgementDetails,
  saveClinicalJudgement,
} from '../../services/configApi/eligibility/clinicalEligibility';
import { useLocation, useNavigate } from 'react-router';
import * as yup from 'yup';
import { Formik, FormikProps } from 'formik';
import ActionToolTip from '../../components/formlib/ActionToolTip';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';
import Textinput from '../../components/formlib/Textinput';
import dayjs from 'dayjs';
import SignatureComponent from '../../components/formlib/modal/SignatureComponent';
import ModalPopup from '../../components/formlib/ModalPopup';
import { ReactComponent as Backarrow } from '../../assets/images/backarrow.svg';
import { ReactComponent as Editsignature } from '../../assets/images/editsignature.svg';
import _, { cloneDeep, debounce } from 'lodash';
import { BATELLE_DEVELOPMENT_INVENTORY_2 } from '../../routes/Routing';
import CustomcardComponent from '../../components/formlib/CustomCardComponent';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';
import { OptionType } from '../../utils/type';

const teamHeadCells: HeadCell<TableData>[] = [
  {
    id: 'name',
    labelId: 'Name',
    defaultLabelId: 'Name',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'disciplineType',
    labelId: 'disciplineText',
    defaultLabelId: 'Discipline',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'email',
    labelId: 'Email',
    defaultLabelId: 'Email',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'phoneNumber',
    labelId: 'Phone Number',
    defaultLabelId: 'Phone Number',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: 'Actions',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
];

interface TableData {
  name: string;
  disciplineType: string;
  email: string;
  phoneNumber: string;
  actions: string;
}

const validationSchema = yup.object().shape({
  teams: yup.array().of(
    yup.object().shape({
      name: yup.string().nullable(),
      disciplineType: yup.string().nullable(),
      email: yup.string(),
      phoneNumber: yup.string(),
    }),
  ),
  additionalTools: yup
    .object()
    .shape({
      tools: yup.array().of(
        yup.object().shape({
          nameOfToolUsed: yup.string().nullable(),
          dateAdministered: yup.string().nullable(),
          administeredBy: yup.string().nullable(),
          disciplineOfPerson: yup.string().nullable(),
          specifyOther: yup.string().nullable(),
        }),
      ),
      additionalToolComments: yup.string().nullable(),
    })
    .nullable(),
  notes: yup.string().required('ClinicalJudgement.ConcernsValidation'),
  concerns: yup.array().of(
    yup.object().shape({
      areaOfConcern: yup.string().nullable(),
      teamMember: yup.string().nullable(),
      comments: yup.string().nullable(),
    }),
  ),
  signature: yup.object().shape({
    signatureValue: yup.string(),
    partitionKey: yup.string(),
    rowKey: yup.string(),
    name: yup.string().nullable(),
    signature: yup.string().nullable(),
  }),
});

const initialValue: ClinicalEligibilty = cloneDeep({
  teams: [],
  additionalTools: {
    tools: [
      {
        additionalToolsId: '',
        nameOfToolUsed: null,
        dateAdministered: null,
        administeredBy: null,
        disciplineOfPerson: null,
        specify: null,
        status: null,
      },
    ],
    additionalToolComments: null,
  },
  notes: '',
  signature: {
    signatureValue: '',
    partitionkey: '',
    rowkey: '',
    name: null,
    date: null,
  },
  concerns: [
    {
      concernId: null,
      areaOfConcern: null,
      teamMember: null,
      comments: null,
    },
  ],
});

const ClinicalJudgementEligibility = () => {
  const rctl = useIntl();
  const location = useLocation();
  const [clinicalJudgementInitialValue, setClinicalJudgementInitialValue] =
    useState<ClinicalEligibilty>(initialValue);
  const [uploadedToTBR, setUploadedToTBR] = useState<boolean>(false);
  const [numOfConcerns, setNumOfConcerns] = useState<number>(1);
  const [clientId, setClientId] = useState<string>();
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [successOrError, setSuccessOrError] = useState<AlertColor>('success');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState<string>();
  const [employees, setEmployees] = useState<EmployeeListType[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<EmployeeListType | null>(
    null,
  );
  const [signClicked, setSignClicked] = useState<boolean>(false);
  const formikRef = useRef<FormikProps<ClinicalEligibilty>>(null);
  const navigate = useNavigate();
  const [clinicalJudgement, setClinicalJudgement] =
    useState<ClinicalJudgement>();
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
  const [addMoreToolCount, setAddMoreToolCount] = useState<number>(1);
  const { lookups } = useContext(LookUpContext) as LookUpContextType;
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);

  useEffect(() => {
    setUploadedToTBR(location.state?.isUploadedToTBR);
    const id = location.state?.evaluationId;
    if (id) {
      setClientId(id);
      getEmployeesData(id);
    }
  }, []);

  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);

  const parentRef = useRef<HTMLDivElement>(null);
  const getDisciplineTypes = (disciplineType: string | null): OptionType[] => {
    const optionTypes: OptionType[] = [];
    if (lookups === null || disciplineType === null || disciplineType === '')
      return optionTypes;
    const disciplineTypes = disciplineType.split(',') || [];
    if (disciplineTypes.length > 0) {
      disciplineTypes.forEach((e) => {
        const discipline = lookups.discipline.find((d) => {
          if (d.code === e) {
            return d;
          }
        });
        if (discipline) {
          optionTypes.push({
            label: discipline.description,
            id: discipline.code,
          });
        }
      });
      return optionTypes;
    } else {
      return optionTypes;
    }
  };

  const getClinicalJudgementDetails = (cId: string) => {
    getClientClinicalJudgementDetails(cId)
      .then((response) => {
        toggleLoader(false);
        const preValues = cloneDeep(formikRef.current?.values);
        if (preValues) {
          preValues.concerns =
            response.concerns.length > 0
              ? response.concerns
              : [
                  {
                    concernId: null,
                    areaOfConcern: null,
                    teamMember: null,
                    comments: null,
                  },
                ];
          preValues.signature = response.signature;
          preValues.teams = response.teams;
          if (response.additionalTools) {
            if (response.additionalTools.tools.length === 0) {
              preValues.additionalTools = {
                tools: [
                  {
                    additionalToolsId: '',
                    nameOfToolUsed: null,
                    dateAdministered: null,
                    administeredBy: null,
                    disciplineOfPerson: null,
                    specify: null,
                    status: null,
                  },
                ],
                additionalToolComments:
                  response.additionalTools.additionalToolComments,
              };
            } else {
              preValues.additionalTools = response.additionalTools;
            }
          }
          preValues.notes = response.notes;
          formikRef.current?.setValues(preValues);
          setAddMoreToolCount(preValues.additionalTools?.tools.length || 1);
          setClinicalJudgementInitialValue(cloneDeep(preValues));
          setNumOfConcerns(
            response.concerns.length > 0 ? response.concerns.length : 1,
          );
          setSelectedTeam(null);
          setClinicalJudgement(response);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('loadFailureMessage');
          setToastrDefaultMessage('Failed to get details');
        }
      });
  };
  const deleteTeamMember = (id: string) => {
    toggleLoader(true);
    deleteTeamMemberById(id)
      .then(() => {
        const values = formikRef.current?.values;
        if (values && values.teams !== null && values.teams.length > 0) {
          formikRef.current?.setValues({
            ...values,
            teams: values.teams.filter((item) => item.teamsId !== id),
          });
        }
        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          const errorMessage = error as ApiMessage;
          if (errorMessage.code === 1026) {
            setToastrId('judgementConflictFailureMessage');
            setToastrDefaultMessage(
              'Deleting team is not allowed if only one team is available',
            );
          } else {
            setToastrId('Contacts.deleteToastr');
            setToastrDefaultMessage('Failed to delete');
          }
        }
      });
  };

  const formatDisciplineType = (disciplineType: string) => {
    if (lookups === null) return '';
    const discipline = lookups.discipline.find((d) => {
      if (d.code === disciplineType) {
        return d;
      }
    });
    if (discipline) {
      return discipline.description;
    }
    return '';
  };

  const deleteConcern = (id: string) => {
    toggleLoader(true);
    deleteConcernById(id)
      .then(() => {
        const values = formikRef.current?.values;
        if (values && values.concerns !== null && values.concerns.length > 0) {
          if (numOfConcerns - 1 === 0) {
            formikRef.current?.setFieldValue('concerns', [
              {
                concernId: null,
                areaOfConcern: null,
                teamMember: null,
                comments: null,
              },
            ]);
            setNumOfConcerns(1);
          } else {
            formikRef.current?.setValues({
              ...values,
              concerns: values.concerns.filter((item) => item.concernId !== id),
            });
            setNumOfConcerns(numOfConcerns - 1);
          }
        }
        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          const errorMessage = error as ApiMessage;
          if (errorMessage.code === 1025) {
            setToastrId('judgementConcernFailureMessage');
            setToastrDefaultMessage(
              'Deleting concern is not allowed if only one concern is available',
            );
          } else {
            setToastrId('Contacts.deleteToastr');
            setToastrDefaultMessage('Failed to delete');
          }
        }
      });
  };

  const getEmployeesData = (cId: string) => {
    toggleLoader(true);
    getAllEmployeeMinimalList()
      .then((data) => {
        setEmployees(data);
        getClinicalJudgementDetails(cId);
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('loadFailureMessage');
          setToastrDefaultMessage('Failed to get details');
        }
      });
  };

  const processAndSubmitResponse = (value: ClinicalEligibilty) => {
    value.concerns = value.concerns.filter(
      (item) =>
        item.areaOfConcern !== null ||
        item.teamMember !== null ||
        item.comments !== null,
    );
    if (value.additionalTools) {
      value.additionalTools.tools = value.additionalTools?.tools.filter(
        (item) =>
          item.nameOfToolUsed !== null ||
          item.dateAdministered !== null ||
          item.administeredBy !== null ||
          item.disciplineOfPerson !== null,
      );
    }
    if (clientId === undefined) {
      return;
    }
    toggleLoader(true);
    saveClinicalJudgement(clientId, value)
      .then(() => {
        getClinicalJudgementDetails(clientId);
        setSuccessOrError('success');
        setToastrId('clinicalJudgementUpdated');
        setToastrDefaultMessage('Clinical judgement saved successfully');
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('clinicalJudgementFailedToUpdate');
          setToastrDefaultMessage('Failed to update clinical judgement');
        }
      });
  };

  const getBDIValues = () => {
    const bdiValues: DataJsonType[] = [];
    if (clinicalJudgement) {
      bdiValues.push({
        id: 'toolsUsed',
        labelid: 'toolsUsedText',
        defaultLabel: 'Tools Used:',
        value: 'Battelle Development Inventory II',
      });
    }
    return bdiValues;
  };

  const onSignatureSaveOrUpdate = (signed: string) => {
    if (clinicalJudgement) {
      setClinicalJudgement({
        ...clinicalJudgement,
        signature: {
          partitionkey: '',
          rowkey: '',
          name: null,
          date: null,
          signatureValue: signed,
        },
      });
    }
    formikRef.current?.setFieldValue('signature', {
      partitionKey: '',
      rowKey: '',
      signatureValue: signed,
    });
    setSignClicked(false);
  };

  const onCancelClick = () => {
    setSignClicked(false);
  };

  const handleSuccess = (successError: AlertColor, id: string, mes: string) => {
    setSuccessOrError(successError);
    setToastrId(id);
    setToastrDefaultMessage(mes);
  };

  const resetCallBackForTypeEmployee = () => {
    setSignClicked(!signClicked);

    formikRef.current?.setValues((values) => ({
      ...values,
      signature: {
        partitionkey: '',
        rowkey: '',
        signatureValue: '',
        isUpdated: true,
        name: null,
        date: null,
      },
    }));
  };

  const handleFormSubmission = useCallback(
    debounce((values: ClinicalEligibilty) => {
      processAndSubmitResponse(values);
    }, 500),
    [clientId],
  );

  return (
    <Box component="main">
      <Toolbar disableGutters>
        <Box
          display="flex"
          alignItems="center"
          className="mr-md"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(BATELLE_DEVELOPMENT_INVENTORY_2)}
        >
          <Backarrow />
        </Box>
        <TitleText
          labelid="clinicalJudgementText"
          defaultlabel="Clinical Judgement"
        />
      </Toolbar>
      <div
        ref={parentRef}
        style={{
          pointerEvents: isScreenLocked || uploadedToTBR ? 'none' : 'auto',
          opacity: isScreenLocked || uploadedToTBR ? '0.5' : '1 ',
        }}
        onKeyDownCapture={(e) => {
          if (isScreenLocked) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        onFocus={() => {
          if (isScreenLocked) {
            if (parentRef.current) parentRef.current.focus();
          }
        }}
      >
        <Formik
          innerRef={formikRef}
          initialValues={initialValue}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleFormSubmission(
              JSON.parse(JSON.stringify(values)) as ClinicalEligibilty,
            );
          }}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <>
              {showCancelDialog && (
                <ModalPopup
                  open={showCancelDialog}
                  maxWidth={'xs'}
                  description="formUnsavedChangesMessage"
                  onCancel={() => setShowCancelDialog(false)}
                  onOk={() => {
                    formikRef.current?.setValues(clinicalJudgementInitialValue);
                    setShowCancelDialog(false);
                  }}
                  labelId1="Clientpage.Nobtn"
                  negativeActionLabel="No"
                  labelId2="Clientpage.Yesbtn"
                  positiveActionLabel="Yes"
                />
              )}

              {toastrId && (
                <SnackBarComponent
                  open={toastrId !== undefined}
                  handleClose={() => {
                    setToastrId(undefined);
                  }}
                  labelId={toastrId}
                  defaultMessageId={toastrDefaultMessage}
                  successOrError={successOrError}
                />
              )}

              <>
                {clinicalJudgement && (
                  <Box component="section">
                    <Card sx={{ padding: '16px' }}>
                      <ProgramPanel
                        program={clinicalJudgement.program}
                        client={clinicalJudgement.client}
                      />
                    </Card>
                  </Box>
                )}

                <Box component="section" className="pt-md pb-md">
                  <Card className="p-lg">
                    <Box>
                      <MediumTypography
                        labelid="scoresText"
                        defaultlabel="Scores"
                        className="mainText-xlg pb-md"
                      />
                      <KeyValueComponent data={getBDIValues()} />
                      {clinicalJudgement && (
                        <>
                          <Grid container columnSpacing={'20px'}>
                            <Grid item xs={3.5} className="pt-md">
                              <CustomcardComponent
                                headTextWithValue={`Adaptive(DQ):`}
                                headTextWithValue2={
                                  clinicalJudgement.developmentQuotientResults
                                    .adaptiveDQScore
                                }
                                subText1={`Self Care(ScRaw):`}
                                subText1Value={
                                  clinicalJudgement.subDomainsResults
                                    .selfCareScore
                                }
                                subText2={`Personal responsibility (PrRaw):`}
                                subText2Value={
                                  clinicalJudgement.subDomainsResults
                                    .personalResponsibilityScore
                                }
                              />
                            </Grid>
                            <Grid item xs={3.5} className="pt-md">
                              <CustomcardComponent
                                headTextWithValue={`Communication(DQ):`}
                                headTextWithValue2={
                                  clinicalJudgement.developmentQuotientResults
                                    .communicationDQScore
                                }
                                subText1={`Receptive Communication (RcRaw):`}
                                subText1Value={
                                  clinicalJudgement.subDomainsResults
                                    .respectiveCommunicationScore
                                }
                                subText2={`Expressive Communication (EcRaw):`}
                                subText2Value={
                                  clinicalJudgement.subDomainsResults
                                    .expressiveCommunicationScore
                                }
                              />
                            </Grid>
                            <Grid item xs={3.5} className="pt-md">
                              <CustomcardComponent
                                headTextWithValue={`Personal-Social(DQ):`}
                                headTextWithValue2={
                                  clinicalJudgement.developmentQuotientResults
                                    .personalSocialDQScore
                                }
                                subText1={`Adult Interaction(AiRaw):`}
                                subText1Value={
                                  clinicalJudgement.subDomainsResults
                                    .adultInteractionScore
                                }
                                subText2={`Peer Interaction(PiRaw):`}
                                subText2Value={
                                  clinicalJudgement.subDomainsResults
                                    .peerInteractionScore
                                }
                                subText3={`Self Concept & Social Role (SrRaw):`}
                                subText3Value={
                                  clinicalJudgement.subDomainsResults
                                    .selfConceptSocialRoleScore
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid container columnSpacing={'20px'}>
                            <Grid item xs={3.5} className="pt-md">
                              <CustomcardComponent
                                headTextWithValue={`Motor(DQ):`}
                                headTextWithValue2={
                                  clinicalJudgement.developmentQuotientResults
                                    .motorDQScore
                                }
                                subText1={`Gross Motor(GmRaw):`}
                                subText1Value={
                                  clinicalJudgement.subDomainsResults
                                    .grossMotorScore
                                }
                                subText2={`Fine Motor(FmRaw):`}
                                subText2Value={
                                  clinicalJudgement.subDomainsResults
                                    .fineMotorScore
                                }
                                subText3={`Perceptual Motor(PmRaw):`}
                                subText3Value={
                                  clinicalJudgement.subDomainsResults
                                    .perceptualMotorScore
                                }
                              />
                            </Grid>
                            <Grid item xs={3.5} className="pt-md">
                              <CustomcardComponent
                                headTextWithValue={`Cognitive(DQ):`}
                                headTextWithValue2={
                                  clinicalJudgement.developmentQuotientResults
                                    .cognitiveDQScore
                                }
                                subText1={`Attention / Memory (AmRaw): `}
                                subText1Value={
                                  clinicalJudgement.subDomainsResults
                                    .attentionMemoryScore
                                }
                                subText2={`Reasoning / Academic Skills (RaRaw):`}
                                subText2Value={
                                  clinicalJudgement.subDomainsResults
                                    .reasoningAcademicSkillsScore
                                }
                                subText3={`Perception / Concepts (PcRaw):`}
                                subText3Value={
                                  clinicalJudgement.subDomainsResults
                                    .perceptionConceptsScore
                                }
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      <Box className="pt-xlg">
                        <MediumTypography
                          labelid="disciplinesNote"
                          defaultlabel="Disciplines on Team:"
                          className="mainText-md"
                        />

                        <Box component="div">
                          <Box component="div" className="mt-md">
                            <Grid container columnSpacing={'40px'}>
                              <Grid item xs={5}>
                                <DropdownComponent
                                  disabled={uploadedToTBR}
                                  names={employees.map((item) => {
                                    return {
                                      label: item.name ?? '',
                                      id: item.id,
                                    };
                                  })}
                                  labelid="IFSP.selectName"
                                  defaultlabelid="Select Name"
                                  value={
                                    selectedTeam !== null ? selectedTeam.id : ''
                                  }
                                  handleChange={(value) => {
                                    const employee = employees.find(
                                      (item) => item.id === value,
                                    );
                                    if (employee) {
                                      setSelectedTeam(employee);
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item xs={5} className="mb-md">
                                <DropdownComponent
                                  disabled={uploadedToTBR}
                                  names={
                                    selectedTeam !== null
                                      ? getDisciplineTypes(
                                          selectedTeam.disciplineType,
                                        )
                                      : []
                                  }
                                  labelid="selectDisciplineText"
                                  defaultlabelid="Select Discipline"
                                  value={
                                    selectedTeam !== null &&
                                    selectedTeam.disciplineType !== null &&
                                    selectedTeam.disciplineType !== ''
                                      ? selectedTeam.disciplineType.split(
                                          ',',
                                        )[0]
                                      : ''
                                  }
                                  handleChange={() => {}}
                                />
                              </Grid>
                              <Grid
                                container
                                xs={1}
                                className="cursorPointer "
                                onClick={() => {
                                  if (
                                    selectedTeam !== null &&
                                    values.teams !== null
                                  ) {
                                    const existingTeams = values.teams;
                                    if (existingTeams.length > 0) {
                                      const emp = existingTeams.find(
                                        (team) =>
                                          team.email === selectedTeam.email,
                                      );
                                      if (emp) {
                                        return;
                                      }
                                    }
                                    existingTeams.push({
                                      teamsId: '',
                                      name: `${selectedTeam.firstName} ${selectedTeam.lastName}`,
                                      disciplineType:
                                        selectedTeam.disciplineType !== null
                                          ? selectedTeam.disciplineType
                                          : '-',
                                      email:
                                        selectedTeam.email !== null
                                          ? selectedTeam.email
                                          : '-',
                                      phone:
                                        selectedTeam.phone !== null
                                          ? selectedTeam.phone
                                          : '-',
                                    });
                                    formikRef.current?.setValues({
                                      ...values,
                                      teams: existingTeams,
                                    });
                                    setSelectedTeam(null);
                                  }
                                }}
                              >
                                <Grid item>
                                  <Box
                                    component="div"
                                    className="flex__ pt-xs "
                                  >
                                    <AddNew className="ml-sm mr-sm" />
                                    <MediumTypography
                                      labelid="IFSP.Add"
                                      defaultlabel="Add"
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                          <TableContainer>
                            <Table>
                              <TableHeader
                                className="listDataTableHead"
                                headerNames={teamHeadCells}
                                checkBoxRequired={false}
                              />
                              <TableBody className="tableRowcss">
                                {values.teams !== null &&
                                  values.teams.map((team, index) => (
                                    <TableRow
                                      sx={{
                                        backgroundColor:
                                          index % 2 === 0
                                            ? '#ECF9F8'
                                            : '#ffffff',
                                      }}
                                    >
                                      <TableCell>
                                        <MediumTypography label={team.name} />
                                      </TableCell>
                                      <TableCell>
                                        <MediumTypography
                                          label={formatDisciplineType(
                                            team.disciplineType,
                                          )}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <MediumTypography label={team.email} />
                                      </TableCell>
                                      <TableCell>
                                        <MediumTypography label={team.phone} />
                                      </TableCell>
                                      <TableCell
                                        className="pt-md"
                                        sx={{ cursor: 'pointer' }}
                                      >
                                        <ActionToolTip
                                          labelId="BlockTime.delete"
                                          defaultLabel="Delete"
                                        >
                                          <DeleteIcon
                                            onClick={() => {
                                              if (values.teams !== null) {
                                                const teamMember =
                                                  values.teams[index];
                                                if (teamMember.teamsId === '') {
                                                  formikRef.current?.setValues({
                                                    ...values,
                                                    teams: values.teams.filter(
                                                      (item) =>
                                                        item.email !==
                                                        teamMember.email,
                                                    ),
                                                  });
                                                } else {
                                                  deleteTeamMember(
                                                    teamMember.teamsId,
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </ActionToolTip>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                            {values.teams !== null &&
                              values.teams.length === 0 && (
                                <Box component="div" className="width__100">
                                  <MediumTypography
                                    textstyle={'center'}
                                    labelid="IFSP.emptyMessage"
                                    defaultlabel="No data added yet"
                                    className="p-sm"
                                  />
                                </Box>
                              )}
                          </TableContainer>
                        </Box>
                      </Box>

                      <Box className="mt-sm pt-xlg">
                        <MediumTypography
                          labelid="assessmentNote"
                          defaultlabel="Additional Assessment Tool(s) Used: (Note: An additional assessment tool specific to the area of concern is required to be administered)."
                          className="mainText-md"
                        />

                        {values.additionalTools !== null &&
                          values.additionalTools.tools.map((item, index) => (
                            <Box>
                              {(item.status === null || item.status === '') && (
                                <Box sx={{ display: 'flex' }}>
                                  <Grid
                                    container
                                    xs={8}
                                    className="pt-md"
                                    columnSpacing={'32px'}
                                    rowSpacing={'24px'}
                                  >
                                    {index !== 0 && (
                                      <Grid item xs={12}>
                                        <Box className="defaultHorizontalLine" />
                                      </Grid>
                                    )}

                                    <Grid item xs={6}>
                                      {clinicalJudgement && (
                                        <SelectComponent
                                          disabled={uploadedToTBR}
                                          names={clinicalJudgement.assessmentTools.map(
                                            (tool) => tool.nameOfTool,
                                          )}
                                          labelid="nameOfToolsText"
                                          defaultValue="Name of Tool(s)"
                                          value={item.nameOfToolUsed ?? ''}
                                          handleChange={(value) => {
                                            const toolFound =
                                              clinicalJudgement.assessmentTools.find(
                                                (tool) =>
                                                  tool.nameOfTool === value,
                                              );
                                            if (toolFound) {
                                              const date =
                                                toolFound.dateAdministered !==
                                                null
                                                  ? dayjs(
                                                      toolFound.dateAdministered,
                                                    ).format('MM/DD/YYYY')
                                                  : null;
                                              if (
                                                values.additionalTools !== null
                                              ) {
                                                const tool =
                                                  values.additionalTools.tools[
                                                    index
                                                  ];
                                                tool.nameOfToolUsed = value;
                                                tool.dateAdministered = date;
                                                formikRef.current?.setValues(
                                                  values,
                                                );
                                              }
                                            }
                                          }}
                                        />
                                      )}
                                    </Grid>

                                    <Grid item xs={6}>
                                      <DatePickerComponent
                                        disabledDate={uploadedToTBR}
                                        labelid="dateAdministeredText"
                                        defaultlabelid="Date Administered"
                                        value={
                                          item.dateAdministered
                                            ? dayjs(item.dateAdministered)
                                            : null
                                        }
                                        handlechange={(date) => {
                                          const formattedDate =
                                            dayjs(date).format('MM/DD/YYYY');
                                          if (values.additionalTools) {
                                            values.additionalTools.tools[
                                              index
                                            ].dateAdministered =
                                              date !== null
                                                ? formattedDate
                                                : null;
                                          }
                                          formikRef.current?.setValues(values);
                                        }}
                                      />
                                    </Grid>

                                    {item.nameOfToolUsed !== null &&
                                      item.nameOfToolUsed === 'Other' && (
                                        <Grid item xs={12}>
                                          <Textinput
                                            disabled={uploadedToTBR}
                                            inputProps={{ maxLength: 50 }}
                                            Value={item.specify ?? ''}
                                            labelid="IFSP.specify"
                                            defaultlabelid="Specify"
                                            handlechange={(value) => {
                                              if (values.additionalTools) {
                                                values.additionalTools.tools[
                                                  index
                                                ].specify = value;
                                              }
                                              formikRef.current?.setValues(
                                                values,
                                              );
                                            }}
                                          />
                                        </Grid>
                                      )}

                                    <Grid item xs={6}>
                                      <Box>
                                        <DropdownComponent
                                          disabled={uploadedToTBR}
                                          names={employees.map((emp) => {
                                            return {
                                              label: emp.name ?? '',
                                              id: emp.id,
                                            };
                                          })}
                                          labelid="administeredByText"
                                          defaultValue="Administered By"
                                          value={item.administeredBy ?? ''}
                                          handleChange={(value) => {
                                            const employee = employees.find(
                                              (emp) => emp.id === value,
                                            );
                                            if (employee) {
                                              if (
                                                values.additionalTools !== null
                                              ) {
                                                const tool =
                                                  values.additionalTools.tools[
                                                    index
                                                  ];
                                                tool.administeredBy =
                                                  employee.id;
                                                tool.disciplineOfPerson =
                                                  employee.disciplineType ??
                                                  null;
                                                formikRef.current?.setValues(
                                                  values,
                                                );
                                              }
                                            }
                                          }}
                                        />
                                        <MediumTypography />
                                      </Box>
                                    </Grid>

                                    <Grid item xs={6}>
                                      <DropdownComponent
                                        disabled={uploadedToTBR}
                                        names={
                                          item.disciplineOfPerson !== null
                                            ? getDisciplineTypes(
                                                item.disciplineOfPerson,
                                              )
                                            : []
                                        }
                                        labelid="disciplineAdministeringText"
                                        defaultlabelid="Discipline of Person Administering"
                                        value={
                                          item.disciplineOfPerson !== null &&
                                          item.disciplineOfPerson !== ''
                                            ? item.disciplineOfPerson.split(
                                                ',',
                                              )[0]
                                            : ''
                                        }
                                        handleChange={() => {}}
                                      />
                                    </Grid>
                                  </Grid>

                                  {addMoreToolCount !== 1 && (
                                    <Box
                                      sx={{
                                        cursor: 'pointer',
                                        display: 'inline-flex',
                                        alignSelf: 'flex-end',
                                        marginLeft: '16px',
                                        marginBottom: '8px',
                                      }}
                                      onClick={() => {
                                        if (values.additionalTools) {
                                          const t =
                                            values.additionalTools.tools[index];
                                          let tools: Tools[] = [];
                                          if (t.additionalToolsId === '') {
                                            tools =
                                              values.additionalTools.tools.filter(
                                                (__, i) => i !== index,
                                              );
                                            const count = tools.filter(
                                              (i) => i.status !== 'DELETE',
                                            ).length;
                                            setAddMoreToolCount(count);
                                          } else {
                                            tools =
                                              values.additionalTools.tools.map(
                                                (tool, i) => {
                                                  if (index === i) {
                                                    tool.status = 'DELETE';
                                                  }
                                                  return tool;
                                                },
                                              );
                                            const count = tools.filter(
                                              (i) => i.status !== 'DELETE',
                                            ).length;
                                            setAddMoreToolCount(count);
                                          }
                                          formikRef.current?.setValues({
                                            ...values,
                                            additionalTools: {
                                              ...values.additionalTools,
                                              tools,
                                            },
                                          });
                                        }
                                      }}
                                    >
                                      <DeleteAlertIcon />
                                    </Box>
                                  )}
                                </Box>
                              )}
                            </Box>
                          ))}

                        {addMoreToolCount < 3 && (
                          <ButtonComponent
                            disabled={uploadedToTBR}
                            className="btn-add-code mt-md"
                            variantType="contained"
                            type="submit"
                            label={`${rctl.formatMessage({
                              id: 'addMoreToolText',
                              defaultMessage: '+Add More Tools',
                            })}`}
                            defaultLabelId="+Add More Tools"
                            onClick={() => {
                              setAddMoreToolCount(addMoreToolCount + 1);
                              if (values.additionalTools) {
                                values.additionalTools.tools.push({
                                  additionalToolsId: '',
                                  nameOfToolUsed: null,
                                  dateAdministered: null,
                                  administeredBy: null,
                                  disciplineOfPerson: null,
                                  specify: null,
                                  status: null,
                                });
                                formikRef.current?.setValues(values);
                              }
                            }}
                          />
                        )}
                      </Box>

                      <Box
                        sx={{
                          width: '64%',
                          marginTop: addMoreToolCount === 3 ? '16px' : '8px',
                        }}
                      >
                        <CommentTextbox
                          disabled={uploadedToTBR}
                          placeholder={rctl.formatMessage({
                            id: 'additionalToolCommentsText',
                            defaultMessage: 'Additional Tool Comments',
                          })}
                          maxLength={1000}
                          rows={4}
                          Value={
                            values.additionalTools !== null
                              ? values.additionalTools.additionalToolComments
                              : ''
                          }
                          handlechange={(text) => {
                            setFieldValue(
                              'additionalTools.additionalToolComments',
                              text,
                            );
                          }}
                        />
                      </Box>

                      <Box className="pt-lg pb-lg">
                        <MediumTypography
                          labelid="JustificationConcernsText"
                          defaultlabel="Justification of Qualitative Concerns"
                          className="mainText-xlg"
                        />
                        <MediumTypography
                          labelid="JustificationDescText"
                          defaultlabel="How do the atypical behaviours / Developmental patterns affect the child’s functioning in the context of the child’s daily activities and/or interactions with peer/adults"
                          className="mainText-md pt-md pb-md"
                        />
                        <Grid container xs={7.6}>
                          <CommentTextbox
                            disabled={uploadedToTBR}
                            placeholder={rctl.formatMessage({
                              id: 'concernsText',
                              defaultMessage: 'Concerns',
                            })}
                            maxLength={3000}
                            rows={5}
                            Value={values.notes}
                            handlechange={(text) => {
                              setFieldValue('notes', text);
                            }}
                          />
                          {errors.notes && touched.notes && (
                            <MediumTypography
                              className="errorText-md"
                              labelid={errors.notes as string}
                              defaultlabel="Concerns are Required"
                            />
                          )}
                        </Grid>
                      </Box>

                      <MediumTypography
                        labelid="areaOfConcernsTitleText"
                        defaultlabel="Battelle Developmental Inventory Concerns"
                        className="mainText-xlg mb-lg"
                      />

                      <Box
                        className="p-sm borderRadius4"
                        sx={{
                          backgroundColor: '#ECF9F8',
                          border: '1px solid #00C6B8',
                        }}
                      >
                        {[...Array(numOfConcerns)].map((__, index) => {
                          return (
                            <AreaOfConcern
                              uploadedToTBR={uploadedToTBR}
                              values={values.concerns}
                              areaOfConcernValue={values.concerns[index]}
                              employees={employees.map((item) => {
                                return {
                                  label: item.name ?? '',
                                  id: item.id,
                                };
                              })}
                              index={index}
                              onDelete={(i: number) => {
                                const concern = values.concerns[i];
                                if (concern.concernId === null) {
                                  if (values.concerns.length === 1) {
                                    values.concerns = [
                                      {
                                        concernId: null,
                                        areaOfConcern: null,
                                        teamMember: null,
                                        comments: null,
                                      },
                                    ];
                                    formikRef.current?.setValues(values);
                                  } else {
                                    values.concerns = values.concerns.filter(
                                      (___, id) => i != id,
                                    );
                                    setNumOfConcerns(numOfConcerns - 1);
                                    formikRef.current?.setValues(values);
                                  }
                                } else {
                                  deleteConcern(concern.concernId);
                                }
                              }}
                              disableLine={numOfConcerns != index + 1}
                              handleDataChange={(
                                i: number,
                                concerns: AreaOfConcerns,
                              ) => {
                                values.concerns[i] = concerns;
                                formikRef.current?.setValues(values);
                              }}
                            />
                          );
                        })}
                        <ButtonComponent
                          disabled={uploadedToTBR}
                          labelId="addAnotherConcernText"
                          defaultLabelId="+ Add Another Concern"
                          className="pl-sm"
                          sxProps={{
                            color: '#00C6B8',
                            textTransform: 'none',
                            textDecoration: 'underline',
                            textUnderlineOffset: '4px',
                            cursor:
                              numOfConcerns < 5 ? 'pointer' : 'not-allowed',
                            opacity: numOfConcerns < 5 ? '1' : '0.5',
                            fontWeight: 'bold',
                          }}
                          onClick={() => {
                            if (numOfConcerns < 5) {
                              values.concerns.push({
                                concernId: null,
                                areaOfConcern: null,
                                teamMember: null,
                                comments: null,
                              });
                              formikRef.current?.setValues(values);
                              setNumOfConcerns(numOfConcerns + 1);
                            }
                          }}
                        />
                      </Box>
                      <Box component="div" className="mt-lg pb-md">
                        <MediumTypography
                          labelid="signatureText"
                          defaultlabel="Signature"
                          className="mainText-xlg pt-md pb-md"
                        />

                        {values.signature !== null &&
                          (values.signature.signatureValue !== '' ||
                            values.signature.rowkey !== '') && (
                            <ButtonComponent
                              className="btn-primary btn-cancel signature_view_Box"
                              labelId={'ProgressNoteViewSignature'}
                              defaultLabelId={'View signature'}
                              variantType="outlined"
                              LeftIcon={<Editsignature />}
                              onClick={() => setSignClicked(true)}
                            />
                          )}

                        {values.signature !== null &&
                          values.signature.signatureValue === '' &&
                          values.signature.rowkey === '' && (
                            <ButtonComponent
                              disabled={uploadedToTBR}
                              className="btn-primary btn-cancel signature_add_Box"
                              labelId="Insurance.Addsignature"
                              defaultLabelId="Add Signature"
                              variantType="outlined"
                              onClick={() => {
                                setSignClicked(true);
                              }}
                            />
                          )}
                      </Box>
                      {signClicked && (
                        <Box component="div">
                          <SignatureComponent
                            onCancelClick={onCancelClick}
                            signaturePartitionKey={
                              values.signature.partitionkey
                            }
                            signatureRowKey={values.signature.rowkey}
                            signature={values.signature.signatureValue}
                            signatureSaveOrUPdate={onSignatureSaveOrUpdate}
                            handleSuccess={handleSuccess}
                            signClicked={signClicked}
                            hideRemoveButton={uploadedToTBR}
                            resetCallBackForTypeEmployee={
                              resetCallBackForTypeEmployee
                            }
                            type="Employee"
                          />
                        </Box>
                      )}
                    </Box>
                  </Card>
                </Box>
              </>

              <Box>
                <Grid
                  className="flex__ justifyContent-FlexEnd pt-md pb-lg "
                  container
                  direction="row"
                  alignItems="right"
                >
                  <Grid item>
                    <ButtonComponent
                      disabled={uploadedToTBR}
                      className="btn-primary btn-cancel mr-md"
                      variantType="contained"
                      labelId="Clientpage.cancelbtn"
                      defaultLabelId="Cancel"
                      onClick={() => {
                        if (_.isEqual(clinicalJudgementInitialValue, values)) {
                          navigate(BATELLE_DEVELOPMENT_INVENTORY_2);
                        } else {
                          setShowCancelDialog(true);
                        }
                      }}
                    />

                    <ButtonComponent
                      disabled={uploadedToTBR}
                      className="btn-primary btn_width_90px btn-submit"
                      variantType="contained"
                      labelId="ProgressNote.Save"
                      defaultLabelId="Save"
                      onClick={() => {
                        formikRef.current?.submitForm();
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Formik>
      </div>
    </Box>
  );
};

export default ClinicalJudgementEligibility;
