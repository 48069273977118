import { Box } from '@mui/material';
import ActivityListTableView from './ActivityListTableView';
import TitleText from '../../components/formlib/TitleText';
import MediumTypography from '../../components/formlib/MediumTypography';
import { useState, useEffect, useContext } from 'react';
import { STORAGE_USER_ID_KEY } from '../../services/Constant';
import {
  Activity,
  ActivitySheet,
  getWeeklyActivitySheet,
  GroupAppointment,
} from '../../services/configApi/activitySheet/weeklyActivitySheetProvider';
import { convertTimeDuration, formatDate } from '../../utils/dateUtil';
import { useIntl } from 'react-intl';
import FilterSelection from '../../components/formlib/FilterSelection';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import { FilterStatuses } from './activityUtils';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { ResponseType } from '../../utils/type';
import WeekDatePicker from '../../components/formlib/modal/WeekDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import ActivitySheetChart from './ActivitySheetChart';
import ProductivityChart from './ProductivityChart';
import { Context } from '../../LanguageWrapper';
import {
  Holiday,
  getHolidayList,
} from '../../services/configApi/employees/holidayServices';
import HolidayListDialog from '../holidays/HolidayListDialog';
import { useLocation } from 'react-router';

const EmployeeActivityDashboard = () => {
  const rctl = useIntl();
  const location = useLocation();
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [originalActivities, setOriginalActivities] = useState<ActivitySheet>();
  const [activityDetails, setActivityDetails] = useState<ActivitySheet>();
  const [reload, setReload] = useState<boolean>(true);
  const [toastrId, setToastrId] = useState<string>();
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');
  const [defaultlabel, setToastrDefaultMessage] = useState<string>();
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [showHolidays, setShowHolidays] = useState<boolean>(false);
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const context = useContext(Context);

  const getActvitySheet = (newDate: Date, pickerChange?: boolean) => {
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (userId === null) {
      return;
    }
    const navParams = pickerChange ? null : location.state;
    toggleLoader(true);
    getWeeklyActivitySheet(
      navParams
        ? formatDate(navParams.date, 'MM/DD/YYYY')
        : formatDate(newDate, 'MM/DD/YYYY'),
      userId,
    )
      .then((response0: ActivitySheet) => {
        response0.activities.forEach((activity) => {
          activity.appointments?.forEach((appt) => {
            appt.attendeeId = userId;
          });
        });
        response0.activities.forEach((activity) => {
          if (activity.appointments.length > 0) {
            const appointmentsGroup: Map<string, GroupAppointment> = new Map();

            activity.appointments?.forEach((appt) => {
              if (appointmentsGroup.has(appt.appointmentId)) {
                const group = appointmentsGroup.get(appt.appointmentId);
                if (group) {
                  group.appointments.push(appt);
                  appointmentsGroup.set(appt.appointmentId, group);
                }
              } else {
                const attendeeId = localStorage.getItem('userId');
                appointmentsGroup.set(appt.appointmentId, {
                  apptTime: appt.startTime ?? '',
                  appointmentId: appt.appointmentId,
                  attendeeId: attendeeId ?? '',
                  appointmentType: appt.appointmentType,
                  groupBillingStartTime: appt.groupBillingStartTime,
                  groupBillingDuration:
                    appt.groupBillingDuration !== null
                      ? convertTimeDuration(appt.groupBillingDuration, true)
                      : null,
                  appointments: [appt],
                });
              }
            });

            activity.appointments = [];
            activity.groupBilling = [];

            appointmentsGroup.forEach((values) => {
              if (values.appointments.length > 1) {
                activity.groupBilling.push(values);
              } else {
                activity.appointments.push(values.appointments[0]);
              }
            });
          } else {
            activity.groupBilling = [];
          }
        });
        setOriginalActivities(response0);
        return response0;
      })
      .then((response) => {
        return JSON.parse(JSON.stringify(formatAndReadResponse(response)));
      })
      .then((response1) => {
        setActivityDetails(response1);
        setSelectedStatus(null);
        toggleLoader(false);
      })
      .catch((error) => {
        setReload(false);
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          const response = error as ApiMessage;
          if (response.code === 400) {
            setToastrId('contactAdminErrorMessage');
            setToastrDefaultMessage('Please contact administrator');
          }
        }
      });
  };
  useEffect(() => {
    const navParams = location.state;
    if (navParams !== null) {
      setSelectedDate(navParams.date as Date);
    }
  }, []);

  useEffect(() => {
    setReload(false);
  }, [activityDetails]);

  const readHolidayList = () => {
    toggleLoader(true);
    const program = context.selectedProgram;
    if (program === undefined) {
      return;
    }

    getHolidayList(formatDate(selectedDate, 'MM/DD/YYYY'), program.id)
      .then((response) => {
        toggleLoader(false);
        setShowHolidays(true);
        setHolidays(response.holidayList);
      })
      .catch(() => {
        toggleLoader(false);
      });
  };

  const formatAndReadResponse = (response: ActivitySheet) => {
    let rowId = 0;
    const newResponse: ActivitySheet = {
      ...response,
      activities: response.activities.map(
        (activity, index, arrayActivities) => {
          if (index === 0) {
            activity.rowId = rowId;
          } else {
            const previousActivity = arrayActivities[index - 1];
            if (previousActivity.day !== activity.day) {
              rowId = rowId === 0 ? 1 : 0;
            }
            activity.rowId = rowId;
          }
          return activity;
        },
      ),
    };
    newResponse.activities.forEach((activity) => {
      if (activity.appointments !== null && activity.appointments?.length > 0) {
        const lastIndex = activity.appointments.length - 1;
        activity.appointments.forEach((appt, index) => {
          appt.isLastIndex = index === lastIndex;
          return appt;
        });
      }
      return activity;
    });
    return newResponse;
  };

  const filterActivities = (filter: string | null) => {
    if (originalActivities && activityDetails) {
      if (filter === null) {
        const activitiesCopy = JSON.parse(
          JSON.stringify(originalActivities.activities),
        ) as Activity[];
        setActivityDetails({
          ...activityDetails,
          activities: activitiesCopy,
        });
        return;
      }
      const activities = originalActivities.activities;
      const filteredActivities = activities.filter((activity) => {
        return activity.status === rctl.formatMessage({ id: filter });
      });

      const filteredActivitiesCopy = JSON.parse(
        JSON.stringify(filteredActivities),
      ) as Activity[];
      let day =
        filteredActivitiesCopy.length > 0 ? filteredActivitiesCopy[0].day : '';
      let rowId = 0;
      const finalActivities = filteredActivitiesCopy.filter(
        (activity, index, arrayActivities) => {
          if (index === 0) {
            activity.rowId = rowId;
          }
          if (index !== 0) {
            const item = arrayActivities[index].day;
            if (day === item) {
              activity.day = '';
              activity.rowId = rowId;
            } else {
              rowId = rowId === 0 ? 1 : 0;
              activity.rowId = rowId;
              day = activity.day;
            }
          }
          return activity;
        },
      );

      setActivityDetails({
        ...activityDetails,
        activities: finalActivities,
      });
    }
  };

  const getEmployeeType = () => {
    return activityDetails?.employeeType ?? '';
  };

  useEffect(() => {
    if (reload) {
      getActvitySheet(selectedDate);
    }
  }, [reload]);

  return (
    <Box component="main">
      {toastrId && (
        <SnackBarComponent
          open={toastrId !== undefined}
          handleClose={() => {
            setToastrId(undefined);
          }}
          labelId={toastrId}
          defaultMessageId={defaultlabel}
          successOrError={successOrError}
        />
      )}
      {showHolidays && (
        <HolidayListDialog
          open={showHolidays}
          holidays={holidays}
          onClose={() => setShowHolidays(false)}
        />
      )}
      {activityDetails && (
        <Box>
          <Box
            component={'section'}
            sx={{
              padding: '12px 0px',
              display: 'flex',
              flexDirection: 'row',
            }}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <TitleText
              labelid="myActivitySheetText"
              defaultlabel="My Weekly Activity Sheet"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Box sx={{ display: 'flex' }} alignItems={'center'}>
              <WeekDatePicker
                date={dayjs(selectedDate)}
                disableFutureDate={true}
                onDateSelect={(newDate: Dayjs) => {
                  setSelectedDate(newDate.toDate());
                  setOriginalActivities(undefined);
                  setActivityDetails(undefined);
                  setSelectedStatus(null);
                  getActvitySheet(newDate.toDate(), true);
                }}
              />
              <MediumTypography
                label={`${rctl.formatMessage({
                  id: 'employeeTypeText',
                  defaultMessage: 'Employee Type:',
                })} : `}
                sxProps={{ marginLeft: '16px', color: '#97A6A5' }}
              />
              <MediumTypography
                label={getEmployeeType()}
                defaultlabel="Hourly"
                sxProps={{ color: '#008C82', marginLeft: '8px' }}
              />
            </Box>

            <Box
              sx={{ display: 'flex', cursor: 'pointer' }}
              onClick={() => {
                if (holidays.length === 0) {
                  readHolidayList();
                } else {
                  setShowHolidays(true);
                }
              }}
            >
              <MediumTypography
                sxProps={{ color: '#008C82', cursor: 'pointer' }}
                labelid={`${rctl.formatMessage({
                  id: 'holiday(s)Text',
                  defaultMessage: 'Holiday(s)',
                })} ${dayjs().format('YYYY')}`}
                defaultlabel="Holiday(s)"
              />
            </Box>
          </Box>

          <Box
            component={'section'}
            sx={{
              display: 'flex',
              marginTop: '12px',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <ActivitySheetChart
              activity={activityDetails}
              reload={reload}
              cardStyle={{
                width:
                  activityDetails.employeeType === 'Salaried Exempt'
                    ? '65%'
                    : '100%',
                marginRight: '8px',
              }}
            />

            {activityDetails.employeeType === 'Salaried Exempt' && (
              <ProductivityChart
                productivity={activityDetails.productivity}
                cardStyle={{ width: '35%' }}
                barHeight="100px"
              />
            )}
          </Box>

          <Box
            component={'section'}
            display={'flex'}
            marginTop={'12px'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <MediumTypography
              sxProps={{
                fontWeight: 600,
              }}
              labelid="activitySheetRememberNoteText"
              defaultlabel="Remember to complete and submit your activity sheets & notes on Friday"
            />
            <Box sx={{ display: 'flex' }}>
              <FilterSelection
                hintTextId="FiterText"
                value={selectedStatus}
                items={FilterStatuses}
                onSelect={(value) => {
                  if (value === null) {
                    if (originalActivities) {
                      const newResponse =
                        formatAndReadResponse(originalActivities);
                      setActivityDetails(newResponse);
                    }
                  } else {
                    setActivityDetails(undefined);
                    filterActivities(value);
                  }
                  setSelectedStatus(value);
                }}
              />
            </Box>
          </Box>

          <ActivityListTableView
            selectedDate={selectedDate}
            employeeType={activityDetails.employeeType}
            activities={activityDetails.activities}
            setReload={setReload}
            reload={reload}
            isEmployee={true}
            screenName="EmployeeActivity"
          />
        </Box>
      )}
    </Box>
  );
};

export default EmployeeActivityDashboard;
