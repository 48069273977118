import { Box, Tooltip } from '@mui/material';
import { ProgressNoteItem } from '../../services/configApi/employees/employeeServices';
import { FC } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import { getStatusColor } from '../../utils/utilities';
import { useNavigate } from 'react-router-dom';
import { PROGRESS_NOTE_SETUP } from '../../routes/Routing';
import { STORAGE_USERNAME_KEY } from '../../services/Constant';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { ReactComponent as ArrowRight } from '../../assets/images/right.svg';
import { getFullNameWithMiddleName } from '../../utils/nameUtils';

interface ProgressNoteProps {
  progressNotes: ProgressNoteItem[];
  handleNavigation: () => void;
}

const ProgressNoteRow: FC<{
  progressNote: ProgressNoteItem;
  index: number;
}> = ({ progressNote, index }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ width: '98%' }}>
      {index !== 0 && <Box className={'defaultHorizontalLine'} />}
      <Box className={'flex__'}>
        <Box sx={{ flex: 0.4 }} className="pr-sm">
          <Box sx={{ width: '300px' }}>
            <Tooltip
              title={
                getFullNameWithMiddleName(
                  progressNote.firstName,
                  progressNote.middleName,
                  progressNote.lastName,
                ) &&
                getFullNameWithMiddleName(
                  progressNote.firstName,
                  progressNote.middleName,
                  progressNote.lastName,
                ).length > 50
                  ? getFullNameWithMiddleName(
                      progressNote.firstName,
                      progressNote.middleName,
                      progressNote.lastName,
                    )
                  : ''
              }
              followCursor
              arrow={true}
              sx={{ maxWidth: 'min-content' }}
            >
              <Box>
                <MediumTypography
                  onClick={() => {
                    const userId = localStorage.getItem(STORAGE_USERNAME_KEY);
                    if (
                      userId === null &&
                      progressNote.commonAppointmentId === null
                    ) {
                      return;
                    }
                    if (
                      checkPermissionForFeature(
                        'backend.progress_note',
                        'viewPermission',
                      )
                    ) {
                      navigate(PROGRESS_NOTE_SETUP, {
                        state: {
                          sortRequired: true,
                          eventId: progressNote.commonAppointmentId,
                          attendeeId: [userId],
                          clientIds: [progressNote.clientId],
                        },
                      });
                    }
                  }}
                  sxProps={{
                    color: '#008C82',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                  label={getFullNameWithMiddleName(
                    progressNote.firstName,
                    progressNote.middleName,
                    progressNote.lastName,
                  )}
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Box className={'flex__'} sx={{ flex: 0.4 }}>
          <MediumTypography
            labelid="ProgressNote.DPHID"
            defaultlabel="DPH ID:"
            sxProps={{ color: '#97A6A5', marginRight: '8px' }}
          />
          <MediumTypography
            label={
              progressNote.dphId !== null && progressNote.dphId !== ''
                ? progressNote.dphId
                : '-'
            }
          />
        </Box>
        <Box sx={{ flex: 0.2, textAlign: 'right' }}>
          <MediumTypography
            sxProps={{
              color: getStatusColor(progressNote.status),
            }}
            label={progressNote.status !== null ? progressNote.status : '-'}
          />
        </Box>
      </Box>
    </Box>
  );
};

const DashboardProgressNote: FC<ProgressNoteProps> = ({
  progressNotes,
  handleNavigation,
}) => {
  return (
    <Box className={'p-md'}>
      <Box className={'flex__ pt-xm'}>
        <MediumTypography
          sxProps={{ flexGrow: 1 }}
          className="mainText-lg"
          labelid="ProgressNote.Title"
          defaultlabel="Progress Note"
        />
        <Box sx={{ cursor: 'pointer' }} onClick={handleNavigation}>
          <ArrowRight />
        </Box>
      </Box>
      <Box
        className={'pt-md'}
        sx={{
          overflowY: 'scroll',
          height: '195px',
        }}
      >
        {progressNotes.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '8%',
            }}
          >
            <MediumTypography
              sxProps={{ fontWeight: 'bold' }}
              labelid="noProgressFoundText"
              defaultlabel="No Progress Note found yet"
            />
          </Box>
        )}
        {progressNotes.map((progressNote, index) => (
          <ProgressNoteRow progressNote={progressNote} index={index} />
        ))}
      </Box>
    </Box>
  );
};

export default DashboardProgressNote;
