import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  debounce,
} from '@mui/material';
import TitleText from '../../components/formlib/TitleText';
import { useIntl } from 'react-intl';
import { SELECTED_PROGRAM_KEY } from '../../services/Constant';
import { OptionType, SnackbarType } from '../../utils/type';
import { useCallback, useContext, useEffect, useState } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import {
  ActivitySheetEmployeeResponse,
  ReviewActivitySheetOverView,
  ReviewEmployeeHeader,
} from './types';
import {
  getReviewActivitySheetHeaders,
  nonWorkingLabelIds,
  workingLabelIds,
} from './activityUtils';
import ActivitySheetEmployeeRow from './ActivitySheetEmployeeRow';
import DoughnutChart from '../../components/charts/DoughnutChart';
import {
  convertTimeDuration,
  formatDate,
  formatStringTime,
} from '../../utils/dateUtil';
import AppPagination from '../../components/shared/AppPagination';
import { useLocation, useNavigate } from 'react-router';
import { ACTIVITY_APPROVAL_SHEET_ROUTE_NAME } from '../../routes/Routing';
import SearchBox from '../../components/formlib/SearchBox';
import {
  getSubmittedActivitySheet,
  getSubmittedActivitySheetEmployees,
} from '../../services/configApi/activitySheet/weeklyActivitySheetProvider';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import WeekDatePicker from '../../components/formlib/modal/WeekDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import HolidayListDialog from '../holidays/HolidayListDialog';
import {
  Holiday,
  getHolidayList,
} from '../../services/configApi/employees/holidayServices';
import { Context } from '../../LanguageWrapper';

const ActivitySheetOverview = () => {
  const rctl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showHolidays, setShowHolidays] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(
    location.state ? location.state.date : new Date(),
  );
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [selectedProgram, setSelectedProgram] = useState<OptionType | null>(
    null,
  );
  const context = useContext(Context);
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [activityChart, setActivityChart] =
    useState<ReviewActivitySheetOverView | null>(null);
  const [employeeResponse, setEmployeeResponse] =
    useState<ActivitySheetEmployeeResponse | null>(null);
  const [toastr, setToastr] = useState<SnackbarType>({
    toasterId: null,
    defaultToasterMessage: '',
    toasterAction: 'info',
  });

  useEffect(() => {
    getActivitySheetChartValues();
  }, [selectedDate, searchQuery]);

  useEffect(() => {
    const navParams = location.state;
    if (navParams !== null) {
      setSelectedDate(navParams.date as Date);
    }
  }, []);

  useEffect(() => {
    getActivitySheetEmployees();
  }, [selectedDate, searchQuery, pageNumber]);

  const getActivitySheetChartValues = () => {
    getSubmittedActivitySheet(formatDate(selectedDate, 'MM/DD/YYYY'))
      .then((response) => {
        setActivityChart(response);
      })
      .catch((error) => {
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastr({
            toasterId: apiError.id,
            toasterAction: 'error',
            defaultToasterMessage: apiError.message,
          });
        } else {
          setToastr({
            toasterId: 'requestFailureMessage',
            toasterAction: 'error',
            defaultToasterMessage: 'Request Failure',
          });
        }
      });
  };

  const getActivitySheetEmployees = () => {
    toggleLoader(true);
    getSubmittedActivitySheetEmployees(
      formatDate(selectedDate, 'MM/DD/YYYY'),
      searchQuery,
      pageNumber,
    )
      .then((response) => {
        toggleLoader(false);
        setEmployeeResponse(response);
      })
      .catch((error) => {
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastr({
            toasterId: apiError.id,
            toasterAction: 'error',
            defaultToasterMessage: apiError.message,
          });
        } else {
          setToastr({
            toasterId: 'requestFailureMessage',
            toasterAction: 'error',
            defaultToasterMessage: 'Request Failure',
          });
        }
      });
  };

  const readHolidayList = () => {
    toggleLoader(true);
    const program = context.selectedProgram;
    if (program === undefined) {
      return;
    }

    getHolidayList(formatDate(selectedDate, 'MM/DD/YYYY'), program.id)
      .then((response) => {
        toggleLoader(false);
        setShowHolidays(true);
        setHolidays(response.holidayList);
      })
      .catch(() => {
        toggleLoader(false);
      });
  };

  const getChartValues = (): number[] => {
    if (activityChart !== null) {
      const totalWorkingHours = formatStringTime(
        activityChart.totalPayHours !== null
          ? activityChart.totalPayHours
          : '00:00',
      );
      const billableHours = formatStringTime(
        activityChart.totalBillableHours !== null
          ? activityChart.totalBillableHours
          : '00:00',
      );
      if (totalWorkingHours === 0 && billableHours === 0) {
        return [0, 1];
      }
      return [billableHours, totalWorkingHours];
    }
    return [0, 0];
  };

  const getSalariedChartValues = (): number[] => {
    if (activityChart !== null) {
      const etHours = formatStringTime(activityChart.totalEtHours ?? '00:00');
      const ltiHours = formatStringTime(activityChart.totalLtiHours ?? '00:00');
      const profHours = formatStringTime(
        activityChart.totalProfessionalHours ?? '00:00',
      );
      const otherHours = formatStringTime(
        activityChart.totalOtherHours ?? '00:00',
      );
      if (
        etHours === 0 &&
        ltiHours === 0 &&
        profHours === 0 &&
        otherHours === 0
      ) {
        return [0, 0, 0, 0, 1];
      } else {
        return [etHours, ltiHours, profHours, otherHours];
      }
    }
    return [0, 0, 0, 0, 1];
  };

  const getSalariedTotalWorkUsage = () => {
    if (activityChart === null) {
      return '00.00 hrs';
    }
    return activityChart.totalHoursOut !== null
      ? convertTimeDuration(activityChart.totalHoursOut, true) + ' hrs'
      : '00.00 hrs';
  };

  const getTotalWorkUsage = () => {
    if (!activityChart) {
      return '00%';
    }
    if (
      activityChart?.totalPayHours === null ||
      activityChart?.totalBillableHours === null
    ) {
      return '00%';
    }
    const totalWorkingHours = formatStringTime(activityChart.totalPayHours);
    const billableHours = formatStringTime(activityChart.totalBillableHours);
    if (billableHours === 0) {
      return '00%';
    }
    return ((billableHours / totalWorkingHours) * 100).toFixed(2) + '%';
  };

  useEffect(() => {
    const program = localStorage.getItem(SELECTED_PROGRAM_KEY);
    if (typeof program === 'string') {
      setSelectedProgram(JSON.parse(program) as OptionType);
    }
  }, []);

  const handleToasterClose = () => {
    setToastr({
      toasterId: null,
      toasterAction: 'info',
      defaultToasterMessage: '',
    });
  };

  const changeTextDebouncer = useCallback(
    debounce((input: string) => {
      setPageNumber(0);
      setSearchQuery(input);
    }, 500),
    [],
  );

  const [headerCells, setHeaderCells] = useState<
    HeadCell<ReviewEmployeeHeader>[]
  >([]);

  useEffect(() => {
    setHeaderCells(getReviewActivitySheetHeaders());
  }, []);

  return (
    <Box component={'main'}>
      <Box component={'section'} sx={{ display: 'flex', marginTop: '24px' }}>
        {toastr.toasterId !== null && (
          <SnackBarComponent
            open={true}
            handleClose={handleToasterClose}
            successOrError={toastr.toasterAction}
            labelId={toastr.toasterId}
            defaultMessageId={toastr.defaultToasterMessage}
          />
        )}
        {showHolidays && (
          <HolidayListDialog
            open={showHolidays}
            holidays={holidays}
            onClose={() => setShowHolidays(false)}
          />
        )}
        <Box sx={{ display: 'flex' }}>
          <TitleText
            label={`${rctl.formatMessage({
              id: 'reviewActivitySheetText',
            })}:`}
            defaultlabel="reviewActivitySheetText"
          />
          <TitleText
            label={`${selectedProgram !== null ? selectedProgram.label : ''}`}
            defaultlabel="Program Name"
            Sxprops={{ color: '#008C82', marginLeft: '8px' }}
          />
        </Box>
      </Box>
      <Box component={'section'} sx={{ display: 'flex', marginTop: '16px' }}>
        <WeekDatePicker
          date={dayjs(selectedDate)}
          disableFutureDate={true}
          onDateSelect={(newDate: Dayjs) => {
            setSelectedDate(newDate.toDate());
          }}
        />

        <Box
          component={'section'}
          sx={{ display: 'flex', alignItems: 'center', marginLeft: '24px' }}
        >
          <MediumTypography
            sxProps={{ fontSize: '16px' }}
            labelid="employeeSubmitSheetText"
            defaultlabel="No. of employees submitted the Activity Sheet -"
          />
          <MediumTypography
            label={`${
              employeeResponse !== null ? employeeResponse.submittedCount : 0
            }`}
            defaultlabel="0 / 0"
            sxProps={{
              fontSize: '16px',
              color: '#008C82',
              marginLeft: '8px',
              fontWeight: 'bold',
            }}
          />
        </Box>

        <Box
          sx={{
            alignSelf: 'center',
            marginLeft: 'auto',
          }}
          onClick={() => {
            if (holidays.length === 0) {
              readHolidayList();
            } else {
              setShowHolidays(true);
            }
          }}
        >
          <MediumTypography
            sxProps={{ color: '#008C82', cursor: 'pointer' }}
            labelid={`${rctl.formatMessage({
              id: 'holiday(s)Text',
              defaultMessage: 'Holiday(s)',
            })} ${dayjs().format('YYYY')}`}
            defaultlabel="Holiday(s)"
          />
        </Box>
      </Box>

      <Box
        component={'section'}
        sx={{
          display: 'flex',
          marginTop: '16px',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <Card
          sx={{
            padding: '16px',
            display: 'flex',
            width: '65%',
          }}
        >
          <Grid container>
            <Grid item>
              <Box sx={{ display: 'flex' }}>
                <MediumTypography
                  labelid="totalHoursPayText"
                  sxProps={{ color: '#97A6A5', fontSize: '18px' }}
                />
                {activityChart !== null && (
                  <MediumTypography
                    label={
                      activityChart.totalPayHours !== null
                        ? convertTimeDuration(
                            activityChart.totalPayHours,
                            true,
                          ) + ' hrs'
                        : '00.00 hrs'
                    }
                    sxProps={{
                      fontSize: '18px',
                      color: '#2A4241',
                      fontWeight: 'bold',
                      paddingLeft: '8px',
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid container direction={'row'} paddingTop={'8px'}>
              <Grid item>
                <Grid item>
                  <DoughnutChart
                    redraw={false}
                    data={getChartValues()}
                    totalWorkUsage={getTotalWorkUsage()}
                    labelIds={workingLabelIds}
                  />
                </Grid>
              </Grid>

              <>
                <Grid item sx={{ marginLeft: '16px' }}>
                  <Grid item>
                    <DoughnutChart
                      redraw={false}
                      data={getSalariedChartValues()}
                      totalWorkUsage={getSalariedTotalWorkUsage()}
                      subTextId={'hoursOutText'}
                      defaultLable="Hours Out"
                      labelIds={nonWorkingLabelIds}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sx={{ marginLeft: '16px' }}
                  display={'flex'}
                  alignItems={'center'}
                >
                  {activityChart !== null && (
                    <Box>
                      <Grid item>
                        <Box display={'flex'} marginBottom={'8px'}>
                          <MediumTypography
                            label={`${rctl.formatMessage({
                              id: 'sickHrsText',
                              defaultMessage: 'Sick Hrs',
                            })} : `}
                            sxProps={{
                              color: '#97A6A5',
                              fontWeight: '500',
                              marginRight: '5px',
                            }}
                          />
                          <MediumTypography
                            label={
                              activityChart.totalSickHours !== null
                                ? convertTimeDuration(
                                    activityChart.totalSickHours,
                                    true,
                                  )
                                : '00.00'
                            }
                            defaultlabel="00:00"
                            sxProps={{
                              color: '#2A4241',
                              fontWeight: '500',
                              marginRight: '5px',
                            }}
                          />
                        </Box>
                        <Box display={'flex'}>
                          <MediumTypography
                            label={`${rctl.formatMessage({
                              id: 'flexHrsText',
                              defaultMessage: 'Flex Hrs',
                            })} : `}
                            sxProps={{
                              color: '#97A6A5',
                              fontWeight: '500',
                              marginRight: '5px',
                            }}
                          />
                          <MediumTypography
                            label={
                              activityChart.totalFlexHours !== null
                                ? convertTimeDuration(
                                    activityChart.totalFlexHours,
                                    true,
                                  )
                                : '00.00'
                            }
                            sxProps={{
                              color: '#2A4241',
                              fontWeight: '500',
                              marginRight: '5px',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </Grid>
              </>
            </Grid>
          </Grid>
        </Card>

        <Card className="formCardview" sx={{ width: '35%', marginLeft: '8px' }}>
          <Box sx={{ justifyContent: 'center' }}>
            <MediumTypography
              sxProps={{ fontSize: '16px', fontWeight: 'bold' }}
              labelid="programProductivityText"
              defaultlabel="Program's Productivity"
            />
            <Box
              component={'section'}
              sx={{ display: 'flex', marginTop: '24px' }}
            >
              <MediumTypography
                sxProps={{ fontSize: '16px' }}
                labelid="salariedEmployeesNameText"
                defaultlabel="No. of Salaried Employees:"
              />
              <MediumTypography
                // label={`${activityChart?.salariedEmployeeCount} / ${activityChart?.totalEmployeesCount}`}
                label={`${0} / ${0}`}
                defaultlabel="00 / 00"
                sxProps={{
                  fontSize: '16px',
                  color: '#008C82',
                  marginLeft: '8px',
                }}
              />
            </Box>

            <Box
              component={'section'}
              sx={{
                display: 'flex',
                marginTop: '16px',
              }}
            >
              <MediumTypography
                sxProps={{ fontSize: '16px' }}
                labelid="productivityReachedText"
                defaultlabel="Productivity Reached"
              />
              <MediumTypography
                label="0 / 0"
                defaultlabel="00 / 00"
                sxProps={{
                  fontSize: '16px',
                  color: '#008C82',
                  marginLeft: '8px',
                }}
              />
            </Box>
          </Box>
        </Card>
      </Box>

      <Box sx={{ display: 'flex', marginTop: '16px' }}>
        <SearchBox
          labelId="searchEmployeeText"
          defaultlabel="Search Employee"
          onChange={changeTextDebouncer}
        />
      </Box>

      <Box component={'section'} sx={{ marginTop: '16px' }}>
        <Card sx={{ width: '100%' }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750, maxWidth: '100%' }}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <TableHeader
                className="listDataTableHead"
                labelSxProp={{ whiteSpace: 'normal', padding: 'none' }}
                headerNames={headerCells}
                checkBoxRequired={false}
              />
              <TableBody className="tableRowcss">
                {employeeResponse?.employeesReview.map((item) => {
                  return (
                    <ActivitySheetEmployeeRow
                      key={item.id}
                      data={item}
                      handleViewClick={(id: string) => {
                        navigate(ACTIVITY_APPROVAL_SHEET_ROUTE_NAME, {
                          state: {
                            id: id,
                            date: selectedDate,
                            firstName: item.firstName,
                            lastName: item.lastName,
                          },
                        });
                      }}
                    />
                  );
                })}
                {employeeResponse !== null &&
                  employeeResponse.employeesReview.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Box
                          display={'flex'}
                          justifyContent={'center'}
                          padding={'16px'}
                        >
                          {searchQuery.length === 0 ? (
                            <MediumTypography
                              labelid="noRecordsFoundMessage"
                              defaultlabel="No records found"
                            />
                          ) : (
                            <MediumTypography
                              labelid="searchnotfound.smallText"
                              defaultlabel="We could not find any search results. Give it another go"
                            />
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </TableContainer>

          {employeeResponse !== null &&
            employeeResponse.employeesReview.length > 10 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <AppPagination
                  pageNumber={pageNumber}
                  paginationCount={employeeResponse.submittedCount}
                  handleChangePage={(_event, newPage) => {
                    if (newPage - 1 !== pageNumber) {
                      setPageNumber(newPage - 1);
                    }
                  }}
                />
              </Box>
            )}
        </Card>
      </Box>
    </Box>
  );
};

export default ActivitySheetOverview;
