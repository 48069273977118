import {
  AlertColor,
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  autocompleteClasses,
} from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import TitleText from '../../components/formlib/TitleText';
import MediumTypography from '../../components/formlib/MediumTypography';
import CustomCheckBox from '../../components/formlib/CustomCheckBox';
import { ReactComponent as Ifsp } from '../../assets/images/PublicHealthIfspLogo.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/infoIcon.svg';
import { ReactComponent as AddNew } from '../../assets/images/Plus.svg';
import { ReactComponent as Lock } from '../../assets/images/locked.svg';
import { ReactComponent as File } from '../../assets/images/file.svg';
import ProgramDetails from './ProgramDetails';
import SingleLine from '../../components/formlib/SingleLine';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';

import InformationModal from './InformationModal';
import ServiceDeliveryPlan from './ServiceDeliveryPlan';
import DateTimePickerComponent from '../../components/formlib/DateTimePickerComponent';
import HealthEligibilitySummary from './HealthEligibilitySummary';
import './IFSP.css';
import DevelopmentalSummary from './DevelopmentalSummary';
import FamiliyConcernsPrioritiesandResources from './FamilyConcernsPrioritiesandResources';
import ChildandFamilyIFSPOutcomes from './ChildandFamilyIFSPOutcomes';
import TransitionPreparation from './TransitionPreparation';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import DailyRoutine from './DailyRoutine';
import TransitionPlan from './TransitionPlan';
import ConsentChangeServiceDeliveryPlan from './ConsentChangeServiceDeliveryPlan';
import IfspContext, {
  IfspContextDataType,
  IfspTeamMembersType,
} from './ifspContextApi/IfspContex';
import AutocompleteSearch from '../../components/formlib/AutocompleteSearch';
import { OptionType, employeeRoles } from '../../utils/type';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import {
  EmployeeListType,
  createIfsp,
  downloadIfsp,
  getAllEmployeeMinimalList,
} from '../../services/ifspClient/IfspApi';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import ModalPopup from '../../components/formlib/ModalPopup';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import { FACESHEET } from '../../routes/Routing';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import { useLocation, useNavigate } from 'react-router';
import IfspTeamMembersTableRow from './IfspTeamMemberTableRow';
import { convertGenderToFrontend } from '../../utils/genderUtil';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { datePickerMinDateMaxDateValidate } from '../../utils/dateUtil';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';
import IfspUpdateModalPopup from './IfspUpdateModalPopup';
import IfspReviewedOnListModal from './IfspReviewedOnListModal';
import Textinput from '../../components/formlib/Textinput';
import { checkOffline } from '../../services/Offline';
import ShareDownloadModal from '../../components/formlib/modal/ShareDownloadModal';
import { v4 as uuidv4 } from 'uuid';
import TranslateWidget from '../../TranslateWidget';

const info =
  'The Individualized Family Service Plan (IFSP) is a working document that outlines the Early Intervention (EI) services to be provided. The initial IFSP (based on a timely and comprehensive multidisciplinary evaluation and assessment) is completed within 45 days of referral. The plan is developed collaboratively among IFSP Team Members - including parents, caregivers, EI staff, Specialty Services Provider (SSP) staff and/or others, as needed. Participants in the development of the IFSP may also include community representatives, extended family members, and others as requested by the parent if feasible to do so. The EI Service Coordinator is responsible for implementing the plan, preparing for ongoing IFSP meetings, and meeting state and federal timelines.';
const reportText = `When document is complete click on "This report is complete". This will lock in the responses and date and time stamp the form. To make changes to the document un-check the "Report is complete" box. Edit document then lock file. Keep a copy for your records. Click on the "Save" button at the top of the page.`;
interface TableData {
  name: string;
  role: string;
  discipline: string;
  email: string;
  phoneNumber: string;
  jobDescription: string;
  startDate: string;
  endDate: string;
  actions: string;
}
const headCells: HeadCell<TableData>[] = [
  {
    id: 'name',
    labelId: 'IFSP.Name',
    defaultLabelId: 'Name',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'discipline',
    labelId: 'IFSP.DisciplineText',
    defaultLabelId: 'Discipline',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'role',
    labelId: 'IFSP.RoleText',
    defaultLabelId: 'Role',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'email',
    labelId: 'IFSP.EmailText',
    defaultLabelId: 'Email',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'phoneNumber',
    labelId: 'IFSP.PhoneNumberText',
    defaultLabelId: 'Phone Number',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'jobDescription',
    labelId: 'IFSP.JobDescriptionText',
    defaultLabelId: 'Job Description',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'startDate',
    labelId: 'IFSP.StartDateText',
    defaultLabelId: 'Start Date',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'endDate',
    labelId: 'IFSP.EndDateText',
    defaultLabelId: 'End Date',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: 'IFSP.ActionsText',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
];

export const IfspRadioData = [
  {
    value: 'parent',
    labelId: 'IFSP.GuardianText',
    defaultLabel: 'Guardian',
  },
  {
    value: 'teamMember',
    labelId: 'IFSP.TeamMemberText',
    defaultLabel: 'Team Member',
  },
  {
    value: 'other',
    labelId: 'IFSP.OtherText',
    defaultLabel: 'Others',
  },
];

const validationSchema = yup.object().shape({
  contactType: yup.string(),
  yetToAddContactId: yup.string().when('contactType', {
    is: 'parent',
    then: yup.string().required('IFSP.yetToAddContactId.validation'),
    otherwise: yup.string().nullable(),
  }),
  yetToAddOtherContactId: yup.string().when('contactType', {
    is: 'teamMember',
    then: yup.string().required('IFSP.yetToAddOtherContactId.validation'),
    otherwise: yup.string().nullable(),
  }),
  yetToAddRoleId: yup.string().when('contactType', {
    is: 'teamMember',
    then: yup.string().required('IFSP.yetToAddRoleId.validation'),
    otherwise: yup.string().nullable(),
  }),
  yetToAdddisciplineId: yup.string().when('contactType', {
    is: 'teamMember',
    then: yup.string().required('IFSP.yetToAddDisciplineId.validation'),
    otherwise: yup.string().nullable(),
  }),
  teamMemberStartDate: yup.string().when('contactType', {
    is: 'teamMember',
    then: yup.string().required('IFSP.teamMemberStartDate.validation'),
    otherwise: yup.string().nullable(),
  }),
  teamMemberEndDate: yup.string().nullable(),
  othersName: yup.string().when('contactType', {
    is: 'other',
    then: yup.string().required('IFSP.othersName.validation'),
    otherwise: yup.string().nullable(),
  }),
  othersJobDescription: yup.string().when('contactType', {
    is: 'other',
    then: yup.string().required('IFSP.otherJobDescription.validation'),
    otherwise: yup.string().nullable(),
  }),
  othersStartDate: yup.string().when('contactType', {
    is: 'other',
    then: yup.string().required('IFSP.othersStartDate.validation'),
    otherwise: yup.string().nullable(),
  }),
  othersEndDate: yup.string().nullable(),
});
const validateDate = (values: {
  contactType: string;
  yetToAddContactId: string;
  yetToAddOtherContactId: string;
  yetToAddRoleId: string;
  teamMemberStartDate: string | Dayjs | null;
  teamMemberEndDate: string | Dayjs | null;
  othersStartDate: string | Dayjs | null;
  othersEndDate: string | Dayjs | null;
}) => {
  const errors: Partial<{
    contactType: string;
    yetToAddContactId: string;
    yetToAddOtherContactId: string;
    yetToAddRoleId: string;
    teamMemberStartDate: string | null;
    teamMemberEndDate: string | null;
    othersStartDate: string | null;
    othersEndDate: string | null;
  }> = {};

  if (values.teamMemberStartDate && values.teamMemberEndDate) {
    const startDate = dayjs(values.teamMemberStartDate, 'MM/DD/YYYY');
    const endDate = dayjs(values.teamMemberEndDate, 'MM/DD/YYYY');

    if (startDate.isAfter(endDate)) {
      errors.teamMemberEndDate = 'IFSP.endDateValidationError';
    }
  }
  if (datePickerMinDateMaxDateValidate(values.teamMemberStartDate)) {
    errors.teamMemberStartDate = 'datePickerMinDateMaxDateValidate';
  }

  if (datePickerMinDateMaxDateValidate(values.teamMemberEndDate)) {
    errors.teamMemberEndDate = 'datePickerMinDateMaxDateValidate';
  }

  if (values.othersStartDate && values.othersEndDate) {
    const startDate = dayjs(values.othersStartDate, 'MM/DD/YYYY');
    const endDate = dayjs(values.othersEndDate, 'MM/DD/YYYY');
    if (startDate.isAfter(endDate)) {
      errors.othersEndDate = 'IFSP.endDateValidationError';
    }
  }
  if (datePickerMinDateMaxDateValidate(values.othersStartDate)) {
    errors.othersStartDate = 'datePickerMinDateMaxDateValidate';
  }

  if (datePickerMinDateMaxDateValidate(values.othersEndDate)) {
    errors.othersEndDate = 'datePickerMinDateMaxDateValidate';
  }
  return errors;
};

const IFSPScreen = () => {
  const {
    ifsp,
    getIfsp,
    updateIfspContext,
    updateIfspTouched,
    ifspTouched,
    isTransitionDateReqired,
    leaReferralDateOutOfRange,
    transitionDateOutOfRange,
    isOptOutNotificationDateRequired,
    primarySettingCode,
    reasonData,
  } = useContext(IfspContext);
  const navigation = useNavigate();
  const { state } = useLocation();
  const [mainSessionCheckBox, setMainSessionCheckBox] =
    useState<boolean>(false);
  const [ifspDueDate, setIfspDueDate] = useState<string>('');
  const [isIfspDueDatePassed, setIsIfspDueDatePassed] =
    useState<boolean>(false);
  const [reasonForDelay, setReasonForDelay] = useState<OptionType>(
    ifsp.reasonForDelay,
  );
  const [openDelayModal, setOpenDelayModal] = useState<boolean>(false);
  const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);
  const [infoModal, setInfoModal] = useState(false);
  const [contactType, setContactType] = useState('parent');
  const [closeDailyRoutine, setCloseDailyRoutine] = useState<boolean>(false);
  const [closeHealthEligibility, setCloseHealthEligibility] = useState(false);
  const [closeDevelopmentSummary, setCloseDevelopmentSummary] = useState(false);
  const [closeConsentChangeSDP, setCloseConsentChangeSDP] = useState(false);
  const [closeFamilyConcern, setCloseFamilyConcern] = useState<boolean>(false);
  const [openTransitionModal, setOpenTransitionModal] =
    useState<boolean>(false);
  const [openSDP, setOpenSDP] = useState(false);

  const [openOutCome, setOpenOutCome] = useState<boolean>(false);
  const [openTransistionPrep, setOpenTransistionPrep] =
    useState<boolean>(false);
  const [infoDailyRoutineModal, setInfoDailyRoutineModal] = useState(false);
  const [infoTransitionPlanModal, setInfoTransitionPlanModal] =
    useState<boolean>(false);
  const [infoConsentChangeSDPModal, setInfoConsentChangeSDPModal] =
    useState<boolean>(false);
  const [infoSDPModal, setInfoSDPModal] = useState<boolean>(false);
  const [infoHESModal, setInfoHESModal] = useState<boolean>(false);
  const [infoOutComeModal, setInfoOutComeModal] = useState<boolean>(false);
  const [infoReviewModal, setInfoReviewModal] = useState<boolean>(false);
  const [infoTransistionPrepModal, setInfoTransistionPrepModal] =
    useState<boolean>(false);
  const [infoDevelopmentalSummaryModal, setInfoDevelopmentalSummaryModal] =
    useState<boolean>(false);
  const [infoFamilyConcernsModal, setInfoFamilyConcernsModal] =
    useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [openIfspReviewedOnListModal, setOpenIfspReviewedOnListModal] =
    useState<boolean>(false);
  const [
    openIfspReviewedOnListClickedIndex,
    setOpenIfspReviewedOnListClickedIndex,
  ] = useState<number>(0);
  const [shareModal, setShareModal] = useState<boolean>(false);

  const [employeeList, setEmployeeList] = useState<EmployeeListType[]>([
    {
      id: '',
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      disciplineType: '',
      phone: '',
      roles: [],
    },
  ]);
  const [parentContactes, setParentContactes] = useState<OptionType[]>([]);
  const [teamMembers, setTeamMembers] = useState<OptionType[]>([]);
  const [teamMembersRole, setTeamMembersRole] = useState<OptionType[]>([]);
  const [reportCompletedByRole, setReportCompletedByRole] = useState<
    OptionType[]
  >([]);
  const [teamMembersDiscipline, setTeamMembersDiscipline] = useState<
    OptionType[]
  >([]);
  const [teamMembersStartDate, setTeamMembersStartDate] = useState<
    string | null | Dayjs
  >(null);
  const [teamMembersEndDate, setTeamMembersEndDate] = useState<
    string | Dayjs | null
  >(null);

  const [ifspTeamTable, setIfspTeamTable] = useState<IfspTeamMembersType[]>([]);

  const [yetToAddContactId, setYetToAddContactId] = useState<string>('');
  const [yetToAddOtherContactId, setYetToAddOtherContactId] =
    useState<string>('');
  const [yetToAddRoleId, setYetToAddRoleId] = useState<string>('');
  const [yetToAdddisciplineId, setYetToAddDisciplineId] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [othersName, setOthersName] = useState<string>('');
  const [othersJobDescription, setOthersJobDescription] = useState<string>('');
  const [othersStartDate, setOthersStartDate] = useState<string | Dayjs | null>(
    null,
  );
  const [othersEndDate, setOthersEndDate] = useState<string | Dayjs | null>(
    null,
  );
  const [editRecordIndex, setEditRecordIndex] = useState<number | null>(null);
  const [editOtherRecordIndex, setEditOtherRecordIndex] = useState<
    number | null
  >(null);
  const [deleteIndex, setDeleteIndex] = useState<number>(0);
  const [deleteWarning, setDeleteWarning] = useState<boolean>(false);

  const [reportedCompletedBy, setReportedCompletedBy] =
    useState<OptionType | null>(ifsp.completedBy);

  const [open, setOpen] = useState<boolean>(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('');

  const [ifspScreenFieldLock, setIfspScreenFieldLock] =
    useState<boolean>(false);

  const [isreportCompletedByMandatory, setIsreportCompletedByMandatory] =
    useState<boolean>(false);
  const [isreportCompletedRoleMandatory, setIsreportCompletedRoleMandatory] =
    useState<boolean>(false);
  const [repoteCompletedByRoleId, setRepoteCompletedByRoleId] =
    useState<string>('');
  const [isCompletedIfspIsMandatory, setIsCompletedIfspIsMandatory] =
    useState<boolean>(false);
  const [isCompletedIfspDateIsMandatory, setIsCompletedIfspDateIsMandatory] =
    useState<boolean>(false);

  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [offlineMode, setOffline] = React.useState(false);

  React.useLayoutEffect(() => {
    const getOfflineMode = async () => {
      const offlineFlag = await checkOffline();
      setOffline(offlineFlag);
    };
    getOfflineMode();
  });

  const sessions = [
    {
      id: 0,
      dataLabelId: 'IFSP.session1',
      defaultDataLabelId: 'Daily Routines, Strengths and Needs',
      checked: ifsp.ifspTeam.forms.dailyRoutines[0].isChecked,
    },
    {
      id: 1,
      dataLabelId: 'IFSP.session2',
      defaultDataLabelId: 'Family Concerns, Priorities and Resources',
      checked: ifsp.ifspTeam.forms.familyConcerns[0].isChecked,
    },
    {
      id: 2,
      dataLabelId: 'IFSP.session3',
      defaultDataLabelId: 'Transition Preparation ',
      checked: ifsp.ifspTeam.forms.transitionPreparation.isChecked,
    },
    {
      id: 3,
      dataLabelId: 'IFSP.session4',
      defaultDataLabelId: 'Health & Eligibility Summary',
      checked: ifsp.ifspTeam.forms.healthSummary[0].isChecked,
    },
    {
      id: 4,
      dataLabelId: 'IFSP.session5',
      defaultDataLabelId: 'Developmental Summary',
      checked: ifsp.ifspTeam.forms.developmentalSummary[0].isChecked,
    },
    {
      id: 5,
      dataLabelId: 'IFSP.session6',
      defaultDataLabelId: 'Transition Plan',
      checked: ifsp.ifspTeam.forms.transitionPlan.isChecked,
    },
    {
      id: 6,
      dataLabelId: 'IFSP.session7',
      defaultDataLabelId: 'Child and Family IFSP Outcomes',
      checked:
        ifsp.ifspTeam.forms.childIfspOutcomes.length > 0
          ? ifsp.ifspTeam.forms.childIfspOutcomes[0].isChecked
          : false,
    },
    {
      id: 7,
      dataLabelId: 'IFSP.session8',
      defaultDataLabelId: 'Service Delivery Plan',
      checked:
        ifsp.ifspTeam.forms.serviceDeliveryPlan.length > 0
          ? ifsp.ifspTeam.forms.serviceDeliveryPlan[0].isChecked
          : false,
    },
    {
      id: 8,
      dataLabelId: 'IFSP.session9',
      defaultDataLabelId: 'Consent',
      checked: ifsp.ifspTeam.forms.consentForChangeForSDP[0].isChecked,
    },
  ];
  const initialSessionCheckBoxes = [
    {
      id: '0',
      value: ifsp.ifspTeam.forms.dailyRoutines[0].isChecked,
    },
    {
      id: '1',
      value: ifsp.ifspTeam.forms.familyConcerns[0].isChecked,
    },
    {
      id: '2',
      value: ifsp.ifspTeam.forms.transitionPreparation.isChecked,
    },
    {
      id: '3',
      value: ifsp.ifspTeam.forms.healthSummary[0].isChecked,
    },
    {
      id: '4',
      value: ifsp.ifspTeam.forms.developmentalSummary[0].isChecked,
    },
    {
      id: '5',
      value: ifsp.ifspTeam.forms.transitionPlan.isChecked,
    },
    {
      id: '6',
      value:
        ifsp.ifspTeam.forms.childIfspOutcomes.length > 0
          ? ifsp.ifspTeam.forms.childIfspOutcomes[0].isChecked
          : false,
    },
    {
      id: '7',
      value:
        ifsp.ifspTeam.forms.serviceDeliveryPlan.length > 0
          ? ifsp.ifspTeam.forms.serviceDeliveryPlan[0].isChecked
          : false,
    },
    {
      id: '8',
      value: ifsp.ifspTeam.forms.consentForChangeForSDP[0].isChecked,
    },
  ];

  const [sessionCheckBoxes, setSessionCheckBoxes] = useState(
    initialSessionCheckBoxes,
  );

  const latestConsentForChange =
    ifsp.ifspTeam.forms.consentForChangeForSDP[
      ifsp.ifspTeam.forms.consentForChangeForSDP.length - 1
    ];

  const hasConsentSignature = latestConsentForChange.consentSignature.some(
    (x) => x.signature || x.rowKey || x.verbalConsent,
  );
  const hasParentSignature = latestConsentForChange.parentSignature.some(
    (x) => x.signature || x.rowKey || x.verbalConsent,
  );
  const hasChildOutcomes = ifsp.ifspTeam.forms.childIfspOutcomes.some(
    (x) => x.outcomesProgressReview.startDate,
  );
  const hasServiceDeliveryPlan = ifsp.ifspTeam.forms.serviceDeliveryPlan.some(
    (x) => x.earlyInterventionService.length > 0,
  );

  const isLatestConsentSigned = hasConsentSignature && hasParentSignature;

  const [isScreenLocked, setIsScreenLocked] = React.useState<boolean>(false);
  const [isFromClientJourny, setIsFromClientJourny] =
    React.useState<boolean>(false);
  const [referralDate, setReferralDate] = React.useState<Dayjs | null>(null);
  const [selectedLang, setSelectedLang] = useState<string>(
    ifsp.selectedLanguage,
  );
  const { lookups } = useContext(LookUpContext) as LookUpContextType;
  const checkAndSetIfspScreenFieldLock = (ifspData: IfspContextDataType) => {
    const { consentForChangeForSDP } = ifspData.ifspTeam.forms;
    const latestConsent =
      consentForChangeForSDP[consentForChangeForSDP.length - 1];

    const isEmptySignature = (
      signatures: {
        id: string;
        isUpdated: boolean;
        signature: string;
        date: string | null | Dayjs;
        partitionKey: string;
        rowKey: string;
        verbalConsent: boolean;
      }[],
    ) =>
      signatures.every(
        (sig) => !sig.rowKey && !sig.signature && !sig.verbalConsent,
      );

    const hasEmptyParentSignature = isEmptySignature(
      latestConsent.parentSignature,
    );
    const hasEmptyConsentSignature = isEmptySignature(
      latestConsent.consentSignature,
    );

    if (hasEmptyParentSignature && hasEmptyConsentSignature) {
      setIfspScreenFieldLock(false);
    } else if (
      !hasEmptyParentSignature &&
      !hasEmptyConsentSignature &&
      ifsp.ifspTeam.forms.serviceDeliveryPlan.some(
        (x) => x.earlyInterventionService.length > 0,
      ) &&
      ifsp.ifspTeam.forms.childIfspOutcomes.some(
        (x) => x.outcomesProgressReview.startDate,
      )
    ) {
      setIfspScreenFieldLock(true);
    }
  };

  useEffect(() => {
    const clientInfo = localStorage.getItem('Demographics');
    if (clientInfo !== null) {
      const date = JSON.parse(clientInfo).referralDate;
      setReferralDate(dayjs(date));
    }
    checkAndSetIfspScreenFieldLock(ifsp);
  }, [ifsp.selectedLanguage]);
  useEffect(() => {
    setSelectedLang(ifsp.selectedLanguage);
  }, [ifsp.selectedLanguage]);
  useEffect(() => {
    if (state?.openTransitionPlanning) {
      setIsFromClientJourny(state.openTransitionPlanning);
      setOpenTransitionModal(true);
      autoScrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);
  const autoScrollRef = React.useRef<HTMLDivElement>(null);

  const formikRef = useRef<
    FormikProps<{
      contactType: string;
      yetToAddContactId: string;
      yetToAddOtherContactId: string;
      yetToAddRoleId: string;
      yetToAdddisciplineId: string;
      teamMemberStartDate: string | null | Dayjs;
      teamMemberEndDate: string | null | Dayjs;
      othersName: string;
      othersJobDescription: string;
      othersStartDate: string | null | Dayjs;
      othersEndDate: string | null | Dayjs;
    }>
  >(null);

  const handleOpenInfoModal = () => {
    setInfoModal(true);
  };

  const handleCloseInfoModal = () => {
    setInfoModal(false);
  };

  const handleMainSessionCheckBoxClick = (e: boolean) => {
    setMainSessionCheckBox(e);
    const updateSessionCheckBoxes = [...sessionCheckBoxes];
    ifsp.ifspTeam.forms.dailyRoutines[0].isChecked = e;
    ifsp.ifspTeam.forms.familyConcerns[0].isChecked = e;
    ifsp.ifspTeam.forms.healthSummary[0].isChecked = e;
    ifsp.ifspTeam.forms.developmentalSummary[0].isChecked = e;
    if (ifsp.ifspTeam.forms.childIfspOutcomes.length > 0)
      ifsp.ifspTeam.forms.childIfspOutcomes[0].isChecked = e;
    if (ifsp.ifspTeam.forms.serviceDeliveryPlan.length > 0)
      ifsp.ifspTeam.forms.serviceDeliveryPlan[0].isChecked = e;
    ifsp.ifspTeam.forms.consentForChangeForSDP[0].isChecked = e;
    updateIfspContext(ifsp);
    setSessionCheckBoxes(updateSessionCheckBoxes);
  };

  const handleSessionCheckBoxClick = (sessionId: number, e: boolean) => {
    const updateSessionCheckBoxes = [...sessionCheckBoxes];
    updateSessionCheckBoxes[sessionId].value = e;
    if (sessionId === 7) {
      if (e) {
        updateSessionCheckBoxes[8].value = true;
      } else {
        updateSessionCheckBoxes[8].value = false;
      }
    }
    if (sessionId === 0) {
      ifsp.ifspTeam.forms.dailyRoutines[0].isChecked = e;
    } else if (sessionId === 1) {
      ifsp.ifspTeam.forms.familyConcerns[0].isChecked = e;
    } else if (sessionId === 2) {
      ifsp.ifspTeam.forms.transitionPreparation.isChecked = e;
    } else if (sessionId === 3) {
      ifsp.ifspTeam.forms.healthSummary[0].isChecked = e;
    } else if (sessionId === 4) {
      ifsp.ifspTeam.forms.developmentalSummary[0].isChecked = e;
    } else if (sessionId === 5) {
      ifsp.ifspTeam.forms.transitionPlan.isChecked = e;
    } else if (sessionId === 6) {
      if (ifsp.ifspTeam.forms.childIfspOutcomes.length > 0)
        ifsp.ifspTeam.forms.childIfspOutcomes[0].isChecked = e;
    } else if (sessionId === 7) {
      if (ifsp.ifspTeam.forms.serviceDeliveryPlan.length > 0)
        ifsp.ifspTeam.forms.serviceDeliveryPlan[0].isChecked = e;
      ifsp.ifspTeam.forms.consentForChangeForSDP[0].isChecked = e;
    } else if (sessionId === 8) {
      ifsp.ifspTeam.forms.consentForChangeForSDP[0].isChecked = e;
    }
    updateIfspContext(ifsp);
    setSessionCheckBoxes(updateSessionCheckBoxes);
  };

  const handleAddNewContact = () => {
    const newContact = ifsp.ifspTeam.contact.find(
      (contact) => contact.id === yetToAddContactId,
    );

    if (newContact) {
      updateIfspContext({
        ...ifsp,
        ifspTeam: {
          ...ifsp.ifspTeam,
          parents: [
            ...ifsp.ifspTeam.parents,
            {
              id: newContact.id.toString(),
              email: newContact.email,
              name: newContact.name,
              phone: newContact.phone,
              role: newContact.relationshipToClient,
            },
          ],
        },
      });
    }
    formikRef.current?.resetForm();
    formikRef.current?.setFieldValue('contactType', 'parent');
    setTeamMembersRole([]);
    setTeamMembersDiscipline([]);
    setYetToAddContactId('');
    setYetToAddOtherContactId('');
    setYetToAddRoleId('');
    setYetToAddDisciplineId('');
  };

  const handleAddNewTeamMemberContact = () => {
    const newOtherContact = employeeList.find(
      (employee) => employee.id === yetToAddOtherContactId,
    );

    // Check if there's any record in the table with the same email, role, and overlapping startDate/endDate
    const isDuplicated = ifspTeamTable.some((otherContact) => {
      const startDateprev = dayjs(otherContact.startDate, 'MM/DD/YYYY');
      const endDateprev = dayjs(otherContact.endDate, 'MM/DD/YYYY');
      const startDateNew = dayjs(
        formikRef.current?.values.teamMemberStartDate,
        'MM/DD/YYYY',
      );
      const endDateNew = dayjs(
        formikRef.current?.values.teamMemberEndDate,
        'MM/DD/YYYY',
      );

      const isOverlapping =
        (startDateprev.isBefore(endDateNew) &&
          endDateprev.isAfter(startDateNew)) ||
        startDateprev.isSame(startDateNew) ||
        endDateprev.isSame(endDateNew);

      return (
        otherContact.email === newOtherContact?.email &&
        otherContact.role === role &&
        isOverlapping
      );
    });

    if (editRecordIndex !== null && !isDuplicated) {
      const updatedRecord = {
        id: newOtherContact?.id ?? ifspTeamTable[editRecordIndex].id,
        email: newOtherContact?.email ?? ifspTeamTable[editRecordIndex].email,
        name: newOtherContact?.name ?? ifspTeamTable[editRecordIndex].name,
        phone: newOtherContact?.phone ?? ifspTeamTable[editRecordIndex].phone,
        role: role ?? ifspTeamTable[editRecordIndex].role,
        discipline:
          newOtherContact?.disciplineType ??
          ifspTeamTable[editRecordIndex].discipline,
        startDate:
          formikRef.current?.values.teamMemberStartDate ??
          dayjs(ifspTeamTable[editRecordIndex].startDate).format('MM/DD/YYYY'),
        endDate:
          formikRef.current?.values.teamMemberEndDate ??
          dayjs(ifspTeamTable[editRecordIndex].endDate).format('MM/DD/YYYY'),
      };

      setIfspTeamTable((prev) => {
        const updatedTable = [...prev];
        updatedTable[editRecordIndex] = updatedRecord;
        updateIfspContext({
          ...ifsp,
          ifspTeam: {
            ...ifsp.ifspTeam,
            parents: updatedTable.filter(
              (item) => typeof item.id !== 'number' && !item.jobDescription,
            ),
            teamMember: updatedTable.filter(
              (item) => typeof item.id !== 'number' && !item.jobDescription,
            ),
          },
        });

        return updatedTable;
      });
    } else if (newOtherContact && !isDuplicated) {
      updateIfspContext({
        ...ifsp,
        ifspTeam: {
          ...ifsp.ifspTeam,
          teamMember: [
            ...ifsp.ifspTeam.teamMember,
            {
              id: newOtherContact.id || '',
              email: newOtherContact.email ?? '',
              name: newOtherContact.name ?? '',
              phone: newOtherContact.phone ?? '',
              role: role || '',
              discipline: newOtherContact.disciplineType || '',
              startDate: formikRef.current?.values.teamMemberStartDate ?? '',
              endDate: formikRef.current?.values.teamMemberEndDate ?? '',
            },
          ],
        },
      });
    } else if (isDuplicated) {
      setShowAlertDialog(true);
    }
    setEditRecordIndex(null);
    setTeamMembersRole([]);
    setTeamMembersDiscipline([]);
    setTeamMembersStartDate('');
    setTeamMembersEndDate('');
    setYetToAddContactId('');
    setYetToAddOtherContactId('');
    setYetToAddRoleId('');
    setYetToAddDisciplineId('');
    formikRef.current?.resetForm();
    formikRef.current?.setValues({
      teamMemberEndDate: '',
      teamMemberStartDate: '',
      contactType: 'teamMember',
      yetToAddContactId: '',
      yetToAddOtherContactId: '',
      yetToAddRoleId: '',
      yetToAdddisciplineId: '',
      othersEndDate: '',
      othersStartDate: '',
      othersJobDescription: '',
      othersName: '',
    });
    formikRef.current?.setFieldValue('contactType', 'teamMember');
  };

  const handelAddOtherContact = () => {
    if (editOtherRecordIndex !== null) {
      const updatedRecord = {
        name:
          formikRef.current?.values.othersName ??
          ifspTeamTable[editOtherRecordIndex].name,
        jobDescription:
          formikRef.current?.values.othersJobDescription ??
          ifspTeamTable[editOtherRecordIndex].jobDescription ??
          '',
        startDate:
          formikRef.current?.values.othersStartDate ??
          ifspTeamTable[editOtherRecordIndex].startDate ??
          '',
        endDate:
          formikRef.current?.values.othersEndDate ??
          ifspTeamTable[editOtherRecordIndex].endDate ??
          '',
        id: `${editOtherRecordIndex}`,
        email: '', // Assuming these fields should be empty
        phone: '', // Assuming these fields should be empty
        role: '', // Assuming these fields should be empty
        discipline: '', // Assuming these fields should be empty
      };

      setIfspTeamTable((prev) => {
        const updatedTable = [...prev];
        updatedTable[editOtherRecordIndex] = updatedRecord;

        updateIfspContext({
          ...ifsp,
          ifspTeam: {
            ...ifsp.ifspTeam,
            others: updatedTable
              .filter((item) => item.jobDescription) // Include only items with a jobDescription
              .map((item) => ({
                name: item.name,
                jobDescription: item.jobDescription ?? '',
                startDate: item.startDate,
                endDate: item.endDate,
              })),
          },
        });

        return updatedTable;
      });
    } else {
      const newRecord = {
        id: `${ifspTeamTable.length}`,
        name: formikRef.current?.values.othersName ?? '',
        jobDescription: formikRef.current?.values.othersJobDescription ?? '',
        startDate: formikRef.current?.values.othersStartDate ?? '',
        endDate: formikRef.current?.values.othersEndDate ?? '',
        email: '',
        phone: '',
        role: '', // Adjust as needed
        discipline: '',
      };
      setIfspTeamTable((prev) => {
        return [...prev, newRecord];
      });
      updateIfspContext({
        ...ifsp,
        ifspTeam: {
          ...ifsp.ifspTeam,
          others: [...ifsp.ifspTeam.others, newRecord],
        },
      });
    }

    // Reset the form and state
    formikRef.current?.setValues({
      contactType: 'other',
      othersEndDate: '',
      othersJobDescription: '',
      othersName: '',
      othersStartDate: '',
      teamMemberEndDate: '',
      teamMemberStartDate: '',
      yetToAddContactId: '',
      yetToAdddisciplineId: '',
      yetToAddOtherContactId: '',
      yetToAddRoleId: '',
    });
    formikRef.current?.setTouched({});
    setEditOtherRecordIndex(null);
  };
  const handleDeleteOtherContact = (index: number) => {
    setDeleteIndex(index);
    setDeleteWarning(true);
  };
  useEffect(() => {
    if (editRecordIndex !== null) {
      handleEditTeamMemberContact(editRecordIndex);
    }
  }, [editRecordIndex]);
  const handleEditTeamMemberContact = (editIndex: number) => {
    setEditRecordIndex(editIndex);
    setContactType('teamMember');

    const data = ifspTeamTable[editIndex];
    const recordToEdit = employeeList.filter(
      (employee) => employee.id === data?.id,
    );
    const roleAvailable = recordToEdit[0].roles?.includes(data?.role);
    if (data) {
      setYetToAddOtherContactId(data.id);
      setTeamMembersRole(() => {
        const filteredContactsRoles: OptionType[] = employeeList
          .filter((contact) => contact.id === data?.id)
          .flatMap((contact) =>
            contact.roles
              ? contact.roles.flatMap((roleString) =>
                  roleString.split(',').map((roleId) => ({
                    id: roleId.trim(),
                    label:
                      employeeRoles.find(
                        (empRole) => empRole.id === roleId.trim(),
                      )?.label || '',
                  })),
                )
              : [],
          );
        return filteredContactsRoles;
      });
      if (lookups === null) {
        return;
      }
      setTeamMembersDiscipline(() => {
        const filteredContactsDisciplines = employeeList
          .filter((contact) => contact.id === data?.id)
          .map((contact) => ({
            id: contact.id,
            label:
              lookups?.discipline?.find(
                (discipline) => discipline.code === contact.disciplineType,
              )?.description || '',
          }));
        return filteredContactsDisciplines;
      });
      if (roleAvailable) {
        setRole(data.role);
        formikRef.current?.setFieldValue('yetToAddRoleId', data.role);
      } else {
        setRole('');
        formikRef.current?.setFieldValue('yetToAddRoleId', '');
      }

      formikRef.current?.setFieldValue('contactType', 'teamMember');
      formikRef.current?.setFieldValue('yetToAddOtherContactId', data?.id);
      formikRef.current?.setFieldValue('teamMemberStartDate', data?.startDate);
      formikRef.current?.setFieldValue('teamMemberEndDate', data?.endDate);

      formikRef.current?.setFieldValue('yetToAdddisciplineId', data.id);
      formikRef.current?.setFieldValue('yetToAddContactId', '');
      setTeamMembersStartDate(data?.startDate || '');
      setTeamMembersEndDate(data?.endDate || '');
    }
  };

  const handleEditOtherContact = (editIndex: number) => {
    setEditOtherRecordIndex(editIndex);
    setContactType('other');
    const data = ifspTeamTable[editIndex];
    formikRef.current?.setFieldValue('contactType', 'other');
    formikRef.current?.setFieldValue('othersName', data?.name);
    formikRef.current?.setFieldValue(
      'othersJobDescription',
      data?.jobDescription,
    );
    formikRef.current?.setFieldValue('othersStartDate', data?.startDate);
    formikRef.current?.setFieldValue('othersEndDate', data?.endDate);

    setOthersName(data?.name || '');
    setOthersJobDescription(data?.jobDescription || '');
    setOthersStartDate(data?.startDate || '');
    setOthersEndDate(data?.endDate || '');
  };
  const handleCreateIFSP = () => {
    let updatedIfsp: IfspContextDataType;
    if (isLatestConsentSigned) {
      const ifspLatestConsent =
        ifsp.ifspTeam?.forms?.consentForChangeForSDP[
          ifsp.ifspTeam.forms.consentForChangeForSDP.length - 1
        ] || [];
      const dailyRoutines = ifsp.ifspTeam?.forms?.dailyRoutines || [];
      const dailyRoutineLastIndex = dailyRoutines.length - 1;
      const healthSummary = ifsp.ifspTeam?.forms?.healthSummary || [];
      const healthSummaryLastIndex = healthSummary.length - 1;
      const familyConcerns = ifsp.ifspTeam?.forms?.familyConcerns || [];
      const familyConcernsLastIndex = familyConcerns.length - 1;
      const developmentalSummary =
        ifsp.ifspTeam?.forms?.developmentalSummary || [];
      const developmentalSummaryLastIndex = developmentalSummary.length - 1;
      const consentForChangeForSDP =
        ifsp.ifspTeam?.forms?.consentForChangeForSDP || [];
      const consentForChangeForSDPLastIndex = consentForChangeForSDP.length - 1;

      // Create a new array with the updated item at the last index
      const updatedDailyRoutines = dailyRoutines.map((routine, index) =>
        index === dailyRoutineLastIndex &&
        !routine.sessionReviewedDate &&
        routine.isChecked
          ? {
              ...routine,
              sessionReviewedDate: `${dayjs(
                ifspLatestConsent.parentSignature[0].date
                  ? ifspLatestConsent.parentSignature[0].date
                  : dayjs().format('MM/DD/YYYY'),
              ).format('MM/DD/YYYY')} ${dayjs().format('hh:mm A')}`,
            }
          : routine,
      );
      const updatedHealthSummary = healthSummary.map((summary, index) =>
        index === healthSummaryLastIndex &&
        !summary.sessionReviewedDate &&
        summary.isChecked
          ? {
              ...summary,
              sessionReviewedDate: `${dayjs(
                ifspLatestConsent.parentSignature[0].date
                  ? ifspLatestConsent.parentSignature[0].date
                  : dayjs().format('MM/DD/YYYY'),
              ).format('MM/DD/YYYY')} ${dayjs().format('hh:mm A')}`,
            }
          : summary,
      );
      const updatedFamilyConcerns = familyConcerns.map((concern, index) =>
        index === familyConcernsLastIndex &&
        !concern.sessionReviewedDate &&
        concern.isChecked
          ? {
              ...concern,
              sessionReviewedDate: `${dayjs(
                ifspLatestConsent.parentSignature[0].date
                  ? ifspLatestConsent.parentSignature[0].date
                  : dayjs().format('MM/DD/YYYY'),
              ).format('MM/DD/YYYY')} ${dayjs().format('hh:mm A')}`,
            }
          : concern,
      );

      const updatedDevelopmentalSummary = developmentalSummary.map(
        (summary, index) =>
          index === developmentalSummaryLastIndex &&
          !summary.sessionReviewedDate &&
          summary.isChecked
            ? {
                ...summary,
                sessionReviewedDate: `${dayjs(
                  ifspLatestConsent.parentSignature[0].date
                    ? ifspLatestConsent.parentSignature[0].date
                    : dayjs().format('MM/DD/YYYY'),
                ).format('MM/DD/YYYY')} ${dayjs().format('hh:mm A')}`,
              }
            : summary,
      );

      const updatedConsentForChangeForSDP = consentForChangeForSDP.map(
        (concern, index) =>
          index === consentForChangeForSDPLastIndex &&
          !concern.sessionReviewedDate &&
          concern.isChecked
            ? {
                ...concern,
                sessionReviewedDate: `${dayjs(
                  ifspLatestConsent.parentSignature[0].date
                    ? ifspLatestConsent.parentSignature[0].date
                    : dayjs().format('MM/DD/YYYY'),
                ).format('MM/DD/YYYY')} ${dayjs().format('hh:mm A')}`,
              }
            : concern,
      );
      const updatedServiceDeliveryPlay =
        ifsp.ifspTeam.forms.serviceDeliveryPlan.map((sdp) => ({
          ...sdp,
          earlyInterventionService: sdp.earlyInterventionService.map((eis) => ({
            ...eis,
            servicePlanId: eis.servicePlanId || uuidv4(),
            isRecordSubmitted: true,
          })),
        }));
      const updatedChildAndFamilyOutcomes =
        ifsp.ifspTeam.forms.childIfspOutcomes.map((outcome) => ({
          ...outcome,
          outcomesProgressReview: (outcome.outcomesProgressReview = {
            ...outcome.outcomesProgressReview,
            outcomeId:
              outcome.outcomesProgressReview.outcomeId &&
              outcome.outcomesProgressReview.startDate
                ? outcome.outcomesProgressReview.outcomeId
                : outcome.outcomesProgressReview.outcomeId === '' &&
                  outcome.outcomesProgressReview.startDate
                ? uuidv4()
                : '',
            isRecordSubmitted: true,
          }),
        }));
      const updatedReviewedList = [
        ...ifsp.reviewedList,
        {
          reviewedByDiscipline: ifsp.completedByRole,
          reviewedByName: ifsp.completedBy.label,
          reviewedDate: `${dayjs(
            ifsp.completionDate,
            'YYYY-MM-DD hh:mm:ss a',
          ).format('MM/DD/YYYY')} ${dayjs(
            ifsp.completionDate,
            'YYYY-MM-DD hh:mm:ss a',
          ).format('hh:mm A')}`,
        },
      ];

      updatedIfsp = {
        ...ifsp,
        ifspTeam: {
          ...ifsp.ifspTeam,
          forms: {
            ...ifsp.ifspTeam.forms,
            dailyRoutines: updatedDailyRoutines,
            healthSummary: updatedHealthSummary,
            familyConcerns: updatedFamilyConcerns,
            serviceDeliveryPlan: updatedServiceDeliveryPlay,
            childIfspOutcomes: updatedChildAndFamilyOutcomes,
            developmentalSummary: updatedDevelopmentalSummary,
            consentForChangeForSDP: updatedConsentForChangeForSDP,
          },
        },
        reviewedList: updatedReviewedList,
        isLocked: true,
      };
      updateIfspContext({
        ...ifsp,
        selectedLanguage: selectedLang,
        ifspTeam: {
          ...ifsp.ifspTeam,
          forms: {
            ...ifsp.ifspTeam.forms,
            dailyRoutines: updatedDailyRoutines,
            healthSummary: updatedHealthSummary,
            familyConcerns: updatedFamilyConcerns,
            developmentalSummary: updatedDevelopmentalSummary,
            consentForChangeForSDP: updatedConsentForChangeForSDP,
            serviceDeliveryPlan: updatedServiceDeliveryPlay,
            childIfspOutcomes: updatedChildAndFamilyOutcomes,
          },
        },
        reviewedList: updatedReviewedList,
      });
    } else {
      updatedIfsp = {
        ...ifsp,
        selectedLanguage: selectedLang,
        ifspTeam: {
          ...ifsp.ifspTeam,
          forms: {
            ...ifsp.ifspTeam.forms,
            childIfspOutcomes: ifsp.ifspTeam.forms.childIfspOutcomes.map(
              (outcome) => ({
                ...outcome,
                outcomesProgressReview: {
                  ...outcome.outcomesProgressReview,
                  outcomeId:
                    outcome.outcomesProgressReview.outcomeId &&
                    outcome.outcomesProgressReview.startDate
                      ? outcome.outcomesProgressReview.outcomeId
                      : outcome.outcomesProgressReview.outcomeId === '' &&
                        outcome.outcomesProgressReview.startDate
                      ? uuidv4()
                      : '',
                },
              }),
            ),
            serviceDeliveryPlan: ifsp.ifspTeam.forms.serviceDeliveryPlan.map(
              (sdp) => ({
                ...sdp,
                earlyInterventionService: sdp.earlyInterventionService.map(
                  (eis) => ({
                    ...eis,
                    servicePlanId: eis.servicePlanId || uuidv4(),
                  }),
                ),
              }),
            ),
          },
        },
      };
      updateIfspContext({ ...updatedIfsp });
    }
    updateIfspTouched(false);
    checkAndSetIfspScreenFieldLock(ifsp);
    const clientId = localStorage.getItem('ClientId');
    if (clientId) {
      toggleLoader(true);
      createIfsp(updatedIfsp, clientId)
        .then(() => {
          updateIfspTouched(false);
          setOpen(true);
          formikRef.current?.setTouched({}, false);
          setToastrVariable('success');
          setToastrId('IFSP.savedSuccessfully');
          setToastrDefaultMessage('Data Saved Successfully');
          if (getIfsp) getIfsp(clientId);
          setOpenTransitionModal(false);
          setCloseDailyRoutine(true);
          setCloseHealthEligibility(true);
          setCloseFamilyConcern(true);
          setCloseDevelopmentSummary(true);
          setCloseConsentChangeSDP(true);
          setOpenSDP(false);
          setOpenOutCome(false);
          setOpenTransistionPrep(false);
          toggleLoader(false);
        })
        .catch((error) => {
          setToastrVariable('error');
          toggleLoader(false);
          setOpen(true);
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('IFSP.failedToSave');
            setToastrDefaultMessage('Failed to Save IFSP');
          }
        });
    }
  };

  useEffect(() => {
    setReportCompletedByRole(() => {
      const filteredContactsRoles: OptionType[] = employeeList
        .filter((contact) => contact.id === ifsp.completedBy.id)
        .flatMap((contact) =>
          contact.roles
            ? contact.roles.flatMap((roleString) =>
                roleString.split(',').map((roleId) => ({
                  id: roleId.trim(),
                  label:
                    employeeRoles.find(
                      (empRole) => empRole.id === roleId.trim(),
                    )?.label || '',
                })),
              )
            : [],
        );

      return filteredContactsRoles;
    });
    setReportedCompletedBy(ifsp.completedBy);
    setRepoteCompletedByRoleId(ifsp.completedByRole);
    setParentContactes(() => {
      const formattedContacts: OptionType[] = ifsp.ifspTeam.contact
        .filter(
          (contact) =>
            !ifsp.ifspTeam.parents.some(
              (parent) => parent.id.toString() === contact.id.toString(),
            ),
        )
        .map((filteredContact) => ({
          id: filteredContact.id,
          label: filteredContact.name,
        }));
      return formattedContacts;
    });
  }, [
    ifsp.ifspTeam.contact,
    ifsp.ifspTeam.parents,
    ifsp.ifspTeam.teamMember,
    employeeList,
  ]);
  useEffect(() => {
    const ifspTeamMembers: IfspTeamMembersType[] = [];
    const uniqueMembersSet = new Set<string>();

    const isMemberUnique = (member: IfspTeamMembersType) => {
      return !Array.from(uniqueMembersSet).some((existingMember) => {
        const parsedMember = JSON.parse(existingMember);
        if (member.startDate || member.endDate) {
          return false;
        } else {
          return (
            parsedMember.name === member.name &&
            parsedMember.role === member.role
          );
        }
      });
    };

    const filteredSelectContacts = ifsp.ifspTeam.parents.map((contactId) => {
      return ifsp.ifspTeam.contact.find(
        (contact) => contact.id.toString() === contactId.id.toString(),
      );
    });

    filteredSelectContacts.forEach((contact) => {
      if (contact) {
        const member = {
          id: contact.id,
          name: contact.name,
          email: contact.email,
          phone: contact.phone,
          role: contact.relationshipToClient,
          discipline: contact.type,
          startDate: contact.startDate,
          endDate: contact.endDate,
        };

        if (isMemberUnique(member)) {
          uniqueMembersSet.add(JSON.stringify(member));
          ifspTeamMembers.push(member);
        }
      }
    });

    ifsp.ifspTeam.teamMember.forEach((other) => {
      const member = {
        id: other.id,
        name: other.name,
        email: other.email,
        phone: other.phone,
        role: other.role,
        discipline: other.discipline,
        startDate: other.startDate,
        endDate: other.endDate,
      };

      if (isMemberUnique(member)) {
        uniqueMembersSet.add(JSON.stringify(member));
        ifspTeamMembers.push(member);
      }
    });

    ifsp.ifspTeam.others.forEach((other, index) => {
      ifspTeamMembers.push({
        id: `${index}`, // or some other identifier
        name: other.name,
        email: '',
        phone: '',
        role: '', // Adjust as needed
        discipline: '',
        jobDescription: other.jobDescription,
        startDate: other.startDate,
        endDate: other.endDate,
      });
    });

    setIfspTeamTable(ifspTeamMembers);
  }, [ifsp.ifspTeam.contact, ifsp.ifspTeam.parents, ifsp.ifspTeam.teamMember]);

  useEffect(() => {
    setTeamMembers(() => {
      return employeeList.map((data) => {
        return {
          label: data.name ?? '',
          id: data.id,
        };
      });
    });
  }, [employeeList]);
  useEffect(() => {
    updateIfspContext({
      ...ifsp,
      ifspMeetingDate:
        ifsp.ifspMeetingDate !== '' || null
          ? ifsp.ifspMeetingDate
          : dayjs().format('MM/DD/YYYY'),
    });
    if (ifspDueDate !== '') {
      if (dayjs(ifsp.ifspMeetingDate).isAfter(dayjs(ifspDueDate))) {
        setIsIfspDueDatePassed(true);
      } else {
        updateIfspContext({
          ...ifsp,
          reasonForDelay: { id: '', label: '' },
        });
        setIsIfspDueDatePassed(false);
      }
    }
  }, [ifsp.ifspMeetingDate]);
  useEffect(() => {
    if (ifspDueDate !== '') {
      if (dayjs(ifsp.ifspMeetingDate).isAfter(dayjs(ifspDueDate))) {
        setIsIfspDueDatePassed(true);
      }
    }
  }, [ifspDueDate]);
  useEffect(() => {
    setRepoteCompletedByRoleId(ifsp.completedByRole);
    setIfspDueDate(state?.ifspDueDate);
    setReportedCompletedBy(ifsp.completedBy);
    getAllEmployeeMinimalList()
      .then((data) => {
        setEmployeeList(data);
        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('IFSP.failedToGetEmployeeData');
          setToastrDefaultMessage('Failed to get employee data');
        }
      });
  }, []);

  const handleCloseSnakBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleDelaySave = () => {
    updateIfspContext({
      ...ifsp,
      reasonForDelay: reasonForDelay,
    });
    setOpenDelayModal(false);
  };

  const handleDownload = () => {
    toggleLoader(true);
    const clientId = localStorage.getItem('ClientId');
    const localTimezone = dayjs.tz.guess();
    if (clientId) {
      downloadIfsp(clientId, localTimezone)
        .then((response) => {
          const blob = new Blob([response], { type: 'application/pdf' });

          // Create a Blob URL
          const blobUrl = URL.createObjectURL(blob);

          // Open the Blob URL in a new tab
          const anchor = document.createElement('a');
          anchor.href = blobUrl;
          anchor.target = '_blank';
          anchor.download = `Individualized Family Service Plan (IFSP).pdf`; // Set desired file name here

          // Append anchor to document body
          document.body.appendChild(anchor);

          // Trigger anchor click
          anchor.click();

          window.open(blobUrl, '_blank');

          // Clean up after use
          URL.revokeObjectURL(blobUrl);
          document.body.removeChild(anchor);

          toggleLoader(false);
        })
        .catch((error) => {
          toggleLoader(false);
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('ConsentForms.downloadErrorMsg');
            setToastrDefaultMessage('Failed to download');
          }
        });
    }
  };

  const ifspScreenLockedToaster = () => {
    return (
      <Box
        className="flex__ align__items__center pt-md pb-md pl-lg pr-lg"
        sx={{ gap: '8px', backgroundColor: '#2A4241', borderRadius: '60px' }}
      >
        <Lock />
        <MediumTypography
          labelid="IFSP.LockedToaster"
          defaultlabel="IFSP is Locked"
          textColor="#fff"
        />
      </Box>
    );
  };

  const closeIfspReviewedOnListModal = () => {
    setOpenIfspReviewedOnListModal(false);
  };
  const ifspId = useRef('IFSP');
  return (
    <Box component="main">
      {selectedLang !== '' && (
        <TranslateWidget
          initialLanguage={selectedLang}
          screenId={ifspId.current}
          languageChangeCallback={(language) => {
            setSelectedLang(language);
          }}
        >
          <Box component="section">
            <ModalPopup
              open={openModal}
              description="formUnsavedChangesMessage"
              onCancel={() => setOpenModal(false)}
              onOk={() => {
                toggleLoader(true);
                const clientId = localStorage.getItem('ClientId');
                if (clientId && getIfsp) {
                  setIsreportCompletedByMandatory(false);
                  setIsreportCompletedRoleMandatory(false);
                  setIsCompletedIfspIsMandatory(false);
                  setIsCompletedIfspDateIsMandatory(false);
                  setOpenTransitionModal(false);
                  setCloseDailyRoutine(true);
                  setCloseHealthEligibility(true);
                  setCloseFamilyConcern(true);
                  setCloseDevelopmentSummary(true);
                  setCloseConsentChangeSDP(true);
                  setOpenSDP(false);
                  setOpenOutCome(false);
                  setOpenTransistionPrep(false);
                  toggleLoader(false);
                  getIfsp(clientId);
                }
                setMainSessionCheckBox(false);
                setOpenModal(false);
              }}
              labelId1="Clientpage.cancelbtn"
              negativeActionLabel="Cancel"
              labelId2="Clientpage.Okbtn"
              positiveActionLabel="Ok"
            />
            <IfspUpdateModalPopup
              openIfspUpdateModal={openUpdateModal}
              onCancel={() => setOpenUpdateModal(false)}
              onOk={() => {
                setReportCompletedByRole([{ id: '', label: '' }]);
                setRepoteCompletedByRoleId('');
                setReportedCompletedBy({ id: '', label: '' });
                updateIfspContext({
                  ...ifsp,
                  completed: false,
                  isLocked: false,
                  completedBy: { id: '', label: '' },
                  completionDate: '',
                  completedByRole: '',
                  ifspTeam: {
                    ...ifsp.ifspTeam,
                    forms: {
                      ...ifsp.ifspTeam.forms,
                      consentForChangeForSDP: [
                        ...ifsp.ifspTeam.forms.consentForChangeForSDP,
                        {
                          changes: '',
                          isEligibleUntilThirdBirthday: '',
                          eligibleTool: { id: '', label: '' },
                          assessmentCompletionDate: null,
                          reEstablishedDate: null,
                          parentInitial: { id: '', label: '' },
                          isChecked: true,
                          consentType: 'consentForChange',
                          sessionReviewedDate: null,
                          notifiedParent: '',
                          participated: false,
                          agreeDisagree: 'agree',
                          exceptionList: '',
                          parentSignature: [
                            {
                              id: 'p.0',
                              isUpdated: false,
                              signature: '',
                              verbalConsent: false,
                              date: dayjs().format('MM/DD/YYYY'),
                              partitionKey: '',
                              rowKey: '',
                            },
                            {
                              id: 'p.1',
                              isUpdated: false,
                              signature: '',
                              verbalConsent: false,
                              date: dayjs().format('MM/DD/YYYY'),
                              partitionKey: '',
                              rowKey: '',
                            },
                          ],
                          consent: false,
                          consentSignature: [
                            {
                              id: 'c.0',
                              isUpdated: false,
                              signature: '',
                              verbalConsent: false,
                              date: dayjs().format('MM/DD/YYYY'),
                              partitionKey: '',
                              rowKey: '',
                            },
                            {
                              id: 'c.1',
                              isUpdated: false,
                              signature: '',
                              verbalConsent: false,
                              date: dayjs().format('MM/DD/YYYY'),
                              partitionKey: '',
                              rowKey: '',
                            },
                          ],
                        },
                      ],
                    },
                  },
                });
                setOpenUpdateModal(false);
                setIfspScreenFieldLock(false);
              }}
              labelId1="Clientpage.cancelbtn"
              negativeActionLabel="Cancel"
              labelId2="Clientpage.Okbtn"
              positiveActionLabel="Ok"
            />
            {ifsp.reviewedList.length > 0 && (
              <IfspReviewedOnListModal
                data={ifsp.reviewedList[openIfspReviewedOnListClickedIndex]}
                closeIfspReviewedOnListModal={closeIfspReviewedOnListModal}
                openIfspReviewedOnListModal={openIfspReviewedOnListModal}
              />
            )}
            <Dialog open={openDelayModal} fullWidth>
              <DialogTitle>
                <MediumTypography
                  labelid="IFSP.ChooseTheReasonForDelay"
                  defaultlabel="Choose the reason for delay"
                  fontSize="24px"
                  fontweight={700}
                  textColor="#2A4241"
                />
              </DialogTitle>
              <DialogContent>
                <Box className={'flex__ pb-sm'}>
                  <Box component="div" className="flex__ ">
                    <MediumTypography
                      labelid="IFSP.IFSPdueDate"
                      defaultlabel="IFSP due date:"
                      textColor="#97A6A5"
                      fontSize="16px"
                    />
                    <MediumTypography
                      fontSize="16px"
                      textColor="#2A4241"
                      className="pl-xs pr-lg"
                      label={ifspDueDate}
                    />
                  </Box>
                  <Box component="div" className="flex__ ">
                    <MediumTypography
                      labelid="IFSP.firstmeetingDate"
                      defaultlabel="First Meeting date:"
                      textColor="#97A6A5"
                      fontSize="16px"
                    />
                    <MediumTypography
                      fontSize="16px"
                      textColor="#2A4241"
                      className="pl-xs pr-lg"
                      label={ifsp.ifspMeetingDate?.toString()}
                    />
                  </Box>
                </Box>
                <AutocompleteSearch
                  labelId={'IFSP.SelecttheReasonforDelay'}
                  defaultId={'Select the reason for delay'}
                  selectedValue={reasonForDelay}
                  data={reasonData}
                  onSelectItem={(value: OptionType | null) => {
                    if (value) {
                      setReasonForDelay({
                        id: value.id,
                        label: value.label,
                      });
                    } else {
                      setReasonForDelay({ id: '', label: '' });
                    }
                  }}
                  disableLabel={false}
                />
                <Box className="pt-lg flex__ justifyContent-FlexEnd">
                  <ButtonComponent
                    className="btn-primary btn-cancel"
                    labelId={'cancelText'}
                    defaultLabelId="Cancel"
                    variantType="outlined"
                    onClick={() => setOpenDelayModal(false)}
                  />
                  <ButtonComponent
                    variantType="contained"
                    type="submit"
                    className="btn-primary btn-submit alrt_Width_btn ml-md"
                    labelId="saveText"
                    defaultLabelId="Save"
                    onClick={handleDelaySave}
                    disabled={reasonForDelay?.id === ''}
                  />
                </Box>
              </DialogContent>
            </Dialog>
            <SnackBarComponent
              open={open}
              handleClose={handleCloseSnakBar}
              successOrError={toastrVariable}
              labelId={toastrId}
              defaultMessageId={toastrDefaultMessage}
            />
            <Box component="div" className="rowContainer">
              <Grid container>
                <Grid item xs={6} lg={6}>
                  <Box className="flex__  align__items__center">
                    <CustomBackArrow onClick={() => navigation(FACESHEET)} />
                    <TitleText
                      labelid="ifsp.Title"
                      defaultlabel="Individualized Family Service Plan (IFSP)"
                      className="ml-md"
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  lg={6}
                  className="flex__ align__items__center justifyContent-FlexEnd"
                >
                  <Box component="div" className="flex__ pr-lg">
                    {!offlineMode && (
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        labelId="ConsentForms.listActionDownloadToolTipLabel"
                        defaultLabelId="Download"
                        variantType="contained"
                        onClick={handleDownload}
                      />
                    )}
                    {!offlineMode && (
                      <ButtonComponent
                        className="btn-primary btn-submit ml-xs"
                        labelId="ConsentForms.listActionShareToolTipLabel"
                        defaultLabelId="Share"
                        variantType="contained"
                        onClick={() => setShareModal(true)}
                      />
                    )}
                    {(ifspScreenFieldLock || ifsp.isLocked) &&
                      !isScreenLocked && (
                        <ButtonComponent
                          className="btn-primary btn-submit ml-md"
                          labelId="IFSP.Update"
                          defaultLabelId="Update"
                          variantType="contained"
                          onClick={() => {
                            setOpenUpdateModal(true);
                          }}
                        />
                      )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box component="div">
              <Formik
                initialValues={{
                  contactType: contactType,
                  yetToAddContactId: yetToAddContactId,
                  yetToAddOtherContactId: yetToAddOtherContactId,
                  yetToAddRoleId: yetToAddRoleId,
                  yetToAdddisciplineId: yetToAdddisciplineId,
                  teamMemberStartDate: teamMembersStartDate,
                  teamMemberEndDate: teamMembersEndDate,
                  othersName: othersName,
                  othersJobDescription: othersJobDescription,
                  othersEndDate: othersEndDate,
                  othersStartDate: othersStartDate,
                }}
                validate={validateDate}
                innerRef={formikRef}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  if (values.contactType === 'parent') {
                    handleAddNewContact();
                  } else if (values.contactType === 'teamMember') {
                    handleAddNewTeamMemberContact();
                  } else {
                    handelAddOtherContact();
                  }
                }}
              >
                {({ values, setFieldValue, errors, touched, handleSubmit }) => (
                  <Box component="div">
                    <ModalPopup
                      open={showAlertDialog}
                      description="duplicateWarningMessage"
                      onOk={() => {
                        setShowAlertDialog(false);
                      }}
                      labelId2="Clientpage.Okbtn"
                      positiveActionLabel="Ok"
                    />
                    <ModalPopup
                      open={deleteWarning}
                      description="deleteConfirmationMessage"
                      onOk={() => {
                        const updatedTeamMembers = ifspTeamTable.filter(
                          (__, i) => i !== deleteIndex,
                        );
                        updateIfspContext({
                          ...ifsp,
                          ifspTeam: {
                            ...ifsp.ifspTeam,
                            parents: updatedTeamMembers.filter(
                              (item) =>
                                typeof item.id !== 'number' &&
                                item.startDate === undefined &&
                                !item.jobDescription,
                            ),
                            teamMember: updatedTeamMembers.filter(
                              (item) =>
                                typeof item.id !== 'number' &&
                                item.startDate &&
                                !item.jobDescription,
                            ),
                            others: updatedTeamMembers
                              .filter((item) => item.jobDescription)
                              .map((item) => ({
                                name: item.name,
                                jobDescription: item.jobDescription ?? '',
                                startDate: item.startDate,
                                endDate: item.endDate,
                              })),
                          },
                        });
                        setEditRecordIndex(null);
                        setTeamMembersRole([]);
                        setTeamMembersDiscipline([]);
                        setTeamMembersStartDate('');
                        setTeamMembersEndDate('');
                        setYetToAddContactId('');
                        setYetToAddOtherContactId('');
                        setYetToAddRoleId('');
                        setYetToAddDisciplineId('');

                        formikRef.current?.resetForm(); // Automatically resets all fields to their initial values
                        formikRef.current?.setValues({
                          contactType: formikRef.current?.values.contactType, // Preserving specific values if needed
                          teamMemberEndDate: '',
                          teamMemberStartDate: '',
                          yetToAddContactId: '',
                          yetToAdddisciplineId: '',
                          yetToAddOtherContactId: '',
                          yetToAddRoleId: '',
                          othersEndDate: '',
                          othersStartDate: '',
                          othersJobDescription: '',
                          othersName: '',
                        });

                        formikRef.current?.setTouched({});
                        setEditOtherRecordIndex(null);
                        setDeleteWarning(false);
                      }}
                      onCancel={() => {
                        setDeleteWarning(false);
                      }}
                      labelId1="Clientpage.cancelbtn"
                      negativeActionLabel="cancelText"
                      labelId2="Clientpage.Okbtn"
                      positiveActionLabel="Ok"
                    />

                    {infoModal && (
                      <InformationModal
                        open={infoModal}
                        titleLabel="Information"
                        titleDefaultLabel="Information"
                        buttonLabel="Ok"
                        buttonDefaultLabel="Ok"
                        onClose={handleCloseInfoModal}
                        info={info}
                      />
                    )}
                    <Card className="formCardview">
                      {(ifspScreenFieldLock || ifsp.isLocked) && (
                        <Box
                          sx={{
                            position: 'relative',
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '-24px',
                            }}
                          >
                            {ifspScreenLockedToaster()}
                          </Box>
                        </Box>
                      )}
                      <Box
                        component="div"
                        className="flex__  mb-md alignItemCenter justifyContentSpaceBtw"
                      >
                        <Box className="flex__ alignItemCenter ">
                          <Ifsp />
                          <MediumTypography
                            labelid="ifsp.FormTitle"
                            defaultlabel="Massachusetts Department of Public Health Individualized Family Service Plan"
                            className="ml-md"
                            fontweight={600}
                            fontSize="20px"
                          />
                        </Box>
                        <InfoIcon
                          onClick={handleOpenInfoModal}
                          style={{ cursor: 'pointer' }}
                        />
                      </Box>

                      <SingleLine className="width__100" />
                      <ProgramDetails
                        program={{
                          programName: ifsp.programDetails.name,
                          phoneNumber: ifsp.programDetails.phoneNumber,
                          faxNumber: ifsp.programDetails.faxNumber,
                          image: ifsp.programDetails.imageUrl,
                          website: ifsp.programDetails.website,
                        }}
                        programNameLabel={'ifsp.programName'}
                        programNameDefaultLabel={'Program:'}
                        phoneNumberLabel={'ifsp.programPhone'}
                        phoneNumberDefaultLabel={'Phone:'}
                        faxNumberLabel={'ifsp.programFax'}
                        faxNumberDefaultLabel={'Fax:'}
                        websiteLabel={'ifsp.programWebsite'}
                        websiteDefaultLabel={'Website:'}
                      />
                      <SingleLine className="width__100" />
                      <Box component="div" className="flex__ mt-sm mb-sm ">
                        <Box component="div" className="flex__ ">
                          <MediumTypography
                            labelid="ifsp.ClientName"
                            defaultlabel="Client Name:"
                            textColor="#97A6A5"
                            fontSize="16px"
                          />

                          <Tooltip
                            className="minWidthContent"
                            title={
                              ifsp.client.name && ifsp.client.name.length > 10
                                ? `${ifsp.client.name}`
                                : ''
                            }
                            followCursor
                            arrow={true}
                          >
                            <Box component="div" className="width150px ">
                              <MediumTypography
                                fontSize="16px"
                                sxProps={{
                                  paddingLeft:
                                    ifsp.client.name === '' ||
                                    ifsp.client.name === null
                                      ? '24px'
                                      : '6px',
                                  color: '#2A4241',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                }}
                                label={
                                  ifsp.client.name === '' ||
                                  ifsp.client.name === null
                                    ? '-'
                                    : `${ifsp.client.name}`
                                }
                              />
                            </Box>
                          </Tooltip>
                        </Box>
                        <Box component="div" className="flex__">
                          <MediumTypography
                            className="ml-sm"
                            labelid="ifsp.Sex"
                            defaultlabel="Sex:"
                            textColor="#97A6A5"
                            fontSize="16px"
                          />
                          <MediumTypography
                            fontSize="16px"
                            textColor="#2A4241"
                            className="pr-lg"
                            sxProps={{
                              paddingLeft:
                                ifsp.client.sex === '' ||
                                ifsp.client.sex === null
                                  ? '24px'
                                  : '6px',
                            }}
                            label={
                              ifsp.client.sex === '' || ifsp.client.sex === null
                                ? '-'
                                : convertGenderToFrontend(ifsp.client.sex)
                            }
                          />
                        </Box>
                        <Box component="div" className="flex__">
                          <MediumTypography
                            labelid="ifsp.DPHID"
                            defaultlabel="DPH ID:"
                            textColor="#97A6A5"
                            fontSize="16px"
                          />
                          <MediumTypography
                            fontSize="16px"
                            textColor="#2A4241"
                            className="pl-xs pr-lg"
                            label={
                              ifsp.client.dphId === '' ||
                              ifsp.client.dphId === null
                                ? '-'
                                : ifsp.client.dphId
                            }
                          />
                        </Box>
                        <Box component="div" className="flex__">
                          <MediumTypography
                            labelid="ifsp.DateofBirth"
                            defaultlabel="Date of Birth:"
                            textColor="#97A6A5"
                            fontSize="16px"
                          />
                          <MediumTypography
                            fontSize="16px"
                            textColor="#2A4241"
                            className="pl-xs pr-lg"
                            label={
                              ifsp.client.dob === '' || ifsp.client.dob === null
                                ? '-'
                                : ifsp.client.dob
                            }
                          />
                        </Box>
                        <Box
                          component="div"
                          className="flex__ visibility__hidden"
                        >
                          <MediumTypography
                            labelid="ifsp.Status"
                            defaultlabel="Status:"
                            textColor="#97A6A5"
                            fontSize="16px"
                          />
                          <MediumTypography
                            fontSize="16px"
                            textColor="#2A4241"
                            className="pl-xs pr-lg"
                            label={'jo'}
                          />
                        </Box>
                      </Box>
                      <SingleLine className="width__100" />
                      <Grid container className="mt-md mb-md">
                        <Grid container rowSpacing={'24px'} className="pt-md">
                          <Grid item xs={4}>
                            <DatePickerComponent
                              labelid={'IFSP.FirstMeetingDate'}
                              defaultlabelid={'IFSP First Meeting Date'}
                              disableFuture={false}
                              value={
                                ifsp.ifspMeetingDate === ''
                                  ? dayjs()
                                  : (dayjs(ifsp.ifspMeetingDate) as Dayjs)
                              }
                              handlechange={(dateValue: Dayjs | null) => {
                                if (dateValue !== null) {
                                  const formattedDate =
                                    dayjs(dateValue).format('MM/DD/YYYY');
                                  updateIfspContext({
                                    ...ifsp,
                                    ifspMeetingDate: formattedDate,
                                  });
                                } else {
                                  updateIfspContext({
                                    ...ifsp,
                                    ifspMeetingDate: null,
                                  });
                                }
                              }}
                              minDate={referralDate as Dayjs}
                              disabledDate={
                                ifsp.ifspTeam.forms.consentForChangeForSDP[0]
                                  .sessionReviewedDate
                                  ? true
                                  : false || isScreenLocked
                              }
                            />
                          </Grid>
                          <Grid item xs={0.3}></Grid>
                          <Grid item xs={4}>
                            <DatePickerComponent
                              labelid={'IFSP.SignedDate'}
                              defaultlabelid={'IFSP Signed Date'}
                              disableFuture={false}
                              value={
                                ifsp.ifspSignedDate === ''
                                  ? dayjs()
                                  : (dayjs(ifsp.ifspSignedDate) as Dayjs)
                              }
                              handlechange={(dateValue: Dayjs | null) => {
                                if (dateValue !== null) {
                                  const formattedDate =
                                    dayjs(dateValue).format('MM/DD/YYYY');
                                  updateIfspContext({
                                    ...ifsp,
                                    ifspSignedDate: formattedDate,
                                  });
                                } else {
                                  updateIfspContext({
                                    ...ifsp,
                                    ifspSignedDate: null,
                                  });
                                }
                              }}
                              disabledDate={
                                ifsp.ifspTeam.forms.consentForChangeForSDP[0]
                                  .sessionReviewedDate
                                  ? true
                                  : false || isScreenLocked
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <DropdownComponent
                              names={primarySettingCode}
                              labelid="IFSP.PrimarySettingCode"
                              defaultlabelid="Primary Settings Code"
                              value={ifsp.primarySettingCode}
                              handleChange={(value) => {
                                if (value === 'HOME' || value === 'COMMUNITY') {
                                  updateIfspContext({
                                    ...ifsp,
                                    primarySettingCode: value,
                                    primarySettingCodeJustification: '',
                                  });
                                } else {
                                  updateIfspContext({
                                    ...ifsp,
                                    primarySettingCode: value,
                                  });
                                }
                              }}
                              disabled={
                                ifspScreenFieldLock ||
                                ifsp.isLocked ||
                                isScreenLocked
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {ifsp.primarySettingCode != 'HOME' &&
                      ifsp.primarySettingCode != 'COMMUNITY' &&
                      ifsp.primarySettingCode ? (
                        <Box sx={{ width: '70%', marginY: '24px' }}>
                          <Textinput
                            fieldheight="150px"
                            labelid="IFSP.Justification"
                            defaultlabelid="Justification"
                            Value={ifsp.primarySettingCodeJustification}
                            inputProps={{
                              style: {
                                height: '120px',
                                paddingBottom: '8px',
                                padding: '0px 0px 0px 0px',
                                textAlign: 'justify',
                              },
                              className: 'translate',
                              maxLength: 500,
                            }}
                            handlechange={(e) => {
                              updateIfspContext({
                                ...ifsp,
                                primarySettingCodeJustification: e,
                              });
                            }}
                            multiline={true}
                            Required
                            disabled={
                              ifspScreenFieldLock ||
                              ifsp.isLocked ||
                              isScreenLocked
                            }
                          />
                        </Box>
                      ) : null}
                      {ifsp.reasonForDelay.id !== '' && isIfspDueDatePassed && (
                        <Box
                          component="div"
                          className="flex__ pt-sm pb-sm borderRadius4 justifyContent-Center alignItemCenter"
                          sx={{ backgroundColor: '#ECF9F8' }}
                        >
                          <MediumTypography
                            labelid="IFSP.ReasonForLateIFSPMeeting"
                            defaultlabel="Reason for late IFSP Meeting:"
                            textColor="#97A6A5"
                            fontweight={600}
                            fontSize="14px"
                          />
                          <MediumTypography
                            fontSize="14px"
                            textColor="#2A4241"
                            fontweight={600}
                            className="pl-xs pr-md"
                            label={
                              reasonData.find(
                                (x) => x.id === ifsp.reasonForDelay.id,
                              )?.label
                            }
                          />
                          {!ifsp.ifspTeam.forms.consentForChangeForSDP[0]
                            .sessionReviewedDate &&
                            !isScreenLocked && (
                              <MediumTypography
                                labelid="IFSP.UpdateComment"
                                defaultlabel="Update Comment:"
                                textColor="#00C6B8"
                                fontSize="14px"
                                fontweight={600}
                                className="cursorPointer"
                                onClick={() => {
                                  setReasonForDelay({
                                    id: ifsp.reasonForDelay.id,
                                    label: ifsp.reasonForDelay.label
                                      ? ifsp.reasonForDelay.label
                                      : reasonData.find(
                                          (x) =>
                                            x.id === ifsp.reasonForDelay.id,
                                        )?.label ?? '',
                                  });
                                  setOpenDelayModal(true);
                                }}
                                sxProps={{
                                  textDecorationLine: 'underline',
                                  textUnderlineOffset: '3px',
                                }}
                              />
                            )}
                        </Box>
                      )}
                      {ifsp.reviewedList.length > 0 && (
                        <Box
                          component="div"
                          className="mt-md"
                          sx={{ borderTop: '1px solid #CFDEDE' }}
                        >
                          <MediumTypography
                            labelid="ifsp.reviewedon"
                            defaultlabel="Reviewed on"
                            fontSize="18px"
                            fontweight={600}
                            textColor="#2A4241"
                            className="pb-md pt-md"
                          />
                          <Box className="flex__">
                            {ifsp.reviewedList.map((date, index) => (
                              <Box
                                className="flex__ align__items__center mr-sm"
                                sx={{
                                  backgroundColor: '#E7F0F0',
                                  padding: '5px',
                                  borderRadius: '4px',
                                }}
                              >
                                <MediumTypography
                                  key={index}
                                  label={
                                    date.reviewedDate
                                      ?.toString()
                                      .split(' ')[0] as string
                                  }
                                  fontSize="14px"
                                  fontweight={400}
                                  textColor="#2A4241"
                                  sxProps={{ paddingRight: '5px' }}
                                />
                                <File
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setOpenIfspReviewedOnListClickedIndex(
                                      index,
                                    );
                                    setOpenIfspReviewedOnListModal(true);
                                  }}
                                />
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      )}
                      <Box
                        component="div"
                        className="mt-md"
                        sx={{ borderTop: '1px solid #CFDEDE' }}
                      >
                        <MediumTypography
                          labelid="ifsp.MembersTitle"
                          defaultlabel="IFSP Team Members: (including family members, caregivers, EI providers, SSP and others)"
                          fontSize="18px"
                          fontweight={600}
                          textColor="#2A4241"
                          className="pb-md pt-md"
                        />
                        <CustomRadioButton
                          data={IfspRadioData}
                          value={values.contactType}
                          onSelectItem={(value) => {
                            formikRef.current?.setTouched({});
                            if (value === 'teamMember') {
                              toggleLoader(true);
                              getAllEmployeeMinimalList()
                                .then((data) => {
                                  setEmployeeList(data);
                                  toggleLoader(false);
                                })
                                .catch((err) => {
                                  toggleLoader(false);
                                  setOpen(true);
                                  setToastrId('IFSP.failedToGetEmployeeData');
                                  setToastrVariable('error');
                                  setToastrDefaultMessage(err);
                                });
                            }
                            if (value) {
                              setEditRecordIndex(null);
                              setTeamMembersRole([]);
                              setTeamMembersDiscipline([]);
                              setTeamMembersStartDate('');
                              setTeamMembersEndDate('');
                              setYetToAddContactId('');
                              setYetToAddOtherContactId('');
                              setYetToAddRoleId('');
                              setYetToAddDisciplineId('');

                              formikRef.current?.resetForm(); // Automatically resets all fields to their initial values
                              formikRef.current?.setValues({
                                contactType: value, // Preserving specific values if needed
                                teamMemberEndDate: '',
                                teamMemberStartDate: '',
                                yetToAddContactId: '',
                                yetToAdddisciplineId: '',
                                yetToAddOtherContactId: '',
                                yetToAddRoleId: '',
                                othersEndDate: '',
                                othersStartDate: '',
                                othersJobDescription: '',
                                othersName: '',
                              });

                              formikRef.current?.setTouched({});
                              setEditOtherRecordIndex(null);
                              setDeleteWarning(false);

                              setContactType(value);
                              setFieldValue('contactType', value);
                            }
                          }}
                          disable={
                            ifspScreenFieldLock ||
                            ifsp.isLocked ||
                            isScreenLocked
                          }
                        />
                      </Box>
                      {contactType === 'parent' && (
                        <Box component="div">
                          <Grid
                            container
                            direction={'row'}
                            className="mt-md mb-lg"
                          >
                            <Grid item xs={5}>
                              <AutocompleteSearch
                                data={parentContactes}
                                labelId="IFSP.selectName"
                                defaultId="Select Name"
                                Required={true}
                                selectedValue={{
                                  id:
                                    parentContactes.find(
                                      (item) => item.id === yetToAddContactId,
                                    )?.id ?? '',
                                  label:
                                    parentContactes.find(
                                      (item) => item.id === yetToAddContactId,
                                    )?.label ?? '',
                                }}
                                onSelectItem={(e) => {
                                  if (e) {
                                    setYetToAddContactId(e.id);
                                    setFieldValue('yetToAddContactId', e.id);
                                  } else {
                                    setYetToAddContactId('');
                                    setFieldValue('yetToAddContactId', '');
                                  }
                                }}
                                disableLabel={false}
                                disabled={
                                  ifspScreenFieldLock ||
                                  ifsp.isLocked ||
                                  isScreenLocked
                                }
                              />
                              {errors.yetToAddContactId &&
                                touched.yetToAddContactId && (
                                  <MediumTypography
                                    className="errorText-md"
                                    labelid={errors.yetToAddContactId}
                                    defaultlabel="Contact is required"
                                  />
                                )}
                            </Grid>
                            {ifspScreenFieldLock ||
                            ifsp.isLocked ||
                            isScreenLocked ? null : (
                              <Grid
                                item
                                xs={1}
                                className="cursorPointer align__items__center flex__justify__center"
                                onClick={() => {
                                  handleSubmit();
                                }}
                              >
                                <Box component="div" className="flex__ mt-xs ">
                                  <AddNew className="ml-sm" />
                                  <MediumTypography
                                    labelid="IFSP.Add"
                                    defaultlabel="Add"
                                    className="pl-sm"
                                  />
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      )}
                      {contactType === 'teamMember' && (
                        <Box component="div" className="mt-md mb-md">
                          <Grid container columnSpacing={'32px'}>
                            <Grid item xs={5}>
                              <AutocompleteSearch
                                data={teamMembers}
                                labelId="IFSP.selectName"
                                defaultId="Select Name*"
                                selectedValue={{
                                  id:
                                    teamMembers.find(
                                      (item) =>
                                        item.id ===
                                        values.yetToAddOtherContactId,
                                    )?.id ?? '',
                                  label:
                                    teamMembers.find(
                                      (item) =>
                                        item.id ===
                                        values.yetToAddOtherContactId,
                                    )?.label ?? '',
                                }}
                                onSelectItem={(e) => {
                                  if (e) {
                                    setTeamMembers(() => {
                                      return employeeList.map((data) => {
                                        return {
                                          label: data.name ?? '',
                                          id: data.id,
                                        };
                                      });
                                    });
                                    setTeamMembersRole(() => {
                                      const filteredContactsRoles: OptionType[] =
                                        employeeList
                                          .filter(
                                            (contact) => contact.id === e.id,
                                          )
                                          .flatMap((contact) =>
                                            contact.roles
                                              ? contact.roles.flatMap(
                                                  (roleString) =>
                                                    roleString
                                                      .split(',')
                                                      .map((roleId) => ({
                                                        id: roleId.trim(),
                                                        label:
                                                          employeeRoles.find(
                                                            (empRole) =>
                                                              empRole.id ===
                                                              roleId.trim(),
                                                          )?.label || '',
                                                      })),
                                                )
                                              : [],
                                          );
                                      return filteredContactsRoles;
                                    });
                                    setTeamMembersDiscipline(() => {
                                      const filteredContactsDisciplines =
                                        employeeList
                                          .filter(
                                            (contact) => contact.id === e.id,
                                          )
                                          .map((contact) => ({
                                            id: contact.id,
                                            label:
                                              lookups?.discipline.find(
                                                (dis) =>
                                                  dis.code ===
                                                  contact.disciplineType,
                                              )?.description || '',
                                          }));
                                      return filteredContactsDisciplines;
                                    });

                                    setFieldValue(
                                      'yetToAddOtherContactId',
                                      e.id,
                                    );
                                    setYetToAddOtherContactId(e.id);
                                  } else {
                                    setYetToAddOtherContactId('');
                                    setTeamMembersRole([]);
                                    setTeamMembersDiscipline([]);
                                    setYetToAddDisciplineId('');
                                    setRole('');
                                    setYetToAddRoleId('');
                                    formikRef.current?.setValues({
                                      ...formikRef.current?.values,
                                      yetToAddOtherContactId: '',
                                      yetToAdddisciplineId: '',
                                      yetToAddRoleId: '',
                                    });
                                  }
                                }}
                                disableLabel={false}
                                disabled={
                                  ifspScreenFieldLock ||
                                  ifsp.isLocked ||
                                  editRecordIndex !== null
                                }
                              />
                              {errors.yetToAddOtherContactId &&
                                touched.yetToAddOtherContactId && (
                                  <MediumTypography
                                    className="errorText-md"
                                    labelid={errors.yetToAddOtherContactId}
                                    defaultlabel="Contact is required"
                                  />
                                )}
                            </Grid>
                            <Grid item xs={5} className="mb-md">
                              <DropdownComponent
                                names={teamMembersDiscipline}
                                labelid="Ifsp.discipline"
                                defaultlabelid="Select Discipline"
                                value={values.yetToAdddisciplineId}
                                handleChange={(e) => {
                                  if (e) {
                                    setFieldValue('yetToAdddisciplineId', e);
                                    setYetToAddDisciplineId(e);
                                  }
                                }}
                                Required
                                disabled={
                                  ifspScreenFieldLock ||
                                  ifsp.isLocked ||
                                  isScreenLocked
                                }
                              />
                              {errors.yetToAdddisciplineId &&
                                touched.yetToAdddisciplineId && (
                                  <MediumTypography
                                    className="errorText-md"
                                    labelid={errors.yetToAdddisciplineId}
                                    defaultlabel="Discipline is required"
                                  />
                                )}
                            </Grid>
                            <Grid item xs={5} className="mb-md">
                              <DropdownComponent
                                names={teamMembersRole}
                                labelid="Ifsp.selectRole"
                                defaultlabelid="Select Role"
                                value={values.yetToAddRoleId}
                                handleChange={(e) => {
                                  if (e) {
                                    setRole(e);
                                    setFieldValue('yetToAddRoleId', e);
                                    setYetToAddRoleId(e);
                                  }
                                }}
                                Required
                                disabled={
                                  ifspScreenFieldLock ||
                                  ifsp.isLocked ||
                                  isScreenLocked
                                }
                              />
                              {errors.yetToAddRoleId &&
                                touched.yetToAddRoleId && (
                                  <MediumTypography
                                    className="errorText-md"
                                    labelid={errors.yetToAddRoleId}
                                    defaultlabel="Role is required"
                                  />
                                )}
                            </Grid>
                            <Grid item xs={5}>
                              <DatePickerComponent
                                labelid={'IFSP.startDate'}
                                defaultlabelid={'Start Date*'}
                                required={true}
                                disableFuture={false}
                                value={
                                  values.teamMemberStartDate === ''
                                    ? null
                                    : (dayjs(
                                        values.teamMemberStartDate,
                                      ) as Dayjs)
                                }
                                handlechange={(dateValue: Dayjs | null) => {
                                  if (dateValue !== null) {
                                    const formattedDate =
                                      dayjs(dateValue).format('MM/DD/YYYY');
                                    setTeamMembersStartDate(formattedDate);
                                    setFieldValue(
                                      'teamMemberStartDate',
                                      formattedDate,
                                    );
                                  } else {
                                    setTeamMembersStartDate(null);
                                    setFieldValue('teamMemberStartDate', null);
                                  }
                                }}
                                disabledDate={
                                  ifspScreenFieldLock ||
                                  ifsp.isLocked ||
                                  isScreenLocked
                                }
                              />
                              {errors.teamMemberStartDate &&
                                touched.teamMemberEndDate && (
                                  <MediumTypography
                                    className="errorText-md"
                                    labelid={errors.teamMemberStartDate}
                                    defaultlabel="Start date is required"
                                  />
                                )}
                            </Grid>
                            <Grid item xs={5}>
                              <DatePickerComponent
                                labelid={'IFSP.teamMembersEndDate'}
                                defaultlabelid={'End Date'}
                                disableFuture={false}
                                value={
                                  values.teamMemberEndDate === ''
                                    ? null
                                    : (dayjs(values.teamMemberEndDate) as Dayjs)
                                }
                                handlechange={(dateValue: Dayjs | null) => {
                                  if (dateValue !== null) {
                                    const formattedDate =
                                      dayjs(dateValue).format('MM/DD/YYYY');
                                    setTeamMembersEndDate(formattedDate);
                                    setFieldValue(
                                      'teamMemberEndDate',
                                      formattedDate,
                                    );
                                  } else {
                                    setTeamMembersEndDate(null);
                                    setFieldValue('teamMemberEndDate', null);
                                  }
                                }}
                                disabledDate={
                                  ifspScreenFieldLock ||
                                  ifsp.isLocked ||
                                  isScreenLocked
                                }
                              />
                              {errors.teamMemberEndDate &&
                                touched.teamMemberEndDate && (
                                  <MediumTypography
                                    className="errorText-md"
                                    labelid={errors.teamMemberEndDate}
                                  />
                                )}
                            </Grid>
                            {ifspScreenFieldLock ||
                            ifsp.isLocked ||
                            isScreenLocked ? null : (
                              <Grid
                                container
                                xs={1}
                                className="cursorPointer "
                                onClick={() => {
                                  handleSubmit();
                                }}
                              >
                                <Grid item>
                                  <Box
                                    component="div"
                                    className="flex__ pt-xs "
                                  >
                                    <AddNew className="ml-sm mr-sm" />
                                    <MediumTypography
                                      labelid={
                                        editRecordIndex !== null
                                          ? 'IFSP.Update'
                                          : 'IFSP.Add'
                                      }
                                      defaultlabel={
                                        editRecordIndex !== null
                                          ? 'Update'
                                          : 'Add'
                                      }
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      )}
                      {contactType === 'other' && (
                        <Box component="div" className="mt-md mb-md">
                          <Grid container columnSpacing={'32px'}>
                            <Grid item xs={5} className="mb-md">
                              <Textinput
                                labelid="IFSP.Name"
                                defaultlabelid="Name"
                                Value={values.othersName}
                                inputProps={{
                                  className: 'translate',
                                  maxLength: 40,
                                }}
                                handlechange={(e) => {
                                  setFieldValue('othersName', e);
                                  setOthersName(e);
                                }}
                                Required
                              />
                              {errors.othersName && touched.othersName && (
                                <MediumTypography
                                  className="errorText-md"
                                  labelid={errors.othersName}
                                  defaultlabel="Name is required"
                                />
                              )}
                            </Grid>
                            <Grid item xs={5} className="mb-md">
                              <Textinput
                                labelid="IFSP.JobDescription"
                                defaultlabelid="Job Description"
                                Value={values.othersJobDescription}
                                inputProps={{
                                  className: 'translate',
                                  maxLength: 200,
                                }}
                                handlechange={(e) => {
                                  setFieldValue('othersJobDescription', e);
                                  setOthersJobDescription(e);
                                }}
                                Required
                              />
                              {errors.othersJobDescription &&
                                touched.othersJobDescription && (
                                  <MediumTypography
                                    className="errorText-md"
                                    labelid={errors.othersJobDescription}
                                    defaultlabel="Job Description is required"
                                  />
                                )}
                            </Grid>
                            <Grid item xs={5}>
                              <DatePickerComponent
                                labelid={'IFSP.startDate'}
                                defaultlabelid={'Start Date*'}
                                required={true}
                                disableFuture={false}
                                value={
                                  values.othersStartDate === ''
                                    ? null
                                    : (dayjs(values.othersStartDate) as Dayjs)
                                }
                                handlechange={(dateValue: Dayjs | null) => {
                                  if (dateValue !== null) {
                                    const formattedDate =
                                      dayjs(dateValue).format('MM/DD/YYYY');
                                    setOthersStartDate(formattedDate);
                                    setFieldValue(
                                      'othersStartDate',
                                      formattedDate,
                                    );
                                  } else {
                                    setOthersStartDate(null);
                                    setFieldValue('othersStartDate', null);
                                  }
                                }}
                                disabledDate={
                                  ifspScreenFieldLock ||
                                  ifsp.isLocked ||
                                  isScreenLocked
                                }
                              />
                              {errors.othersStartDate &&
                                touched.othersStartDate && (
                                  <MediumTypography
                                    className="errorText-md"
                                    labelid={errors.othersStartDate}
                                    defaultlabel="Start date is required"
                                  />
                                )}
                            </Grid>
                            <Grid item xs={5}>
                              <DatePickerComponent
                                labelid={'IFSP.teamMembersEndDate'}
                                defaultlabelid={'End Date'}
                                disableFuture={false}
                                value={
                                  values.othersEndDate === ''
                                    ? null
                                    : (dayjs(values.othersEndDate) as Dayjs)
                                }
                                handlechange={(dateValue: Dayjs | null) => {
                                  if (dateValue !== null) {
                                    const formattedDate =
                                      dayjs(dateValue).format('MM/DD/YYYY');
                                    setOthersEndDate(formattedDate);
                                    setFieldValue(
                                      'othersEndDate',
                                      formattedDate,
                                    );
                                  } else {
                                    setOthersEndDate(null);
                                    setFieldValue('othersEndDate', null);
                                  }
                                }}
                                disabledDate={
                                  ifspScreenFieldLock ||
                                  ifsp.isLocked ||
                                  isScreenLocked
                                }
                              />
                              {errors.othersEndDate &&
                                touched.othersEndDate && (
                                  <MediumTypography
                                    className="errorText-md"
                                    labelid={errors.othersEndDate}
                                    defaultlabel="End date is required"
                                  />
                                )}
                            </Grid>
                            {(ifspScreenFieldLock || ifsp.isLocked) &&
                            isScreenLocked ? null : (
                              <Grid
                                container
                                xs={1}
                                className="cursorPointer "
                                onClick={() => {
                                  handleSubmit();
                                }}
                              >
                                <Grid item>
                                  <Box
                                    component="div"
                                    className="flex__ pt-xs "
                                  >
                                    <AddNew className="ml-sm mr-sm" />
                                    <MediumTypography
                                      labelid={
                                        editOtherRecordIndex !== null
                                          ? 'IFSP.Update'
                                          : 'IFSP.Add'
                                      }
                                      defaultlabel={
                                        editOtherRecordIndex !== null
                                          ? 'Update'
                                          : 'Add'
                                      }
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      )}
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHeader
                            className="listDataTableHead"
                            headerNames={headCells}
                            checkBoxRequired={false}
                          />
                          <TableBody className="tableRowcss">
                            {ifspTeamTable.map((listData, index) => (
                              <IfspTeamMembersTableRow
                                isIfspScreenLocked={
                                  ifspScreenFieldLock ||
                                  ifsp.isLocked ||
                                  isScreenLocked
                                }
                                key={index}
                                data={listData}
                                onDelete={() => handleDeleteOtherContact(index)}
                                onEdit={() => {
                                  if (listData.role) {
                                    handleEditTeamMemberContact(index);
                                  } else {
                                    handleEditOtherContact(index);
                                  }
                                }}
                              />
                            ))}
                          </TableBody>
                        </Table>
                        {ifspTeamTable.length === 0 && (
                          <Box component="div" className="width__100">
                            <MediumTypography
                              textstyle={'center'}
                              labelid="IFSP.emptyMessage"
                              defaultlabel="No data added yet"
                              className="p-sm"
                            />
                          </Box>
                        )}
                      </TableContainer>
                      <Box className="horizontalLine" />
                      <MediumTypography
                        className="pb-md"
                        labelid="ifsp.Checkforms"
                        defaultlabel="Check all forms needed for session"
                        fontSize="18px"
                        fontweight={600}
                        textColor="#2A4241"
                      />
                      <Box
                        component="div"
                        className="flex__ align__items__center pb-md"
                      >
                        <CustomCheckBox
                          value={mainSessionCheckBox}
                          style={{ padding: 0, paddingRight: '8px' }}
                          onCheckBoxClick={(e) =>
                            handleMainSessionCheckBoxClick(e)
                          }
                          disable={
                            ifspScreenFieldLock ||
                            ifsp.isLocked ||
                            isScreenLocked
                          }
                        />
                        <MediumTypography
                          labelid="ifsp.checkBoxMain"
                          defaultlabel="Individualized Family Service Plan (all sections below including consent)"
                          fontweight={400}
                          textColor="#2A4241"
                        />
                      </Box>
                      <Box component="div" className="ml-lg">
                        <Grid container spacing={2}>
                          {sessions.map((session) => (
                            <Grid key={session.id} item xs={3.5}>
                              <Box
                                component="div"
                                className="flex__ align__items__center"
                              >
                                <CustomCheckBox
                                  value={session.checked}
                                  style={{ padding: 0, paddingRight: '8px' }}
                                  onCheckBoxClick={(e) =>
                                    handleSessionCheckBoxClick(session.id, e)
                                  }
                                  disable={
                                    ifspScreenFieldLock ||
                                    ifsp.isLocked ||
                                    isScreenLocked
                                  }
                                />
                                <MediumTypography
                                  label={session.defaultDataLabelId}
                                  fontweight={400}
                                  textColor="#2A4241"
                                />
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                      <div ref={autoScrollRef}>
                        {sessions[5]?.checked && isFromClientJourny && (
                          <Box component="div" className="mt-lg mb-lg">
                            <TransitionPlan
                              isIfspScreenLocked={
                                ifspScreenFieldLock || ifsp.isLocked
                              }
                              selectedLanguage={selectedLang}
                              info={infoTransitionPlanModal}
                              open={openTransitionModal}
                              onOpen={() => {
                                setOpenTransitionModal(true);
                              }}
                              openInfo={() => {
                                setInfoTransitionPlanModal(true);
                              }}
                              onClose={() => {
                                setOpenTransitionModal(false);
                              }}
                              closeInfo={() => {
                                setInfoTransitionPlanModal(false);
                              }}
                            />
                          </Box>
                        )}
                      </div>
                      {sessions[0]?.checked && (
                        <Box component="div" className="mt-lg mb-lg">
                          <DailyRoutine
                            info={infoDailyRoutineModal}
                            close={closeDailyRoutine}
                            handelClose={setCloseDailyRoutine}
                            isIfspScreenLocked={
                              ifspScreenFieldLock || ifsp.isLocked
                            }
                            selectedLanguage={selectedLang}
                            openInfo={() => {
                              setInfoDailyRoutineModal(true);
                            }}
                            closeInfo={() => {
                              setInfoDailyRoutineModal(false);
                            }}
                          />
                        </Box>
                      )}
                      {sessions[1]?.checked && (
                        <Box component="div" className="mt-lg mb-lg">
                          <FamiliyConcernsPrioritiesandResources
                            close={closeFamilyConcern}
                            handelClose={setCloseFamilyConcern}
                            info={infoFamilyConcernsModal}
                            isIfspScreenLocked={
                              ifspScreenFieldLock || ifsp.isLocked
                            }
                            selectedLanguage={selectedLang}
                            openInfo={() => {
                              setInfoFamilyConcernsModal(true);
                            }}
                            closeInfo={() => {
                              setInfoFamilyConcernsModal(false);
                            }}
                          />
                        </Box>
                      )}
                      {sessions[3]?.checked && (
                        <Box component="div" className="mt-lg mb-lg">
                          <HealthEligibilitySummary
                            close={closeHealthEligibility}
                            handelClose={setCloseHealthEligibility}
                            info={infoHESModal}
                            employeeList={employeeList}
                            isIfspScreenLocked={
                              ifspScreenFieldLock || ifsp.isLocked
                            }
                            selectedLanguage={selectedLang}
                            openInfo={() => {
                              setInfoHESModal(true);
                            }}
                            closeInfo={() => {
                              setInfoHESModal(false);
                            }}
                          />
                        </Box>
                      )}
                      {sessions[4]?.checked && (
                        <Box component="div" className="mt-lg mb-lg">
                          <DevelopmentalSummary
                            close={closeDevelopmentSummary}
                            handelClose={setCloseDevelopmentSummary}
                            info={infoDevelopmentalSummaryModal}
                            isIfspScreenLocked={
                              ifspScreenFieldLock || ifsp.isLocked
                            }
                            selectedLanguage={selectedLang}
                            openInfo={() => {
                              setInfoDevelopmentalSummaryModal(true);
                            }}
                            closeInfo={() => {
                              setInfoDevelopmentalSummaryModal(false);
                            }}
                          />
                        </Box>
                      )}
                      {sessions[6]?.checked && (
                        <Box component="div" className="mt-lg mb-lg">
                          <ChildandFamilyIFSPOutcomes
                            isIfspScreenLocked={
                              ifspScreenFieldLock || ifsp.isLocked
                            }
                            selectedLanguage={selectedLang}
                            info={infoOutComeModal}
                            open={openOutCome}
                            onOpen={() => {
                              setOpenOutCome(true);
                            }}
                            openInfo={() => {
                              setInfoOutComeModal(true);
                            }}
                            onClose={() => {
                              setOpenOutCome(false);
                            }}
                            closeInfo={() => {
                              setInfoOutComeModal(false);
                            }}
                            reviewInfo={infoReviewModal}
                            openReviewInfo={() => {
                              setInfoReviewModal(true);
                            }}
                            closeReviewInfo={() => {
                              setInfoReviewModal(false);
                            }}
                          />
                        </Box>
                      )}
                      {sessions[7]?.checked && (
                        <Box component="div" className="mt-lg mb-lg">
                          <ServiceDeliveryPlan
                            isIfspScreenLocked={
                              ifspScreenFieldLock || ifsp.isLocked
                            }
                            selectedLanguage={selectedLang}
                            info={infoSDPModal}
                            open={openSDP}
                            onOpen={() => {
                              setOpenSDP(true);
                            }}
                            openInfo={() => {
                              setInfoSDPModal(true);
                            }}
                            onClose={() => {
                              setOpenSDP(false);
                            }}
                            closeInfo={() => {
                              setInfoSDPModal(false);
                            }}
                          />
                        </Box>
                      )}
                      {sessions[2]?.checked && (
                        <Box component="div" className="mt-lg mb-lg">
                          <TransitionPreparation
                            isIfspScreenLocked={
                              ifspScreenFieldLock || ifsp.isLocked
                            }
                            selectedLanguage={selectedLang}
                            info={infoTransistionPrepModal}
                            open={openTransistionPrep}
                            onOpen={() => {
                              setOpenTransistionPrep(true);
                            }}
                            openInfo={() => {
                              setInfoTransistionPrepModal(true);
                            }}
                            onClose={() => {
                              setOpenTransistionPrep(false);
                            }}
                            closeInfo={() => {
                              setInfoTransistionPrepModal(false);
                            }}
                          />
                        </Box>
                      )}

                      {sessions[5]?.checked && !isFromClientJourny && (
                        <Box component="div" className="mt-lg mb-lg">
                          <TransitionPlan
                            isIfspScreenLocked={
                              ifspScreenFieldLock || ifsp.isLocked
                            }
                            selectedLanguage={selectedLang}
                            info={infoTransitionPlanModal}
                            open={openTransitionModal}
                            onOpen={() => {
                              setOpenTransitionModal(true);
                            }}
                            openInfo={() => {
                              setInfoTransitionPlanModal(true);
                            }}
                            onClose={() => {
                              setOpenTransitionModal(false);
                            }}
                            closeInfo={() => {
                              setInfoTransitionPlanModal(false);
                            }}
                          />
                        </Box>
                      )}

                      {sessions[8]?.checked && (
                        <Box component="div" className="mt-lg mb-lg">
                          <ConsentChangeServiceDeliveryPlan
                            isIfspScreenLocked={
                              ifspScreenFieldLock || ifsp.isLocked
                            }
                            selectedLanguage={selectedLang}
                            close={closeConsentChangeSDP}
                            handelClose={setCloseConsentChangeSDP}
                            info={infoConsentChangeSDPModal}
                            openInfo={() => {
                              setInfoConsentChangeSDPModal(true);
                            }}
                            closeInfo={() => {
                              setInfoConsentChangeSDPModal(false);
                            }}
                          />
                        </Box>
                      )}
                      <Box className="horizontalLine" />
                      {/* ReportComplete */}
                      <Box className="gray3 borderRadius p-lg" component="div">
                        <MediumTypography
                          labelid="IFSP.reportComplete"
                          defaultlabel="Report Complete"
                          fontSize="18px"
                          fontweight={600}
                          className="pb-md"
                        />
                        <MediumTypography
                          labelid={reportText}
                          defaultlabel={reportText}
                          fontweight={400}
                          className="pb-md"
                        />
                        <MediumTypography
                          labelid={'IFSP.reportCopyInstruction'}
                          defaultlabel={
                            'For each session completed keep a soft or hard date stamped copy before modifying for next session.'
                          }
                          fontweight={700}
                        />
                      </Box>
                      <Grid container direction={'row'} className="mt-md">
                        <Grid item xs={5.2}>
                          <AutocompleteSearch
                            labelId={'IFSP.reportCompletedBy'}
                            defaultId="Report Completed By"
                            optionHintId={'noemployeeDropdownTextId'}
                            sxProps={{
                              [`& .${autocompleteClasses.popupIndicator}`]: {
                                transform: 'none',
                              },
                            }}
                            data={employeeList.map((item) => ({
                              id: item.id,
                              label: item.name !== null ? item.name ?? '' : '',
                            }))}
                            onSelectItem={(value: OptionType | null) => {
                              if (value) {
                                setReportedCompletedBy(value);
                                updateIfspContext({
                                  ...ifsp,
                                  completedBy: {
                                    id: value.id,
                                    label: value.label,
                                  },
                                });
                                setReportCompletedByRole(() => {
                                  const filteredContactsRoles: OptionType[] =
                                    employeeList
                                      .filter(
                                        (contact) => contact.id === value.id,
                                      )
                                      .flatMap((contact) =>
                                        contact.roles
                                          ? contact.roles.flatMap(
                                              (roleString) =>
                                                roleString
                                                  .split(',')
                                                  .map((roleId) => ({
                                                    id: roleId.trim(),
                                                    label:
                                                      employeeRoles.find(
                                                        (empRole) =>
                                                          empRole.id ===
                                                          roleId.trim(),
                                                      )?.label || '',
                                                  })),
                                            )
                                          : [],
                                      );
                                  return filteredContactsRoles;
                                });
                                setIsreportCompletedByMandatory(false);
                                setRepoteCompletedByRoleId('');
                                setIsreportCompletedRoleMandatory(true);
                              } else {
                                setReportedCompletedBy(null);
                                updateIfspContext({
                                  ...ifsp,
                                  completedByRole: '',
                                  completed: false,
                                  completionDate: '',
                                  completedBy: {
                                    id: '',
                                    label: '',
                                  },
                                });
                                setReportCompletedByRole([]);
                                if (isLatestConsentSigned) {
                                  setIsreportCompletedByMandatory(true);
                                  setIsreportCompletedRoleMandatory(true);
                                } else {
                                  setIsreportCompletedByMandatory(false);
                                  setIsreportCompletedRoleMandatory(false);
                                }
                                setRepoteCompletedByRoleId('');
                              }
                            }}
                            selectedValue={reportedCompletedBy as OptionType}
                            disableLabel={false}
                            disabled={
                              ifspScreenFieldLock ||
                              ifsp.isLocked ||
                              isScreenLocked
                            }
                          />
                          {isreportCompletedByMandatory && (
                            <MediumTypography
                              className="errorText-md"
                              labelid={'IFSP.reportCompletedByIsRequired'}
                              defaultlabel="Report Completed By is required"
                            />
                          )}
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={5.2}>
                          <DropdownComponent
                            names={reportCompletedByRole}
                            labelid="IFSP.selectRoleNonMandatory"
                            defaultlabelid="Select Role"
                            value={
                              repoteCompletedByRoleId ?? ifsp.completedByRole
                            }
                            handleChange={(e) => {
                              if (e) {
                                updateIfspContext({
                                  ...ifsp,
                                  completedByRole: e,
                                });
                                setRepoteCompletedByRoleId(e);
                                setIsreportCompletedRoleMandatory(false);
                              }
                            }}
                            disabled={
                              ifspScreenFieldLock ||
                              ifsp.isLocked ||
                              isScreenLocked
                            }
                          />
                          {isreportCompletedRoleMandatory && (
                            <MediumTypography
                              className="errorText-md"
                              labelid={'IFSP.roleIsRequired'}
                              defaultlabel="Role is required"
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Box
                        component="div"
                        className="flex__ align__items__center pt-md pr-md"
                      >
                        <CustomCheckBox
                          value={ifsp.completed}
                          style={{ padding: 0, paddingTop: '2px' }}
                          onCheckBoxClick={(e) => {
                            updateIfspContext({
                              ...ifsp,
                              completed: !ifsp.completed,
                              completionDate:
                                !ifsp.completed && ifsp.completionDate === ''
                                  ? dayjs()
                                      .format('YYYY-MM-DD hh:mm:ss a')
                                      .toString()
                                  : dayjs(ifsp.completionDate)
                                      .format('YYYY-MM-DD hh:mm:ss a')
                                      .toString(),
                            });
                            setIsCompletedIfspIsMandatory(e ? false : true);
                            setIsCompletedIfspDateIsMandatory(false);
                          }}
                          disable={
                            ifsp.completedBy?.label === '' ||
                            ifspScreenFieldLock ||
                            ifsp.isLocked ||
                            isScreenLocked
                          }
                        />
                        <MediumTypography
                          labelid="IFSP.thisReportIsComplete"
                          defaultlabel="This report is complete"
                          fontweight={400}
                          textColor="#2A4241"
                          className="ml-sm"
                        />
                      </Box>
                      {isCompletedIfspIsMandatory && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={'IFSP.reportCompleteCheckBox'}
                          defaultlabel="Ensure to check the box before proceeding"
                        />
                      )}
                      <Box
                        component="div"
                        className="mt-md mb-md"
                        sx={{ width: '400px' }}
                      >
                        <DateTimePickerComponent
                          value={
                            ifsp.completionDate === ''
                              ? null
                              : dayjs(ifsp.completionDate)
                          }
                          labelid="IFSP.Date/timeStamp"
                          defaultlabelid="Date/Time Stamp"
                          handlechange={(date: Dayjs) => {
                            updateIfspContext({
                              ...ifsp,
                              completionDate: date
                                .format('YYYY-MM-DD hh:mm:ss a')
                                .toString(),
                            });
                            setIsCompletedIfspDateIsMandatory(false);
                          }}
                          inputFormat="ddd, MMM DD hh:mm a"
                          disabledDate={
                            ifsp.completedBy?.label === '' ||
                            ifspScreenFieldLock ||
                            ifsp.isLocked ||
                            isScreenLocked
                          }
                        />
                        {isCompletedIfspDateIsMandatory && (
                          <MediumTypography
                            className="errorText-md"
                            labelid={'IFSP.Date/timeStampIsRequired'}
                            defaultlabel="Date/Time Stamp is required"
                          />
                        )}
                      </Box>
                    </Card>

                    <Box
                      component="div"
                      className="flex__ justifyContent-FlexEnd mt-md mb-xlg"
                    >
                      <ButtonComponent
                        className="btn-primary btn-cancel"
                        labelId={'cancelText'}
                        defaultLabelId="Cancel"
                        variantType="outlined"
                        onClick={() => {
                          if (!ifspTouched) {
                            const clientId = localStorage.getItem('ClientId');
                            navigation(FACESHEET, { state: { clientId } });
                          } else {
                            setOpenModal(true);
                          }
                        }}
                      />
                      <ButtonComponent
                        className="btn-primary btn-submit  ml-md"
                        labelId="ConsentSave"
                        defaultLabelId="Save"
                        variantType="contained"
                        onClick={() => {
                          if (
                            ifsp.ifspMeetingDate === '' ||
                            ifsp.ifspMeetingDate === null
                          ) {
                            setOpen(true);
                            setToastrVariable('error');
                            setToastrDefaultMessage(
                              "Meeting Date can't be empty",
                            );
                            setToastrId('IFSP.MeetingDateRequired');
                          } else if (
                            datePickerMinDateMaxDateValidate(
                              ifsp.ifspMeetingDate,
                            )
                          ) {
                            setOpen(true);
                            setToastrVariable('error');
                            setToastrDefaultMessage('Meeting Date is invalid');
                            setToastrId('datePickerMinDateMaxDateValidate');
                          } else if (
                            ifsp.ifspSignedDate === '' ||
                            ifsp.ifspSignedDate === null
                          ) {
                            setOpen(true);
                            setToastrVariable('error');
                            setToastrDefaultMessage(
                              "IFSP Signed Date can't be empty",
                            );
                            setToastrId('IFSP.ifspSignedDateRequired');
                          } else if (
                            datePickerMinDateMaxDateValidate(
                              ifsp.ifspSignedDate,
                            )
                          ) {
                            setOpen(true);
                            setToastrVariable('error');
                            setToastrDefaultMessage(
                              'IFSP Signed Date is invalid',
                            );
                            setToastrId('datePickerMinDateMaxDateValidate');
                          } else if (
                            ifsp.primarySettingCode != 'HOME' &&
                            ifsp.primarySettingCode != 'COMMUNITY' &&
                            ifsp.primarySettingCode &&
                            (ifsp.primarySettingCodeJustification === '' ||
                              ifsp.primarySettingCodeJustification === null)
                          ) {
                            setOpen(true);
                            setToastrVariable('error');
                            setToastrDefaultMessage(
                              'Justification is required',
                            );
                            setToastrId(
                              'IFSP.primarySettingCodeJustificationRequired',
                            );
                          } else if (
                            latestConsentForChange.isChecked &&
                            latestConsentForChange.consentType === 'consent' &&
                            (latestConsentForChange.reEstablishedDate === '' ||
                              latestConsentForChange.reEstablishedDate === null)
                          ) {
                            setOpen(true);
                            setToastrVariable('error');
                            setToastrDefaultMessage(
                              'Re-Established Date is required',
                            );
                            setToastrId('IFSP.reEstablishedDateRequired');
                          } else if (
                            datePickerMinDateMaxDateValidate(
                              latestConsentForChange.reEstablishedDate,
                            )
                          ) {
                            setOpen(true);
                            setToastrVariable('error');
                            setToastrDefaultMessage(
                              'Re-Established Date is invalid',
                            );
                            setToastrId('datePickerMinDateMaxDateValidate');
                          } else if (
                            isLatestConsentSigned &&
                            (!ifsp.completedBy ||
                              !ifsp.completedByRole ||
                              !ifsp.completed ||
                              !ifsp.completionDate)
                          ) {
                            const missingFields = [
                              !ifsp.completedBy?.id,
                              !ifsp.completedByRole,
                              !ifsp.completed,
                              !ifsp.completionDate,
                            ];

                            const missingFieldHandlers = [
                              () => setIsreportCompletedByMandatory(true),
                              () => setIsreportCompletedRoleMandatory(true),
                              () => setIsCompletedIfspIsMandatory(true),
                              () => setIsCompletedIfspDateIsMandatory(true),
                            ];

                            missingFields.forEach((missing, index) => {
                              if (missing) missingFieldHandlers[index]();
                            });
                          } else if (
                            (!hasChildOutcomes || !hasServiceDeliveryPlan) &&
                            isLatestConsentSigned
                          ) {
                            setOpen(true);
                            setToastrVariable('error');
                            setToastrDefaultMessage(
                              'Missing child outcomes or service delivery plan',
                            );
                            setToastrId(
                              'IFSP.missingChildOutcomesOrServiceDeliveryPlan',
                            );
                          } else if (
                            dayjs(ifsp.ifspMeetingDate).isBefore(
                              dayjs(referralDate),
                            )
                          ) {
                            setOpen(true);
                            setToastrVariable('error');
                            setToastrDefaultMessage(
                              'Meeting Date should be after Referral Date',
                            );
                            setToastrId(
                              'IFSP.MeetingDateShouldBeAfterReferralDate',
                            );
                          } else if (
                            dayjs(ifsp.ifspMeetingDate).isAfter(
                              dayjs(ifspDueDate),
                            ) &&
                            ifsp.reasonForDelay.id === ''
                          ) {
                            setOpenDelayModal(true);
                          } else {
                            handleCreateIFSP();
                          }
                        }}
                        disabled={
                          (transitionDateOutOfRange &&
                            ifsp.ifspTeam.forms.transitionPlan
                              .noTransitionPlanReason === '') ||
                          (leaReferralDateOutOfRange &&
                            ifsp.ifspTeam.forms.transitionPlan
                              .outsideTPCReason === '') ||
                          isOptOutNotificationDateRequired ||
                          isTransitionDateReqired ||
                          ifspScreenFieldLock ||
                          ifsp.isLocked ||
                          isCompletedIfspDateIsMandatory ||
                          isreportCompletedByMandatory ||
                          isreportCompletedRoleMandatory ||
                          isCompletedIfspIsMandatory ||
                          isScreenLocked
                        }
                      />
                    </Box>
                  </Box>
                )}
              </Formik>
            </Box>
          </Box>
          {shareModal && (
            <ShareDownloadModal
              modalVisible={shareModal}
              closeModal={() => setShareModal(false)}
              screenName="IFSP"
            />
          )}
        </TranslateWidget>
      )}
    </Box>
  );
};

export default IFSPScreen;
