import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AlertColor, Box, Card, Grid } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import MediumTypography from '../../components/formlib/MediumTypography';
import Textinput from '../../components/formlib/Textinput';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import * as yup from 'yup';
import SelectComponent from '../../components/formlib/SelectComponent';
import { Formik, FormikErrors, FormikProps, FormikTouched } from 'formik';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { AddNewReferral, OptionType } from '../../utils/type';
import ModalPopup from '../../components/formlib/ModalPopup';
import {
  addOrUpdateReferral,
  addOrUpdateReferralDraft,
  getDraftById,
} from '../../services/configApi/User';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import '../ClientsDashboardPage.css';
import { ReferralNavStateProps } from './ReferralDashboard';
import {
  CLIENT_NAME_KEY,
  EMAIL_REGEX,
  REFERRAL_ID_KEY,
  STORAGE_USER_ID_KEY,
} from '../../services/Constant';
import _, { debounce } from 'lodash';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import CustomRadioButton, {
  RadioButtonData,
} from '../../components/formlib/CustomRadioButton';
import { formatPhoneNumber } from '../../utils/formValidationUtil';
import {
  ReferralParent,
  ReferralParentComponent,
} from './ReferralParentComponent';
import { useIntl } from 'react-intl';
import { getFullName } from '../../utils/nameUtils';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import TitleText from '../../components/formlib/TitleText';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import {
  convertTimeDuration,
  datePickerMinDateMaxDateValidate,
  formatStringDateInDecimals,
  formatTimeDurationInDecimals,
  threeYearsAgo,
} from '../../utils/dateUtil';
import {
  CLIENTS_ROUTE_NAME,
  REFERRAL_DASHBOARD_ROUTE_NAME,
} from '../../routes/Routing';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import CommentTextbox from '../../components/formlib/CommentTextbox';
import DurationPicker from '../../components/formlib/DurationPicker';
import { LookUpContext } from '../../context/LookUpContextProvider';
import {
  convertGenderToBackend,
  convertGenderToFrontend,
} from '../../utils/genderUtil';
import AddressForm from '../../components/formlib/AddressForm';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import {
  ReferralResponseType,
  getAllReferrals,
} from '../../services/configApi/Clients';
import AutocompleteSearch from '../../components/formlib/AutocompleteSearch';
import {
  Codes,
  getCodesAndDescription,
} from '../../services/configApi/codelist/diagnosticCodes';
import CustomCheckBox from '../../components/formlib/CustomCheckBox';
import SimilarClientsModal from './SimilarClientsModal';
import { SimilarClient } from './type';

const addressSchema = yup.object().shape({
  address1: yup.string().required('addressLine1RequiredMessage'),
  address2: yup.string(),
  zipCode: yup.object().shape({
    label: yup.string().required('zipCodeRequiredMessage'),
  }),
  city: yup.string().required('cityRequiredMessage'),
  state: yup.string().required('stateRequiredMessage'),
});

const parentsSchema = yup.object().shape({
  firstName: yup.string().required('ParentFirstName.Validation'),
  lastName: yup.string().required('ParentLastName.Validation'),
  email: yup
    .string()
    .matches(EMAIL_REGEX, 'EmailFormat.Validation')
    .email('EmailFormat.Validation'),
  relationShipToClient: yup.string().required('parentRelationship.Validation'),
  homePhoneNumber: yup
    .string()
    .min(12, 'PhoneNumber.Validation')
    .required('parentHomePhone.Validation'),
  address: addressSchema,
});

const validationSchema = yup.object().shape({
  firstName: yup.string().required('Firstname.Validation'),
  lastName: yup.string().required('Lastname.Validation'),
  sex: yup.string().required('AddNewReferralDetails.gender.Validation'),
  dateOfBirth: yup.date().required('DateOfBirth.Validation').nullable(),
  address1: yup.string().required('addressLine1RequiredMessage'),
  address2: yup.string(),
  zipCode: yup.object().shape({
    label: yup.string().required('zipCodeRequiredMessage'),
  }),
  city: yup.string().required('cityRequiredMessage'),
  state: yup.string().required('stateRequiredMessage'),
  referralBeginDate: yup
    .date()
    .required('AddNewReferralDetails.referralBeginDate.Validation')
    .nullable(),
  referralEndDate: yup.date().nullable(),
  parents: yup.array().of(parentsSchema),
  referralContact: yup.object().shape({
    referralContactDate: yup
      .date()
      .required('AddNewReferralDetails.referralContactDate.Validation')
      .nullable(),
    referralContactName: yup.string(),
    referralContactEmail: yup
      .string()
      .matches(EMAIL_REGEX, 'EmailFormat.Validation'),
    referralContactJobTitle: yup.string(),
    referralContactType: yup.string(),
    referralContactPhone: yup.string(),
    referralContactFax: yup.string().min(12, 'FaxFormat.Validation'),
    referralSource: yup.string().required('referralSource.Validation'),
    referralReason: yup.string().required('referralReason.Validation'),
  }),
  notes: yup.string(),
  referralType: yup
    .string()
    .required('AddNewReferralDetails.referralType.Validation'),
  elProgram: yup.string().when('referralType', {
    is: 'Incoming Transfer',
    then: yup.string().required('AddNewReferralDetails.elProgram.Validation'),
  }),
  elContactNumber: yup.string().when('referralType', {
    is: 'Incoming Transfer',
    then: yup
      .string()
      .min(12, 'PhoneNumber.Validation')
      .required('AddNewReferralDetails.elContactNumber.Validation'),
  }),
  remainingAssessmentHours: yup.string(),
  beginDate: yup.date().when('referralType', {
    is: 'Incoming Transfer',
    then: yup
      .date()
      .required('AddNewReferralDetails.beginDate.Validation')
      .nullable(),
    otherwise: yup.date().nullable(),
  }),
  rollOverDate: yup.date().when('referralType', {
    is: 'Incoming Transfer',
    then: yup.date().nullable(),
    otherwise: yup.date().nullable(),
  }),
  referralDiagnosisCode: yup.string().nullable(),
});

const validateDateOfBirth = (dateOfBirth: string | Dayjs) => {
  if (dayjs(dateOfBirth).isBefore(threeYearsAgo().format('MM/DD/YYYY'))) {
    return 'dob3YearValidationMessage';
  }
  if (dayjs(dateOfBirth).isAfter(dayjs().format('MM/DD/YYYY'))) {
    return 'dobFutureDateMessage';
  }
  if (datePickerMinDateMaxDateValidate(dateOfBirth)) {
    return 'datePickerMinDateMaxDateValidate';
  }
  return null;
};

const validateForm = (values: AddNewReferral) => {
  const errors: Partial<AddNewReferral> = {};
  if (values.dateOfBirth) {
    const error = validateDateOfBirth(values.dateOfBirth);
    if (error) {
      errors.dateOfBirth = error;
    }
  }

  if (values.referralBeginDate) {
    if (dayjs(values.referralBeginDate).isAfter(dayjs().format('MM/DD/YYYY'))) {
      errors.referralBeginDate = 'validDate';
    }
  }

  if (values.referralContact.referralContactDate) {
    if (
      dayjs(values.referralContact.referralContactDate).isBefore(
        values.dateOfBirth,
      ) ||
      dayjs(values.referralContact.referralContactDate).isAfter(
        dayjs().format('MM/DD/YYYY'),
      )
    ) {
      errors.referralContact = errors.referralContact || {
        referralContactDate: '',
        referralContactPhone: '',
        referralContactFax: '',
        referralContactEmail: '',
        referralContactType: '',
        referralContactJobTitle: '',
        referralContactName: '',
        referralSource: '',
        referralReason: '',
        isDcf: false,
        notes: '',
      };
      errors.referralContact.referralContactDate = 'validDate';
    }
    if (
      datePickerMinDateMaxDateValidate(
        values.referralContact.referralContactDate,
      )
    ) {
      errors.referralContact = errors.referralContact || {
        referralContactDate: '',
        referralContactPhone: '',
        referralContactFax: '',
        referralContactEmail: '',
        referralContactType: '',
        referralContactJobTitle: '',
        referralContactName: '',
        referralSource: '',
        referralReason: '',
        isDcf: false,
        notes: '',
      };
      errors.referralContact.referralContactDate =
        'datePickerMinDateMaxDateValidate';
    }
  }

  if (values.referralBeginDate) {
    if (datePickerMinDateMaxDateValidate(values.referralBeginDate)) {
      errors.referralBeginDate = 'datePickerMinDateMaxDateValidate';
    }
  }
  if (values.beginDate) {
    if (datePickerMinDateMaxDateValidate(values.beginDate)) {
      errors.beginDate = 'datePickerMinDateMaxDateValidate';
    }
  }

  return errors;
};

const nameValidation = (value: string): string | undefined => {
  const trimmedValue = value?.replace(/^\s+/, '');
  const regex = /^[A-Za-z!,'. -]*$/;
  if (regex.test(trimmedValue)) {
    return trimmedValue;
  }
};

export const referralTypeOptions: RadioButtonData[] = [
  { defaultLabel: 'New', labelId: 'newText', value: 'New' },
  {
    defaultLabel: 'Incoming Transfer',
    labelId: 'transferText',
    value: 'Incoming Transfer',
  },
  { defaultLabel: 'EIPP Child', labelId: 'eippChildText', value: 'EIPP Child' },
  { defaultLabel: 'EIPP Mom', labelId: 'eippMomText', value: 'EIPP Mom' },
];

const referrals: AddNewReferral = {
  savedAsDraft: false,
  id: '',
  firstName: '',
  lastName: '',
  middleName: '',
  sex: '',
  dateOfBirth: null,
  eligibilityDate: null,
  address1: '',
  address2: '',
  zipCode: { id: '', label: '' },
  city: '',
  state: '',
  ifspDueDate: null,
  referralBeginDate: null,
  referralEndDate: null,
  beginDate: null,
  rollOverDate: null,
  referralType: 'New',
  ageInMonths: '',
  elProgram: '',
  elContactNumber: '',
  remainingAssessmentHours: '00:00',
  createdBy: '',
  parents: [
    {
      firstName: '',
      lastName: '',
      email: '',
      homePhoneNumber: '',
      relationShipToClient: '',
      address: {
        address1: '',
        address2: '',
        zipCode: { id: '', label: '' },
        city: '',
        state: '',
      },
      isAddressSameAsClientAddress: false,
    },
  ],
  duplicateClient: null,
  similarClients: [],
  clientId: null,
  dphId: null,
  referralContact: {
    referralContactDate: null,
    referralContactName: '',
    referralContactType: 'otherText',
    referralContactJobTitle: '',
    referralContactEmail: '',
    referralContactFax: '',
    referralContactPhone: '',
    referralSource: '',
    referralReason: '',
    isDcf: false,
    notes: '',
  },
  isAddressSameAsClientAddress: false,
  referralDiagnosisCode: null,
};

const AddNewReferralDetails = () => {
  const rctl = useIntl();
  const [openModal, setOpenModal] = React.useState(false);
  const formRef = React.useRef<FormikProps<AddNewReferral>>(null);
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [actionButton, setActionButton] = React.useState(true);
  const [titleText, setTitleText] = useState<string>('');
  // Will be removed in future
  // const [showDuplicateModel, setShowDuplicateModel] = useState<boolean>(false);
  const [showSimilarClientsModal, setShowSimilarClientsModal] =
    useState<boolean>(false);
  const [similarClients, setSimilarClients] = useState<SimilarClient[]>([]);
  const [pointerEvents, setPointerEvents] = useState<'none' | 'auto'>('auto');
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const { lookups } = useContext(LookUpContext);
  const [referralReasons, setReferralReasons] = useState<OptionType[]>([]);
  const [referralSources, setReferralSources] = useState<OptionType[]>([]);
  const [dcfChecked, setDcfChecked] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedDiagnosisCodesList, setSelectedDiagnosisCodesList] =
    React.useState<OptionType | null>(null);
  const [diagnosisCodesList, setDiagnosisCodesList] = React.useState<
    OptionType[]
  >([]);

  React.useEffect(() => {
    handleDiagnosisDataGetAPI();
  }, []);

  const handleDiagnosisDataGetAPI = async () => {
    getCodesAndDescription().then((response: Codes[]) => {
      const diagnosisCodeList: OptionType[] = [];
      if (response.length > 0) {
        response.forEach((codeDetails) => {
          diagnosisCodeList.push({
            id: codeDetails.code,
            label: codeDetails.isUnderlined
              ? `${codeDetails.code} - * ${codeDetails.description}`
              : `${codeDetails.code} - ${codeDetails.description}`,
          });
        });
        setDiagnosisCodesList(diagnosisCodeList);
      }
    });
  };

  useEffect(() => {
    if (lookups === null) {
      return;
    }
    setReferralReasons(() => {
      return lookups.referralReason.map((reason) => {
        return {
          label: reason.description,
          id: reason.code,
        };
      });
    });
    setReferralSources(() => {
      return lookups.referralSource.map((source) => {
        return {
          label: source.description,
          id: source.code,
        };
      });
    });
  }, [lookups]);

  const handleAddAsClient = useCallback(
    debounce((values: AddNewReferral) => {
      saveReferralAndNavigate(values, false, false);
    }, 500),
    [],
  );

  const handleDraft = useCallback(
    debounce((values: AddNewReferral) => {
      if (
        values.firstName == '' ||
        values.lastName == '' ||
        values.sex == '' ||
        values.dateOfBirth == null
      ) {
        setOpen(true);
        setToastrVariable('error');
        setToastrId('Referral.saveAsDraftError');
        setToastrDefaultMessage(
          'First name, Last name, sex and date of birth is required to save the referral as draft.',
        );
      } else {
        saveDraftReferralAndNavigate(values);
      }
    }, 500),
    [],
  );

  const Gender = ['Female', 'Male', 'Unassigned'];
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (formRef.current) {
      if (diagnosisCodesList.length > 0) {
        const response = formRef.current?.values;
        const diagnosisCode = response.referralDiagnosisCode;
        if (diagnosisCode) {
          const code = diagnosisCodesList.find(
            (item) => item.id === diagnosisCode,
          );
          if (code) {
            setSelectedDiagnosisCodesList(code);
          }
        }
      }
    }
  }, [diagnosisCodesList, formRef.current?.values]);

  useEffect(() => {
    if (localStorage.getItem('referralId')) {
      toggleLoader(true);
      getDraftById()
        .then((response: AddNewReferral) => {
          toggleLoader(false);
          response.zipCode =
            response.zipCode !== null
              ? ({ id: 'asd', label: response.zipCode as string } as OptionType)
              : { id: '', label: '' };
          response.parents = response.parents.map((parent) => {
            return {
              ...parent,
              address: {
                ...parent.address,
                zipCode:
                  parent.address.zipCode !== null &&
                  parent.address.zipCode !== ''
                    ? { id: 'aa', label: parent.address.zipCode as string }
                    : { id: 'aa', label: '' },
              },
            };
          });
          setDcfChecked(response.referralContact.isDcf);
          response.sex = convertGenderToFrontend(response.sex) ?? '';
          formRef.current?.setValues(response, true);
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          setToastrId('failed');
          setToastrDefaultMessage(error);
        });
    }
  }, [localStorage.getItem('referralId')]);

  const dateExtension = (date: string) => {
    const originalDate = new Date(date);
    originalDate.setDate(originalDate.getDate() + 45);
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
    const day = originalDate.getDate().toString().padStart(2, '0');
    const year = originalDate.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const saveReferralAndNavigate = (
    request: AddNewReferral,
    createDuplicateClient: boolean,
    skipSimilarClientCheck: boolean,
  ) => {
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (userId) {
      request.createdBy = userId;
    }
    request.firstName = request.firstName.trim();
    request.middleName = request.middleName.trim();
    request.lastName = request.lastName.trim();
    request.sex = convertGenderToBackend(request.sex) ?? '';
    request.referralContact.referralContactType = rctl.formatMessage({
      id: request.referralContact.referralContactType,
    });
    request.parents.forEach((parent) => {
      parent.address.zipCode = (parent.address.zipCode as OptionType).label;
    });
    request.zipCode = (request.zipCode as OptionType).label;
    if (request.remainingAssessmentHours) {
      request.remainingAssessmentHours = convertTimeDuration(
        request.remainingAssessmentHours,
        false,
      );
    }

    request.duplicateClient = null;
    toggleLoader(true);
    addOrUpdateReferral(request, createDuplicateClient, skipSimilarClientCheck)
      .then((response: AddNewReferral) => {
        if (response.similarClients?.length > 0) {
          response.similarClients.forEach((item) => (item.isChecked = false));
          toggleLoader(false);
          setShowSimilarClientsModal(true);
          setSimilarClients(response.similarClients);
          // Will be removed in future if there is duplicate functionality
          // formRef.current?.setValues({
          //   ...formRef.current?.values,
          //   duplicateClient: response.duplicateClient,
          // });
          // setTimeout(() => {
          //   toggleLoader(false);
          //   setShowDuplicateModel(true);
          // }, 200);
        } else {
          setShowSimilarClientsModal(false);
          setOpen(true);
          setToastrVariable('success');
          setToastrId('Referral.addsuccess');
          setToastrDefaultMessage('Referral added successfully');
          setTimeout(() => {
            toggleLoader(false);
            navigateToClientCreation(response);
          }, 1000);
        }
      })
      .catch((error) => {
        handleSaveReferralError(error, request.id);
      });
  };

  const saveDraftReferralAndNavigate = (request: AddNewReferral) => {
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (userId) {
      request.createdBy = userId;
    }
    request.firstName = request.firstName.trim();
    request.middleName = request.middleName.trim();
    request.lastName = request.lastName.trim();
    request.id = request.id ? request.id : '';
    request.sex = convertGenderToBackend(request.sex) ?? '';
    request.referralContact.referralContactType = rctl.formatMessage({
      id: request.referralContact.referralContactType,
    });
    request.parents.forEach((parent) => {
      parent.address.zipCode = (parent.address.zipCode as OptionType).label;
    });
    request.zipCode = (request.zipCode as OptionType).label;
    if (request.remainingAssessmentHours) {
      request.remainingAssessmentHours = convertTimeDuration(
        request.remainingAssessmentHours,
        false,
      );
    }
    request.duplicateClient = null;
    toggleLoader(true);
    addOrUpdateReferralDraft(request)
      .then(() => {
        setOpen(true);
        setToastrVariable('success');
        setToastrId('Referral.draftSuccess');
        setToastrDefaultMessage('Draft saved successfully');
        setTimeout(() => {
          toggleLoader(false);
          navigate(REFERRAL_DASHBOARD_ROUTE_NAME);
        }, 1000);
      })
      .catch((error) => {
        handleSaveReferralError(error, request.id);
      });
  };

  const handleSaveReferralError = (
    error: ApiError | ApiMessage | object,
    id: string,
  ) => {
    toggleLoader(false);
    setToastrVariable('error');
    setOpen(true);
    if (isCustomError(error)) {
      const apiError = error as ApiError;
      setToastrId(apiError.id);
      setToastrDefaultMessage(apiError.message);
    } else {
      const response = error as ApiMessage;
      if (response.code === 409) {
        setToastrDefaultMessage(
          'This referral is added to the system with the same referral date. Choose a different referral date.',
        );
        setToastrId('referralConflictMessage');
      } else {
        setToastrId(
          id === '' ? 'Referral.addFailure' : 'Referral.updateFailure',
        );
        setToastrDefaultMessage(
          id === '' ? 'Failed to add referral' : 'Failed to update referral',
        );
      }
    }
  };

  const getAllReferralsBasedOnSelectedClient = async (
    clientId: string,
    defaultReferralId: string,
  ) => {
    toggleLoader(true);
    if (clientId) {
      await getAllReferrals(clientId, defaultReferralId)
        .then(async (response: ReferralResponseType[]) => {
          if (response) {
            localStorage.setItem(
              'clientReferralDetails',
              JSON.stringify(response),
            );
          }
          toggleLoader(false);
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('clientLoadError');
            setToastrDefaultMessage('Failed to get client details');
          }
        });
    }
  };

  const navigateToClientCreation = async (values: AddNewReferral) => {
    if (values.clientId !== null) {
      sessionStorage.setItem('tabName', '0');
      localStorage.setItem('ClientId', values.clientId);
      localStorage.setItem(
        CLIENT_NAME_KEY,
        getFullName(values.firstName.trim(), values.lastName.trim()),
      );
      localStorage.setItem(REFERRAL_ID_KEY, values.id);
      localStorage.setItem('defaultReferralId', values.id);
      await getAllReferralsBasedOnSelectedClient(values.clientId, values.id);
      if (checkPermissionForFeature('backend.clients', 'viewPermission')) {
        navigate(CLIENTS_ROUTE_NAME, {
          replace: true,
          state: { clientId: values.clientId, isCreate: true },
        });
      } else {
        navigate(REFERRAL_DASHBOARD_ROUTE_NAME);
      }
    }
  };

  useEffect(() => {
    const stateParams = location.state as ReferralNavStateProps;
    if (stateParams) {
      const { editable, data } = stateParams;
      if (editable) {
        setActionButton(true);
        setPointerEvents('auto');
        setTitleText('Referraldashboard.EditTitletext');
      } else {
        setActionButton(false);
        setPointerEvents('none');
        setTitleText('Referraldashboard.ViewTitletext');
      }
      formRef.current?.setValues(data);
    } else {
      setActionButton(true);
      setPointerEvents('auto');
      setTitleText('Referraldashboard.AddTitletext');
    }
  }, []);

  return (
    <Box component="main" style={{ pointerEvents: pointerEvents }}>
      {showSimilarClientsModal && (
        <SimilarClientsModal
          showModal={showSimilarClientsModal}
          onModalClose={() => {
            setShowSimilarClientsModal(false);
          }}
          referralBeginDate={formRef.current?.values?.referralBeginDate ?? null}
          data={similarClients}
          addNewReferral={(id) => {
            const values = JSON.parse(
              JSON.stringify(formRef.current?.values),
            ) as AddNewReferral;
            if (values) {
              values.clientId = id;
              saveReferralAndNavigate(values, false, true);
            }
          }}
          addNewClient={() => {
            const values = JSON.parse(
              JSON.stringify(formRef.current?.values),
            ) as AddNewReferral;
            if (values) {
              saveReferralAndNavigate(values, true, true);
            }
          }}
        />
      )}
      {/* Will be removed in the future when we have duplicate client functionality
      {showDuplicateModel && formRef.current?.values && (
        <DuplicateClientModel
          enable={showDuplicateModel}
          referral={formRef.current?.values}
          onModelClose={() => {
            setShowDuplicateModel(!showDuplicateModel);
            navigate(-1);
          }}
          updateClient={(referral: AddNewReferral) => {
            setShowDuplicateModel(!showDuplicateModel);
            saveReferralAndNavigate(
              JSON.parse(JSON.stringify(referral)) as AddNewReferral,
              true,
            );
          }}
        />
      )} */}
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Box component="section">
        <Box className="rowContainer">
          <CustomBackArrow
            onClick={() => navigate(REFERRAL_DASHBOARD_ROUTE_NAME)}
          />
          <Box component="div" className="ml-md">
            <TitleText labelid={titleText} defaultlabel="Referral" />
          </Box>
        </Box>
        <Box component="div">
          <Formik
            innerRef={formRef}
            validateOnMount={true}
            enableReinitialize={true}
            initialValues={referrals}
            validateOnBlur={true}
            validateOnChange={false}
            validationSchema={validationSchema}
            validate={validateForm}
            onSubmit={(values) => {
              const request = JSON.parse(
                JSON.stringify(values),
              ) as AddNewReferral;
              handleAddAsClient(request);
            }}
          >
            {({
              setFieldValue,
              handleChange,
              handleSubmit,
              handleBlur,
              values,
              errors,
              touched,
              initialValues,
            }) => (
              <Box component="div" onBlur={handleBlur}>
                <Card className="formCardview">
                  <Grid
                    container
                    rowSpacing={'24px'}
                    columnSpacing={'40px'}
                    className="mb-lg"
                  >
                    <Grid item xs={12} lg={12}>
                      <MediumTypography
                        className="mainText-lg"
                        labelid="QuestionnaireClientInfo.SubHeaderOnetext2"
                        defaultlabel="Referral Information"
                      />
                    </Grid>
                    <Grid item xs={4.5} lg={4.5}>
                      <Textinput
                        name="firstName"
                        labelid="AddNewReferralDetails.First"
                        defaultlabelid="First Name"
                        Value={values.firstName}
                        Required={true}
                        inputProps={{
                          maxLength: 40,
                        }}
                        handlechange={(value: string) => {
                          const validatedValue = nameValidation(value);
                          if (validatedValue !== undefined) {
                            setFieldValue('firstName', validatedValue);
                          }
                        }}
                      />

                      {errors.firstName && touched.firstName && (
                        <MediumTypography
                          labelid={errors.firstName}
                          defaultlabel="First Name is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                    <Grid item xs={4.5} lg={4.5}>
                      <Textinput
                        name="middleName"
                        Value={values.middleName}
                        labelid="Clientpage.Middle"
                        defaultlabelid="Middle Name"
                        inputProps={{
                          maxLength: 20,
                        }}
                        handlechange={(value: string) => {
                          const validatedValue = nameValidation(value);
                          if (validatedValue !== undefined) {
                            setFieldValue('middleName', validatedValue);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={4.5} lg={4.5}>
                      <Textinput
                        name="lastName"
                        Value={values.lastName}
                        labelid="AddNewReferralDetails.Last"
                        defaultlabelid="Last Name"
                        Required={true}
                        inputProps={{
                          maxLength: 40,
                        }}
                        handlechange={(value: string) => {
                          const validatedValue = nameValidation(value);
                          if (validatedValue !== undefined) {
                            setFieldValue('lastName', validatedValue);
                          }
                        }}
                      />
                      {errors.lastName && touched.lastName && (
                        <MediumTypography
                          labelid={errors.lastName}
                          defaultlabel="Last Name is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                    <Grid item xs={4.5} lg={4.5}>
                      <SelectComponent
                        labelid="Clientpage.Sex"
                        defaultlabelid="sex"
                        name="sex"
                        value={values.sex}
                        handleChange={(value: string) => {
                          setFieldValue('sex', value);
                        }}
                        names={Gender}
                        Required
                      />
                      {errors.sex && touched.sex && (
                        <MediumTypography
                          labelid={errors.sex}
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                    <Grid item xs={4.5} lg={4.5}>
                      <DatePickerComponent
                        value={dayjs(values.dateOfBirth)}
                        disableFuture={true}
                        minDate={threeYearsAgo()}
                        maxDate={dayjs()}
                        labelid="AddNewReferralDetails.dateofbirth"
                        defaultlabelid="Date of Birth"
                        handlechange={(date: Dayjs | null) => {
                          const formattedDate =
                            dayjs(date).format('MM/DD/YYYY');
                          if (date === null) {
                            formRef.current?.setValues({
                              ...values,
                              dateOfBirth: null,
                              referralContact: {
                                ...values.referralContact,
                                referralContactDate: null,
                              },
                            });
                          } else {
                            formRef.current?.setValues({
                              ...values,
                              dateOfBirth: formattedDate,
                              referralContact: {
                                ...values.referralContact,
                                referralContactDate: null,
                              },
                            });
                          }
                        }}
                        required={true}
                      />
                      {errors.dateOfBirth && touched.dateOfBirth && (
                        <MediumTypography
                          labelid={errors.dateOfBirth}
                          className="errorText-md"
                        />
                      )}
                    </Grid>

                    <Grid item xs={4.5} lg={4.5}>
                      <DatePickerComponent
                        value={dayjs(values.referralBeginDate)}
                        labelid="AddNewReferralDetails.referralBeginDate"
                        maxDate={dayjs()}
                        defaultlabelid="Referral Begin Date"
                        handlechange={(date: Dayjs | null) => {
                          const formattedDate =
                            dayjs(date).format('MM/DD/YYYY');
                          if (date === null) {
                            formRef.current?.setValues({
                              ...values,
                              referralBeginDate: null,
                              ifspDueDate: null,
                            });
                          } else {
                            formRef.current?.setValues({
                              ...values,
                              referralBeginDate: formattedDate,
                              ifspDueDate: dateExtension(formattedDate),
                            });
                          }
                        }}
                        required={true}
                      />
                      {values.ifspDueDate && (
                        <MediumTypography
                          label={`${rctl.formatMessage({
                            id: 'AddNewReferralDetails.ifspDueDate',
                          })}: ${values.ifspDueDate as string}`}
                          fontweight={600}
                          sxProps={{
                            color: '#2A4241',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            mt: '4px',
                          }}
                        />
                      )}
                      {errors.referralBeginDate &&
                        touched.referralBeginDate && (
                          <MediumTypography
                            labelid={errors.referralBeginDate}
                            className="errorText-md"
                          />
                        )}
                    </Grid>

                    <Grid item xs={4.5} lg={4.5} display={'none'}>
                      <DatePickerComponent
                        value={dayjs(values.referralEndDate)}
                        disableFuture={false}
                        minDate={dayjs()}
                        labelid="AddNewReferralDetails.referralEndDate"
                        defaultlabelid="Referral End Date"
                        handlechange={(date: Dayjs | null) => {
                          const formattedDate =
                            dayjs(date).format('MM/DD/YYYY');
                          if (date === null) {
                            formRef.current?.setValues({
                              ...values,
                              referralEndDate: null,
                            });
                          } else {
                            formRef.current?.setValues({
                              ...values,
                              referralEndDate: formattedDate,
                            });
                          }
                        }}
                      />
                    </Grid>
                    <Grid container item xs={9}>
                      <AddressForm
                        address1PlaceholderId="address1Text"
                        address2PlaceholderId="address2Text"
                        zipCodePlaceholderId="Clientpage.Zip"
                        cityPlaceholderId="Clientpage.City"
                        statePlaceholderId="Clientpage.State"
                        value={{
                          address1: values.address1,
                          address2: values.address2,
                          zipCode: values.zipCode,
                          city: values.city,
                          state: values.state,
                        }}
                        isClient={true}
                        disableForm={false}
                        formRequired={true}
                        formikErrors={{
                          address1: errors.address1,
                          address2: errors.address2,
                          zipCode: errors.zipCode,
                          city: errors.city,
                          state: errors.state,
                        }}
                        formikTouched={{
                          address1: touched.address1,
                          address2: touched.address2,
                          zipCode: touched.zipCode,
                          city: touched.city,
                          state: touched.state,
                        }}
                        handleAddressUpdate={(newAddress) => {
                          formRef.current?.setValues({
                            ...values,
                            address1: newAddress.address1,
                            address2: newAddress.address2,
                            zipCode: newAddress.zipCode,
                            city: newAddress.city,
                            state: newAddress.state,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box className="horizontalLine" />

                  <Grid container rowSpacing={'24px'} columnSpacing={'40px'}>
                    <Grid item xs={12} display={'flex'} alignItems={'center'}>
                      <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <MediumTypography
                            labelid="AddNewReferralDetails.referralType"
                            defaultlabel="Referral Type"
                            sxProps={{
                              color: '#2A4241',
                              fontWeight: 'bold',
                              fontSize: '14px',
                              marginRight: '16px',
                            }}
                          />
                          <CustomRadioButton
                            data={referralTypeOptions}
                            value={values.referralType}
                            onSelectItem={(value) => {
                              if (value !== null) {
                                setFieldValue('referralType', value);
                              }
                            }}
                          />
                        </Box>
                        {errors.referralType && touched.referralType && (
                          <MediumTypography
                            labelid={errors.referralType}
                            className="errorText-md"
                          />
                        )}
                      </Box>
                    </Grid>
                    {values.referralType === 'Incoming Transfer' && (
                      <>
                        <Grid item xs={4.5} lg={4.5}>
                          <Textinput
                            name="elProgram"
                            Value={values.elProgram}
                            labelid="AddNewReferralDetails.elProgram"
                            defaultlabelid="EI Program"
                            inputProps={{
                              maxLength: 100,
                            }}
                            handlechange={(value: string) => {
                              setFieldValue('elProgram', value);
                            }}
                            Required={true}
                          />
                          {errors.elProgram && touched.elProgram && (
                            <MediumTypography
                              labelid={errors.elProgram}
                              className="errorText-md"
                            />
                          )}
                        </Grid>
                        <Grid item xs={4.5} lg={4.5}>
                          <Textinput
                            name="elContactNumber"
                            Value={values.elContactNumber}
                            labelid="AddNewReferralDetails.elContactNumber"
                            defaultlabelid="EI Contact Number"
                            handlechange={(value: string) => {
                              setFieldValue(
                                'elContactNumber',
                                formatPhoneNumber(value),
                              );
                            }}
                            Required={true}
                          />
                          {errors.elContactNumber &&
                            touched.elContactNumber && (
                              <MediumTypography
                                labelid={errors.elContactNumber}
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                        <Grid item xs={4.5} lg={4.5}>
                          <DurationPicker
                            labelId="AddNewReferralDetails.assessmentHoursText"
                            defaultLabel="Remaining Assessment Hours"
                            maxDuration={{ hour: 20, minutes: 0 }}
                            duration={formatStringDateInDecimals(
                              values.remainingAssessmentHours,
                            )}
                            required={true}
                            interval={25}
                            setDuration={(duration) => {
                              setFieldValue(
                                'remainingAssessmentHours',
                                formatTimeDurationInDecimals(duration),
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={4.5} lg={4.5}>
                          <DatePickerComponent
                            value={dayjs(values.beginDate)}
                            labelid="AddNewReferralDetails.beginDate"
                            defaultlabelid="Begin Date"
                            handlechange={(date: Dayjs | null) => {
                              const formattedDate =
                                dayjs(date).format('MM/DD/YYYY');
                              if (date === null) {
                                formRef.current?.setValues({
                                  ...values,
                                  beginDate: null,
                                  rollOverDate: null,
                                });
                              } else {
                                formRef.current?.setValues({
                                  ...values,
                                  beginDate: formattedDate,
                                  rollOverDate: dayjs(date)
                                    .add(1, 'year')
                                    .format('MM/DD/YYYY'),
                                });
                              }
                            }}
                            required={true}
                          />
                          {values.rollOverDate && (
                            <MediumTypography
                              label={`${rctl.formatMessage({
                                id: 'AddNewReferralDetails.rollOverDate',
                              })}: ${values.rollOverDate as string}`}
                              fontweight={600}
                              sxProps={{
                                color: '#2A4241',
                                fontWeight: 'bold',
                                fontSize: '14px',
                                mt: '4px',
                              }}
                            />
                          )}
                          {errors.beginDate && touched.beginDate && (
                            <MediumTypography
                              labelid={errors.beginDate}
                              className="errorText-md"
                            />
                          )}
                        </Grid>
                        <Grid item xs={4.5} lg={4.5}>
                          <DatePickerComponent
                            value={dayjs(values.eligibilityDate)}
                            labelid="AddNewReferralDetails.eligibilityDate"
                            defaultlabelid="Eligibility Date Date"
                            handlechange={(date: Dayjs | null) => {
                              const formattedDate =
                                dayjs(date).format('MM/DD/YYYY');
                              if (date === null) {
                                formRef.current?.setValues({
                                  ...values,
                                  eligibilityDate: null,
                                });
                              } else {
                                formRef.current?.setValues({
                                  ...values,
                                  eligibilityDate: formattedDate,
                                });
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={4.5} lg={4.5}></Grid>
                      </>
                    )}
                  </Grid>

                  <Box className="horizontalLine" />

                  <Grid container rowSpacing={'24px'} columnSpacing={'40px'}>
                    <Grid item xs={12} display={'flex'} alignItems={'center'}>
                      <Grid item xs={6} lg={6}>
                        <AutocompleteSearch
                          textDecoration={
                            selectedDiagnosisCodesList?.label.includes('- *')
                              ? 'underline'
                              : 'none'
                          }
                          labelId={'referralDiagnosisCodeText'}
                          defaultId="Referral Diagnosis Code"
                          optionHintId={'noBillingDiagnosisCodesFound'}
                          renderOption={(props, option: OptionType) => {
                            const parts = option.label.split(' - ');
                            let beforeHyphen = '';
                            let afterHyphen = '';

                            if (parts.length > 1) {
                              beforeHyphen = parts[0];
                              afterHyphen = parts?.slice(1)?.join(' - ');
                            } else {
                              beforeHyphen = option.label;
                            }

                            const underlineStyle = afterHyphen.includes('*')
                              ? 'underline'
                              : 'none';

                            return (
                              <li {...props}>
                                {parts.length > 1 ? (
                                  <>
                                    <span>{beforeHyphen} - </span>
                                    <span
                                      style={{
                                        textDecoration: underlineStyle,
                                      }}
                                    >
                                      {afterHyphen}
                                    </span>
                                  </>
                                ) : (
                                  <span
                                    style={{
                                      textDecoration: underlineStyle,
                                    }}
                                  >
                                    {beforeHyphen}
                                  </span>
                                )}
                              </li>
                            );
                          }}
                          data={diagnosisCodesList}
                          selectedValue={selectedDiagnosisCodesList}
                          onSelectItem={(value: OptionType | null) => {
                            if (value === null) {
                              setSelectedDiagnosisCodesList(null);
                              setFieldValue('referralDiagnosisCode', null);
                            } else {
                              setSelectedDiagnosisCodesList(value);
                              setFieldValue('referralDiagnosisCode', value.id);
                            }
                          }}
                          disableLabel={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Box className="horizontalLine" />

                  <Grid container rowSpacing={'24px'} columnSpacing={'40px'}>
                    <Grid item>
                      <ReferralParentComponent
                        headerTitleId={
                          'AddNewReferralDetails.ParentInformation'
                        }
                        defaultHeaderTitle={'Parent/Guardian Information'}
                        formikErrors={formRef.current?.values.parents.map(
                          (parent, index) => {
                            if (parent.isAddressSameAsClientAddress) {
                              const parentErrors = errors.parents?.[index] as
                                | FormikErrors<ReferralParent>
                                | undefined;

                              return {
                                email: parentErrors?.email,
                                firstName: parentErrors?.firstName,
                                homePhoneNumber: parentErrors?.homePhoneNumber,
                                isAddressSameAsClientAddress:
                                  parentErrors?.isAddressSameAsClientAddress,
                                lastName: parentErrors?.lastName,
                                relationShipToClient:
                                  parentErrors?.relationShipToClient,
                                address: {
                                  address1: errors.address1,
                                  address2: errors.address2,
                                  city: errors.city,
                                  state: errors.state,
                                  zipCode: errors.zipCode,
                                },
                              } as FormikErrors<ReferralParent>;
                            } else {
                              return errors.parents?.[
                                index
                              ] as FormikErrors<ReferralParent>;
                            }
                          },
                        )}
                        onDeleteClick={(index: number) => {
                          formRef.current?.setValues({
                            ...values,
                            parents: values.parents.filter(
                              (__, i) => i !== index,
                            ),
                          });
                        }}
                        formikTouched={formRef.current?.values.parents.map(
                          (parent, index) => {
                            if (parent.isAddressSameAsClientAddress) {
                              return {
                                email: touched.parents?.[index]?.email,
                                firstName: touched.parents?.[index]?.firstName,
                                homePhoneNumber:
                                  touched.parents?.[index]?.homePhoneNumber,
                                isAddressSameAsClientAddress:
                                  touched.parents?.[index]
                                    ?.isAddressSameAsClientAddress,
                                lastName: touched.parents?.[index]?.lastName,
                                relationShipToClient:
                                  touched.parents?.[index]
                                    ?.relationShipToClient,
                                address: {
                                  address1: touched.address1,
                                  address2: touched.address2,
                                  city: touched.city,
                                  state: touched.state,
                                  zipCode: touched.zipCode,
                                },
                              } as FormikTouched<ReferralParent>;
                            } else {
                              return touched.parents?.[index];
                            }
                          },
                        )}
                        clientAddress={{
                          address1: values.address1,
                          address2: values.address2,
                          zipCode: values.zipCode,
                          city: values.city,
                          state: values.state,
                        }}
                      />

                      {values.parents.length < 3 && (
                        <ButtonComponent
                          className="btn-add-code mt-md"
                          variantType="contained"
                          type="submit"
                          label={`+ ${rctl.formatMessage({
                            id: 'Parent.addParentText',
                            defaultMessage: 'Add Parent',
                          })}`}
                          defaultLabelId="+ Add Parent"
                          onClick={() => {
                            if (
                              formRef.current !== null &&
                              formRef.current.values.parents.length < 3
                            ) {
                              formRef.current.setValues({
                                ...values,
                                parents: [
                                  ...values.parents,
                                  {
                                    firstName: '',
                                    lastName: '',
                                    email: '',
                                    homePhoneNumber: '',
                                    relationShipToClient: '',
                                    address: {
                                      address1: '',
                                      address2: '',
                                      zipCode: { id: '', label: '' },
                                      city: '',
                                      state: '',
                                    },
                                    isAddressSameAsClientAddress: false,
                                  },
                                ],
                              });
                            }
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Box className="horizontalLine"></Box>

                  <MediumTypography
                    labelid="AddNewReferralDetails.referalContactText"
                    defaultlabel="Referral Contact"
                    sxProps={{
                      color: '#2A4241',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      marginBottom: '16px',
                    }}
                  />

                  <Grid container rowSpacing={'24px'} columnSpacing={'40px'}>
                    <Grid item xs={4.5} lg={4.5}>
                      <Textinput
                        name="referralContactName"
                        labelid="AddNewReferralDetails.Name"
                        defaultlabelid="Name"
                        Value={values.referralContact.referralContactName}
                        inputProps={{
                          maxLength: 50,
                        }}
                        handlechange={(value: string) => {
                          const validatedValue = nameValidation(value);
                          if (validatedValue !== undefined) {
                            setFieldValue(
                              'referralContact.referralContactName',
                              validatedValue,
                            );
                          }
                        }}
                      />
                      {errors?.referralContact?.referralContactName &&
                        touched?.referralContact?.referralContactName && (
                          <MediumTypography
                            labelid={
                              errors.referralContact?.referralContactName
                            }
                            defaultlabel="Name is Required"
                            className="errorText-md"
                          />
                        )}
                    </Grid>

                    <Grid item xs={4.5} lg={4.5}>
                      <Textinput
                        name="referralContactJobTitle"
                        Value={values.referralContact.referralContactJobTitle}
                        labelid="AddNewReferralDetails.JobTitle"
                        defaultlabelid="Job Title"
                        inputProps={{
                          maxLength: 50,
                        }}
                        handlechange={(value: string) => {
                          const validatedValue = nameValidation(value);
                          if (validatedValue !== undefined) {
                            setFieldValue(
                              'referralContact.referralContactJobTitle',
                              validatedValue,
                            );
                          }
                        }}
                      />
                      {errors?.referralContact?.referralContactJobTitle &&
                        touched?.referralContact?.referralContactJobTitle && (
                          <MediumTypography
                            labelid={
                              errors.referralContact?.referralContactJobTitle
                            }
                            defaultlabel="Job Title is Required"
                            className="errorText-md"
                          />
                        )}
                    </Grid>
                    <Grid item xs={4.5} lg={4.5} className="pt-none">
                      <Box component="div" className="flex__">
                        <CustomCheckBox
                          value={dcfChecked}
                          style={{ marginTop: '24px' }}
                          onCheckBoxClick={(value) => {
                            setDcfChecked(value);
                            setFieldValue('referralContact.isDcf', value);
                          }}
                        />
                        <MediumTypography
                          labelid="AddNewReferralDetails.DCF"
                          defaultlabel="DCF"
                          sxProps={{
                            color: '#2A4241',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            marginTop: '30px',
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={4.5} lg={4.5}></Grid>

                    <Grid item xs={4.5} lg={4.5}>
                      <DatePickerComponent
                        value={
                          values.referralContact.referralContactDate !== null
                            ? dayjs(values.referralContact.referralContactDate)
                            : null
                        }
                        labelid="AddNewReferralDetails.referralDate"
                        disableFuture={true}
                        minDate={dayjs(values.dateOfBirth)}
                        defaultlabelid="Referral Contact Date"
                        handlechange={(date: Dayjs | null) => {
                          const formattedDate =
                            dayjs(date).format('MM/DD/YYYY');
                          if (date === null) {
                            formRef.current?.setValues({
                              ...values,
                              referralContact: {
                                ...values.referralContact,
                                referralContactDate: null,
                              },
                            });
                          } else {
                            formRef.current?.setValues({
                              ...values,
                              referralContact: {
                                ...values.referralContact,
                                referralContactDate: formattedDate,
                              },
                            });
                          }
                        }}
                        required={true}
                      />
                      {errors.referralContact?.referralContactDate &&
                        touched.referralContact?.referralContactDate && (
                          <MediumTypography
                            labelid={
                              errors.referralContact?.referralContactDate
                            }
                            className="errorText-md"
                          />
                        )}
                    </Grid>
                    <Grid item xs={4.5} lg={4.5}>
                      <DropdownComponent
                        Required
                        names={referralSources}
                        labelid="AddNewReferralDetails.referralSource"
                        defaultlabelid="Referral Source"
                        value={values.referralContact.referralSource}
                        handleChange={handleChange(
                          'referralContact.referralSource',
                        )}
                      />
                      {errors.referralContact?.referralSource &&
                        touched.referralContact?.referralSource && (
                          <MediumTypography
                            labelid={errors.referralContact?.referralSource}
                            defaultlabel="Referral Source is Required"
                            className="errorText-md"
                          />
                        )}
                    </Grid>

                    <Grid item xs={4.5} lg={4.5}>
                      <DropdownComponent
                        Required
                        names={referralReasons}
                        labelid="AddNewReferralDetails.referralReason"
                        defaultlabelid="Referral Reason"
                        value={values.referralContact.referralReason}
                        handleChange={handleChange(
                          'referralContact.referralReason',
                        )}
                      />

                      {errors.referralContact?.referralReason &&
                        touched.referralContact?.referralReason && (
                          <MediumTypography
                            labelid={errors.referralContact?.referralReason}
                            defaultlabel="Referral Reason is Required"
                            className="errorText-md"
                          />
                        )}
                    </Grid>
                    <Grid item xs={4.5} lg={4.5}>
                      <Textinput
                        name="referralContactEmail"
                        Value={values.referralContact.referralContactEmail}
                        labelid="AddNewReferralDetails.Email"
                        defaultlabelid="Email"
                        Required={false}
                        inputProps={{
                          maxLength: 50,
                        }}
                        handlechange={(value: string) => {
                          setFieldValue(
                            `referralContact.referralContactEmail`,
                            value,
                          );
                        }}
                      />
                      {errors.referralContact?.referralContactEmail &&
                        touched.referralContact?.referralContactEmail && (
                          <MediumTypography
                            labelid={
                              errors.referralContact?.referralContactEmail
                            }
                            defaultlabel="Invalid Email Address"
                            className="errorText-md"
                          />
                        )}
                    </Grid>

                    <Grid item xs={4.5} lg={4.5}>
                      <Textinput
                        name="referralContactPhone"
                        Value={values.referralContact.referralContactPhone}
                        labelid="AddNewReferralDetails.referralPhone"
                        defaultlabelid="Phone"
                        inputProps={{
                          maxLength: 50,
                        }}
                        handlechange={(value: string) => {
                          setFieldValue(
                            'referralContact.referralContactPhone',
                            formatPhoneNumber(value),
                          );
                        }}
                      />
                      {errors?.referralContact?.referralContactPhone &&
                        touched &&
                        touched.referralContact?.referralContactPhone && (
                          <MediumTypography
                            labelid={
                              errors.referralContact?.referralContactPhone
                            }
                            defaultlabel="Phone is Required"
                            className="errorText-md"
                          />
                        )}
                    </Grid>
                    <Grid item xs={4.5} lg={4.5}>
                      <Textinput
                        name="referralContactFax"
                        Value={values.referralContact.referralContactFax}
                        labelid="AddNewReferralDetails.Fax"
                        defaultlabelid="Fax"
                        Required={false}
                        inputProps={{
                          maxLength: 50,
                        }}
                        handlechange={(value: string) => {
                          setFieldValue(
                            'referralContact.referralContactFax',
                            formatPhoneNumber(value),
                          );
                        }}
                      />
                      {errors?.referralContact?.referralContactFax &&
                        touched?.referralContact?.referralContactFax && (
                          <MediumTypography
                            labelid={errors.referralContact?.referralContactFax}
                            defaultlabel="Invalid Fax Number"
                            className="errorText-md"
                          />
                        )}
                    </Grid>

                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        name="notes"
                        placeholder={rctl.formatMessage({
                          id: 'AddNewReferralDetails.notes',
                          defaultMessage: 'Notes',
                        })}
                        Value={values.referralContact.notes}
                        maxLength={2000}
                        handlechange={(value: string) => {
                          setFieldValue('referralContact.notes', value);
                        }}
                      />

                      {errors?.referralContact?.notes &&
                        touched?.referralContact?.notes && (
                          <MediumTypography
                            labelid={errors.referralContact.notes}
                            defaultlabel="Notes is Required"
                            className="errorText-md"
                          />
                        )}
                    </Grid>
                  </Grid>
                </Card>

                {actionButton && (
                  <Box className="rowContainer">
                    <Grid container>
                      <Grid item xs={6} lg={6}>
                        <ButtonComponent
                          className="btn-primary btn-cancel mr-md"
                          variantType="contained"
                          labelId="Save as Draft"
                          defaultLabelId="Save as Draft"
                          onClick={() => {
                            handleDraft(values);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Box
                          component="div"
                          className="flex__ text-align-end justifyContent-FlexEnd"
                        >
                          <ButtonComponent
                            className="btn-primary btn-cancel btn_width_90px mr-md"
                            variantType="contained"
                            labelId="Clientpage.cancelbtn"
                            defaultLabelId="Cancel"
                            onClick={() => {
                              if (_.isEqual(initialValues, values)) {
                                navigate(REFERRAL_DASHBOARD_ROUTE_NAME);
                              } else {
                                setOpenModal(true);
                              }
                            }}
                          />

                          <ButtonComponent
                            className="btn-primary btn-submit"
                            variantType="contained"
                            labelId="addAsClientText"
                            defaultLabelId="Add Referral"
                            onClick={() => {
                              handleSubmit();
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            )}
          </Formik>
        </Box>

        <ModalPopup
          description="formUnsavedChangesMessage"
          open={openModal}
          onCancel={() => {
            setOpenModal(false);
          }}
          onOk={() => {
            formRef.current?.resetForm();
            setOpenModal(false);
            navigate(-1);
          }}
          labelId1="Clientpage.Nobtn"
          negativeActionLabel="No"
          labelId2="Clientpage.Yesbtn"
          positiveActionLabel="Yes"
        />
      </Box>
    </Box>
  );
};

export default AddNewReferralDetails;
